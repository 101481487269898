// converts the <kebab case>theme key name to an appropriate css variable name
export function getCssKeyName(key: string, suffix: string = "host-theme") {
    return `--${key}-${suffix}`;
}

// Sets a document css variable to the supplied value. Will not check for key format validity
export function setCssVar(key: string, val: string) {
    document.documentElement.style.setProperty(key, val);
}

// Reads a given document css variable property and returns it.
export function readCssVar(key: string) {
    return typeof getComputedStyle !== "undefined"
        ? getComputedStyle(document.body).getPropertyValue(key)
        : "";
}
