import { IWatermark } from "@Modules/common/Watermark/IWatermark";
import {
    LISTING_TYPES,
    ADDRESS_TYPE as _ADDRESS_TYPE,
} from "repoV2/constants/listing";
import {
    DISCOUNT_OFF_TYPES as _DISCOUNT_OFF_TYPES,
    LISTING_ENUM,
    LISTING_STATUSES,
    QUESTION_PLACEMENT as _QUESTION_PLACEMENT,
} from "repoV2/features/Listing/Listing.constants";
import {
    INDIA_COUNTRY_DATA,
    USA_COUNTRY_DATA,
} from "repoV2/utils/common/location/countriesData";
import { getCountryIsIndia } from "repoV2/utils/common/location/location";

/**
 * @deprecated use `COUNTRIES`
 */
// eslint-disable-next-line prefer-destructuring
export const UNITED_STATES = USA_COUNTRY_DATA.name;

/**
 * @deprecated use `COUNTRIES`
 */
// eslint-disable-next-line prefer-destructuring
export const INDIA = INDIA_COUNTRY_DATA.name;

/**
 * @deprecated use `getCountryIsIndia`
 */
// Is lowercase. Always turn the compared string to lowercase for standardization
export const isIndia = getCountryIsIndia;

/**
 * @deprecated use `LISTING_TYPES`
 */
export const EVENT_TYPE: {
    [k in
        | "APPOINTMENTS"
        | "ONE_ON_ONE_CLASSES"
        | "GROUP_CLASSES"
        | "WORKSHOPS"
        | "NO_SCHEDULE"
        | "RECORDED_CONTENT"
        | "MERCHANDISE"
        | "ROLLING_CLASSES"
        | "TELEGRAM"
        | "WHATSAPP"
        | "PLANS"
        | "WEBINAR"
        | "BRANDED_COMMUNITY"]: number;
} = LISTING_TYPES;

export const EVENT_CATEGORISATION_TYPE = {
    SKU_TYPE: 1, // Categorise By SKU type
    SKU_TYPE_KEYWORDS: 2, // Categorise By SKU type and sub-categorisation by keywords
    KEYWORDS_SKU_TYPE: 3, // Categorisation by keywords, with SKU type tags on listing cards
    NO_CATEGORISATION: 4,
    KEYWORDS: 5, // Categorisation by keywords
};

/**
 * @deprecated use `ADDRESS_TYPE` in `repoV2/constants/listing.ts`
 */
export const ADDRESS_TYPE = _ADDRESS_TYPE;

/**
 * @deprecated use `LISTING_ENUM` in repoV2/constants/listing.ts
 */
export const EVENT_ENUM = LISTING_ENUM;

export const DISCOUNT_CODE_TYPES = {
    NOT_APPLIED: 0,
    NORMAL: 1,
    LOYALTY: 2,
};

/**
 * @deprecated use DISCOUNT_OFF_TYPES in repoV2/features/Listing/Listing.constants.ts
 */
export const DISCOUNT_OFF_TYPES = _DISCOUNT_OFF_TYPES;

export const EVENT_LISTING_CARD_CTA = {
    EXPRESS_INTEREST: "Express Interest",
    BUY_NOW: "Buy Now!",
    BOOK_A_SPOT: "Book a Spot",
    SUBSCRIBE_NOW: "Subscribe Now",
};

export const EXPRESS_INTEREST_MESSAGE_BEHAVIOR = {
    MANDATORY: 1,
    OPTIONAL: 2,
    HIDDEN: 3,
};

export const VIDEO_CALL_ENUM: {
    [k: string]: { id: string; title: string; domain: string };
} = {
    google_duo: {
        id: "google_duo",
        title: "Google Duo",
        domain: "duo.google.com",
    },
    google_meet: {
        id: "google_meet",
        title: "Google Meet",
        domain: "meet.google.com",
    },
    ms_teams: {
        id: "ms_teams",
        title: "Microsoft Teams",
        domain: "teams.microsoft.com",
    },
    skype: {
        id: "skype",
        title: "Skype",
        domain: "join.skype.com",
    },
    webex: {
        id: "webex",
        title: "Cisco Webex",
        domain: "webex.com",
    },
    zoom: {
        id: "zoom",
        title: "Zoom",
        domain: "us04web.zoom.us",
    },
};

/**
 * @deprecated
 * use `LISTING_STATUSES` from `repoV2/constants/listing.ts`
 */
export const EVENT_STATUS: {
    [k in "DRAFT" | "LIVE" | "SOLD_OUT" | "EXPIRED"]: number;
} = LISTING_STATUSES;

export type IFeedbackCustomQnTypeKeys = "TEXT" | "SINGLE" | "MULTI" | "EMOJI";
export type IFeedbackCustomQnTypeValues = 1 | 2 | 3 | 4;

export const FEEDBACK_CUSTOM_QUESTION_TYPES: Record<
    IFeedbackCustomQnTypeKeys,
    IFeedbackCustomQnTypeValues
> = {
    TEXT: 1,
    SINGLE: 2,
    MULTI: 3,
    EMOJI: 4,
};

export const WATERMARK_TYPES: IWatermark.IWatermarkTypes = {
    STATIC: 1,
    DYNAMIC: 2,
};

/**
 * @deprecated Please use the one defined at repoV2/features/Listing/Listing.constants.ts
 */
export const QUESTION_PLACEMENT = _QUESTION_PLACEMENT;

export const AVAILABILITY_CHOICE = {
    NOT_DECIDED: 4,
};

export const LISTING_MISCELLANEOUS_DATA_TYPES = {
    requirements: { value: 1, key: "requirements", title: "Requirements" },
    highlights: { value: 2, key: "highlights", title: "Highlights" },
    reviews: { value: 3, key: "reviews", title: "reviews" },
    order_bumps: { value: 4, key: "order_bumps", title: "Order Bumps" },
};

export const LISTING_REVIEW_STATUS = {
    draft: 1,
    live: 2,
    hidden: 3,
};

export const JOIN_MEETING: string = "Join Meeting";
export const FAVICON_ICON_ROUTE = "/static/images/favicon.ico";

export const COURSE_END_CHOICE_ENUM: {
    [k: number]: { value: number; label: string };
} = {
    3: { value: 3, label: "Days" },
    1: { value: 1, label: "Weeks" },
    2: { value: 2, label: "Months" },
};

export const NEED_USER_INPUT_TO_PROCEED_KEY = "needUserInputToProceedData";
