/**
 * check if object has no keys
 */
export function checkIsObjectEmpty(obj: any) {
    return (
        !obj ||
        (obj.constructor === String && obj.length === 0) ||
        (Array.isArray(obj) && obj.length === 0) ||
        (obj.constructor === Object && Object.keys(obj).length === 0)
    );
}
