import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { CALL_API_METHODS } from "repoV2/constants/apis";
import { NODE_ENV_IS_NOT_PRODUCTION } from "repoV2/constants/environment";
import { getBrowserTimezone } from "@Utils/getBrowserTimezone";
import { getTimezone } from "@Utils/getTimezone";
import {
    logoutUser,
    shouldRelogin,
} from "repoV2/utils/user&auth&login/login&logout";
import {
    APP_ROUTE_KEYS,
    NEXT_ROUTE_PATHNAMES,
} from "repoV2/constants/urls&routing/routing";
import { appendURLSearchParams } from "repoV2/utils/urls&routing/urlParams";
import { URL_SEARCH_PARAMS } from "repoV2/constants/urls&routing/urlParams";
import { IAPICall } from "./constants/api";

const BASE_URL = `${process.env.NEXT_PUBLIC_API_URL}`;

export interface IResponse extends AxiosResponse {
    // TODO: Add types here after API revamp
    data: any;
    [k: string]: any;
}

/*
Note:
The best way to encode the uri params is to pass them as the object in queryParams
and axios takes care of the rest.

If you manually append them in the url, you need to encode each param value like this
`https://www.website.com?param1=${encodeURIComponent(param1Value)&param2=${encodeURIComponent(param2Value)}`
*/

export async function ApiCall({
    url,
    method = CALL_API_METHODS.GET,
    payload,
    queryParams = {},
    baseURL = BASE_URL,
    configOptions = {},
    headers = {},
    responseType,
}: IAPICall.IParams) {
    // let percentCompleted;

    const config: AxiosRequestConfig = {
        baseURL,
        url,
        method,
        headers: {
            // `configOptions.disableTimezoneHeaders` is not true
            ...(!configOptions?.disableTimezoneHeaders &&
                // headers do not contain user-timezone AND system-timezone
                !(
                    "user-timezone" in headers && "system-timezone" in headers
                ) && {
                    "user-timezone": getTimezone().timezone,
                    "system-timezone": getBrowserTimezone(),
                }),
            // The timezone headers are placed before general headers so as to give preference
            // to custom timezone headers passed to the function over the fallback implemented here

            ...headers,

            ...(!configOptions?.disableAuthHeaders && {
                "content-type": "application/json",
                "auth-token": process.env.NEXT_PUBLIC_AUTH_TOKEN,
                "scoot-origin": "web_app",
            }),
        },
        // @dev This is explicitly done for GCP Server setup for webapp as
        // there was issue with GCP load balancer(LB) returning 400 BAD REQUEST Error
        // When GET request is made with this data key having either
        // value/{} empty-object/null/undefined(so GCP LB wants GET Request
        // without data/payload) so to solve this data(payload) will be
        // sent only in case of method other than GET
        // issue thread: https://issuetracker.google.com/issues/36886282
        ...(method !== CALL_API_METHODS.GET && { data: payload }),
        params: queryParams,
        validateStatus: (status: number) => status < 500,
        responseType,
        //     onUploadProgress: progressEvent => {
        //         percentCompleted = Math.round(
        //             (progressEvent.loaded * 100) / progressEvent.total
        //         );
        //     },
    };
    // console.log({percentCompleted})
    // console.log("API Call to", { baseURL, url: config.url, configOptions });
    // console.log("Params: ", { url, method, payload, queryParams });
    const request = await axios(config)
        .then((response: IResponse) => {
            // console.log("API Call Success: ", config.url, response.status);

            if (
                configOptions?.shouldRelogin && // if this request requires to relogin if the access token is expired or absent
                shouldRelogin(response?.data?.status)
            ) {
                logoutUser();

                const urlToRedirect: string =
                    NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.LOGIN];

                window.location.href = appendURLSearchParams(urlToRedirect, {
                    [URL_SEARCH_PARAMS.NEXT]: encodeURIComponent(
                        window.location.href
                    ),
                });
            }

            return response;
        })
        .catch((error: AxiosError) => {
            if (NODE_ENV_IS_NOT_PRODUCTION) {
                console.log("API Call Error: ", {
                    url: config.url,
                    headers: config.headers,
                    message: error.message,
                });
            }
            // console.log("Error: ", error);
            return error;
        });

    return request;
}

// TODO: create asyncAPIDispatch method here
