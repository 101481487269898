/* eslint-disable react/button-has-type */
import { BUTTON_DESIGNS } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import styles from "./Button.module.scss";

/**
 * @deprecated - use `repoV2/components/common/Button/index.tsx`
 */
export const Button = ({
    content,
    buttonType,
    onClick,
    design,
    className: customClass,
    disabled,
    ...buttonProps
}: {
    content: string | JSX.Element;
    buttonType?: "button" | "reset" | "submit";
    onClick?: (params?: any) => void;
    design: number;
    className?: string;
    disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const onClickFunction = disabled ? () => {} : onClick;
    const className = classNames(
        styles.button,
        disabled && styles.disabledButton,
        customClass
    );

    switch (design) {
        case BUTTON_DESIGNS.ROUNDED_CORNERS:
            return (
                <button
                    type={buttonType}
                    onClick={onClickFunction}
                    className={classNames(
                        styles.roundedCornersButton,
                        className
                    )}
                    {...buttonProps}
                >
                    {content}
                </button>
            );
        case BUTTON_DESIGNS.PILL:
            return (
                <button
                    type={buttonType}
                    onClick={onClickFunction}
                    className={classNames(styles.pillButton, className)}
                    {...buttonProps}
                >
                    {content}
                </button>
            );
        case BUTTON_DESIGNS.PLAIN_TEXT:
            return (
                <button
                    type={buttonType}
                    onClick={onClickFunction}
                    className={classNames(styles.plainTextButton, className)}
                    {...buttonProps}
                >
                    {content}
                </button>
            );
        default:
            return null;
    }
};

Button.defaultProps = {
    onClick: undefined,
    buttonType: "button",
    className: undefined,
    disabled: undefined,
};
