import dynamic from "next/dynamic";

// import Modal from "./root";

const Share = dynamic(() => import("./Share"));
const Reviews = dynamic(() => import("./Reviews"));
const SoldOut = dynamic(() => import("./SoldOut"));
const SocialShare = dynamic(() => import("./SocialShare"));
const UserDetails = dynamic(() => import("./UserDetails"));
const GetInTouch = dynamic(() => import("./GetInTouch"));
const PreviewPopup = dynamic(() => import("./PreviewPopup"));
const ClassicGallery = dynamic(() => import("./ClassicGallery"));
const ExitIntentForm = dynamic(() => import("./ExitIntentForm"));
const ModernTestimonial = dynamic(() => import("./ModernTestimonial"));
const EventModalWrapper = dynamic(() => import("./EventModalWrapper"));

const PaymentStatus = dynamic(() => import("./PaymentStatus"));

const PaymentNotification = dynamic(() => import("./PaymentNotification"));
const PaymentSelection = dynamic(() => import("./PaymentSelection"));
const ExitOffer = dynamic(() => import("./ExitOffer"));

export {
    // Modal,
    Share,
    Reviews,
    SoldOut,
    SocialShare,
    UserDetails,
    GetInTouch,
    PreviewPopup,
    ClassicGallery,
    ExitIntentForm,
    ModernTestimonial,
    EventModalWrapper,
    PaymentStatus,
    PaymentNotification,
    PaymentSelection,
    ExitOffer,
};
