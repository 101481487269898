import { TEMPLATE_KEYS } from "@Utils";
import { INavbar } from "../INavbar";
import {
    NavbarElementary,
    NavbarClassic,
    NavbarUnfold,
    NavbarLinktemp,
    NavbarYoga,
    NavbarEduverse,
    NavbarGrowth,
    NavbarUnlock,
} from "../templates";

export const getNavbarTemplate = ({
    template,
}: {
    template?: string;
}): React.ComponentType<INavbar.IChildProps> => {
    let NavbarTemplate: React.ComponentType<INavbar.IChildProps>;
    switch (template) {
        case TEMPLATE_KEYS.ELEMENTARY:
            NavbarTemplate = NavbarElementary;
            break;
        case TEMPLATE_KEYS.UNFOLD:
            NavbarTemplate = NavbarUnfold;
            break;
        case TEMPLATE_KEYS.LINKTEMP:
            NavbarTemplate = NavbarLinktemp;
            break;
        case TEMPLATE_KEYS.EDUVERSE:
            NavbarTemplate = NavbarEduverse;
            break;
        case TEMPLATE_KEYS.GROWTH:
            NavbarTemplate = NavbarGrowth;
            break;
        case TEMPLATE_KEYS.YOGA:
            NavbarTemplate = NavbarYoga;
            break;
        case TEMPLATE_KEYS.UNLOCK:
            NavbarTemplate = NavbarUnlock;
            break;
        case TEMPLATE_KEYS.MODERN:
        case TEMPLATE_KEYS.CLASSIC:
        default:
            NavbarTemplate = NavbarClassic;
            break;
    }

    return NavbarTemplate;
};
