import { CUSTOM_CODES_ACTION_TYPES } from "../constants";
import {
    ICustomCodeAction,
    // ICustomCodeActionPayload,
    ICustomCodeState,
} from "../interfaces";

export const custom_code_initial_state = {
    codes: [],
};

export default function customCodeReducer(
    state: ICustomCodeState = custom_code_initial_state,
    action: ICustomCodeAction
) {
    const { type, payload } = action;
    switch (type) {
        case CUSTOM_CODES_ACTION_TYPES.SET: {
            return {
                ...state,
                codes: payload?.customCodes,
            };
        }
        default:
            return state;
    }
}
