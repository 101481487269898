import { ICartPage, IEvent, IHost } from "@Interfaces";
import { CURRENCY_SYMBOLS } from "repoV2/constants/payment";
import {
    removeSessionStorageItem,
    setSessionStorageItem,
} from "../repoV2/utils/common/storage/getterAndSetters";
import { isEmpty } from "./common";
import { EVENT_TYPE, STORAGE_KEYS } from "./constants";
import { getIfSlotsAvailable } from "./event";

export const canSetCartItemQuantity = ({
    eventData,
    newQuantity,
}: {
    eventData: IEvent.IEventData;
    newQuantity: number;
}) =>
    [EVENT_TYPE.MERCHANDISE].includes(eventData.type) &&
    getIfSlotsAvailable({
        eventData,
        merchQuantity: newQuantity,
    });

export const canAddItemToCart = ({
    cartCurrencySymbol,
    eventData,
}: {
    cartCurrencySymbol: string;
    eventData: IEvent.IEventData | IHost.IEventData;
}) => {
    return cartCurrencySymbol === (eventData?.currency || CURRENCY_SYMBOLS.INR);
};

export const DEFAULT_CART_DATA: ICartPage.ICart = {
    listingMap: {},
    currency: {},
};

export const setSessionStorageCart = (newCartData: ICartPage.ICart) => {
    if (isEmpty(Object.keys(newCartData?.listingMap || {}))) {
        removeSessionStorageItem(STORAGE_KEYS.CART);
    } else {
        setSessionStorageItem(STORAGE_KEYS.CART, newCartData);
    }
};
