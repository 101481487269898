import axios, { AxiosRequestConfig } from "axios";
import { CALL_API_METHODS } from "../../../constants/apis";
import {
    getSystemTimezone,
    getUserTimezone,
} from "../../../features/Common/modules/Timezone/Timezone.utils";
import { getUserAccessToken } from "../../user&auth&login/authTokens";

type ICallApiArgs = {
    url: string;
    method?: AxiosRequestConfig["method"];
    params?: AxiosRequestConfig["params"];
    data?: AxiosRequestConfig["data"];
    headers?: AxiosRequestConfig["headers"];
    signal?: AbortSignal;
    extraOptions?: {
        appendExlyBackendHeaders?: boolean;
        appendTimezoneHeaders?: boolean;
        appendAuthHeaders?: boolean;
        appendExlyOriginHeaders?: boolean;
        appendJwtTokenHeaders?: boolean;
    };
};

/* appends TimezoneHeaders, AuthHeaders, ExlyOriginHeaders, JwtTokenHeader */
/**
 * @param extraOptions.appendExlyBackendHeaders - if `true` appends appends TimezoneHeaders, AuthHeaders, ExlyOriginHeaders, JwtTokenHeaders
 */
export const callApi = ({
    url,
    method = CALL_API_METHODS.GET,
    params,
    data,
    headers: headersArg = {},
    extraOptions = {},
    signal,
}: ICallApiArgs) => {
    const headers = headersArg;
    if (!headers["content-type"]) {
        headers["content-type"] = "application/json";
    }
    if (
        extraOptions.appendExlyBackendHeaders ||
        extraOptions.appendTimezoneHeaders
    ) {
        headers["user-timezone"] = getUserTimezone();
        headers["system-timezone"] = getSystemTimezone();
    }
    if (
        extraOptions.appendExlyBackendHeaders ||
        extraOptions.appendAuthHeaders
    ) {
        headers["auth-token"] = process.env.NEXT_PUBLIC_AUTH_TOKEN || "";
    }
    if (
        extraOptions.appendExlyBackendHeaders ||
        extraOptions.appendExlyOriginHeaders
    ) {
        headers["scoot-origin"] = "web_app";
    }

    const userAccessToken = getUserAccessToken();
    if (
        userAccessToken &&
        (extraOptions.appendExlyBackendHeaders ||
            extraOptions.appendJwtTokenHeaders)
    ) {
        headers["jwt-token"] = userAccessToken;
    }

    const config: AxiosRequestConfig = {
        url,
        method,
        headers,
        // @dev This is explicitly done for GCP Server setup for webapp as
        // there was issue with GCP load balancer(LB) returning 400 BAD REQUEST Error
        // When GET request is made with this data key having either
        // value/{} empty-object/null/undefined(so GCP LB wants GET Request
        // without data/payload) so to solve this data(payload) will be
        // sent only in case of method other than GET
        // issue thread: https://issuetracker.google.com/issues/36886282
        ...(method !== CALL_API_METHODS.GET && { data }),
        params,
        signal,
    };

    return axios(config);
};
