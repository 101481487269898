import { IUser } from "@Interfaces";
import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import { FormField } from "../FormField";
import styles from "./formLine.module.scss";

export const FormLine = ({
    field,
    preField,
    classNames: classNameProps,
    fieldMethodsExtraData,
}: {
    field: IUser.IFormField;
    preField?: IUser.IFormField;
    classNames?: {
        container?: string;
        inputContainer?: string;
        label?: string;
        errorMessage?: string;
    };
    fieldMethodsExtraData?: { [k: string]: any };
}) => {
    if (field.hidden?.()) return null;

    return (
        <div className={classNameProps?.container}>
            <div className={classNameProps?.label}>
                {field.label}
                <span className={styles.red}>
                    {field.mandatory?.(field.value, fieldMethodsExtraData)
                        ? " *"
                        : ""}
                </span>
            </div>
            <div
                className={classNames(
                    styles.formFieldLine,
                    classNameProps?.inputContainer
                )}
            >
                {/**
                 * send className and style for field and preField in their respective fields prop.
                 * To understand how that will work, read `FormField` component code.
                 */}
                {preField ? <FormField field={preField} /> : null}
                <FormField field={field} />
            </div>
            <div
                className={classNames(classNameProps?.errorMessage, styles.red)}
            >
                {(field.showError && field.errorMessage) || <></>}
            </div>
        </div>
    );
};

FormLine.defaultProps = {
    preField: undefined,
    classNames: undefined,
    fieldMethodsExtraData: undefined,
};
