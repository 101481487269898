import React from "react";

export const QuoteBeginIconSvg = (props: any): JSX.Element => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="50.198"
            height="43.691"
            viewBox="0 0 50.198 43.691"
        >
            <path
                id="Path_1537"
                data-name="Path 1537"
                d="M55.658-55.988a14,14,0,0,0-7.049,5.268,14.886,14.886,0,0,0-2.4,8.521h9.451v20.916H36.291V-42.2a20.737,20.737,0,0,1,1.549-8.056,22.342,22.342,0,0,1,4.183-6.585,25.73,25.73,0,0,1,6.12-4.88,36.3,36.3,0,0,1,7.514-3.254Zm-30.831,0a14,14,0,0,0-7.049,5.268,14.886,14.886,0,0,0-2.4,8.521h9.451v20.916H5.46V-42.2a20.737,20.737,0,0,1,1.549-8.056,22.342,22.342,0,0,1,4.183-6.585,25.73,25.73,0,0,1,6.12-4.88,36.3,36.3,0,0,1,7.514-3.254Z"
                transform="translate(-5.46 64.974)"
                fill="#000000"
            />
        </svg>
    );
};
