import { useState } from "react";

export module IPagination {
    export type IProps<T> = [
        Array<T>,
        {
            stepSize?: number;
            baseLength?: number;
        }?
    ];
    export type IReturnProps<T> = [
        Array<T>,
        {
            handleShowMore: () => void;
            handleShowLess: () => void;
            allShown: boolean;
        }
    ];
}

const INITIAL_LEN = 6;
const STEP_SIZE = 3;

/**
 * Hook with ts generic that manages state and provides handlers to truncate and incrementally expand a list of data
 * @param list the array to be truncated
 * @param config is the configuration of the truncation with params `stepSize` and `baseLength`
 * @returns
 */
export function useListTruncation<T = any>(
    ...props: IPagination.IProps<T>
): IPagination.IReturnProps<T> {
    const [list = [], { stepSize = STEP_SIZE, baseLength = INITIAL_LEN } = {}] =
        props;
    const [pageLen, setPageLen] = useState(baseLength);

    const truncatedList = list.slice(0, pageLen);

    const allShown = pageLen >= list.length;

    const handleShowLess = () => {
        setPageLen(baseLength);
    };

    const handleShowMore = () => {
        if (allShown) return;

        setPageLen(pageLenProp => pageLenProp + stepSize);
    };
    return [
        truncatedList,
        {
            handleShowMore,
            handleShowLess,
            allShown,
        },
    ];
}
