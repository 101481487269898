import * as Sentry from "@sentry/nextjs";
// import {
//     ExtraErrorData,
//     CaptureConsole,
//     Debug,
//     Offline,
// } from "@sentry/integrations";

const sentryEnv = process.env.NEXT_PUBLIC_SENTRY_ENV || "";

const envs_to_log = ["production"];

// only log in the above envs
if (envs_to_log.includes(sentryEnv)) {
    Sentry.init({
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        environment: sentryEnv,
        tracesSampleRate: 1.0,
        integrations: [
            /**
             * https://docs.sentry.io/platforms/javascript/guides/nextjs/session-replay
             * For this integration to work, the minimum version required for the "@sentry/nextjs" is 7.27.0 .
             * Currently we are running 7.12.1 .
             * Reason: nextjs throws `TypeError: Cannot read property 'getInitialProps' of undefined` if higher version is used
             * Resources:
             *      - https://github.com/getsentry/sentry-javascript/issues/5978#issuecomment-1331827888
             *      - https://github.com/vercel/next.js/issues/36221#issuecomment-1306355929
            new Sentry.Replay({
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1,
            }),
             */
            /**
             * https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/integrations/plugin/#extraerrordata
             * Limit of how deep the object serializer should go. Anything deeper than limit will
             * be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
             * a primitive value. Defaults to 3.
                new ExtraErrorData({ depth: number }),
             */
            /**
             * https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/integrations/plugin/#captureconsole
             * console methods that should be captured in sentry
             * defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
                new CaptureConsole({ levels: string[] }),
             */
            /**
             * https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/integrations/plugin/#debug
             * allows you to inspect the contents of the event sent to sentry
                new Debug({
                    debugger: boolean; // trigger DevTools debugger instead of using console.log
                    stringify: boolean; // stringify event before passing it to console.log
                })
             */
            /**
             * https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/integrations/plugin/#offline
             * limit how many events will be saved locally. Defaults to 30.
                new Offline({ maxStoredEvents: number })
             */
        ],
    });
}
