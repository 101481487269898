import React from "react";

export const EmojiRating1Svg = (props: any = {}): JSX.Element => {
    const { fill } = props;
    return (
        <svg
            {...props}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 16C4.00119 12.2823 5.38295 8.69746 7.87732 5.94072C10.3717 3.18398 13.8009 1.45177 17.5 1.08C17.0017 1.02789 16.501 1.00119 16 1C12.0218 1 8.20644 2.58035 5.3934 5.3934C2.58035 8.20644 1 12.0218 1 16C1 19.9782 2.58035 23.7936 5.3934 26.6066C8.20644 29.4196 12.0218 31 16 31C16.501 30.9988 17.0017 30.9721 17.5 30.92C13.8009 30.5482 10.3717 28.816 7.87732 26.0593C5.38295 23.3025 4.00119 19.7177 4 16Z"
                fill="black"
                fillOpacity="0.1"
            />
            <path
                d="M16 32C12.8355 32 9.74207 31.0616 7.11088 29.3035C4.4797 27.5454 2.42894 25.0466 1.21793 22.1229C0.0069325 19.1993 -0.309921 15.9823 0.307443 12.8786C0.924806 9.77487 2.44866 6.92394 4.6863 4.6863C6.92394 2.44866 9.77487 0.924806 12.8786 0.307443C15.9823 -0.309921 19.1993 0.0069325 22.1229 1.21793C25.0466 2.42894 27.5454 4.4797 29.3035 7.11088C31.0616 9.74207 32 12.8355 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32ZM16 2.00001C13.2311 2.00001 10.5243 2.82109 8.22202 4.35943C5.91974 5.89777 4.12532 8.08427 3.06569 10.6424C2.00607 13.2006 1.72882 16.0155 2.26901 18.7313C2.80921 21.447 4.14258 23.9416 6.10051 25.8995C8.05845 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C30 12.287 28.525 8.72602 25.8995 6.10051C23.274 3.475 19.713 2.00001 16 2.00001Z"
                fill={fill || "#B3B3B3"}
            />
            <path
                d="M16 19C14.7998 18.9904 13.6196 19.3073 12.5857 19.9168C11.5517 20.5262 10.7029 21.4053 10.13 22.46C10.0644 22.5742 10.0219 22.7003 10.0049 22.831C9.98797 22.9616 9.99693 23.0944 10.0313 23.2216C10.0656 23.3488 10.1247 23.468 10.2052 23.5724C10.2856 23.6768 10.3858 23.7643 10.5 23.83C10.6143 23.8956 10.7403 23.9381 10.871 23.9551C11.0017 23.972 11.1344 23.9631 11.2617 23.9287C11.3889 23.8943 11.5081 23.8353 11.6125 23.7548C11.7168 23.6744 11.8044 23.5742 11.87 23.46C12.2782 22.7222 12.8765 22.1073 13.6028 21.6791C14.3292 21.2509 15.1569 21.025 16 21.025C16.8432 21.025 17.6709 21.2509 18.3972 21.6791C19.1235 22.1073 19.7218 22.7222 20.13 23.46C20.2169 23.6144 20.3432 23.7431 20.496 23.8327C20.6489 23.9223 20.8228 23.9697 21 23.97C21.1706 23.9696 21.3386 23.9284 21.49 23.85C21.7205 23.7197 21.8899 23.5034 21.9611 23.2485C22.0323 22.9936 21.9996 22.7208 21.87 22.49C21.3011 21.4297 20.454 20.5445 19.4198 19.9296C18.3855 19.3147 17.2033 18.9933 16 19Z"
                fill={fill || "#B3B3B3"}
            />
            <path
                d="M24.6201 10.82L24.4301 10.69C23.9231 10.3537 23.344 10.1417 22.7397 10.0711C22.1355 10.0005 21.5231 10.0733 20.9522 10.2837C20.3814 10.4942 19.8682 10.8362 19.4543 11.2821C19.0404 11.728 18.7375 12.2651 18.5701 12.85C18.5297 12.9397 18.4995 13.0336 18.4801 13.13C18.4077 13.4156 18.421 13.7161 18.5183 13.9941C18.6155 14.2721 18.7925 14.5154 19.0271 14.6935C19.2618 14.8716 19.5436 14.9766 19.8376 14.9956C20.1315 15.0145 20.4245 14.9465 20.6801 14.8C20.8525 14.7015 21.0034 14.5693 21.1236 14.4112C21.2439 14.2532 21.3311 14.0725 21.3801 13.88C21.4333 13.6914 21.4475 13.4939 21.4217 13.2996C21.3959 13.1053 21.3307 12.9183 21.2301 12.75C21.1849 12.6711 21.1313 12.5973 21.0701 12.53C21.3772 12.254 21.7686 12.09 22.1807 12.0646C22.5928 12.0392 23.0014 12.1539 23.3401 12.39L23.5301 12.51C23.751 12.6314 24.0097 12.6649 24.2542 12.6037C24.4988 12.5425 24.7112 12.3913 24.849 12.1802C24.9868 11.9691 25.0398 11.7137 24.9973 11.4652C24.9549 11.2167 24.8201 10.9934 24.6201 10.84V10.82Z"
                fill={fill || "#B3B3B3"}
            />
            <path
                d="M13.52 13.1401C13.4994 13.0549 13.4726 12.9714 13.44 12.8901C13.2767 12.3011 12.9762 11.7591 12.5631 11.3086C12.1501 10.8581 11.6361 10.5119 11.0635 10.2982C10.4908 10.0846 9.87569 10.0096 9.26848 10.0794C8.66126 10.1493 8.07922 10.362 7.57003 10.7001L7.38003 10.8301C7.17998 10.9835 7.04525 11.2067 7.00281 11.4552C6.96036 11.7037 7.01333 11.9591 7.15112 12.1702C7.2889 12.3813 7.50134 12.5326 7.74589 12.5937C7.99045 12.6549 8.24909 12.6214 8.47003 12.5001L8.67003 12.3701C9.00889 12.1377 9.41607 12.0262 9.82602 12.0534C10.236 12.0806 10.6248 12.245 10.93 12.5201C10.8689 12.5874 10.8152 12.6611 10.77 12.7401C10.6694 12.9083 10.6042 13.0953 10.5785 13.2896C10.5527 13.4839 10.5668 13.6814 10.62 13.8701C10.669 14.0625 10.7562 14.2432 10.8765 14.4012C10.9967 14.5593 11.1476 14.6915 11.32 14.7901C11.5456 14.9188 11.8003 14.9877 12.06 14.9901C12.3237 14.9912 12.583 14.9225 12.8114 14.7907C13.0397 14.6589 13.2291 14.4689 13.36 14.2401C13.4577 14.0762 13.5222 13.8948 13.5496 13.706C13.5771 13.5172 13.567 13.3249 13.52 13.1401Z"
                fill={fill || "#B3B3B3"}
            />
        </svg>
    );
};
