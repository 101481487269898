import { SELECTORS } from "@Redux";
import React, { useState } from "react";
import { IoCaretDownOutline } from "@react-icons/all-files/io5/IoCaretDownOutline";
import { useSelector } from "react-redux";
import { LoadingIndicator } from "@Modules/common";
import { LOADING_INDICATOR_DESIGNS } from "repoV2/components/common/LoadingIndicators/LoadingIndicator.data";
import { IReplySection } from "./IReplySection";
import styles from "../CommentsSection/commentSection.module.scss";
import Reply from "../Reply";

const ReplySection = ({
    commentId,
    commentUuid,
    replyCount,
    fetchReplies,
    commentColors,
    commenterName,
    componentParent,
}: IReplySection.IProps) => {
    const host = useSelector(SELECTORS.host);
    const replies = host?.commentReplies;
    const [showReplies, setShowReplies] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <div>
            {!showReplies ? (
                <div
                    onClick={() => {
                        setIsLoading(true);
                        fetchReplies(componentParent, commentId || commentUuid);
                        setShowReplies(true);
                        setIsLoading(false);
                    }}
                >
                    <IoCaretDownOutline className={styles.downarrow} />
                    {replyCount}
                    {replyCount === 1 ? `REPLY` : `REPLIES`}
                </div>
            ) : (
                <>
                    {isLoading ? (
                        <LoadingIndicator
                            applyTheme
                            design={LOADING_INDICATOR_DESIGNS.rotating_arcs}
                        />
                    ) : (
                        replies?.results?.map(reply => (
                            <Reply
                                reply={reply}
                                commenterName={commenterName}
                                commentColors={commentColors}
                            />
                        ))
                    )}
                </>
            )}
        </div>
    );
};

export default ReplySection;
