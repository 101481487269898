import React from "react";

import Toast from "./Toast";
import useToastMessage from "./useToastMessage";
import styles from "./index.module.scss";
import { IToast } from "./Toast.interface";

const ToastPopup = () => {
    const { toastList } = useToastMessage();

    return (
        <div className={styles.root}>
            <div className={styles.list}>
                {Object.values({ ...toastList })?.map(
                    (item: IToast.ToastData) => (
                        <div className={styles.toastContainer} key={item.id}>
                            <Toast {...item.props} onDelete={item.onDelete} />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default ToastPopup;
