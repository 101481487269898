import { useEffect, useRef } from "react";

// Use the previous value of the state
export function usePrevious<T = any>(value: T, updateCondition?: boolean) {
    const ref = useRef<T>(value);
    useEffect(() => {
        if (updateCondition ?? true) {
            ref.current = value;
        }
    });
    return ref.current;
}

// TODO: useData hook
