import { SESSION_STORAGE_KEYS } from "repoV2/constants/common/storage/sessionStorage";
import {
    APP_ROUTE_KEYS,
    ROUTE_BASE_PATHNAMES,
} from "repoV2/constants/urls&routing/routing";
import {
    getSessionStorageItem,
    setSessionStorageItem,
    removeSessionStorageItem,
} from "../storage/getterAndSetters";

const REFERRER_DOMAIN_BLACKLIST = [
    "",
    "api.razorpay.com",
    "host.myscoot.in",
    "alpha.myscoot.in",
];

/**
 * we only set referrer when its not in REFERRER_DOMAIN_BLACKLIST
 */
export const initAndGetReferrerWithLogic = (): string | null => {
    const existingReferrer = getSessionStorageItem(
        SESSION_STORAGE_KEYS.REFERER
    );
    const currentReferrer = document.referrer;

    let actualReferrer = existingReferrer;
    if (currentReferrer) {
        const referrerURLObj = new URL(currentReferrer);
        // Checking if the user is coming from thank you page
        const isReferrerThankyouPage = referrerURLObj.pathname.endsWith(
            ROUTE_BASE_PATHNAMES[APP_ROUTE_KEYS.THANKYOU_PURCHASE]
        );
        // if the payment was a failure or not
        const isPaymentFailureInReferrer =
            referrerURLObj.searchParams.get("status") === "8";
        // paymentPageReferrer - It is the url of the page through wihich payment (if any) was made by user
        const paymentPageReferrer = getSessionStorageItem(
            SESSION_STORAGE_KEYS.PAYMENT_PAGE_REFERRER
        );
        // Here, We make sure that
        // 1. User is not coming from thankyou page with payment failure case, reason - incase they fail to make payment
        // then they'll go back, so once again while making the payment we'll have to set the old referrer back
        // 2. The currentReferrer is not in REFERRER_DOMAIN_BLACKLIST
        // 3. And, SESSION_STORAGE_KEYS.PAYMENT_STATUS_GATEWAY_EXISTS is not true, meaning user is not coming
        // after making a payment (This one is so we'll not set the Payment Gateway URL as a referrer)
        if (
            !(isReferrerThankyouPage && isPaymentFailureInReferrer) && // Make sure user is not coming from a thankyou payment failure page
            !REFERRER_DOMAIN_BLACKLIST.includes(referrerURLObj.origin) &&
            getSessionStorageItem(
                SESSION_STORAGE_KEYS.PAYMENT_STATUS_GATEWAY_EXISTS
            ) !== true
        ) {
            // Updating the current referrer value
            setSessionStorageItem(
                SESSION_STORAGE_KEYS.REFERER,
                currentReferrer
            );
            actualReferrer = currentReferrer;
        }
        const currentURL = window.location.href;
        const parsedCurrentURL = new URL(currentURL);

        // Checking if the user is currently on thankyou page
        const isCurrentPageThankyouPage = parsedCurrentURL.pathname.endsWith(
            ROUTE_BASE_PATHNAMES[APP_ROUTE_KEYS.THANKYOU_PURCHASE]
        );

        // Checking if the payment is failure or not
        const isCurrentPaymentFailure =
            parsedCurrentURL.searchParams.get("status") === "8";

        // If the user is on thankyou page and the payment is not failure either, then we
        // set the referrer as the page from which payment was done (paymentPageReferrer, provided we have it)
        if (
            isCurrentPageThankyouPage &&
            !isCurrentPaymentFailure &&
            paymentPageReferrer
        ) {
            // Remove the SESSION_STORAGE_KEYS.PAYMENT_STATUS_GATEWAY_EXISTS key
            removeSessionStorageItem(
                SESSION_STORAGE_KEYS.PAYMENT_STATUS_GATEWAY_EXISTS
            );
            // Set the new referrer as the URL of the page from which payment was done
            setSessionStorageItem(
                SESSION_STORAGE_KEYS.REFERER,
                paymentPageReferrer
            );
            actualReferrer = paymentPageReferrer;
        }
    }
    return actualReferrer;
};
