import { LOCAL_STORAGE_KEYS as _LOCAL_STORAGE_KEYS } from "../../../features/Common/modules/Storage/Storage.constants";

/**
 * @deprecated use keys OR utils mentioned against each one
 */
export const LEGACY_LOCAL_STORAGE_KEYS = {
    // auth and logged in user data
    CUSTOMER_UUID: "username", // getLoggedInUserDetails in repoV2/utils/auth/loggedInUserDetails.ts
    EMAIL: "email", // getLoggedInUserDetails in repoV2/utils/auth/loggedInUserDetails.ts
    CUSTOMER_NAME: "customer_name", // getLoggedInUserDetails in repoV2/utils/auth/loggedInUserDetails.ts
};

/** @deprecated use from repoV2/features/Common/modules/Storage/Storage.constants */
export const LOCAL_STORAGE_KEYS = _LOCAL_STORAGE_KEYS;
