import {
    ILoadingIndicatorDesigns,
    ILoadingIndicatorProgressTypes,
} from "./LoadingIndeCators";

export const LOADING_INDICATOR_DESIGNS: Record<
    ILoadingIndicatorDesigns,
    ILoadingIndicatorDesigns
> = {
    progress_bar: "progress_bar",
    rotating_arcs: "rotating_arcs",
};

export const LOADING_INDICATOR_PROGRESS_TYPES: Record<
    ILoadingIndicatorProgressTypes,
    ILoadingIndicatorProgressTypes
> = {
    controlled: "controlled",
    auto: "auto",
};
