/* generic assets */
export const DEFAULT_PROFILE_PIC_SRC = "/static/images/emptyProfilePic.png";
export const DEFAULT_IMG_TESTIMONIAL_PLACEHOLDER =
    "/static/images/videoTestimonialPlaceholder.png";
export const BOUNDED_LIKE_IMAGE_SRC = "/static/images/bounded_like.svg";
export const PRICE_TAG_IMAGE_SRC = "/static/images/pricetag-discount.svg";
export const PDF_LOGO = "/static/images/pdf_file.svg";
/* feature specific assets */

// branded community
export const COMMUNITY_TAG_SRC =
    "/static/images/brandedCommunity/community_tag.png";
export const COMMUNITY_BLURRED_BG_SRC =
    "/static/images/brandedCommunity/blur_community_bg.jpg";
export const COMMUNITY_BLANK_SCREEN_SRC = // empty state image for bonus offerings or for feeds where only creator can post
    "/static/images/brandedCommunity/blank_screen.png";
export const SPLASH_SCREEN_SRC =
    "/static/images/brandedCommunity/splash_screen_branded.png";
export const WATCH_ICON_SRC = "/static/images/brandedCommunity/watch_icon.svg"; // NO MUI ICON HENCE USING SVG
export const PRIVATE_COMMUNITY_CHANNEL_LOCK_MESSAGE_SRC =
    "/static/images/brandedCommunity/private_community_channel_lock_message.png";

/**
 * @deprecated Please use the constant defined at repoV2/features/Listing/Listing.constants.ts
 */
// listing
export const DEFAULT_LISTING_COVER_IMG_SRC = `https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/000000000000000000000_event_images%2Fcategory%2Fdefault%2Ftes.jpeg?alt=media&token=a4cb0783-5e41-4443-892f-7cafbdc9b201`;
