export const youtube_video_url_formats = [
    "https://www.youtube.com/watch?v=",
    "https://www.youtube.com/shorts/",
    "https://youtu.be/",
];

export const vimeo_video_url_formats = [
    "https://vimeo.com/",
    "https://player.vimeo.com/",
];

export const video_platforms_keys = {
    vimeo: "vimeo",
    wisita: "wisita",
};

export const video_platforms_oembed_api_urls = {
    [video_platforms_keys.vimeo]: "https://vimeo.com/api/oembed.json",
    [video_platforms_keys.wisita]: "https://fast.wistia.com/oembed",
};

export const URL_SEARCH_PARAMS = {
    INIT_CONTACT: "init_contact", // to show contact creator form on did mount of a page - boolean
    CWACCFSS: "cwaccfss", // "Close Window After Contact Creator Form Submit Success" - boolean
    icf_preview: "icf_preview", // to show contact creator form in preview mode
    ei_preview: "ei_preview", // to open exit intent form in preview mode
    PREFILL_FORM_DATA: "prefill_data", // used for creator app registration flow to prefill data in event page
    HIDE_LOGIN_CTA: "hide_login_cta", // used for creator app registration flow hide login cta in navbar
    HIDE_CONTACT_CTA: "hide_contact_cta", // used for creator app registration flow hide get in touch cta in navbar
    HIDE_WEBSITE_CTA: "hide_website_cta", // used for creator app registration flow hide visit website cta in event page
    HIDE_ADD_CART_CTA: "hide_add_cart_cta", // used for creator app registration flow hide add to cart cta in event page
    DISABLE_MULTI_BOOKING: "disable_multi_booking", // used for creator app registration flow to disabled multi users booking in event page

    // login
    NEXT: "next", // for redirecting to next url after login
    GOOGLE_AUTH_CODE: "code", // Google auth code received during login with Google
    GOOGLE_AUTH_ERROR: "error", // Google auth error received during login with Google
    GOOGLE_AUTH_STATE: "state", // Persisted state during login with Google

    // used for listing page
    DYNAMIC_LINK: "dynamic_link", // used to identify if its a custom payment page, contains custom payment page uuid
    INIT_BOOKING: "init_booking", // to initiate booking on did mount of event page - boolean
    COMPACT: "compact", // for redirection on the compact page
    PLAN_SELECTED_LISTED_ID: "plan_event_id", // used for plan selected event id in listing dropdown when slug(seo) is changed.
    RENEW_BOOKING: "renew_subscription", // true if event page is opened for renewal of offering type class
    PLAN_ID: "plan_id", // plan uuid on a listing page

    // used for recorded content
    CONTENT_ID: "content_id",
    HIDE_EXIT: "hide_exit",
    BOOKING_ID: "booking_id", // to fetch booking details, used in instalment payment in locked lesson when unlocking type is `post instalment payment`
    LIVE_SESSION_BOOKING: "live_session_booking", // denotes this offering is a live session in a recorded content course
    CONTENT_CATEGORY_ID: "content_cat", // comes from comms, section to be opened on initial load
    CONTENT_SUB_CATEGORY_ID: "content_sub_cat", // comes from comms, lesson to be opened on initial load
    MEDIA_ITEM_UUID: "miu", // denotes as content uuid of a any rc (listing -> section -> lesson -> content) or can be represented as media item uuid.
    QUESTIONNAIRE: "questionnaire",

    // branded community
    COMMUNITY_ID: "community", // branded community uuid. passed from bonus offerings buy now click in branded community

    // pay page
    CUSTOM_PRICE: "custom_price", // Custom price being paid
    EVENT_ID: "eventId", // ID of the event(for custom payment post-payment status display)
    LISTING_UUID: "listing_uuid", // selected listing in payment link (pay page)

    // TODO: figure out these keys
    FORCE_INTERNATIONAL: "force_intl", // [Dev only]: Force the currency to be USD...

    // instalments page
    TRANSACTION_STATUS: "status", // Success/Failure/Processing transaction status number
    TRANSACTION_ID: "transaction", // Transaction ID

    UTM_AFFILIATE: "utm_affiliate", // Traffic link Affiliate id/flag
    AFFILIATE_UUID: "affiliate_uuid", // affiliate_uuid to add unique view count

    FROM: "from", // The source route from which the user was redirected.
    // Used when user makes payment on one of the pages(event, checkout, pay) and then navigates
    // to payment gateway and then to thank you page. Redirection to the source route is done using this param

    SLUG: "slug", // slug passed from Sales and Thank You page

    CUSTOMER_NAME: "name", // The name of the customer to prefill into the booking form
    CUSTOMER_EMAIL: "email", // The email of the customer to prefill into the booking form
    CUSTOMER_PHONE: "phone", // The phone of the customer to prefill into the booking form
};
