import * as Sentry from "@sentry/nextjs";
import { EXTRA_FIELD } from "@Modules/modals/components/UserDetails/FormData";
import { isBrowser, STORAGE_KEYS } from "@Utils";
import { IUser } from "@Interfaces";
import {
    getUserRefreshToken as _getUserRefreshToken,
    getUserAccessToken as _getUserAccessToken,
} from "repoV2/utils/user&auth&login/authTokens";
import { setLoggedInUserDetails as setLoggedInUserDetailsBase } from "repoV2/utils/user&auth&login/loggedInUserDetails";
import {
    COUNTRIES_DATA_LIST,
    INDIA_COUNTRY_DATA,
} from "repoV2/utils/common/location/countriesData";
import { checkIsObjectEmpty } from "repoV2/utils/common/dataTypes/object";
import { logoutUser as _logoutUser } from "repoV2/utils/user&auth&login/login&logout";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "../repoV2/utils/common/storage/getterAndSetters";

export const getVisitorDetails = () => {
    const visitorDetails: {
        email?: null | string;
        name?: null | string;
        uuid?: null | string;
    } = {};

    if (isBrowser()) {
        visitorDetails.email = getLocalStorageItem(STORAGE_KEYS.VISITOR_EMAIL);
        visitorDetails.name = getLocalStorageItem(STORAGE_KEYS.VISITOR_NAME);
        visitorDetails.uuid = getLocalStorageItem(STORAGE_KEYS.VISITOR_UUID);
    }

    return visitorDetails;
};

export const setVisitorDetails = ({
    email,
    name,
    uuid,
}: {
    email?: string;
    name?: string;
    uuid?: string;
}) => {
    if (isBrowser() && email && name && uuid) {
        setLocalStorageItem(STORAGE_KEYS.VISITOR_EMAIL, email);
        setLocalStorageItem(STORAGE_KEYS.VISITOR_NAME, name);
        setLocalStorageItem(STORAGE_KEYS.VISITOR_UUID, uuid);
    }
};

export const formatUserPaymentAuthPayload = (data: IUser.ICustomerDetail) => {
    const formattedData: { [k: string]: any } = {
        ...data,
        ...(data?.[EXTRA_FIELD.gst_info_gst_number] &&
        data?.[EXTRA_FIELD.gst_info_gst_name]
            ? {
                  gst_info: {
                      gst_number: data?.[EXTRA_FIELD.gst_info_gst_number],
                      gst_name: data?.[EXTRA_FIELD.gst_info_gst_name],
                      gst_company_address: data?.[EXTRA_FIELD.gst_info_address],
                      pincode: data?.[EXTRA_FIELD.gst_info_pincode],
                  },
              }
            : {}),
    };
    delete formattedData[EXTRA_FIELD.gst_info_gst_number];
    delete formattedData[EXTRA_FIELD.gst_info_gst_name];
    delete formattedData[EXTRA_FIELD.gst_info_address];
    delete formattedData[EXTRA_FIELD.gst_info_pincode];

    return formattedData;
};

/**
 * @depricated use the function from `repoV2/utils/common/auth.ts
 */
export const getUserAccessToken = _getUserAccessToken;

/**
 * @depricated use the function from `repoV2/utils/common/auth.ts
 */
export const getUserRefreshToken = _getUserRefreshToken;

/**
 * @depricated use the function from `repoV2/utils/common/auth.ts
 */
export const logoutUser = _logoutUser;

type IOnLoginData = {
    customerUuid?: string;
    customerEmail?: string;
    customerName?: string;
    accessToken?: string;
    refreshToken?: string;
    countryCode?: string;
    phoneNumber?: string;
};
const on_login_param_keys = {
    customer_uuid: "customerUuid",
    customer_email: "customerEmail",
    customer_name: "customerName",
    access_token: "accessToken",
    refresh_token: "refreshToken",
    customer_phone_number: "phoneNumber",
    customer_country_code: "countryCode",
};

const setLoggedInUserDetails = async (params: IOnLoginData = {}) => {
    // async: because we dont need to wait for this function to complete

    // loginUser(parent calling function) can be called without any args
    // we dont want to set empty details
    if (checkIsObjectEmpty(params)) return;

    const countryCode =
        params[
            on_login_param_keys.customer_country_code as keyof typeof params
        ] || undefined;

    setLoggedInUserDetailsBase({
        uuid:
            params[on_login_param_keys.customer_uuid as keyof typeof params] ||
            undefined,
        email:
            params[on_login_param_keys.customer_email as keyof typeof params] ||
            undefined,
        fullName:
            params[on_login_param_keys.customer_name as keyof typeof params] ||
            undefined,
        countryCode,
        phone:
            params[
                on_login_param_keys.customer_phone_number as keyof typeof params
            ] || undefined,
        country:
            (countryCode &&
                COUNTRIES_DATA_LIST.find(
                    countryData => countryData.dial_code === countryCode
                )?.name) ||
            INDIA_COUNTRY_DATA.name,
    });
};

export const loginUser = (params: IOnLoginData = {}) => {
    setLoggedInUserDetails(params);

    const valuesObj: IOnLoginData = {};
    [
        [STORAGE_KEYS.CUSTOMER_UUID, on_login_param_keys.customer_uuid],
        [STORAGE_KEYS.EMAIL, on_login_param_keys.customer_email],
        [STORAGE_KEYS.CUSTOMER_NAME, on_login_param_keys.customer_name],
        [STORAGE_KEYS.ACCESS_TOKEN, on_login_param_keys.access_token],
        [STORAGE_KEYS.REFRESH_TOKEN, on_login_param_keys.refresh_token],
    ].forEach(([storageKey, paramKey]) => {
        let val = params[paramKey as keyof typeof params];
        if (val) {
            setLocalStorageItem(storageKey, val);
            valuesObj[paramKey as keyof typeof valuesObj] = val;
        } else {
            val = getLocalStorageItem(storageKey) || "";
        }
        valuesObj[paramKey as keyof typeof valuesObj] = val;
    });

    const {
        customerUuid,
        customerEmail,
        // customerName,
        // accessToken,
        // refreshToken,
    } = valuesObj;

    // set sentry user details
    Sentry.setUser({
        username: customerUuid,
        email: customerEmail,
    });
};
