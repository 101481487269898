import { IHost } from "@Interfaces";

export const BUTTON_DESIGNS = {
    ROUNDED_CORNERS: 1,
    PILL: 2,
    PLAIN_TEXT: 3,
};

export const GALLERY_TABS = {
    ALL: {
        key: 0,
        label: "All",
    },
    IMAGE: {
        key: 1,
        label: "Images",
    },
    VIDEOS: {
        key: 2,
        label: "Videos",
    },
};

export const GALLERY_FILTERS = {
    [GALLERY_TABS.ALL.key]: (item: IHost.IGalleryItem) => item,
    [GALLERY_TABS.IMAGE.key]: (item: IHost.IGalleryItem) =>
        ["photo", "image"].includes(item.type),
    [GALLERY_TABS.VIDEOS.key]: (item: IHost.IGalleryItem) =>
        ["video"].includes(item.type),
};

export const CONTACT_KEYS = {
    Whatsapp: "Whatsapp",
    Telegram: "Telegram",
};
