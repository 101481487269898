// @ts-ignore
import _ExlyImage from "@my-scoot/exly-react-component-lib/build/cjs/features/Common/modules/ExlyImage/ExlyImage";
import ExlyImageType from "@my-scoot/exly-react-component-lib/build/types/features/Common/modules/ExlyImage/ExlyImage";
import { IExlyImageProps } from "@my-scoot/exly-react-component-lib/build/types/features/Common/modules/ExlyImage/ExlyImage.interfaces";
import React from "react";
import { IMAGE_OPTIMIZATION_CDN_URL } from "./constants/ExlyImage.constants";

const ExlyBaseImage: typeof ExlyImageType = _ExlyImage;

type IProps = Omit<IExlyImageProps, "cdnBaseUrl">;
const ExlyImage = (props: IProps) => (
    <ExlyBaseImage cdnBaseUrl={IMAGE_OPTIMIZATION_CDN_URL} {...props} />
);
export default ExlyImage;
