/* eslint-disable no-case-declarations */
import { ACTION_TYPES } from "@Utils";
import { isDefined } from "repoV2/utils/common/dataTypes/common";
import { IAction, IUtils } from "@Interfaces";
import initialState from "../initialState";

export default function utilsReducer(
    state = initialState?.utils || {},
    { type, payload }: IAction<any>
): IUtils.IStore {
    switch (type) {
        case ACTION_TYPES.UTILS.UPDATE_API_CALL_STATUS:
            return {
                ...state,
                fetchStatus: {
                    ...state.fetchStatus,
                    ...payload,
                },
            };
        case ACTION_TYPES.UTILS.SET_CAN_RENDER:
            return {
                ...state,
                // If the action is called without the {canRender} param, just assume
                // it to be true. Helpful for using this action as a callback.
                canRender: isDefined(payload?.canRender)
                    ? payload.canRender
                    : true,
            };

        case ACTION_TYPES.UTILS.SET_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    ...payload,
                },
            };
        case ACTION_TYPES.UTILS.RESET_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    isModalOpen: false,
                    modalType: null,
                    modalProps: {},
                    isCloseable: true,
                },
            };
        case ACTION_TYPES.UTILS.START_PROCESSING:
            return {
                ...state,
                isProcessing: true,
            };
        case ACTION_TYPES.UTILS.STOP_PROCESSING:
            return {
                ...state,
                isProcessing: false,
            };
        case ACTION_TYPES.UTILS.SET_DATA:
            if (!payload?.key)
                // TODO: Decide if throwing an error is the best way to do this
                throw new Error(
                    `Must provide a key for ${ACTION_TYPES.UTILS.SET_DATA}`
                );

            return {
                ...state,
                data: {
                    ...state.data,
                    [payload?.key]: {
                        key: payload?.key,
                        value: payload?.value,
                        initiated: true,
                    },
                },
            };
        case ACTION_TYPES.UTILS.DELETE_DATA:
            const newData = { ...state.data };
            if (payload?.key) {
                delete newData[payload?.key];
            }
            return {
                ...state,
                data: newData,
            };
        case ACTION_TYPES.UTILS.UPDATE_COMMUNITY_APP_DATA:
            return {
                ...state,
                communityAppData: payload?.response?.data,
            };
        case ACTION_TYPES.UTILS.RESET_COMMUNITY_APP_DATA:
            return {
                ...state,
                communityAppData: null,
            };
        default:
            return state;
    }
}
