import { EXLY_BACKEND_API_HOST } from "repoV2/constants/apis";
import { getS3UploadUrl } from "../Common/modules/URLsNRouting/URLsNRouting.utils";

export const accepted_payments = [
    {
        id: 1,
        alt: "visa",
        src: getS3UploadUrl("webapp/payment_modes/visa.png"),
    },
    {
        id: 2,
        alt: "mastercard",
        src: getS3UploadUrl("webapp/payment_modes/mastercard.png"),
    },
    {
        id: 3,
        alt: "maestro",
        src: getS3UploadUrl("webapp/payment_modes/maestro.png"),
    },
    {
        id: 4,
        alt: "american express",
        src: getS3UploadUrl("webapp/payment_modes/amex.png"),
    },
    {
        id: 5,
        alt: "rupay",
        src: getS3UploadUrl("webapp/payment_modes/rupay.png"),
    },
];

export const ssl_checkout_asset = {
    src: getS3UploadUrl("webapp/payment_modes/ssl-checkout.svg"),
    alt: "ssl-checkout",
};

export const PAYMENT_GATEWAY_RENDER_DELAY = 30000;

export const GATEWAYS_ENUM = {
    PAYPAL: "Paypal",
    RAZORPAY: "Razorpay",
    NEW_JUSPAY: "NewJuspay",
    FREE: "free",
    STRIPE: "Stripe",
    TAZAPAY: "Tazapay",
} as const;

// Gateways supported in the standard transaction
export const GATEWAYS_SUPPORTED = [
    GATEWAYS_ENUM.RAZORPAY,
    GATEWAYS_ENUM.TAZAPAY,
    GATEWAYS_ENUM.NEW_JUSPAY,
    GATEWAYS_ENUM.STRIPE,
] as const;

export const DEFAULT_PAYMENT_GATEWAY = GATEWAYS_ENUM.NEW_JUSPAY;

// Default Payment Method value for payment method input
// Card here means we will use stripe payment method
export const DEFAULT_PAYMENT_METHOD = "Card";

export const PAYMENT_METHOD_OPTIONS = [
    DEFAULT_PAYMENT_METHOD,
    GATEWAYS_ENUM.PAYPAL,
];

/**
For multiple users in a booking, 1st user is `initial_user` and 
all susequent users are `multiple_user`.

For single user booking, the customer type is `initial_user`
 */
export const booking_customer_type = {
    initial_user: 1,
    multiple_user: 2,
};

export const PAYMENT_DEBIT_MODES = {
    PAYMENT_LINKS: 1,
    AUTO_DEBIT: 2,
};

export const PAYMENT_DEBIT_MODES_LABEL = {
    [PAYMENT_DEBIT_MODES.AUTO_DEBIT]: "Auto-debit",
    [PAYMENT_DEBIT_MODES.PAYMENT_LINKS]: "Payment links",
};

export const RAZOR_PAY_SOURCE_URL =
    "https://checkout.razorpay.com/v1/checkout.js";

export const INSTALLMENTS_DURATION_CHOICE = {
    DAYS: 1,
    WEEKS: 2,
    MONTHS: 3,
};

export const PAYMENT_CALCULATION_TYPE = {
    ONE_TIME_PAYMENT: 1,
    PART_PAYMENT: 2,
} as const;

export const PAYMENT_PAGES_TYPES = {
    CUSTOM: 1,
    OPEN: 2,
    UNIVERSAL: 3,
};

export const DEFAULT_PAYMENT_CARD_TYPE =
    PAYMENT_CALCULATION_TYPE.ONE_TIME_PAYMENT;

export const payment_api = {
    initiate_payment: `${EXLY_BACKEND_API_HOST}/payments/v2/order/initiate`,
    retry_payment: `${EXLY_BACKEND_API_HOST}/payments/order/action/rebook`,
    get_installment_details: `${EXLY_BACKEND_API_HOST}/payments/installment/details`,
    get_transaction_details: `${EXLY_BACKEND_API_HOST}/payments/exly/status`,
};
