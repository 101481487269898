import React from "react";

export const EmojiRating4Svg = (props: any = {}): JSX.Element => {
    const { fill } = props;
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 16C4.00119 12.2823 5.38295 8.69746 7.87732 5.94072C10.3717 3.18398 13.8009 1.45177 17.5 1.08C17.0017 1.02789 16.501 1.00119 16 1C12.0218 1 8.20644 2.58035 5.3934 5.3934C2.58035 8.20644 1 12.0218 1 16C1 19.9782 2.58035 23.7936 5.3934 26.6066C8.20644 29.4196 12.0218 31 16 31C16.501 30.9988 17.0017 30.9721 17.5 30.92C13.8009 30.5482 10.3717 28.816 7.87732 26.0593C5.38295 23.3025 4.00119 19.7177 4 16Z"
                fill="black"
                fillOpacity="0.1"
            />
            <path
                d="M16 32C12.8355 32 9.74207 31.0616 7.11088 29.3035C4.4797 27.5454 2.42894 25.0466 1.21793 22.1229C0.0069325 19.1993 -0.309921 15.9823 0.307443 12.8786C0.924806 9.77487 2.44866 6.92394 4.6863 4.6863C6.92394 2.44866 9.77487 0.924806 12.8786 0.307443C15.9823 -0.309921 19.1993 0.0069325 22.1229 1.21793C25.0466 2.42894 27.5454 4.4797 29.3035 7.11088C31.0616 9.74207 32 12.8355 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32ZM16 2.00001C13.2311 2.00001 10.5243 2.82109 8.22202 4.35943C5.91974 5.89777 4.12532 8.08427 3.06569 10.6424C2.00607 13.2006 1.72882 16.0155 2.26901 18.7313C2.80921 21.447 4.14258 23.9416 6.10051 25.8995C8.05845 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C30 12.287 28.525 8.72602 25.8995 6.10051C23.274 3.475 19.713 2.00001 16 2.00001Z"
                fill={fill || "#B3B3B3"}
            />
            <path
                d="M16 23.9999C15.4009 24.0056 14.8117 23.8143 14.2951 23.4464C13.7786 23.0785 13.354 22.5477 13.0667 21.9105C13.0004 21.7708 12.9827 21.6049 13.0173 21.4493C13.052 21.2937 13.1363 21.1611 13.2516 21.0808C13.3669 21.0005 13.5038 20.979 13.6322 21.021C13.7606 21.063 13.8699 21.1652 13.9362 21.3049C14.1402 21.7517 14.4392 22.1241 14.8021 22.3834C15.165 22.6428 15.5787 22.7795 16 22.7795C16.4213 22.7795 16.835 22.6428 17.1979 22.3834C17.5608 22.1241 17.8598 21.7517 18.0638 21.3049C18.0966 21.2357 18.1404 21.175 18.1925 21.1263C18.2447 21.0776 18.3043 21.0418 18.3678 21.021C18.4314 21.0002 18.4977 20.9948 18.563 21.005C18.6283 21.0153 18.6913 21.0411 18.7484 21.0808C18.8055 21.1206 18.8556 21.1736 18.8958 21.2368C18.936 21.3 18.9655 21.3722 18.9827 21.4493C18.9998 21.5263 19.0043 21.6067 18.9958 21.6858C18.9874 21.765 18.9661 21.8413 18.9333 21.9105C18.646 22.5477 18.2214 23.0785 17.7049 23.4464C17.1883 23.8143 16.5991 24.0056 16 23.9999Z"
                fill={fill || "#B3B3B3"}
            />
            <circle cx="10.5" cy="13.5" r="1.5" fill={fill || "#B3B3B3"} />
            <circle cx="21.5" cy="13.5" r="1.5" fill={fill || "#B3B3B3"} />
        </svg>
    );
};
