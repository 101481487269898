import { STORAGE_KEYS } from "@Utils";
import { ICartPage, IHost } from "@Interfaces";
import { DEFAULT_CART_DATA } from "@Utils/cart";
import { getSessionStorageItem } from "repoV2/utils/common/storage/getterAndSetters";
import { getQueryParam } from "repoV2/utils/urls&routing/urlParams";
import { URL_SEARCH_PARAMS } from "repoV2/constants/urls&routing/urlParams";
import { IEventModule } from "../root/IEventModule";

export const getCartData: () => ICartPage.ICart = () =>
    getSessionStorageItem(STORAGE_KEYS.CART) || DEFAULT_CART_DATA;

type IEvaluateHideAddToCartCta = {
    hostData?: IHost.IHostData;
    disablePayment?: boolean;
    dynamicLink?: string;
    isRebooking: boolean;
};
export const evaluateHideAddToCartCta = ({
    hostData = {} as IHost.IHostData,
    disablePayment,
    dynamicLink,
    isRebooking,
}: IEvaluateHideAddToCartCta) =>
    !hostData.is_cart_enabled ||
    disablePayment ||
    !!dynamicLink ||
    isRebooking ||
    Boolean(getQueryParam(URL_SEARCH_PARAMS.HIDE_ADD_CART_CTA));

export const getIsInCartFlow = ({
    userFlow,
    hostData = {} as IHost.IHostData,
    disablePayment,
    dynamicLink,
    isRebooking,
}: {
    userFlow: IEventModule.IProps["userFlow"];
} & IEvaluateHideAddToCartCta) =>
    (
        ["cart edit", "cart add"] as Array<IEventModule.IProps["userFlow"]>
    ).includes(userFlow) &&
    !evaluateHideAddToCartCta({
        hostData,
        disablePayment,
        dynamicLink,
        isRebooking,
    });
