// if: lines of code > 300
// then: breakdown this file and move to repoV2/features/OfferingVariationSelector/.constants folder

import { EXLY_BACKEND_API_HOST } from "repoV2/constants/apis";

export const VARIANT_TYPE_IDS = {
    default: 1,
    others: 2,
};

export const FETCH_OFFERING_VARIATIONS = `${EXLY_BACKEND_API_HOST}/host/offering/variant/details`;
