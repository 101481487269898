// @ts-nocheck
/* tslint:disable */
import { useToastContext } from "repoV2/components/common/Toast/ToastProvider";
import { isFunction } from "util";
import { IToast } from "./Toast.interface";

export const toast_type_keys = {
    info: "info",
    success: "success",
    error: "error",
    warning: "warning",
} as const;

export const toast_variant_keys = {
    default: "default",
    outlined: "outlined",
} as const;

const useToastMessage = () => {
    const { toastList, setToastList } = useToastContext();

    // Add toast to queue
    const pushToQueueAction = (payload: IToast.ToastListData) => {
        setToastList(prev => ({
            ...prev,
            ...payload,
        }));
    };

    // Remove toast from queue
    const popFromQueueAction = (payload: { id: string }) => {
        setToastList(prev => {
            const newPrev = { ...prev };
            delete newPrev[payload?.id];
            return {
                ...newPrev,
            };
        });
    };

    // Common function to delete toast based on id
    const deleteToastMessage = (toastId: string) => {
        popFromQueueAction({
            id: toastId,
        });
    };

    /**
     * @param {*} message content to display. Can be text or a JSX element
     * @param {*} options Configuring the toast
     * @param {*} options.id Has to be unique. If duplicate, will replace an old toast with the same ID and a reset timer.
     * @param {*} options.type Type of the <Toast /> component - success | info | warning | error
     * @param {*} options.variant Variant of the <Toast /> component - default | outlined
     * @param {*} options.timeout after what duration to remove the toast from the queue. Denoted in milliseconds
     * @param {*} options.onDelete after what duration to remove the toast from the queue. Denoted in milliseconds
     * @param {*} options.otherProps any other props for the <Toast /> component
     */

    const notify = (message: string, options: IToast.ToastOptions = {}) => {
        const toastData = {
            id: options?.id || `${Date.now()}`,
            timeout: options?.timeout ?? 5000,
            onDelete: options?.onDelete,
            props: {
                type: options?.type || "info",
                variant: options?.variant || "outlined",
                label: message,
                ...(options?.otherProps || {}),
            },
        };
        const { timeout, id } = toastData;
        const idCopy = `${id}`;

        pushToQueueAction({
            [id]: {
                ...toastData,
                onDelete: () => {
                    deleteToastMessage(toastData.id);
                    if (isFunction(toastData?.onDelete)) {
                        toastData.onDelete();
                    }
                },
            },
        });

        setTimeout(() => {
            deleteToastMessage(idCopy);
        }, timeout);

        return toastData;
    };

    return {
        toastList,
        notify,
        toast_type_keys,
        toast_variant_keys,
    };
};

export default useToastMessage;
