export const FILE_FORMATS = {
    // audio
    aac: "aac",
    m4a: "m4a",
    mp3: "mp3",
    wav: "wav",
    wma: "wma",

    // compressed files
    zip: "zip",

    // docs
    csv: "csv",
    doc: "doc",
    docx: "docx",
    pdf: "pdf",
    ppt: "ppt",
    xlsx: "xlsx",

    // images
    avif: "avif",
    heic: "heic",
    heif: "heif",
    jpeg: "jpeg",
    jpg: "jpg",
    png: "png",
    webp: "webp",
    gif: "gif",
    svg: "svg",
    tiff: "tiff",

    // video
    avi: "avi",
    mp4: "mp4",
    mpeg: "mpeg",
    ogv: "ogv",
    ts: "ts",
    webm: "webm",
    _3gp: "3gp",
    _3g2: "3g2",
};

// Docs format
export const FILE_MIME_DOCS_FORMAT = {
    [FILE_FORMATS.csv]: ["text/csv"],
    [FILE_FORMATS.doc]: ["application/msword"],
    [FILE_FORMATS.docx]: [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    [FILE_FORMATS.pdf]: ["application/pdf"],
    [FILE_FORMATS.ppt]: ["application/vnd.ms-powerpoint"],
    [FILE_FORMATS.xlsx]: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
};

// Image format
export const FILE_MIME_IMAGE_FORMAT = {
    [FILE_FORMATS.heic]: ["image/heic"],
    [FILE_FORMATS.heif]: ["image/heif"],
    [FILE_FORMATS.jpeg]: ["image/jpeg"],
    [FILE_FORMATS.jpg]: ["image/jpeg"],
    [FILE_FORMATS.png]: ["image/png"],
    [FILE_FORMATS.webp]: ["image/webp"],
};

// Video format
export const FILE_MIME_VIDEO_FORMAT = {
    [FILE_FORMATS.avi]: ["video/x-msvideo"],
    [FILE_FORMATS.mp4]: ["video/mp4"],
    [FILE_FORMATS.mpeg]: ["video/mpeg"],
    [FILE_FORMATS.ogv]: ["video/ogg"],
    [FILE_FORMATS.ts]: ["video/mp2t"],
    [FILE_FORMATS.webm]: ["video/webm"],
    [FILE_FORMATS._3gp]: ["video/3gpp", "audio/3gpp"],
    [FILE_FORMATS._3g2]: ["video/3gpp2", "audio/3gpp2"],
};

export const FILE_MIME_TYPES = {
    // audio
    [FILE_FORMATS.aac]: ["audio/aac"],
    [FILE_FORMATS.m4a]: ["audio/x-m4a"],
    [FILE_FORMATS.mp3]: ["audio/mpeg"],
    [FILE_FORMATS.wav]: ["audio/wav"],
    [FILE_FORMATS.wma]: ["audio/x-ms-wma", "video/x-ms-wma"],

    // compressed files
    [FILE_FORMATS.zip]: ["application/zip"],

    // docs
    [FILE_FORMATS.csv]: ["text/csv"],
    [FILE_FORMATS.doc]: ["application/msword"],
    [FILE_FORMATS.docx]: [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    [FILE_FORMATS.pdf]: ["application/pdf"],
    [FILE_FORMATS.ppt]: ["application/vnd.ms-powerpoint"],
    [FILE_FORMATS.xlsx]: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],

    // images
    [FILE_FORMATS.avif]: ["image/avif"],
    [FILE_FORMATS.heic]: ["image/heic"],
    [FILE_FORMATS.heif]: ["image/heif"],
    [FILE_FORMATS.jpeg]: ["image/jpeg"],
    [FILE_FORMATS.jpg]: ["image/jpeg"],
    [FILE_FORMATS.png]: ["image/png"],
    [FILE_FORMATS.webp]: ["image/webp"],
    [FILE_FORMATS.gif]: ["image/gif"],
    [FILE_FORMATS.svg]: ["image/svg+xml"],
    [FILE_FORMATS.tiff]: ["image/tiff"],

    // videos
    [FILE_FORMATS.avi]: ["video/x-msvideo"],
    [FILE_FORMATS.mp4]: ["video/mp4"],
    [FILE_FORMATS.mpeg]: ["video/mpeg"],
    [FILE_FORMATS.ogv]: ["video/ogg"],
    [FILE_FORMATS.ts]: ["video/mp2t"],
    [FILE_FORMATS.webm]: ["video/webm"],
    [FILE_FORMATS._3gp]: ["video/3gpp", "audio/3gpp"],
    [FILE_FORMATS._3g2]: ["video/3gpp2", "audio/3gpp2"],

    ...FILE_MIME_DOCS_FORMAT,
    ...FILE_MIME_IMAGE_FORMAT,
    ...FILE_MIME_VIDEO_FORMAT,
};

export const FORBIDDEN_FILE_UPLOAD_TYPES = [
    ".exe",
    ".jar",
    ".com",
    ".cmd",
    ".apk",
    ".run",
    ".sh",
    ".bin",
    ".bat",
    ".appimage",
    ".snap",
    ".gadget",
    ".ps1",
    ".vb",
    ".vbe",
    ".vbs",
    ".reg",
    ".vbscript",
    ".rgs",
    ".msc",
    ".msi",
];

export const DEFAULT_FILE_UPLOAD_SIZE_IN_BYTES = 5 * 1024 * 1024;

export const MAX_UPLOAD_FILE_SIZES = {
    DOC: 100,
    VIDEO: 16,
    IMG: 5,
};
