// if: lines of code > 300
// then: breakdown this file and move to repoV2/features/Analytics/constants folder

import { prependBackendAPIHost } from "repoV2/utils/common/api/api";
import { GOOGLE_TAG_MANAGER_EVENTS } from "./modules/GoogleTagManager/GoogleTagManager.constants";
import { META_PIXEL_EVENTS } from "./modules/MetaPixel/MetaPixel.constants";

export const ANALYTICS_EVENTS = {
    PAGE_VIEW: "PageView",
    CONTACT: "Contact",
    LEAD: "Lead",
    ADD_TO_CART: "AddToCart",
    INITIATE_CHECKOUT: "InitiateCheckout",
    ADD_PAYMENT_INFO: "AddPaymentInfo",
    PURCHASE: "Purchase",
    SALE_PAGE_REGISTER_CTA_CLICK: "SalePageRegisterCtaClick",
};

export const ANALYTICS_META_EVENTS_MAP = {
    [ANALYTICS_EVENTS.PAGE_VIEW]: META_PIXEL_EVENTS.PAGE_VIEW,
    [ANALYTICS_EVENTS.CONTACT]: META_PIXEL_EVENTS.CONTACT,
    [ANALYTICS_EVENTS.LEAD]: META_PIXEL_EVENTS.LEAD,
    [ANALYTICS_EVENTS.ADD_TO_CART]: META_PIXEL_EVENTS.ADD_TO_CART,
    [ANALYTICS_EVENTS.INITIATE_CHECKOUT]: META_PIXEL_EVENTS.INITIATE_CHECKOUT,
    [ANALYTICS_EVENTS.ADD_PAYMENT_INFO]: META_PIXEL_EVENTS.ADD_PAYMENT_INFO,
    [ANALYTICS_EVENTS.PURCHASE]: META_PIXEL_EVENTS.PURCHASE,
};

export const ANALYTICS_GTM_EVENTS_MAP = {
    [ANALYTICS_EVENTS.PAGE_VIEW]: GOOGLE_TAG_MANAGER_EVENTS.PAGE_VIEW,
    [ANALYTICS_EVENTS.CONTACT]: GOOGLE_TAG_MANAGER_EVENTS.GENERATE_LEAD,
    [ANALYTICS_EVENTS.LEAD]: GOOGLE_TAG_MANAGER_EVENTS.GENERATE_LEAD,
    [ANALYTICS_EVENTS.ADD_TO_CART]: GOOGLE_TAG_MANAGER_EVENTS.ADD_TO_CART,
    [ANALYTICS_EVENTS.INITIATE_CHECKOUT]:
        GOOGLE_TAG_MANAGER_EVENTS.BEGIN_CHECKOUT,
    [ANALYTICS_EVENTS.ADD_PAYMENT_INFO]:
        GOOGLE_TAG_MANAGER_EVENTS.ADD_PAYMENT_INFO,
    [ANALYTICS_EVENTS.PURCHASE]: GOOGLE_TAG_MANAGER_EVENTS.PURCHASE,
};

export const ANALYTICS_APIS = {
    GET_ANALYTICS_CONFIG: prependBackendAPIHost("analytics/config"),
};

export const TRACKING_TYPES = {
    EVENT_PAGE_TRACKING: 1,
    AFFILAITES_TRACKING: 2,
    CHECKOUT_PAGE_TRACKING: 3,
    SALES_PAGE_TRACKING: 4,
};
