import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { getTimezone } from "@Utils/getTimezone";
import { parseDateTime } from "@Utils/parseDateTime";
import { get32BitIntegerCode } from "repoV2/features/Common/modules/Hashing/utils/32BitIntegerCode";
import { CommenterIcon } from "../CommentsSection";
import styles from "../CommentsSection/commentSection.module.scss";
import { getCommenterName } from "../CommentsSection/data";
import { IReply } from "./IReply";

const Reply = ({ reply, commentColors, commenterName }: IReply.IProps) => {
    const replyCommenter = getCommenterName(reply);
    const replyContent = reply?.content || reply?.comment;

    return (
        <div
            key={get32BitIntegerCode(reply?.created_at)}
            className={classNames(
                styles.commentContainer,
                styles.largeSectionGap
            )}
        >
            <CommenterIcon
                initial={replyCommenter?.[0]}
                colorKey={commentColors?.[commenterName]}
            />
            <div className={styles.commentInfoContainer}>
                <div className={styles.commentTitle}>
                    <div className={styles.boldText}>{replyCommenter}</div>
                    <div className={styles.boldText}>&nbsp;&middot;&nbsp;</div>
                    <div
                        className={styles.timestamp}
                        title={parseDateTime(reply?.created_at, {
                            formatString: "E MMM dd, yyyy 'at' hh:mm a xxx",
                            log: true,
                            formatOptions: {
                                timeZone: getTimezone().timezone,
                            },
                        })}
                    >
                        {parseDateTime(
                            reply?.created_at,
                            "MMM dd, yyyy 'at' hh:mm a"
                        )}
                    </div>
                </div>
                <div className={styles.replyContent}>
                    {replyContent?.split("\n")?.map(contentLine => (
                        <>
                            {contentLine}
                            <br />
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Reply;
