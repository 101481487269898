import {
    appendURLSearchParams as _appendURLSearchParams,
    // @ts-ignore
} from "@my-scoot/exly-react-component-lib/build/cjs/utils/common/url";
import { appendURLSearchParams as appendURLSearchParamsType } from "@my-scoot/exly-react-component-lib/build/types/utils/common/url";
import Router from "next/router";
import { logError } from "../common/error/error";
import { isBrowser } from "../common/render/window";

export const appendURLSearchParams: typeof appendURLSearchParamsType =
    _appendURLSearchParams;

/**
 * @param url - url string from which params need to be deleted.\
 * Can be a full URL ("https://abcd.efg.hig/route?param1=value1") OR jus the route ("route?param1=value1" OR "/route?param1=value1")
 * @param params - array of strings containing params that need to be deleted
 * @returns a new url with deleted params
 */
export const deleteURLSearchParams = (url: string, params: Array<string>) => {
    try {
        let urlBase = "";
        // prepare `urlBase` so that a proper URL object can be constructed from `url`
        if (!url.startsWith("https://") && !url.startsWith("http://")) {
            urlBase = "https://www.dummybaseurl.com";
        }
        const urlObj = new URL(urlBase + url);
        const urlSearchParams = new URLSearchParams(urlObj.search);
        params.forEach(i => {
            urlSearchParams.delete(i);
        });
        urlObj.search = urlSearchParams.toString();

        // remove urlBase if it was added
        let urlToReturn = urlObj.href.replace(urlBase, "");

        // remove extra "/" after route,
        // if it was not present in the original url,
        // added while constructing `URL` object.
        if (!url.includes("/?")) {
            urlToReturn = urlToReturn.replace("/?", "?");
        }

        return urlToReturn;
    } catch (e) {
        logError({
            error: e,
            extraErrorData: url,
            when: "useing when deleteURLSearchParams function",
        });
        return url;
    }
};

/**
 * Extracts specified parameters from the search query of a URL.
 * @param {string} url - The URL with search parameters.
 * @param {Array<string>} params - The parameters to extract from the URL.
 * @returns {Object} - An object containing the extracted parameters.
 */
export const getParamsFromSearchQuery = (
    url: string,
    params: Array<string>
): { [key: string]: string } => {
    try {
        let urlBase = "";
        const paramsObj: { [key: string]: string } = {};
        // Check if the URL is missing the protocol and add a default base URL
        if (!url.startsWith("https://") && !url.startsWith("http://")) {
            urlBase = "https://www.dummybaseurl.com";
        }
        const urlObj = new URL(urlBase + url);
        const urlSearchParams = new URLSearchParams(urlObj.search);

        // Iterate through specified parameters and extract values
        params.forEach(paramKey => {
            const paramValue = urlSearchParams.get(paramKey);
            // Add the parameter to the object if a value is found
            if (paramValue) paramsObj[paramKey] = paramValue;
        });

        // Return the object containing extracted parameters
        return paramsObj;
    } catch (e) {
        logError({
            error: e,
            extraErrorData: url,
            when: "useing when getParamsFromSearchQuery function",
        });
        return {};
    }
};

/**
 * Both `next/router` and `window.location.search` are being used in this method,
 * because there have been problems where `next/router` does not provide the latest
 * value of the props, but using `next/router` makes it much easier to extract the
 * values during SSR.
 */
export const getQueryParam = (param: string): string | null => {
    try {
        if (!isBrowser()) {
            return Router?.router?.query?.[param]?.toString() || null;
        }

        return new URLSearchParams(window?.location.search).get(param) || null;
    } catch (error) {
        console.log(`Error in getQueryParam[${param}]:`, error);
        return null;
    }
};
