import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { SELECTORS } from "@Utils";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { classNames } from "repoV2/utils/common/render/classNames";

import { STRIEP_GATEWAY_EVENTS } from "repoV2/utils/payment/Stripe/Stripe.constants";
import { useGetOverrideUDMODB } from "@Modules/eventPage/utils/overrideUDMODB";
import { loadStripePaymentGateway } from "repoV2/utils/payment/Stripe/Stripe.utils";
import CheckoutForm from "./CheckoutForm";
import styles from "./stripeForm.module.scss";

const StripeForm = ({
    stripeKey,
    stripeConnectId,
}: {
    stripeKey: string | null;
    stripeConnectId: string | undefined;
}) => {
    const [show, setShow] = useState<boolean>(false);
    const [clientSecret, setClientSecret] = useState<string | undefined>(
        undefined
    );

    // Stripe is being loaded like this instead of being loaded outside the
    // component(as mentioned in the docs) because we need to load different
    // keys for indian and intl users, and that can only be determined once
    // the host data has been fetched
    // Refer: https://stackoverflow.com/questions/69124551/how-to-load-stripe-promise-outside-of-react-component
    const [stripePromise, setStripePromise] = useState<ReturnType<
        typeof loadStripe
    > | null>(null);

    useEffect(() => {
        const handleLoadStripe = () => {
            // Stripe promise may only be loaded once and then never changed
            if (stripeKey && !stripePromise) {
                const _stripePromise = loadStripe(
                    stripeKey,
                    stripeConnectId
                        ? { stripeAccount: stripeConnectId }
                        : undefined
                );
                setStripePromise(_stripePromise);
            }
        };

        const stripeReadyCallback = (e: Event) => {
            const { clientSecret: clientSecretParam } = (e as CustomEvent)
                ?.detail;

            if (clientSecretParam && stripePromise) {
                setClientSecret(clientSecretParam);
                setShow(true);
            } else if (!stripePromise) {
                alert("Stripe not loaded. Please try again.");
            } else if (!clientSecretParam) {
                alert("Invalid stripe token. Please try again.");
            }
        };
        document.addEventListener(
            STRIEP_GATEWAY_EVENTS.LOAD_STRIPE,
            handleLoadStripe
        );
        document.addEventListener(
            STRIEP_GATEWAY_EVENTS.MAKE_PAYMENT,
            stripeReadyCallback
        );
        return () => {
            document.removeEventListener(
                STRIEP_GATEWAY_EVENTS.LOAD_STRIPE,
                handleLoadStripe
            );
            document.removeEventListener(
                STRIEP_GATEWAY_EVENTS.MAKE_PAYMENT,
                stripeReadyCallback
            );
        };
    }, [stripePromise]);

    // `useGetOverrideUDMODB` related code starts
    /**
    see definition of `useGetOverrideUDMODB` first to understand better
    */
    const overrideUDMODB = useGetOverrideUDMODB();

    useEffect(() => {
        // load stripe gateway as we might be using it when modal is SSR'd
        // why???
        // when: UDMODB is SSR'd
        // then: the call to `loadStripePaymentGateway` happens before the event
        //       listener for `STRIEP_GATEWAY_EVENTS.LOAD_STRIPE` is actually added.
        // so: we explicitly trigger the load call in this case anyways to avoid
        //     failure.
        if (overrideUDMODB) {
            loadStripePaymentGateway();
        }
    }, []);
    // `useGetOverrideUDMODB` related code ends

    useEffect(() => {
        const noScrollClass = "noscroll-stripe";

        // This will add overflow: hidden to the body's class of the page to
        // prevent the page from scrolling in the background when the stripe form is open
        // This is added independent of if any other class of a similar purpose is added
        // (eg: Host Page Placeholder or Modal Open) to decouple this from the state of
        // those components, since as far as the stripe component is concerned, it will
        // only prevent scrolling while it is open.

        // TODO @aakarsh: Central mechanism to enable/disable body scroll
        if (show && document.body.style.overflow !== "hidden") {
            document.body.classList.add(noScrollClass);
        } else {
            document.body.classList.remove(noScrollClass);
        }
        return () => {
            document.body.classList.remove(noScrollClass);
        };
    }, [show]);

    const { font } = useSelector(SELECTORS.host);

    const options: StripeElementsOptions = {
        clientSecret,
        appearance: {
            theme: "stripe",
        },
        fonts: [{ family: font.title, src: `url(${font.url})` }],
    };

    const handleClose = () => {
        if (
            window.confirm(
                "Are you sure you want to cancel the payment and return?"
            )
        ) {
            setShow(false);
        }
    };

    /**
     * Stripe needs `clientSecret` form the beginning, for rendering.
     *
     * There might be a race condition b/w setState of `show` and `clientSecret`,
     * To avoid it, we will use `show && clientSecret` as render contdition.
     */
    return show && clientSecret ? (
        <div className={classNames(styles.root, show && styles.show)}>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm handleClose={handleClose} />
            </Elements>
        </div>
    ) : null;
};

export default StripeForm;
