import { CURRENCY_STRINGS, CURRENCY_SYMBOLS } from "repoV2/constants/payment";
import { checkIsArrayEmpty } from "repoV2/utils/common/dataTypes/array";
import { checkIsObjectEmpty } from "repoV2/utils/common/dataTypes/object";
import { ITransactionSectionContextValue } from "../contexts/TransactionSectionContext";
import { IReferralSectionContextValue } from "../contexts/ReferralSectionContext";
import {
    IThankyouPagesExternalTypes,
    IThankyouPagesPreBookingQuesData,
    IThankyouPagesSummary,
    IThankyouPagesTransactionData,
} from "../types/IThankyouPages";
import {
    BOOKING_DETAILS_FIELD_KEYS,
    BOOKING_DETAILS_LABELS_MAP,
    CUSTOM_QUESTION_ANSWERS,
    FORM_SUMMARY_FIELD_KEYS,
    FORM_SUMMARY_LABELS_MAP,
} from "../constants/thankyou.contants";
import { getDateStringWithTimeZone } from "../utils";

const processPrebookingQuestions = (
    bookingQuestions: IThankyouPagesPreBookingQuesData = []
): IThankyouPagesSummary[] => {
    return bookingQuestions.map(item => {
        const {
            question_type,
            question_text = "",
            text_answer = "",
            choices = [],
        } = item;

        const questionProperties =
            question_type && CUSTOM_QUESTION_ANSWERS[question_type];
        /**
        Backend uses same enum for all custom questions(pre booking, post booking, express interest, recorded content exam).
        But some are not used in pre booking, post booking, express interest custom questions.
        And sends the data for these questions if someone has entered them by mistake.
        We only want to process those, which are booking, post booking, express interest custom questions.

        See CUSTOM_QUESTION_TYPES_ID const to understand further.
         */
        const answerValue = questionProperties
            ? questionProperties.processAnswerText(
                  (text_answer || choices) as string & string[]
              )
            : "";
        return {
            label: question_text,
            value: answerValue,
        };
    });
};

const processBookingDetailsData = ({
    bookingData,
    preBookingQuestions,
    currency,
    hostData,
}: {
    bookingData: IThankyouPagesTransactionData["bookingDetails"];
    preBookingQuestions: IThankyouPagesPreBookingQuesData;
    currency: string;
    hostData: IThankyouPagesExternalTypes["hostData"];
}): IThankyouPagesSummary[] => {
    const hideUserDetailsInBookingDetails =
        hostData?.hide_user_details_in_booking_details;
    const processedBookingData: IThankyouPagesSummary[] = Object.values(
        BOOKING_DETAILS_FIELD_KEYS
    )
        .filter(key =>
            hideUserDetailsInBookingDetails
                ? ![
                      BOOKING_DETAILS_FIELD_KEYS.billingState,
                      BOOKING_DETAILS_FIELD_KEYS.email,
                      BOOKING_DETAILS_FIELD_KEYS.name,
                      BOOKING_DETAILS_FIELD_KEYS.phone,
                  ].includes(key)
                : true
        )
        .map(key => {
            if (key === BOOKING_DETAILS_FIELD_KEYS.transactionDate) {
                const bookingDate =
                    bookingData?.[key as keyof typeof bookingData];
                return {
                    label: BOOKING_DETAILS_LABELS_MAP[key],
                    value: bookingDate
                        ? `${getDateStringWithTimeZone(bookingDate as string)}`
                        : "",
                    id: key,
                };
            } else if (key === BOOKING_DETAILS_FIELD_KEYS.price) {
                const initialValue =
                    bookingData?.[key as keyof typeof bookingData];
                const formattedValue = initialValue
                    ? `${
                          CURRENCY_SYMBOLS[
                              currency as keyof typeof CURRENCY_SYMBOLS
                          ]
                      }${initialValue}`
                    : "";
                return {
                    label: BOOKING_DETAILS_LABELS_MAP[key],
                    value: formattedValue,
                    id: key,
                };
            }
            return {
                label: BOOKING_DETAILS_LABELS_MAP[key],
                value: bookingData?.[key as keyof typeof bookingData] || "",
                id: key,
            };
        });
    const processedPrebookingQuestions =
        processPrebookingQuestions(preBookingQuestions);
    const finalBookingData = processedBookingData.concat(
        processedPrebookingQuestions
    );
    return finalBookingData;
};

const processLeadAnswers = (
    formSummary: IThankyouPagesTransactionData["formSummary"]
): IThankyouPagesSummary[] => {
    if (checkIsObjectEmpty(formSummary)) return [];
    const unprocessedLeadAnswers = formSummary?.unprocessed_lead_answers;
    const customAnswers = unprocessedLeadAnswers?.customAnswers || {};
    const customQuestions = unprocessedLeadAnswers?.customQuestions || [];
    const processedLeadAnswers = Object.entries(customAnswers)
        .map(([quesId, answer]) => {
            const question = customQuestions.find(ques => ques.uuid === quesId);
            const type = question?.type || Number.POSITIVE_INFINITY;

            const quesLabel = question?.question || "";
            const questionProperties = CUSTOM_QUESTION_ANSWERS[type];

            /**
            Backend uses same enum for all custom questions(pre booking, post booking, express interest, recorded content exam).
            But some are not used in pre booking, post booking, express interest custom questions.
            And sends the data for these questions if someone has entered them by mistake.
            We only want to process those, which are booking, post booking, express interest custom questions.
            
            See CUSTOM_QUESTION_TYPES_ID const to understand further
             */
            if (!questionProperties) return null;

            const answerValue = questionProperties.processAnswerText(answer);
            return {
                label: quesLabel,
                value: answerValue,
            };
        })
        .filter(i => i) as {
        label: string;
        value: string;
    }[];
    return processedLeadAnswers;
};

const processFormSummaryData = (
    formSummary: IThankyouPagesTransactionData["formSummary"],
    eventData: IThankyouPagesExternalTypes["eventData"]
): IThankyouPagesSummary[] => {
    const processedFormSummaryData: IThankyouPagesSummary[] = Object.keys(
        FORM_SUMMARY_FIELD_KEYS
    ).map(key => {
        if (key === FORM_SUMMARY_FIELD_KEYS.date_n_time)
            return {
                label: FORM_SUMMARY_LABELS_MAP[
                    FORM_SUMMARY_FIELD_KEYS.date_n_time
                ],
                value: getDateStringWithTimeZone(new Date().toUTCString()),
                id: key,
            };
        else if (key === FORM_SUMMARY_FIELD_KEYS.listing_name)
            return {
                label: FORM_SUMMARY_LABELS_MAP[
                    FORM_SUMMARY_FIELD_KEYS.listing_name
                ],
                value: eventData.title,
                id: key,
            };
        else if (key === FORM_SUMMARY_FIELD_KEYS.phone_number)
            return {
                label: FORM_SUMMARY_LABELS_MAP[
                    FORM_SUMMARY_FIELD_KEYS.phone_number
                ],
                value: `${formSummary?.country_code || ""}${
                    formSummary?.[key as keyof typeof formSummary] || ""
                }`,
                id: key,
            };
        return {
            label: FORM_SUMMARY_LABELS_MAP[key],
            value: formSummary?.[key as keyof typeof formSummary] || "",
            id: key,
        };
    });
    const processedLeadAnswers = processLeadAnswers(formSummary) || [];
    const finalFormSummaryData =
        processedFormSummaryData.concat(processedLeadAnswers);
    return finalFormSummaryData;
};

export const processTransactionData = ({
    unprocessedTransactionData,
    eventData,
    hostData,
}: {
    unprocessedTransactionData: any;
    eventData: IThankyouPagesExternalTypes["eventData"];
    hostData: IThankyouPagesExternalTypes["hostData"];
}): ITransactionSectionContextValue => {
    const transactionId = unprocessedTransactionData.id || "<Transaction Id>";
    const transactionAmount =
        unprocessedTransactionData.amount?.toString() || "<Amount Paid>";
    const transactionCurrency =
        unprocessedTransactionData.currency || CURRENCY_STRINGS.INR;
    const bookingDetails = processBookingDetailsData({
        bookingData: unprocessedTransactionData.bookingDetails,
        preBookingQuestions: unprocessedTransactionData.bookingQuestions,
        currency: unprocessedTransactionData.currency,
        hostData,
    });
    const formSummary = processFormSummaryData(
        unprocessedTransactionData.formSummary,
        eventData
    );
    const isLoading = checkIsArrayEmpty(
        Object.keys(transactionId ? bookingDetails : formSummary)
    );
    return {
        transactionId,
        transactionAmount,
        transactionCurrency,
        bookingDetails,
        formSummary,
        isLoading,
    };
};

export const processReferralData: (
    referralData: any
) => IReferralSectionContextValue = referralData => {
    return {
        refundPercent: referralData?.refund_percent,
        discountPercent: referralData?.discount_percent,
        coupon: referralData?.coupon,
    };
};

export const processTestimonialList: (data: any) => Array<{
    reviewerName: string;
    reviewerImgSrc: string;
    testimonialType: string;
    testimonialImgSrc: string;
    testimonialThumbUrl: string;
    testimonialVideoSrc: string;
    reviewContent: string;
}> = data => {
    if (!data) return [];
    return data?.map((item: any) => ({
        reviewerName: item.data.name,
        reviewerImgSrc: item.data.image,
        testimonialType: item.data.testimonialType,
        testimonialImgSrc: item.data.testimonialImg,
        testimonialThumbUrl: item.data.testimonialThumbUrl,
        testimonialVideoSrc: item.data.testimonialVideoUrl,
        reviewContent: item.data.description,
    }));
};

export const getProcessedUpsellOfferingId = (builderPageSectionData: any) => {
    const { upsell_offering_uuid } = builderPageSectionData;
    return upsell_offering_uuid ?? "none";
};
