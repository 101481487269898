import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
    BUTTON_DESIGNS,
    isBrowser,
    SHARE_PLATFORMS,
    SHARE_PLATFORM_KEYS,
} from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { appendQueryParams } from "@Utils/urls";
import { Button } from "@Modules/common";
import { Popup, usePopup } from "..";
import { ISharePopup } from "./ISharePopup";
import styles from "./SharePopup.module.scss";

export const SharePopup = ({
    closePopup,
    popupTitle = "Share",
    shareTitle = "",
    shareText = "",
    shareUrl = "",
    classNames: classNamesProp,
}: ISharePopup.IProps): JSX.Element => {
    const { closePopupWithAnimation, closing, animationDuration } = usePopup({
        closePopup,
    });

    const LINKS: { [key: string]: string } = {};
    const encodedShareText = encodeURIComponent(shareText);
    const encodedShareUrl = encodeURIComponent(shareUrl);
    const encodedShareTitle = encodeURIComponent(shareTitle);

    if (encodedShareText && encodedShareUrl) {
        LINKS[SHARE_PLATFORM_KEYS.FACEBOOK] = appendQueryParams(
            "https://www.facebook.com/dialog/feed",
            {
                app_id: "253311268567195",
                link: encodedShareUrl,
                quote: encodedShareText,
            }
        );
    }
    if (encodedShareText) {
        LINKS[
            SHARE_PLATFORM_KEYS.WHATSAPP
        ] = `https://api.whatsapp.com/send?&text=${encodedShareText}`;
        LINKS[
            SHARE_PLATFORM_KEYS.TWITTER
        ] = `https://twitter.com/intent/tweet?text=${encodedShareText}`;
        LINKS[SHARE_PLATFORM_KEYS.SMS] = `sms:?&body=${encodedShareText}`;
    }
    if (encodedShareText && encodedShareTitle) {
        LINKS[
            SHARE_PLATFORM_KEYS.EMAIL
        ] = `mailto:?subject=${encodedShareTitle}&body=${encodedShareText}`;
    }

    const handleLinkClick = (id: string): void => {
        if (id === SHARE_PLATFORM_KEYS.MOBILE_SHARE && navigator.share) {
            navigator.share({
                title: shareTitle,
                text: shareText,
                url: shareUrl,
            });
        } else if (isBrowser()) {
            window.open(LINKS[id], "_blank");
        }
    };

    return (
        <Popup
            closePopup={closePopupWithAnimation}
            containerClass={styles.container}
            closing={closing}
            animationDuration={animationDuration}
            classNames={classNamesProp}
        >
            <>
                <div className={classNames(styles.header, styles.popupSection)}>
                    <div className={styles.boldText}>{popupTitle}</div>
                    <Button
                        className={classNames(
                            styles.closeButton,
                            styles.plainButton
                        )}
                        design={BUTTON_DESIGNS.PLAIN_TEXT}
                        content={<>&#10006;</>}
                        onClick={closePopupWithAnimation}
                    />
                </div>
                <div className={styles.popupSection}>
                    <div className={styles.shareButtonsContainer}>
                        {Object.values(SHARE_PLATFORMS)
                            .filter(p =>
                                p.onlyMobile ? !!navigator?.share : true
                            )
                            .map(p => (
                                <Button
                                    design={BUTTON_DESIGNS.PLAIN_TEXT}
                                    key={p.id}
                                    onClick={() => handleLinkClick(p.id)}
                                    className={classNames(
                                        styles.shareButton,
                                        styles.plainButton
                                    )}
                                    content={
                                        <>
                                            <div className={styles.shareIcon}>
                                                <p.component
                                                    color={p.color}
                                                    size="1.5rem"
                                                />
                                            </div>
                                            <div>{p.title}</div>
                                        </>
                                    }
                                />
                            ))}
                    </div>
                </div>
                <hr style={{ margin: 0 }} />
                <div className={styles.popupSection}>
                    <div className={styles.boldText}>Share the link</div>
                    <div className={styles.shareUrlContainer}>
                        <CopyToClipboard
                            text={shareUrl}
                            onCopy={() => {
                                alert("Link Copied");
                            }}
                        >
                            <div className={styles.shareUrl}>{shareUrl}</div>
                        </CopyToClipboard>
                        <CopyToClipboard
                            text={shareUrl}
                            onCopy={() => {
                                alert("Link Copied");
                            }}
                        >
                            <Button
                                design={BUTTON_DESIGNS.ROUNDED_CORNERS}
                                content="Copy Link"
                            />
                        </CopyToClipboard>
                    </div>
                </div>
            </>
        </Popup>
    );
};
