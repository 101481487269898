// This file is for functions that allow DOM manipulation

import { NODE_ENV_IS_NOT_PRODUCTION } from "repoV2/constants/environment";

/**
 * Uses the DOM's `element.scrollIntoView` method to scroll to an element
 * @param querySelector eg: `#heading`, `.button`, `h3.title`
 * @param scrollOptions implements `ScrollIntoViewOptions` interface
 * @param customTimeout custom timeout after which the scroll method will be called
 */
export const scrollToElement = (
    querySelector: string,
    scrollOptions: ScrollIntoViewOptions = { behavior: "smooth" },
    customTimeout: number = 10
) => {
    setTimeout(() => {
        const element = document.querySelector(querySelector);
        if (element) {
            element.scrollIntoView(scrollOptions);
        } else if (NODE_ENV_IS_NOT_PRODUCTION) {
            console.error("Unable to find element: ", querySelector);
        }
    }, customTimeout);
};
