import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import styles from "./TextAreaInput.module.scss";

/**
 * - extraProps can have following keys
 *      - value?: string;
 *      - onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
 *      - name?: string;
 *      - maxLength?: number;
 *      - onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
 *      - onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
 *      - placeholder?: string;
 *      - type?: "email" | "text" | "number" | "tel";
 */
export const TextAreaInput = React.forwardRef<
    HTMLTextAreaElement,
    {
        design?: "rounded_corners";
        className?: string;
        applyTheme?: boolean;
        error?: boolean;
        editingDisabled?: boolean;
        [k: string]: any; // for extraProps
    }
>(
    (
        {
            design = "rounded_corners",
            className,
            applyTheme,
            error,
            editingDisabled,
            ...extraProps
        },
        ref
    ) => {
        switch (design) {
            case "rounded_corners":
                return (
                    <textarea
                        ref={ref}
                        className={classNames(
                            styles.roundedCornersTextInput,
                            error && styles.inputError,
                            editingDisabled && styles.editingDisabled,
                            applyTheme && styles.applyTheme,
                            className
                        )}
                        rows={2}
                        disabled={editingDisabled}
                        {...extraProps}
                    />
                );
            default:
                return null;
        }
    }
);

TextAreaInput.defaultProps = {
    design: "rounded_corners",
    error: undefined,
    applyTheme: false,
    className: undefined,
    editingDisabled: undefined,
};
