import { useDispatch, useSelector } from "react-redux";
import {
    createAction,
    ACTION_TYPES,
    API_ACTION_TYPES,
    SELECTORS,
    getUserAccessToken,
} from "@Utils";
import { useEffect, useState } from "react";
import { useData, useFetchStatus, useUserLogin } from ".";

export const useNotifications = ({
    fetchNotificationOnMount,
    fetchNotificationOnNewNotificationReceived,
}: {
    fetchNotificationOnMount?: boolean;
    fetchNotificationOnNewNotificationReceived?: boolean;
}) => {
    const dispatch = useDispatch();

    const { isLoggedIn } = useUserLogin();

    const notificationsData = useSelector(SELECTORS.user).notifications;

    const fetchNotificationsStatus = useFetchStatus(
        API_ACTION_TYPES.FETCH_NOTIFICATIONS
    );

    const fetchNotifications = (page: number = 1) => {
        if (isLoggedIn) {
            dispatch(
                createAction(ACTION_TYPES.UTILS.API_CALL, {
                    apiActionType: API_ACTION_TYPES.FETCH_NOTIFICATIONS,
                    headers: {
                        "jwt-token": getUserAccessToken(),
                    },
                    urlArgs: { page },
                })
            );
        }
    };

    const {
        data: newFirebaseNotificationReceived,
        setData: setNewFirebaseNotificationReceived,
    } = useData("newFirebaseNotificationReceived");

    const [newNotificationReceived, setNewNotificationRecieved] =
        useState(false);

    const fetchNewNotificationStatus = () => {
        if (isLoggedIn) {
            dispatch(
                createAction(ACTION_TYPES.UTILS.API_CALL, {
                    apiActionType:
                        API_ACTION_TYPES.FETCH_NEW_NOTIFICATION_STATUS,
                    headers: {
                        "jwt-token": getUserAccessToken(),
                    },
                    successCallback: data => {
                        setNewNotificationRecieved(
                            !data?.response?.data?.is_notification_seen
                        );
                    },
                })
            );
        }
    };

    const onOpenNotifications = () => {
        if (isLoggedIn) {
            dispatch(
                createAction(ACTION_TYPES.UTILS.API_CALL, {
                    apiActionType:
                        API_ACTION_TYPES.UPDATE_NEW_NOTIFICATION_STATUS,
                    payload: {},
                    headers: {
                        "jwt-token": getUserAccessToken(),
                    },
                    successCallback: () => {
                        setNewFirebaseNotificationReceived(false);
                        setNewNotificationRecieved(false);
                    },
                })
            );
        }
    };

    const showLoadMoreButton =
        notificationsData.data.length < notificationsData.total;

    const onLoadMoreClick = () => {
        fetchNotifications(1 + Math.floor(notificationsData.data.length / 50));
    };

    const fetchNotificationMessage = (
        customMaild: number,
        successCallback: (data: any) => void
    ) => {
        dispatch(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.FETCH_NOTIFICATION_MESSAGE,
                urlArgs: {
                    custom_mail_id: customMaild,
                },
                headers: {
                    "jwt-token": getUserAccessToken(),
                },
                successCallback,
            })
        );
    };

    useEffect(() => {
        if (fetchNotificationOnMount) {
            fetchNotifications();
            fetchNewNotificationStatus();
        }
    }, []);

    useEffect(() => {
        if (
            fetchNotificationOnNewNotificationReceived &&
            newFirebaseNotificationReceived
        ) {
            fetchNotifications();
            fetchNewNotificationStatus();
        }
    }, [newFirebaseNotificationReceived]);

    return {
        fetchNotificationsStatus,
        notificationsData,
        showLoadMoreButton,
        onLoadMoreClick,
        fetchNotificationMessage,
        newNotificationReceived,
        onOpenNotifications,
    };
};
