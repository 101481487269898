import { useController, UseControllerProps, useForm } from "react-hook-form";

export const useReactHookFormController = ({
    name,
    control: contorlArg,
}: UseControllerProps) => {
    // need to create a dummy control in case `control === undefined`, as we can't conditionally use hooks
    const { control: dummyControl } = useForm();

    const {
        field: { onChange: controllerOnChange, ...restControllerProps },
    } = useController({
        name,
        control: contorlArg || dummyControl,
    });

    return contorlArg
        ? {
              controllerOnChange,
              restControllerProps,
          }
        : {};
};
