import * as Sentry from "@sentry/nextjs";
import { CALL_API_METHODS } from "repoV2/constants/apis";
import {
    LEGACY_LOCAL_STORAGE_KEYS,
    LOCAL_STORAGE_KEYS,
} from "repoV2/constants/common/storage/localStorage";
import { callApi } from "repoV2/utils/common/api/callApi";
import { logError } from "repoV2/utils/common/error/error";
import { removeLocalStorageItem } from "repoV2/utils/common/storage/getterAndSetters";
import { getUserAccessToken } from "repoV2/utils/user&auth&login/authTokens";
import { logout_api_url } from "./Auth&Login&User.constants";

const callLogoutApi = ({
    userAccessToken,
}: {
    userAccessToken: string | null;
}) =>
    callApi({
        url: logout_api_url,
        extraOptions: { appendExlyBackendHeaders: true },
        method: CALL_API_METHODS.POST,
        headers: userAccessToken ? { "jwt-token": userAccessToken } : {},
    });

export const logoutUser = () => {
    const userAccessToken = getUserAccessToken(); // get the access token before it is cleared and pass it to the async funtion.
    callLogoutApi({ userAccessToken }).catch(e =>
        logError({
            error: e,
            occuredAt:
                "repoV2/features/Common/modules/Auth&Login&User/Auth&Login&User.utils.ts",
            when: "calling callLogoutApi",
        })
    );

    [
        LEGACY_LOCAL_STORAGE_KEYS.CUSTOMER_NAME,
        LEGACY_LOCAL_STORAGE_KEYS.CUSTOMER_UUID,
        LEGACY_LOCAL_STORAGE_KEYS.EMAIL,
        LOCAL_STORAGE_KEYS.IS_CREATOR,
        LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
        LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
    ].forEach(removeLocalStorageItem);
    removeLocalStorageItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER_DETAILS);

    // clear sentry user details
    Sentry.setUser(null);
};
