import { SESSION_STORAGE_KEYS } from "repoV2/constants/common/storage/sessionStorage";
import {
    getSessionStorageItem,
    setSessionStorageItem,
} from "../storage/getterAndSetters";
import { initAndGetReferrerWithLogic } from "./referrer";
import {
    IUserIpDetails,
    default_user_ip_details_for_analytics_info,
    getUserIpDetailsForAnalyticsInfo,
} from "./userIp";

export type IAnalyticsInfo = IUserIpDetails["data"] & {
    referer_group?: string;
    source_link?: string;
};

export const getAnalyticsInfo = () => {
    // Setting default values
    let analytics_info: IAnalyticsInfo = {
        ...JSON.parse(
            JSON.stringify(default_user_ip_details_for_analytics_info.data)
        ),
    };
    const source_link = window.location.href; // getting browser URL
    const currentLocationDetails = getUserIpDetailsForAnalyticsInfo().data;
    analytics_info = {
        ...currentLocationDetails,
        source_link,
    };

    analytics_info.referer_group = initAndGetReferrerWithLogic() ?? undefined;
    return analytics_info;
};

export const initAndGetEndUserSessionId = () => {
    const sessionIDInStorage = getSessionStorageItem(
        SESSION_STORAGE_KEYS.END_USER_SESSION_ID
    );
    const sessionID = sessionIDInStorage || new Date().toISOString();

    if (!sessionIDInStorage) {
        setSessionStorageItem(
            SESSION_STORAGE_KEYS.END_USER_SESSION_ID,
            sessionID
        );
    }

    return sessionID;
};
