// if: lines of code > 300
// then: breakdown this file and move to repoV2/features/HostPage/.constants folder
export const CREATOR_MISCELLANEOUS_DATA_TYPES = {
    AWARDS_CERTIFICATES: 1,
    BRAND_COLLABORATION: 2,
    MEDIA_MENTIONS: 3,
    OFFERING_SECTION: 4,
    INTRO_VIDEO: 5,
    ADDITIONAL_POINTERS: 6,
    BLOGS: 7,
    TEAMS: 8,
    CONTENT_CATALOGUE: 9,
    ABOUT_SECTION: 10,
    CUSTOM_FOOTER: 11,
};

export const WHITELABEL_FEATURE_KEY = "whitelabel";
