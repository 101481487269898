import { callApi } from "repoV2/utils/common/api/callApi";
import { logError } from "../../../../utils/common/error/error";
import { GET_SALE_PAGE_DETAILS_API_URL } from "./sale.api.constants";

export const getSalePageCustomCodes = async ({
    salePageUuid = "",
    subDomain = "",
}: {
    salePageUuid: string | undefined;
    subDomain: string | undefined;
}) => {
    if (!(salePageUuid && subDomain)) return [];
    try {
        const response = await callApi({
            url: `${GET_SALE_PAGE_DETAILS_API_URL}/${subDomain}/${salePageUuid}`,
            extraOptions: {
                appendAuthHeaders: true,
                appendExlyOriginHeaders: true,
                appendTimezoneHeaders: true,
            },
        });
        const { custom_codes } = response?.data?.data;
        return custom_codes;
    } catch (error) {
        logError({
            error,
            occuredAt: "At getSalesPageCustomCodes in Custom Codes",
        });
        return [];
    }
};
