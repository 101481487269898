import React from "react";
import ReactHtmlParser from "html-react-parser";
import { ICustomCodeData } from "repoV2/interfaces/customCodes/ICustomCode";
import { useGetCustomCodes } from "./CustomCode.helpers";

export const CustomCodeBase = ({
    position,
    codes,
}: {
    position: number;
    codes: ICustomCodeData[];
}) => {
    const combinedCode = codes
        .filter(code => code.code_position?.includes(position))
        .map(code => code.code_snippet)
        .join("\n");
    return <>{ReactHtmlParser(combinedCode)}</>;
};

export const CustomCode = ({ position }: { position: number }) => {
    const codes = useGetCustomCodes();
    return <CustomCodeBase position={position} codes={codes} />;
};
