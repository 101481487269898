import { API_ACTION_TYPES, ACTION_TYPES, createAction } from "@Utils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const useFetchNavbarItemData = (hostName: string) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.FETCH_HOST_PLANS,
                urlArgs: {
                    hostName,
                },
            })
        );
    }, []);

    useEffect(() => {
        dispatch(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.FETCH_TEAM_DETAILS,
                urlArgs: {
                    hostName,
                },
            })
        );
    }, []);

    useEffect(() => {
        dispatch(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.FETCH_BLOG_POSTS,
                urlArgs: { page: 1, hostName },
            })
        );
    }, []);
};
