import { getCurrentYear } from "repoV2/features/Common/modules/DateTime/modules/Time/utils/time";

export const getFixedLengthNumString = (val: number, numLen: number = 2) =>
    `00${val}`.slice(-1 * numLen);

/**
 * referenced from https://stackoverflow.com/a/29858893
 * @returns capitalises each word in a string
 */
export const capitaliseWords = (str: string) => {
    const regex = /(\b[a-z](?!\s))/g;
    return str.replace(regex, x => {
        return x.toUpperCase();
    });
};

export const checkIsStringEmpty = (str: string | null | undefined = "") =>
    !str?.length;

export const trimText = (textParam: string | undefined, length: number) => {
    const text = textParam || "";
    return text.length > length ? `${text.slice(0, length)}...` : text;
};

export const getCopyrightString = () =>
    `© ${getCurrentYear()}, MyScoot Private Ltd`;
