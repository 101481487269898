import { useExlyConnectZoomAppUrl } from "@Utils/hooks/exlyConnect/useExlyConnectZoomAppUrl";
import { IMyClassAddressPopup } from "./IMyClassAddressPopup";

export const useMyClassAddressPopup = ({
    data,
}: {
    data: IMyClassAddressPopup.IProps["data"];
}): { showExlyConnectZoomAppUrl: boolean; exlyConnectZoomAppUrl: string } => {
    const listingAddressType = data?.listing__address_type;
    const { showExlyConnectZoomAppUrl, exlyConnectZoomAppUrl } =
        useExlyConnectZoomAppUrl({
            listingAddressType,
            listingUuid: data?.listing__uuid,
        });

    return {
        showExlyConnectZoomAppUrl,
        exlyConnectZoomAppUrl,
    };
};
