import React from "react";
import Script from "next/script";
import { useRouter } from "next/router";

import { isBrowser, LOAD_PAYMENT, PAYMENT_GATEWAYS } from "@Utils";
import Stripe from "./Stripe";

/**
 * @deprecated Use PaymentGateways at repoV2/features/Payments/modules/PaymentGateways/PaymentGateways.tsx
 */
const PaymentGateways = () => {
    const router = useRouter();

    const loadPayment: boolean =
        isBrowser() && LOAD_PAYMENT.includes(router.route);

    const paymentScriptsToLoad = loadPayment
        ? Object.values(PAYMENT_GATEWAYS)
        : [];

    return (
        <>
            {loadPayment ? <Stripe /> : null}
            {paymentScriptsToLoad.map(scriptUrl => (
                <Script
                    key={scriptUrl}
                    src={scriptUrl}
                    strategy="afterInteractive"
                />
            ))}
        </>
    );
};

export default PaymentGateways;
