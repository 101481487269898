import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IUtils } from "@Interfaces";

import { IGetInTouch } from "@Modules/modals/components/GetInTouch/IGetInTouch";
import { isDefined } from "repoV2/utils/common/dataTypes/common";
import {
    SELECTORS,
    createAction,
    ACTION_TYPES,
    IModalTypes,
} from "../constants";

export module IUseModal {
    export interface ISetterProps {
        isOpen?: boolean;
        modalType?: IModalTypes | null;
        modalProps?: any;
        isCloseable?: boolean;
        overrides?: IUtils.IModal["overrides"];
    }
    export interface IOpenModalProps {
        modalType?: IModalTypes;
        // TODO: add all modal props and remove `any`
        modalProps?: IGetInTouch.IBaseProps | any;
        isCloseable?: boolean;
        overrides?: IUtils.IModal["overrides"];
    }
    export interface IReturnType {
        modalInfo: IUtils.IModal;
        setModalInfo: (p: IUseModal.ISetterProps) => void;
        closeModal: () => void;
        openModal: (t: IModalTypes, p?: IUseModal.IOpenModalProps) => void;
    }
}

export const useModal = (): IUseModal.IReturnType => {
    const dispatch = useDispatch();
    const modalInfo: IUtils.IModal = useSelector(SELECTORS.modalInfo);

    const modalInfoRef = useRef<IUtils.IModal>(modalInfo);

    // Will update the current value to the latest value of modalInfo every time it updates
    modalInfoRef.current = modalInfo;

    const setModalInfo = (args: IUseModal.ISetterProps): void => {
        const { isOpen, ...rest }: IUseModal.ISetterProps = args;

        const isModalOpen: boolean =
            isDefined(isOpen) && !!args.modalType ? isOpen! : false;
        dispatch(
            createAction(ACTION_TYPES.UTILS.SET_MODAL, {
                ...(isDefined(isOpen) && { isModalOpen }),
                ...rest,
            })
        );
    };

    const closeModal = (): void => {
        const { overrides, isCloseable, isModalOpen } = modalInfoRef.current;
        const setClose = () => {
            if (isModalOpen)
                dispatch(createAction(ACTION_TYPES.UTILS.RESET_MODAL));
        };
        if (!modalInfo.modalType) {
            setClose();
        }

        if (isCloseable) {
            if (overrides?.handleClose) {
                overrides?.handleClose(setClose, modalInfoRef.current);
            } else {
                setClose();
            }
        } else {
            overrides?.handleForbiddenClose?.(setClose, modalInfoRef.current);
        }
    };

    const openModal = (
        modalType: IModalTypes,
        args?: IUseModal.IOpenModalProps
    ) => {
        setModalInfo({
            isOpen: true,
            modalType,
            ...(args || {}),
        });
    };

    return {
        modalInfo,
        setModalInfo,
        openModal,
        closeModal,
    };
};
