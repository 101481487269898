/**
 * - Defines routes for which the code has been migrated to repoV2
 * - existing routes that are not completely migrated to repoV2 should be added to `APP_ROUTE_KEYS`
 * ## Why?
 * At some places, functionalities for old code will collide with repoV2.
 * eg: src/components/common/Root/index.tsx has old modal and payment gateway code.
 * We would not want to have the old functionality slow down the migrated(to repoV2) OR the new routes
 * this separation of routes will help in that.
 * ## Usage
 * use `APP_ROUTE_KEYS` at every place except where you will explicitly need this separation
 */
export const REPO_V2_APP_ROUTE_KEYS = {
    SALE: "sale",
    LEADERBOARD: "leaderboard",
    BRANDED_COMMUNITY: "BRANDED_COMMUNITY",
    CHECKOUT_LISTING: "checkout_listing",
    CHECKOUT_LISTING_TWO: "checkout_listing_2",
    JUMP_FORMS: "jforms",
    OFFERINGS: "offerings",
};

export const APP_ROUTE_KEYS = {
    EVENT: "event",
    HOME: "host",
    LOGIN: "login",
    PLAN: "plan",
    SUCCESS: "SUCCESS",
    CONTENT: "content",
    THANKYOU_PURCHASE: "thankyou_purchase",
    THANKYOU_OPTIN: "thankyou_optin",
    MY_CLASSES: "MY_CLASSES",
    PURCHASE_HISTORY: "PURCHASE_HISTORY",
    MY_MEETINGS: "MY_MEETINGS",
    TESTIMONIALS: "TESTIMONIALS",
    GALLERY: "GALLERY",
    SUBSCRIPTION_HISTORY: "SUBSCRIPTION_HISTORY",
    QUESTIONNAIRE: "QUESTIONNAIRE",
    ...REPO_V2_APP_ROUTE_KEYS,
};

/**
 * for comparison with Next router pathname key
 */
export const NEXT_ROUTE_PATHNAMES = {
    [APP_ROUTE_KEYS.HOME]: "/host",
    [APP_ROUTE_KEYS.EVENT]: "/event",
    [APP_ROUTE_KEYS.LOGIN]: "/login",
    [APP_ROUTE_KEYS.PLAN]: "/plan",
    [APP_ROUTE_KEYS.SUCCESS]: "/success",
    [APP_ROUTE_KEYS.SALE]: "/sale",
    [APP_ROUTE_KEYS.LEADERBOARD]: "/leaderboard",
    [APP_ROUTE_KEYS.BRANDED_COMMUNITY]: "/brandedCommunity",
    [APP_ROUTE_KEYS.CONTENT]: "/content",
    [APP_ROUTE_KEYS.MY_CLASSES]: "/myClasses",
    [APP_ROUTE_KEYS.PURCHASE_HISTORY]: "/purchaseHistory",
    [APP_ROUTE_KEYS.MY_MEETINGS]: "/myMeetings",
    [APP_ROUTE_KEYS.TESTIMONIALS]: "/testimonials",
    [APP_ROUTE_KEYS.GALLERY]: "/gallery",
    [APP_ROUTE_KEYS.SUBSCRIPTION_HISTORY]: "/subscription_history",
    [APP_ROUTE_KEYS.CHECKOUT_LISTING]: "/checkoutListing",
    [APP_ROUTE_KEYS.CHECKOUT_LISTING_TWO]: "/checkoutListing2",
    [APP_ROUTE_KEYS.THANKYOU_OPTIN]: "/thankyouPurchase",
    [APP_ROUTE_KEYS.THANKYOU_PURCHASE]: "/thankyouOptin",
};

/**
used for generating pathnames for navigating using router(or history) push, replace ..etc
 */
export const ROUTE_BASE_PATHNAMES = {
    [APP_ROUTE_KEYS.HOME]: "/",
    [APP_ROUTE_KEYS.LOGIN]: "/login",
    [APP_ROUTE_KEYS.PLAN]: "/plan",
    [APP_ROUTE_KEYS.SALE]: "/offer",
    [APP_ROUTE_KEYS.LEADERBOARD]: "/leaderboard",
    [APP_ROUTE_KEYS.BRANDED_COMMUNITY]: "/branded-community-home",
    [APP_ROUTE_KEYS.CHECKOUT_LISTING]: "/checkout",
    [APP_ROUTE_KEYS.CHECKOUT_LISTING_TWO]: "/checkout2",
    [APP_ROUTE_KEYS.THANKYOU_PURCHASE]: "/purchase/thankyou",
    [APP_ROUTE_KEYS.THANKYOU_OPTIN]: "/optin/thankyou",
    [APP_ROUTE_KEYS.MY_CLASSES]: "/my_bookings",
    [APP_ROUTE_KEYS.CONTENT]: "/content",
    [APP_ROUTE_KEYS.TESTIMONIALS]: "/testimonials",
    [APP_ROUTE_KEYS.GALLERY]: "/gallery",
    [APP_ROUTE_KEYS.SUBSCRIPTION_HISTORY]: "/subscription_history",
    [APP_ROUTE_KEYS.PURCHASE_HISTORY]: "/purchase_history",
    [APP_ROUTE_KEYS.QUESTIONNAIRE]: "/questionnaire",
    [APP_ROUTE_KEYS.OFFERINGS]: "/offerings",
};
