import { getThankyouPgCustomCodes } from "@Modules/thankyou/api/thankyou.api.calls";
import {
    THANKYOU_PAGE_TYPES,
    THANKYOU_PAGE_TYPES_VALUE,
} from "@Modules/thankyou/constants/thankyou.contants";
import { ROUTES, SELECTORS, useEventDataOfSelectedEvent } from "@Utils";
import { useRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";
import { ICustomCodeData } from "repoV2/interfaces/customCodes/ICustomCode";
import { useGetSalesPageCustomCode } from "repoV2/pages/sale/utils/useGetSalePageCustomCode";
import { useGetCustomCodes as useGetCustomCodesFromRedux } from "repoV2/features/CustomCodes/utils/helpers";

const getThankyouPageTypeBasedOnRoute = (route: string) => {
    switch (route) {
        case ROUTES.THANKYOU_OPTIN.route:
            return THANKYOU_PAGE_TYPES_VALUE[THANKYOU_PAGE_TYPES.OPTIN];
        case ROUTES.THANKYOU_PURCHASE.route:
            return THANKYOU_PAGE_TYPES_VALUE[THANKYOU_PAGE_TYPES.PURCHASE];
        default:
            return undefined;
    }
};

export const useGetThankyouPageCustomCode = ({
    listingUuid,
    hostName,
    pageType,
}: {
    listingUuid: string | undefined;
    hostName: string | undefined;
    pageType: number | undefined;
}) => {
    const [thankyouPageCodes, setThankyouPageCustomCodes] = React.useState<
        ICustomCodeData[]
    >([]);
    React.useEffect(() => {
        (async () => {
            if (listingUuid && pageType) {
                try {
                    const codes = await getThankyouPgCustomCodes({
                        listingUuid,
                        pageType,
                        hostName,
                    });
                    if (codes) setThankyouPageCustomCodes(codes);
                } catch (error) {
                    console.log(
                        "Error at useGetThankyouPageCustomCode due to getThankyouPgCustomCodes",
                        error
                    );
                }
            }
        })();
    }, []);

    return {
        thankyouPageCodes,
    };
};

export const useGetCustomCodes = () => {
    const router = useRouter();
    const { pathname = "", query = {} } = router || {};
    const { slug: salePageUuid = "", eventId = "" } = query;

    const hostData = useSelector(SELECTORS.hostData);
    const hostName = useSelector(SELECTORS.hostName);
    const { eventData } = useEventDataOfSelectedEvent();
    const { salesPageCodes } = useGetSalesPageCustomCode({
        salePageUuid: salePageUuid as string,
        subDomain: hostName,
    });
    const pageType = getThankyouPageTypeBasedOnRoute(pathname);
    // through route query param - eventId = (uuid or slug)
    // thankyou page api handles both the cases hence passed to this hook
    const { thankyouPageCodes } = useGetThankyouPageCustomCode({
        listingUuid: eventId as string,
        pageType,
        hostName,
    });

    const customCodesFromRedux = useGetCustomCodesFromRedux();

    let codes: ICustomCodeData[] = [];
    if (ROUTES.HOST.route === pathname) {
        codes = hostData?.custom_codes || [];
    } else if (ROUTES.EVENT.route === pathname) {
        codes = eventData?.custom_codes || [];
    } else if (ROUTES.SALE.route === pathname) {
        codes = salesPageCodes;
    } else if (
        ROUTES.THANKYOU_OPTIN.route === pathname ||
        ROUTES.THANKYOU_PURCHASE.route === pathname
    ) {
        codes = thankyouPageCodes;
    } else {
        codes = customCodesFromRedux;
    }
    return codes;
};
