import { IHost } from "@Interfaces";
import {
    EVENT_CATEGORISATION_TYPE,
    getAllEventCategories,
    handlePrependPlans,
    getEventListingsOfCategory,
    isEmpty,
    planListingFormat,
    ROUTES,
    SECTION_IDS,
    TEMPLATE_KEYS,
} from "@Utils";
import { INavbar } from "../INavbar";

export const getCategorisationProps = ({
    template,
    host,
    hostData,
    goToRoute,
}: {
    template?: string;
    host: IHost.IStore;
    hostData?: IHost.IHostData;
    goToRoute: INavbar.IGoToRoute;
}): {
    showEventListingsTitle: boolean;
    eventListingsTitle: string;
    showEventListingsCategoriesDropdown: boolean;
    eventListingsCategories: Array<INavbar.INavbarItem>;
} => {
    const eventListingsTitle = hostData?.offerings_title || "Offerings";

    const categoriseType =
        hostData?.categorise_type ||
        EVENT_CATEGORISATION_TYPE.NO_CATEGORISATION;
    let isCategorised: boolean;
    switch (template) {
        case TEMPLATE_KEYS.ELEMENTARY:
        case TEMPLATE_KEYS.UNFOLD:
        case TEMPLATE_KEYS.LINKTEMP:
            isCategorised = false;
            break;
        case TEMPLATE_KEYS.YOGA:
        case TEMPLATE_KEYS.MODERN:
        case TEMPLATE_KEYS.CLASSIC:
        default:
            isCategorised =
                categoriseType !== EVENT_CATEGORISATION_TYPE.NO_CATEGORISATION;
            break;
    }
    const listings = hostData?.listings || [];
    const plansData = host.plans;
    const planListings = (plansData?.list || []).map(item =>
        planListingFormat(item, plansData?.title)
    );

    const eventListings = handlePrependPlans(
        listings,
        planListings,
        hostData?.prepend_plans
    );
    const hasAnyEventListings = !isEmpty(eventListings);

    let eventListingsCategories: Array<INavbar.INavbarItem> = [];

    const allEventCategories = getAllEventCategories(
        hostData,
        plansData?.title,
        categoriseType
    );

    if (isCategorised && !isEmpty(allEventCategories) && hasAnyEventListings) {
        allEventCategories.forEach(
            ({ title: category, listingType }, index) => {
                if (
                    !isEmpty(
                        getEventListingsOfCategory(
                            category,
                            categoriseType,
                            eventListings,
                            { listingType }
                        )
                    )
                ) {
                    eventListingsCategories = [
                        ...eventListingsCategories,
                        {
                            content: category,
                            props: {
                                key: `${listingType ?? index}`,
                                href: `${
                                    ROUTES.HOST.urlRoute
                                }#${SECTION_IDS.eventSection(
                                    listingType ?? index
                                )}`,
                                onClick: e =>
                                    goToRoute(
                                        e,
                                        ROUTES.HOST,
                                        SECTION_IDS.eventSection(
                                            listingType ?? index
                                        )
                                    ),
                            },
                        },
                    ];
                }
            }
        );
    }

    const showEventListingsCategoriesDropdown =
        isCategorised && !isEmpty(eventListingsCategories);

    return {
        showEventListingsTitle:
            showEventListingsCategoriesDropdown ||
            (!isCategorised && hasAnyEventListings),
        eventListingsTitle,
        showEventListingsCategoriesDropdown,
        eventListingsCategories,
    };
};
