// @ts-ignore
import { checkout_page_button_types as _checkout_page_button_types } from "@my-scoot/exly-react-component-lib/build/cjs/features/CheckoutPages/constants/CheckoutPages.constants";
import { checkout_page_button_types as checkout_page_button_types_interface } from "@my-scoot/exly-react-component-lib/build/types/features/CheckoutPages/constants/CheckoutPages.constants";
import { ICheckoutPageReducerState } from "../interfaces/CheckoutPages.interface";
import { getUserId } from "../modules/PaymentDetailsSection/modules/FormGroups/modules/UserDetailsFormGroup/utils/getUserId";

/**
 - DEFAULT SECTION: includes data needed during SSR first render like custom codes, listing title etc. (SSR),
 - EXTENSIVE_SECTION: includes listing specific data like sessions(slots/classes)(based on timezone), certificates etc. (CSR),
 - PRICING_SECTION: includes listing specific pricing data and payment gateway related data, (CSR)
 - CREATOR_DETAILS_SECTION: includes host/creator specific data, (SSR)
 - CUSTOM_QUESTIONS_SECTION: includes booking custom questions related Data, (CSR)
 - FAQ_TESTIMONIALS_SECTION: includes listing specific miscellaneous data like faqs and testimonials (CSR)
 - SLUG_SECTION: includes listing specific seo details if changed, currently not in use (SSR)
 - EXPRESS_INTEREST_SECTION: includes express interest related data etc. custom questions etc. (CSR)
 - LOYALTY_AND_PROMOTIONAL_DISCOUNT_SECTION: includes check for show_loyalty and promotional discounts array. (CSR)
 */
export const CHECKOUT_PAGE_DETAILS_API_ENUMS = {
    LISTING_DETAILS_SECTION: "1",
    EXTENSIVE_SECTION: "2",
    PRICING_SECTION: "3",
    CREATOR_DETAILS_SECTION: "4",
    CUSTOM_QUESTIONS_SECTION: "5",
    FAQ_TESTIMONIALS_SECTION: "6",
    SLUG_SECTION: "7",
    EXPRESS_INTEREST_SECTION: "8",
    LOYALTY_AND_PROMOTIONAL_DISCOUNT_SECTION: "9",
} as const;
// @dev
// as const will convert an object keys as literal_type or exact value instead of value's type.
// This allows us to use the constant values as a key while creating any interface.

export const PAGE_SOURCES_VALUES = {
    EVENT_PAGE: "1",
    CHECKOUT_PAGE: "2",
};

/**
 * priceSummary is kept as a sibling to listingDetails as it is independent
 * of individual listing, specifically in the case of order bumps
 */
export const checkout_page_reducer_initial_state: ICheckoutPageReducerState = {
    listingDetails: {},
    priceSummary: {
        totalAmount: 0,
        strikeOffAmount: 0,
    },
    isPaymentProcessing: false,
    isExpressInterestProcessing: false,
};

export const booking_initial_user_id = getUserId(1);

/**
 * Custom questions can be of type:
 - Leads questions: To be shown in express interest or Get in touch form
 - Booking question: To be shown in booking form
 */
export const custom_question_response_types = {
    leads_questions: "leads_questions",
    booking_questions: "booking_questions",
} as const;

/**
 * Checkout form types
 - Payment form: To be shown in case of initiate booking
 - Express interest form: To be shown in case of express interest or join booking
 - Renew subscription form: To be shown in case of renewing a subscription
 */
export const checkout_form_types = {
    payment_form: "payment_form",
    express_interest_form: "express_interest_form",
    renew_subscription_form: "renew_subscription_form",
};

export const single_value_count = 1;

// Checkout Page has a Payment button, and a button on mobile screen which opens payment modal
// By default the button on mobile is called 'Next' and the payment button
// is 'Pay <amount>'. This is the 'default' button type.
// In 'custom' button type we can update the text of these two buttons
export const checkout_page_button_types: typeof checkout_page_button_types_interface =
    _checkout_page_button_types;

export const checkout_page_listing_cover_img_width = 480;
