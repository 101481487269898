export type ISocialPlatformKey =
    | "instagram"
    | "facebook"
    | "facebook_name"
    | "twitter"
    | "youtube"
    | "spotify"
    | "linkedin"
    | "tiktok"
    | "website_url";

export type ISocialPlatformData = {
    key: ISocialPlatformKey;
    subtitle: string;
    icon: string;
    color: string;
    templateIcons: { unlock: string };
};

export const SOCIAL_PLATFORMS: Record<ISocialPlatformKey, ISocialPlatformData> =
    {
        instagram: {
            key: "instagram",
            subtitle: "Followers",
            icon: "/static/images/socialIcons/instagram.svg",
            templateIcons: {
                unlock: "/static/images/socialIcons/instagram_unlock.svg",
            },
            color: "#CF106C",
        },
        facebook: {
            key: "facebook",
            subtitle: "Followers",
            icon: "/static/images/socialIcons/facebook.svg",
            templateIcons: {
                unlock: "/static/images/socialIcons/facebook_unlock.svg",
            },
            color: "#1777F2",
        },
        facebook_name: {
            key: "facebook_name",
            subtitle: "Followers",
            icon: "/static/images/socialIcons/facebook.svg",
            templateIcons: {
                unlock: "/static/images/socialIcons/facebook_unlock.svg",
            },
            color: "#1777F2",
        },
        twitter: {
            key: "twitter",
            subtitle: "Followers",
            icon: "/static/images/socialIcons/twitter.svg",
            templateIcons: {
                unlock: "/static/images/socialIcons/twitter_unlock.svg",
            },
            color: "#1DA1F2",
        },
        youtube: {
            key: "youtube",
            subtitle: "Subscribers",
            icon: "/static/images/socialIcons/youtube.svg",
            templateIcons: {
                unlock: "/static/images/socialIcons/youtube_unlock.svg",
            },
            color: "#FF0000",
        },
        spotify: {
            key: "spotify",
            subtitle: "Followers",
            icon: "/static/images/socialIcons/spotify.svg",
            templateIcons: {
                unlock: "/static/images/socialIcons/spotify_unlock.svg",
            },
            color: "#2EBD59",
        },
        linkedin: {
            key: "linkedin",
            subtitle: "Followers",
            icon: "/static/images/socialIcons/linkedin.svg",
            templateIcons: {
                unlock: "/static/images/socialIcons/linkedin_unlock.svg",
            },
            color: "#006699",
        },
        tiktok: {
            key: "tiktok",
            subtitle: "Followers",
            icon: "/static/images/socialIcons/tiktok.svg",
            templateIcons: {
                unlock: "/static/images/socialIcons/tiktok_unlock.svg",
            },
            color: "#CF106C",
        },
        website_url: {
            key: "website_url",
            subtitle: "Followers",
            icon: "/static/images/socialIcons/website.svg",
            templateIcons: {
                unlock: "/static/images/socialIcons/website_unlock.svg",
            },
            color: "#493AB1",
        },
    };
