import { IEvent } from "@Interfaces";

export const getVariantOptions = (variantDisplayData?: {
    [key: string]: string[];
}) =>
    Object.entries(variantDisplayData || {}).map(([type, options]) => ({
        type,
        options,
    }));

export const getVariantCombinationString = (
    combination: IEvent.ISelectedVariantCombination,
    variantTypeOrder?: string[]
) => {
    return variantTypeOrder
        ?.map(type => `${type}=${combination[type]}`)
        .join(",");
};

export const getVariantMapFromKey = (combinationKey?: string) => {
    return combinationKey?.split(",").reduce((prev, combination) => {
        const [key, value] = combination.split("=");
        return { ...prev, [key]: value };
    }, {});
};
