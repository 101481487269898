import dynamic from "next/dynamic";
import React from "react";
import { useRouter } from "next/router";
import {
    MS_CLARITY_KEY,
    MS_CLARITY_ROUTE_PATHNAMES,
} from "../MSClarity.constants";

const MSClarity = dynamic(() => import("../MSClarity"));

export const LazyLoadedMSClarity = () => {
    const router = useRouter();
    const { pathname = "" } = router || {};

    const enableMSClarity =
        // enable only when key is present
        // right now we are only doing it for production and no ther env
        MS_CLARITY_KEY &&
        // PO call - only enable MS clarity for the sales funnel flow (sale, checkout, thankyou optin)
        Object.values(MS_CLARITY_ROUTE_PATHNAMES).some(
            allowedPathname => allowedPathname === pathname
        );

    if (!enableMSClarity) return null;

    return <MSClarity />;
};
