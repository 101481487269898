import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import { ILoadingIndicatorProps } from "repoV2/components/common/LoadingIndicators/LoadingIndeCators";
import { LoadingIndicator as BaseLoadingIndicator } from "repoV2/components/common/LoadingIndicators";
import styles from "./LoadingIndicator.module.scss";

/**
 * @deprecated use `repoV2/components/common/LoadingIndicators/index.tsx`
 */
const LoadingIndicator = ({
    applyTheme,
    className,
    ...props
}: ILoadingIndicatorProps & { applyTheme?: boolean }) => {
    const customClass = classNames(className, applyTheme && styles.applyTheme);

    const loadingIndicatorProps = {
        className: customClass,
        ...props,
    };

    return <BaseLoadingIndicator {...loadingIndicatorProps} />;
};

LoadingIndicator.defaultProps = {
    applyTheme: undefined,
};

export { LoadingIndicator };
