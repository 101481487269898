export const FORM_INPUT_TYPES = {
    EMAIL: "email",
    NUMBER: "number",
    TEXT: "text",
    TEL: "tel",
    TEXTAREA: "textarea",
    SINGLE_SELECT_DROPDOWN: "single_select_dropdown",
    MULTI_SELECT_CHECKBOXES: "multi_select_checkboxes",
    DATE: "date",
    FILE: "file",
    URL: "url",
};
