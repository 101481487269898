/**
 * converts string to a hash, can be used for `array.map` where key is not provided
 * but can be generated from other data in the array item
 *
 * https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 */
export const get32BitIntegerCode = (str: string) => {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i += 1) {
        const chr = str.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + chr;
        // eslint-disable-next-line no-bitwise
        hash |= 0; // Convert to 32bit integer
    }
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Unsigned_right_shift
    // eslint-disable-next-line no-bitwise
    return hash >>> 0; // Make sure hash is non-negative
};
