/* eslint-disable global-require */
import { createStore, applyMiddleware } from "redux";
import {
    composeWithDevTools,
    EnhancerOptions,
} from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware from "redux-saga";
import Router from "next/router";
import {
    createRouterMiddleware,
    initialRouterState,
} from "connected-next-router";
import { MakeStore } from "next-redux-wrapper";

import { IStore } from "@Interfaces";
import initialState from "./initialState";

import rootReducer from "./reducers";
import getSagas from "./sagas";

const routerMiddleware = createRouterMiddleware();

export const makeStore: MakeStore = (
    preloadedState = initialState,
    options: any
) => {
    const { asPath } =
        options?.res || (!options?.isServer && Router?.router) || {};

    const sagaMiddleware = createSagaMiddleware();
    const composeEnhancers = composeWithDevTools({
        traceLimit: 50, // TODO: Move to .env file
        trace: true,
    } as EnhancerOptions);
    const store: IStore.AppStore = {
        ...createStore(
            rootReducer,
            {
                ...preloadedState,
                // https://www.npmjs.com/package/connected-next-router/v/0.0.6
                router: initialRouterState(asPath),
            },
            composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware))
        ),
        sagaTask: sagaMiddleware.run(getSagas()),
    };

    if ((module as any).hot) {
        // https://stackoverflow.com/questions/37148592/redux-saga-hot-reloading
        // https://github.com/kirill-konshin/next-redux-wrapper/blob/master/packages/demo/src/components/store.tsx

        (module as any).hot.accept("./reducers", () => {
            store.replaceReducer(require("./reducers/index"));
        });
        (module as any).hot.accept("./sagas", () => {
            const getNewSagas = require("./sagas/index").default;
            store.sagaTask.cancel();
            store.sagaTask.toPromise().then(() => {
                store.sagaTask = sagaMiddleware.run(function* replacedSaga() {
                    yield getNewSagas();
                });
            });
        });
    }

    return store;
};
