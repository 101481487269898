import {
    BOOKING_PAYMENT_TYPE,
    LISTING_TYPES as _LISTING_TYPES,
} from "repoV2/features/Listing/Listing.constants";
import { INSTALLMENTS_DURATION_CHOICE } from "repoV2/features/Payments/Payments.constants";

/**
 * @deprecated - use the one in features folder
 */
export const LISTING_TYPES = _LISTING_TYPES;

export const PAYMENT_PLAN_TYPE = {
    ONE_TIME_PAYMENT: 1,
    SUBSCRIPTIONS: 2,
    INSTALLMENTS: 3,
};

/**
 * @deprecated - use the one in features folder
 */
export const ORDER_PAYMENT_TYPE = BOOKING_PAYMENT_TYPE;

/**
 * @deprecated - use the one in repoV2/features/Payments/Payments.constants
 */
export const INSTALMENT_DURATION_TYPES = INSTALLMENTS_DURATION_CHOICE;

/**
 * @deprecated Please use the one defined at repoV2/features/Listing/Listing.constants.ts
 */
// export const QUESTION_PLACEMENT = _QUESTION_PLACEMENT;

export const ADDRESS_TYPE = {
    NONE: -1,
    MEET_AUTO_GENERATE: 0,
    EXISTING_LINK: 1,
    PERSONAL_ADDRESS: 2,
    CUSTOMER_ADDRESS: 3,
    ZOOM_AUTO_GENERATE: 4,
    PHONE_NUMBER: 5,
    WHATSAPP: 6,
    EXLY_CONNECT: 7,
    ZOOM_WEBINAR: 8,
};
