import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ICustomCodeData } from "repoV2/interfaces/customCodes/ICustomCode";
import { CUSTOM_CODES_ACTION_TYPES } from "../constants";
import { IState } from "../interfaces";

export const useGetCustomCodes = () => {
    const customCodes = useSelector((state: IState) => state.customCodes.codes);
    return customCodes;
};

export const useGetCustomCodesSetters = () => {
    const dispatch = useDispatch();

    const setCustomCodes = ({
        customCodes,
    }: {
        customCodes: ICustomCodeData[];
    }) => {
        dispatch({
            type: CUSTOM_CODES_ACTION_TYPES.SET,
            payload: {
                customCodes,
            },
        });
    };

    const resetCustomCodes = () => {
        dispatch({
            type: CUSTOM_CODES_ACTION_TYPES.SET,
            payload: {
                customCodes: [],
            },
        });
    };

    return {
        setCustomCodes,
        resetCustomCodes,
    };
};

export const useSetCustomCodes = ({
    customCodes,
}: {
    customCodes: ICustomCodeData[];
}) => {
    const { setCustomCodes } = useGetCustomCodesSetters();
    useEffect(() => {
        setCustomCodes({ customCodes });
    }, []);
};
