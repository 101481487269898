/* eslint-disable @next/next/no-img-element */
import { Button } from "@Modules/common";
import {
    ACTION_TYPES,
    API_ACTION_TYPES,
    BUTTON_DESIGNS,
    REACTION_TYPES,
    ROUTES,
    createAction,
    getVisitorDetails,
    isBrowser,
    trimText,
    useData,
} from "@Utils";
import { BsHeartFill } from "@react-icons/all-files/bs/BsHeartFill";
import { IoShareSocialSharp } from "@react-icons/all-files/io5/IoShareSocialSharp";
import { MdModeComment } from "@react-icons/all-files/md/MdModeComment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { classNames } from "repoV2/utils/common/render/classNames";
import { parseDateTime } from "@Utils/parseDateTime";
import styles from "./BlogPostCard.module.scss";
import { IBlogPostCard } from "./IBlogPostCard";

export const BlogPostCard = ({
    data,
    preview,
    handlePreviewPopup,
    className,
}: IBlogPostCard) => {
    const [userReactionType, setUserReactionType] = useState(
        REACTION_TYPES.NONE
    );
    const [userReactionDelta, setUserReactionDelta] = useState(0);
    const [userReactionDeltaType, setUserReactionDeltaType] = useState(
        REACTION_TYPES.NONE
    );

    useEffect(() => {
        setUserReactionType(data?.visitor_reaction || REACTION_TYPES.NONE);
    }, [data?.visitor_reaction]);

    const dispatch = useDispatch();
    const reactionClick = (reactionType: number) => {
        if (preview) {
            handlePreviewPopup?.();
            return;
        }
        const visitorDetails = getVisitorDetails();
        if (!visitorDetails.uuid) {
            openBlogVisitorPopup();
        } else {
            const undo = reactionType === userReactionType;

            dispatch(
                createAction(ACTION_TYPES.UTILS.API_CALL, {
                    apiActionType: API_ACTION_TYPES.UPDATE_BLOG_REACTION,
                    urlArgs: {
                        blogPostUuid: data.uuid,
                    },
                    payload: {
                        visitor_uuid: visitorDetails.uuid,
                        reaction: reactionType,
                        reaction_undo: undo,
                    },
                    successCallback: () => {
                        setUserReactionType(
                            undo ? REACTION_TYPES.NONE : reactionType
                        );
                        setUserReactionDelta(
                            userReactionDelta + (undo ? -1 : 1)
                        );
                        setUserReactionDeltaType(reactionType);
                    },
                })
            );
        }
    };

    const postRoute = `${ROUTES.BLOG_POST.urlPath}/${data?.uuid}`;
    let shareUrl = "";
    if (isBrowser()) {
        shareUrl = `${window.location.origin}${postRoute}`;
    }

    const commentClick = () => {
        if (preview) {
            handlePreviewPopup?.();
            return;
        }
        if (isBrowser()) {
            window.location.href = `${postRoute}/#comments`;
        }
    };

    const cardClick = () => {
        if (preview) {
            handlePreviewPopup?.();
            return;
        }
        if (isBrowser()) {
            window.location.href = `${postRoute}`;
        }
    };

    const { setData: setSharePopupData } = useData("sharePopupData", {
        show: false,
        props: {},
    });
    const shareClick = () => {
        if (preview) {
            handlePreviewPopup?.();
            return;
        }
        setSharePopupData({
            show: true,
            props: {
                popupTitle: "Share this Blog Post",
                shareTitle: data?.title || "",
                shareUrl,
                shareText: `${data?.title || ""} - ${shareUrl}`,
            },
        });
    };

    const { setData: setShowBlogVisitorPopup } = useData(
        "showBlogVisitorPopup",
        false
    );
    const openBlogVisitorPopup = () => {
        if (preview) {
            handlePreviewPopup?.();
            return;
        }
        setShowBlogVisitorPopup(true);
    };

    return (
        <div className={classNames(styles.container, className)}>
            <Button
                className={styles.blogButton}
                design={BUTTON_DESIGNS.PLAIN_TEXT}
                onClick={cardClick}
                content={
                    <>
                        {data.thumbnail_image ? (
                            <img
                                src={data.thumbnail_image}
                                className={styles.image}
                                alt={data.title}
                            />
                        ) : null}
                        <div className={styles.infoContainer}>
                            <div
                                className={classNames(
                                    styles.title,
                                    styles.fontWeightBold
                                )}
                            >
                                {trimText(data.title || "", 30)}
                            </div>
                            <div>
                                Published by{" "}
                                <span className={styles.fontWeightBold}>
                                    {trimText(data.author_name || "", 30)}
                                </span>
                            </div>
                            <div className={styles.timeInfo}>
                                {data.created_at
                                    ? parseDateTime(
                                          data.created_at,
                                          "MMM dd, yyyy"
                                      )
                                    : null}
                            </div>
                        </div>
                    </>
                }
            />
            <div className={styles.footer}>
                <hr />
                <div className={styles.postStatsContainer}>
                    <Button
                        design={BUTTON_DESIGNS.PLAIN_TEXT}
                        content={
                            <>
                                <BsHeartFill
                                    color={
                                        userReactionType === REACTION_TYPES.LIKE
                                            ? "#ff6262"
                                            : "grey"
                                    }
                                />
                                <div className={styles.statCount}>
                                    {(data?.like_count || 0) +
                                        (userReactionDeltaType ===
                                        REACTION_TYPES.LIKE
                                            ? userReactionDelta
                                            : 0)}
                                </div>
                            </>
                        }
                        className={styles.postStatContainer}
                        onClick={() => {
                            reactionClick(REACTION_TYPES.LIKE);
                        }}
                    />
                    <Button
                        design={BUTTON_DESIGNS.PLAIN_TEXT}
                        content={
                            <>
                                <MdModeComment size="0.9rem" />
                                <div className={styles.statCount}>
                                    {data.comment_count}
                                </div>
                            </>
                        }
                        className={styles.postStatContainer}
                        onClick={commentClick}
                    />
                    <Button
                        design={BUTTON_DESIGNS.PLAIN_TEXT}
                        content={<IoShareSocialSharp size="0.9rem" />}
                        className={styles.postStatContainer}
                        onClick={shareClick}
                    />
                </div>
            </div>
        </div>
    );
};

BlogPostCard.defaultProps = {};
