// Put the general constants here

import { IDispatchStatus } from "@Interfaces/redux/store/ICommon";
import { URL_SEARCH_PARAMS } from "repoV2/constants/urls&routing/urlParams";
import {
    LOCAL_STORAGE_KEYS,
    LEGACY_LOCAL_STORAGE_KEYS,
} from "repoV2/constants/common/storage/localStorage";
import { ICreatorPages } from "repoV2/features/CreatorPages/CreatorPages.interfaces";
import { TEMPLATE_KEYS as _TEMPLATE_KEYS } from "repoV2/features/CreatorPages/CreatorPages.constants";

/**
 * @deprecated move key to `URL_SEARCH_PARAMS` in `repoV2/constants/urls&routing.ts` and use that
 */
export const QUERY_PARAMS = {
    DYNAMIC_LINK: URL_SEARCH_PARAMS.DYNAMIC_LINK,
    INIT_BOOKING: URL_SEARCH_PARAMS.INIT_BOOKING,
    INIT_CONTACT: URL_SEARCH_PARAMS.INIT_CONTACT,
    ENABLE_DISCOUNT: "enable_discount", // To enable discount on user details form when init_booking=true
    TRANSACTION_STATUS: "status", // Success/Failure/Processing transaction status number
    TRANSACTION_ID: "transaction", // Transaction ID
    FORCE_INTERNATIONAL: URL_SEARCH_PARAMS.FORCE_INTERNATIONAL, // [Dev only]: Force the currency to be USD
    CUSTOM_PRICE: URL_SEARCH_PARAMS.CUSTOM_PRICE, // Custom price being paid
    EVENT_ID: URL_SEARCH_PARAMS.EVENT_ID, // ID of the event(for custom payment post-payment status display)
    ENABLE_LOGGING: "enable_logging", // Enable logging of redux actions. Only works in dev environment
    CURRENCY: "currency",
    PLAN_ID: URL_SEARCH_PARAMS.PLAN_ID,
    COMPACT: "compact", // show compact event page when opened as a separate page(i.e. not  as a modal in host page)
    RENEW_BOOKING: URL_SEARCH_PARAMS.RENEW_BOOKING, // true if event page is opened for renewal of offering type class
    ENABLE_PAYPAL: "enable_paypal", // true if we want to enable paypal booking flow

    // To re-initiate payment after card abandonment
    ORDER: "order",
    ACTION: "action",

    DOWNLOAD: "download",

    REPLY_TO: "reply_to", // For recorded content comments, so as to tell the back-end to trigger certain comms for a reply to a comment

    TEMPLATE: "template",
    THEME: "theme",
    FONT: "font",

    // used for login flow
    NEXT: URL_SEARCH_PARAMS.NEXT, // for redirecting to next url after login
    ACCESS_TOKEN: "access_token",
    REFRESH_TOKEN: "refresh_token",
    CUSTOMER_UUID: "customer_uuid",
    CUSTOMER_NAME: "customer_name",
    CUSTOMER_EMAIL: "customer_email",
    GOOGLE_LOGIN_ERROR: "gle",
    GOOGLE_LOGIN_ERROR_MESSAGE: "gle_message",
    SUBDOMAIN: "subdomain",
    LOGO: "logo",
};

/**
 * @deprecated
 * use `ITemplateKey` from repoV2/features/CreatorPages/CreatorPages.constants.ts
 */
export declare type ITemplateKey = ICreatorPages.ITemplateKey;

/**
 * @deprecated
 * use `TEMPLATE_KEYS` from repoV2/features/CreatorPages/CreatorPages.constants.ts
 */
export const TEMPLATE_KEYS: Record<`${Uppercase<ITemplateKey>}`, ITemplateKey> =
    _TEMPLATE_KEYS;

export const DEFAULT_TEMPLATE_KEY = TEMPLATE_KEYS.MODERN;

export const TEMPLATE_DATA = {
    [TEMPLATE_KEYS.CLASSIC]: {
        BOOKING_BUTTON_BORDER_RADIUS: "1000px",
    },
    [TEMPLATE_KEYS.ELEMENTARY]: {
        BOOKING_BUTTON_BORDER_RADIUS: "0px",
    },
    [TEMPLATE_KEYS.MODERN]: {
        BOOKING_BUTTON_BORDER_RADIUS: "8px",
    },
    [TEMPLATE_KEYS.UNFOLD]: {
        BOOKING_BUTTON_BORDER_RADIUS: "8px",
    },
    [TEMPLATE_KEYS.LINKTEMP]: {
        BOOKING_BUTTON_BORDER_RADIUS: "12px",
    },
    [TEMPLATE_KEYS.YOGA]: {
        BOOKING_BUTTON_BORDER_RADIUS: "8px",
    },
    [TEMPLATE_KEYS.EDUVERSE]: {
        BOOKING_BUTTON_BORDER_RADIUS: "4px",
    },
    [TEMPLATE_KEYS.GROWTH]: {
        BOOKING_BUTTON_BORDER_RADIUS: "4px",
    },
};

export const DEFAULT_FONT = {
    slug: "open-sans",
    title: "Open Sans",
    url: "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap",
};

/*  Data to be stored for payment retry
 * - Booking data for base listing: Everything from event.paymentInfo
 * - Customer data: [{details, answers}]
 * - Discount: discount code [for API hit]
 */

// Use this for storing keys to be used for local/session storage
// TODO: Comment the usage of each of the key
/**
 * @deprecated use `repoV2/constants/storage/`
 */
export const STORAGE_KEYS = {
    // Stored during handleLogin
    CUSTOMER_DETAILS: "customer_details",
    STRIPE_CUSTOMER_DETAILS: "stripe_customer_details",
    DISCOUNT_CODE: "discount_code", // Discount code applied. Useful for payment retry
    DISCOUNT_CODE_TYPE: "discount_code_type", // Discount code type - loyalty or normal
    LOYALTY_DISCOUNT_EMAIL: "loyalty_discount_email", // Discount code type - loyalty or normal
    SELECTED_PAYMENT_GATEWAY: "selected_payment_gateway",
    USER_NAME_LIST: "username_list",

    // Booking data for the specific listing like class/slot/quantity
    // and custom_price, stored during initiate
    BOOKING_DATA: "booking_data",
    TOTAL_AMOUNT: "totalAmount",
    CURRENCY_SYMBOL: "currencySymbol",

    CUSTOMER_UUID: LEGACY_LOCAL_STORAGE_KEYS.CUSTOMER_UUID, // Username as fetched from the Login API call
    CUSTOMER_NAME: LEGACY_LOCAL_STORAGE_KEYS.CUSTOMER_NAME,
    IS_CREATOR: LOCAL_STORAGE_KEYS.IS_CREATOR, // Used for keeping track of if the login done is by the creator of the page itself, instead of an end-user with purchases
    EXIT_INTENT_COUNT: "bookedDemoPopupCount", // This string is kept for legacy reasons. Can be changed if appropriate
    ACCESS_TOKEN: LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
    REFRESH_TOKEN: LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
    EMAIL: LEGACY_LOCAL_STORAGE_KEYS.EMAIL,
    PHONE_NUMBER: "phone_number",
    COUNTRY_CODE: "country_code",
    BOOKED_DEMO: "bookedDemo", // boolean to tell whether user has booked a demo or not

    // visitor details, used for blogs
    VISITOR_EMAIL: "visitor_email",
    VISITOR_NAME: "visitor_name",
    VISITOR_UUID: "visitor_uuid",
    BLOG_SUBSCRIPTION_EMAIL: "blog_subscription_email",

    FCM_TOKEN: "FCM_TOKEN", // firebase cloud messaging token

    TIMEZONE: "timezone", // Timezone value for the user. LocalStorage value is preferred over the one supplied by the browser to allow user to choose
    CART: "cart",

    LOGIN_TYPE: "login_type", // Used to determine login type is number or email
};

export const CUSTOM_QUESTIONS = {
    // If an option has the following prefix, that is an indication to
    // show the "Other" option where a custom input is to be accepted
    OTHER_OPT_PREFIX: "_opt_other_:",
    MALE: "Male",
    FEMALE: "Female",
};

export const GENDER_CHOICES = [
    CUSTOM_QUESTIONS.MALE,
    CUSTOM_QUESTIONS.FEMALE,
    `${CUSTOM_QUESTIONS.OTHER_OPT_PREFIX}Other`,
];

/**
 * @deprecated
 * use `indian_states_and_uts` from `repoV2/features/Common/modules/Location/constants/indianStatesUtsData.ts`
 */
export const INDIAN_STATES = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
];

/**
 * @deprecated Use the cities defined at repoV2/features/Common/modules/Location/constants/indianCitiesData.ts
 * Note: The new array does not contain 'Other' option
 */
export const INDIAN_CITIES = [
    "Mumbai",
    "Kolkāta",
    "Bangalore",
    "Chennai",
    "Hyderābād",
    "Pune",
    "Ahmedabad",
    "Sūrat",
    "Prayagraj",
    "Lucknow",
    "Jaipur",
    "Cawnpore",
    "Mirzāpur",
    "Nāgpur",
    "Ghāziābād",
    "Vadodara",
    "Vishākhapatnam",
    "Indore",
    "Thāne",
    "Bhopāl",
    "Chinchvad",
    "Patna",
    "Bilāspur",
    "Ludhiāna",
    "Agwār",
    "Āgra",
    "Madurai",
    "Jamshedpur",
    "Nāsik",
    "Farīdābād",
    "Aurangābād",
    "Rājkot",
    "Meerut",
    "Jabalpur",
    "Kalamboli",
    "Vasai",
    "Najafgarh",
    "Vārānasi",
    "Srīnagar",
    "Dhanbād",
    "Amritsar",
    "Alīgarh",
    "Guwāhāti",
    "Hāora",
    "Rānchi",
    "Gwalior",
    "Chandīgarh",
    "Vijayavāda",
    "Jodhpur",
    "Raipur",
    "Kota",
    "Kālkāji Devi",
    "Bhayandar",
    "Ambattūr",
    "Salt Lake City",
    "Bhātpāra",
    "Kūkatpalli",
    "Darbhanga",
    "Dāsarhalli",
    "Muzaffarpur",
    "Oulgaret",
    "New Delhi",
    "Tiruvottiyūr",
    "Puducherry",
    "Byatarayanpur",
    "Pallāvaram",
    "Secunderābād",
    "Shimla",
    "Puri",
    "Shrīrāmpur",
    "Hugli",
    "Chandannagar",
    "Sultānpur Mazra",
    "Krishnanagar",
    "Bārākpur",
    "Bhālswa Jahangirpur",
    "Nāngloi Jāt",
    "Yelahanka",
    "Titāgarh",
    "Dam Dam",
    "Bānsbāria",
    "Madhavaram",
    "Baj Baj",
    "Nerkunram",
    "Kendrāparha",
    "Sijua",
    "Manali",
    "Chakapara",
    "Pāppākurichchi",
    "Herohalli",
    "Madipakkam",
    "Sabalpur",
    "Salua",
    "Balasore",
    "Jālhalli",
    "Chinnasekkadu",
    "Jethuli",
    "Nagtala",
    "Bāgalūr",
    "Pakri",
    "Hunasamaranhalli",
    "Hesarghatta",
    "Bommayapālaiyam",
    "Gundūr",
    "Punādih",
    "Harilādih",
    "Alāwalpur",
    "Mādnāikanhalli",
    "Kādiganahalli",
    "Mahuli",
    "Zeyādah Kot",
    "Arshakunti",
    "Hīrāpur",
    "Mirchi",
    "Sonudih",
    "Sondekoppa",
    "Babura",
    "Mādavar",
    "Kadabgeri",
    "Nanmangalam",
    "Taliganja",
    "Tarchha",
    "Belgharia",
    "Kammanhalli",
    "Sonnappanhalli",
    "Kedihāti",
    "Doddajīvanhalli",
    "Simli Murārpur",
    "Sonāwān",
    "Devanandapur",
    "Tribeni",
    "Huttanhalli",
    "Nathupur",
    "Bāli",
    "Vājarhalli",
    "Saino",
    "Shekhpura",
    "Cāchohalli",
    "Nārāyanpur Kola",
    "Gyan Chak",
    "Kasgatpur",
    "Kitanelli",
    "Harchandi",
    "Santoshpur",
    "Bendravādi",
    "Kodagihalli",
    "Harna Buzurg",
    "Mailanhalli",
    "Sultānpur",
    `${CUSTOM_QUESTIONS.OTHER_OPT_PREFIX}Other`,
];

export const DEFAULT_FILE_UPLOAD_ACCEPTED_TYPE =
    "audio/*,video/*,image/*,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,application/pdf";

export const REDUX_DATA_KEYS = {
    START_BOOKINGS_FLOW_ANALYTICS_TRIGGERED:
        "startBookingFlowAnalyticsTriggered",
};

export const COMMENT_STATUS = {
    SHOWN: 1,
    HIDDEN: 2,
};

export const DISPATCH_STATUS: {
    [dispatchStatus in "PENDING" | "DISPATCHED" | "DELIVERED"]: IDispatchStatus;
} = {
    PENDING: 1,
    DISPATCHED: 2,
    DELIVERED: 3,
};

export const LOGIN_TYPE = {
    [STORAGE_KEYS.PHONE_NUMBER]: 0,
    [STORAGE_KEYS.EMAIL]: 1,
};
