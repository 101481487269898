import React, { useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";

import { ButtonV2 } from "@Modules/common/ButtonV2";
import styles from "./stripeForm.module.scss";
import { STRIPE_FORM_RETURN_URL } from "../../../../../repoV2/utils/payment/Stripe/Stripe.utils";

// This component needs to have the context provided by <Elements />, so is placed as a different component
const CheckoutForm = ({ handleClose }: { handleClose: () => void }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isReady, setReady] = useState<boolean>(false);
    const [isProcessing, setProcessing] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!stripe || !elements) {
            alert("Stripe initialisation error!");
            return;
        }
        setProcessing(true);
        try {
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: STRIPE_FORM_RETURN_URL,
                },
            });
            setProcessing(false);
            if (error?.message) {
                alert(error.message);
            }
        } catch (error) {
            setProcessing(false);
            console.log("An error occurred.");
        }
    };

    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            <div className={styles.header}>
                <div className={styles.title}>Payment Details</div>
                <div className={styles.close} onClick={handleClose}>
                    &times;
                </div>
            </div>

            <PaymentElement onReady={() => setReady(true)} />
            <ButtonV2
                disabled={isProcessing || !isReady}
                type="submit"
                className={styles.payCta}
            >
                {isProcessing ? "Processing Payment..." : "Pay with Card"}
            </ButtonV2>
        </form>
    );
};

export default CheckoutForm;
