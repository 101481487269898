import { useSelector } from "react-redux";
import { IStore } from "@Interfaces";
import { FETCH_STATUS, FetchTypes, API_ACTION_TYPES } from "../constants";

// Get the fetchStatus value for a given API Action
export const useFetchStatus = (apiActionType: string): FetchTypes | null => {
    const returnValue = useSelector(
        (state: IStore.IState) => state.utils.fetchStatus[apiActionType]
    ) as FetchTypes;

    if (apiActionType in API_ACTION_TYPES) return returnValue;
    return null;
};

// Get a promise for the fetchStatus value of a given API
// Action that resolves/rejects based on the fetchStatus
export const useFetchStatusPromise = (
    apiActionType: string
): Promise<string | undefined> => {
    const status: FetchTypes | null = useFetchStatus(apiActionType);
    return new Promise((resolve, reject) => {
        // console.log({ status });
        switch (status) {
            case FETCH_STATUS.FETCHED:
                resolve(status);
                break;
            case FETCH_STATUS.NOT_FOUND:
            case FETCH_STATUS.FETCH_ERROR:
                reject(status);
                break;
            default:
                break;
        }
    });
};
