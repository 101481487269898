import React, { createContext, useContext } from "react";
import { IAppCtxContextProviderProps } from "./IRootComponent";

const AppCtxContext = createContext<IAppCtxContextProviderProps["ctx"]>({
    pathname: "",
    asPath: "",
    req: {
        query: {},
        headers: {},
    },
});

export const AppCtxContextProvider = ({
    children,
    ctx,
}: IAppCtxContextProviderProps) => {
    return (
        <AppCtxContext.Provider value={ctx}>{children}</AppCtxContext.Provider>
    );
};

export const useAppCtxContext = () => useContext(AppCtxContext);
