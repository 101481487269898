import { IUser } from "@Interfaces";
import { isDefined } from "repoV2/utils/common/dataTypes/common";
import { getRandomKey, isEmpty } from "@Utils";
import { useDispatch, useSelector } from "react-redux";
import {
    ACTION_TYPES,
    createAction,
    FORM_FIELDS,
    FORM_FIELD_PROPS_FALLBACK,
    SELECTORS,
} from "../constants";

/**
 * @deprecated implement new forms using `react-hook-form`
 */
export namespace IFormHook {
    /**
     * @deprecated implement new forms using `react-hook-form`
     */
    export interface IAddFormFieldsParam {
        key: string;
        value?: string;
        inputType?:
            | "text"
            | "email"
            | "tel"
            | "textarea"
            | "select"
            | "checkbox"
            | "date";
        error?: boolean;
        errorMessage?: string;
        showError?: boolean;
        editingDisabled?: boolean;
        initialValue?: string;
        validate?: (value: string, extraData?: { [k: string]: any }) => string;
        hidden?: (value?: string, extraData?: { [k: string]: any }) => boolean;
        mandatory?: (
            value?: string,
            extraData?: { [k: string]: any }
        ) => boolean;
        processInput?: (
            value: string,
            extraData?: { [k: string]: any }
        ) => string;
        [k: string]: any; // for storing any other field property like label, placeholder
    }

    /**
     * @deprecated implement new forms using `react-hook-form`
     */
    export interface ISetFormFieldsDataParam {
        key: string;
        value?: string;
        error?: boolean;
        errorMessage?: string;
        showError?: boolean;
    }

    /**
     * @deprecated implement new forms using `react-hook-form`
     */
    export interface IDeleteFormFieldsParam {
        key: string;
    }
}

const createFormField = ({
    fieldParam,
    prevField,
    fieldMethodsExtraData,
    keepPreviousValues,
    pickPreDefinedFieldProps,
}: {
    fieldParam: IFormHook.IAddFormFieldsParam;
    fieldMethodsExtraData?: { [k: string]: any };
    keepPreviousValues?: boolean;
    prevField?: IUser.IFormField;
    pickPreDefinedFieldProps?: boolean;
}) => {
    const fieldKey = fieldParam.key;
    /*
        override priority:
        1. passed field prop
        2. previous value if keepPreviousValues === true
        3. predefined field prop if pickPreDefinedFieldProps === true
        4. fallback value
    */
    const finalField = {
        ...FORM_FIELD_PROPS_FALLBACK,
        ...(pickPreDefinedFieldProps ? FORM_FIELDS[fieldKey] || {} : {}),
        ...(keepPreviousValues ? prevField || {} : {}),
        ...fieldParam,
    };

    const initialValue = finalField.processInput(
        finalField.initialValue,
        fieldMethodsExtraData
    );
    const value = initialValue;
    const errorMessage = finalField.validate(value, fieldMethodsExtraData);
    return {
        ...finalField,
        initialValue,
        value,
        error: !isEmpty(errorMessage),
        errorMessage,
    };
};

/**
 * @deprecated implement new forms using `react-hook-form`
 */
export const useFormRead = (formKeyParam: string) => {
    const { forms } = useSelector(SELECTORS.user);
    return {
        formData: forms[formKeyParam] || {
            key: formKeyParam,
            fields: {},
            options: {},
        },
    };
};

/**
 * @deprecated implement new forms using `react-hook-form`
 *
 *
 * - `value` and `initialValue` are stored as stirngs, so it is advised to pass "stringified" values
 * while creating or updating a form
 *
 * - In `createForm` or `addFormFields`, `initilaValue` property is used instead of `value` for a form field.
 *
 * - `formKeyParam` is the unique identifier to reference the same form from different places.
 * It should not be skipped in most cases. In case a form is used only in one component,
 * it can be skipped and the hook will generate the key for you.
 *
 * - In case you define custom field functions(`validate`, `hidden`, `mandatory`, `processInput`),
 * use `fieldMethodsExtraData` to pass any custom parameter.
 *
 * - It is not necessary to pass `fieldMethodsExtraData` in createForm and resetFormData,
 * but it is advised to pass, to avoid bugs.
 */

export const useForm = (formKeyParam?: string) => {
    const dispatch = useDispatch();

    const formKey = formKeyParam || getRandomKey();

    const { formData } = useFormRead(formKey);

    const createForm = ({
        fields: fieldsParam,
        fieldMethodsExtraData,
        keepPreviousValues = true,
        pickPreDefinedFieldProps = true,
        setErrorOnValueChange = true, // used by setFormData form only
    }: {
        fields: Array<IFormHook.IAddFormFieldsParam>;
        fieldMethodsExtraData?: { [k: string]: any };
        keepPreviousValues?: boolean;
        pickPreDefinedFieldProps?: boolean;
        setErrorOnValueChange?: boolean;
    }) => {
        let fields = {};
        fieldsParam.forEach(fieldParam => {
            const field = createFormField({
                fieldParam,
                prevField: formData.fields[fieldParam.key],
                fieldMethodsExtraData,
                keepPreviousValues,
                pickPreDefinedFieldProps,
            });

            fields = {
                ...fields,
                [field.key]: field,
            };
        });

        dispatch(
            createAction(ACTION_TYPES.USER.INITIATE_FORM, {
                key: formKey,
                fields,
                options: { setErrorOnValueChange },
                initiated: true,
            })
        );
    };

    const resetFormData = ({
        fieldMethodsExtraData,
    }: {
        fieldMethodsExtraData?: { [k: string]: any };
    }) => {
        let formFields = {};
        Object.values(formData.fields).forEach(field => {
            const errorMessage = field.validate(
                field.initialValue,
                fieldMethodsExtraData
            );
            formFields = {
                ...formFields,
                [field.key]: {
                    ...field,
                    value: field.initialValue,
                    error: !isEmpty(errorMessage),
                    errorMessage,
                    showError: false,
                },
            };
        });

        dispatch(
            createAction(ACTION_TYPES.USER.RESET_FORM_DATA, {
                key: formKey,
                fields: formFields,
            })
        );
    };

    /**
     * If no param is passed, it deletes the form with which the hook is called.
     *
     * When `Array<formKeys>`is passed, all the forms in the array are deleted.
     * It reduces the number of dipatch calls and eliminates the race condition.
     *
     * This is useful in UserDetails modal, when modal is closed or form is submitted,
     * we need to clear form data for all users at once.
     */
    const deleteForms = (formKeysParam?: Array<string>) => {
        dispatch(
            createAction(ACTION_TYPES.USER.DELETE_FORM, {
                keys: formKeysParam || [formKey],
            })
        );
    };

    const setFormData = ({
        fields: fieldsParam,
        fieldMethodsExtraData,
    }: {
        fields: Array<IFormHook.ISetFormFieldsDataParam>;
        fieldMethodsExtraData?: { [k: string]: any };
    }) => {
        let formFields = formData.fields;
        fieldsParam.forEach(fieldParam => {
            const newFormField = {
                ...formFields[fieldParam.key],
                ...fieldParam,
            };

            /**
             * if `value` is not passed as function param, then dont process the input
             */
            const value = !isDefined(fieldParam.value)
                ? newFormField.value
                : newFormField.processInput(
                      newFormField.value,
                      fieldMethodsExtraData
                  );

            const errorMessage = formData.options.setErrorOnValueChange
                ? newFormField.validate(value, fieldMethodsExtraData)
                : newFormField.errorMessage;

            const error = !isEmpty(errorMessage);

            /**
             * if `showError` value is not passed as function param and error has been resolved
             * then set `showError` as false, otherwise keep the field value as is
             */
            const showError =
                !isDefined(fieldParam.showError) && !error
                    ? false
                    : newFormField.showError;

            formFields = {
                ...formFields,
                [fieldParam.key]: {
                    ...newFormField,
                    value,
                    error,
                    errorMessage,
                    showError,
                },
            };
        });

        dispatch(
            createAction(ACTION_TYPES.USER.SET_FORM_DATA, {
                key: formKey,
                fields: formFields,
            })
        );
    };

    const addFormFields = ({
        fields: fieldsParam,
        fieldMethodsExtraData,
        keepPreviousValues = true,
        pickPreDefinedFieldProps = true,
    }: {
        fields: Array<IFormHook.IAddFormFieldsParam>;
        fieldMethodsExtraData?: { [k: string]: any };
        keepPreviousValues?: boolean;
        pickPreDefinedFieldProps?: boolean;
    }) => {
        let fields = {};
        fieldsParam.forEach(fieldParam => {
            const field = createFormField({
                fieldParam,
                prevField: formData.fields[fieldParam.key],
                fieldMethodsExtraData,
                keepPreviousValues,
                pickPreDefinedFieldProps,
            });

            fields = {
                ...fields,
                [field.key]: field,
            };
        });

        dispatch(
            createAction(ACTION_TYPES.USER.ADD_FORM_FIELDS, {
                key: formKey,
                fields: { ...formData.fields, ...fields },
            })
        );
    };

    const deleteFormFields = ({
        fields: fieldsParam,
    }: {
        fields: Array<IFormHook.IDeleteFormFieldsParam>;
    }) => {
        let fields = {};
        const deletedFields = fieldsParam.map(fieldParam => fieldParam.key);
        Object.values(formData.fields)
            .filter(formDataField => !deletedFields.includes(formDataField.key))
            .forEach(formDataField => {
                fields = {
                    ...fields,
                    [formDataField.key]: formDataField,
                };
            });

        dispatch(
            createAction(ACTION_TYPES.USER.DELETE_FORM_FIELDS, {
                key: formKey,
                fields: { ...formData.fields, ...fields },
            })
        );
    };

    /**
     * simply call this before form submit and this will set `error`, `errorMessage`, `showError`
     * @param setErrorMessageAndShowError is true by default
     * @returns form validity status as a boolean
     */
    const formIsValid = ({
        setErrorMessageAndShowError = true,
        fieldMethodsExtraData,
    }: {
        setErrorMessageAndShowError?: boolean;
        fieldMethodsExtraData?: { [k: string]: any };
    }) => {
        const { isValid, updatedFormFields } = genericFormIsValid({
            fields: formData.fields,
            fieldMethodsExtraData,
        });

        if (setErrorMessageAndShowError) {
            dispatch(
                createAction(ACTION_TYPES.USER.SET_FORM_DATA, {
                    key: formKey,
                    fields: updatedFormFields,
                })
            );
        }

        return isValid;
    };

    return {
        formData,
        createForm,
        resetFormData,
        setFormData,
        addFormFields,
        deleteFormFields,
        formIsValid,
        deleteForms,
    };
};

/**
 * @deprecated implement new forms using `react-hook-form`
 *
 *
 * `isValid` is the key that will be useful outside the formsV2 hook.
 *
 * Rest of the keys are kept for hooks internal functioning
 */
export const genericFormIsValid = ({
    fields,
    fieldMethodsExtraData,
}: {
    fields: IUser.IFormData["fields"];
    fieldMethodsExtraData?: { [k: string]: any };
}) => {
    let updatedFormFields: IUser.IFormData["fields"] = {};
    let isValid = true;
    Object.values(fields).forEach(field => {
        const errorMessage = field.validate(field.value, fieldMethodsExtraData);
        const error = !isEmpty(errorMessage);

        if (isValid && error) {
            isValid = false;
        }

        updatedFormFields = {
            ...updatedFormFields,
            [field.key]: {
                ...field,
                error,
                errorMessage,
                showError: true,
            },
        };
    });

    return { isValid, updatedFormFields };
};
