import * as Sentry from "@sentry/nextjs";
import { NODE_ENV_IS_NOT_PRODUCTION } from "../../../constants/environment";

type ISentryItem = ["sentry"] | ["sentry", { forceLogStacktrace?: boolean }];
type IConsoleItem = ["console"];
export const logError = async ({
    error,
    extraErrorData,
    occuredAt,
    when,
    severity = "medium",
    customErrorMessage,
    logToPlatforms = [],
}: {
    error?: any;
    extraErrorData?: any;
    occuredAt?: string;
    when?: string;
    severity?: "low" | "medium" | "critical" | "blocker";
    customErrorMessage?: string;
    logToPlatforms?: Array<IConsoleItem | ISentryItem>;
}) => {
    const customError = customErrorMessage || {
        error,
        occuredAt,
        when,
        severity,
        extraErrorData,
    };

    let loggedToConsole = false;

    logToPlatforms.forEach(([platform, platformOptions]) => {
        if (platform === "console") {
            console.error(customError);
            loggedToConsole = true;
        }
        if (platform === "sentry") {
            // do we want to log the stacktrace instead of logging the custom object?
            // https://docs.sentry.io/platforms/javascript/guides/nextjs/usage/#capturing-errors
            if (error instanceof Error && platformOptions?.forceLogStacktrace) {
                Sentry.captureException(error);
            } else {
                // convert the customError object to a string
                try {
                    Sentry.captureMessage(JSON.stringify(customError));
                } catch (e) {
                    // if we can't convert the customError object to a string

                    // then log it to console
                    console.log(e);

                    // if we are in development mode, then throw the error
                    if (NODE_ENV_IS_NOT_PRODUCTION) throw e;
                }
            }
        }
    });

    if (!loggedToConsole && NODE_ENV_IS_NOT_PRODUCTION) {
        console.log(customError);
    }
};
