/* eslint-disable no-case-declarations */
import { ACTION_TYPES } from "@Utils/constants/actions";
import { IAction } from "@Interfaces/redux/IAction";
import { IComponentProps } from "@Interfaces/redux/store/IComponentProps";
import initialState from "../initialState";

export default function componentPropsReducer(
    state = initialState?.componentProps || {},
    { type, payload }: IAction<any>
): IComponentProps.IStore {
    switch (type) {
        case ACTION_TYPES.PROPS.COMPONENT_PROPS.UPDATE_UPSELL_COMPONENT_PROPS: {
            return {
                ...state,
                event: {
                    ...state.event,
                    upsellProps: {
                        ...state.event.upsellProps,
                        ...payload,
                    },
                },
            };
        }
        case ACTION_TYPES.PROPS.COMPONENT_PROPS
            .UPDATE_PAYMENT_SUMMARY_COMPONENT_PROPS: {
            return {
                ...state,
                event: {
                    ...state.event,
                    paymentSummaryProps: {
                        ...state.event.paymentSummaryProps,
                        ...payload,
                    },
                },
            };
        }
        default:
            return state;
    }
}
