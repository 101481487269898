import React, { createContext, useContext, useState } from "react";
import { IToastContext } from "./IToastContext";

const initialState = {};

export const ToastContext = createContext<IToastContext.IProps>({});

// TODO: shift to reducer instead of context implementation
const ToastProvider = ({ children }: { children: any }) => {
    const [toastList, setToastList] = useState(initialState);

    return (
        <ToastContext.Provider
            value={{
                toastList,
                setToastList: val => setToastList(val),
            }}
        >
            {children}
        </ToastContext.Provider>
    );
};

export const useToastContext = () => useContext(ToastContext);

export default ToastProvider;
