import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IStore, IUser } from "@Interfaces";

import { formatFormFieldInput } from "@Modules/modals/components/UserDetails/FormData";
import { ACTION_TYPES, createAction } from "../constants";
import { isFormValid, getRandomKey } from "../common";

/**
 * @deprecated implement new forms using `react-hook-form`
 */
export namespace IFormDetails {
    export type IProps = string[];
    export interface IReturnType {
        formDetails: IStore.IState["user"]["formDetails"][0];
        setFormDetails: (
            p: IUser.IFormDataVal | IUser.IFormDataVal[] | undefined | void
        ) => void;
        valid: boolean;
    }
}

/**
 * @deprecated implement new forms using `react-hook-form`
 */
export function useFormDetails(
    fields: IFormDetails.IProps = [],
    keyProp?: string
): IFormDetails.IReturnType {
    const key: string = keyProp || getRandomKey(); // If no key is provided, use a random key for one-time usage

    const formDetails: IStore.IState["user"]["formDetails"][0] =
        useSelector((state: IStore.IState) => state.user.formDetails?.[key]) ||
        {};
    const dispatch = useDispatch();

    const processFieldsForComparison = (unprocessedFields: Array<string>) =>
        unprocessedFields
            .filter(v => v)
            .sort()
            .toString();

    useEffect(() => {
        if (
            processFieldsForComparison(Object.keys(formDetails)) !==
            processFieldsForComparison(fields)
        ) {
            // Don't remove this commented line for now
            // dispatch(
            //     createAction(ACTION_TYPES.USER.CLEAR_FORM_DETAILS, { key })
            // );
            dispatch(
                createAction(ACTION_TYPES.USER.INITIATE_FORM_FIELDS, {
                    key,
                    data: Object.assign(
                        {},
                        ...fields
                            .filter(f => f)
                            .map(f => {
                                if (formDetails?.[f]) {
                                    return {
                                        [f]: {
                                            ...(formDetails?.[f] || {}),
                                        },
                                    };
                                }
                                return {
                                    [f]: {
                                        key: f,
                                        value: "",
                                        error: true,
                                        showError: false,
                                    },
                                };
                            })
                    ),
                })
            );
        }
    }, [fields]);

    // Pass no props at all to clear the form
    const setFormDetails = (
        formDetailData:
            | IUser.IFormDataVal
            | IUser.IFormDataVal[]
            | undefined
            | void
    ): void => {
        if (!formDetailData) {
            dispatch(
                createAction(ACTION_TYPES.USER.CLEAR_FORM_DETAILS, { key })
            );
            return;
        }

        const standardizedParams: IUser.IFormDataVal[] =
            formDetailData instanceof Array ? formDetailData : [formDetailData];

        const params: IUser.IFormDataVal[] = standardizedParams.map(
            (p: IUser.IFormDataVal) => ({
                key: p.key,
                ...("value" in p && {
                    value: formatFormFieldInput(p.key, p.value ?? ""),
                }), // Only change values if they have been passed, else keep the old values
                ...("editingDisabled" in p && {
                    editingDisabled: p.editingDisabled ?? false,
                }),
                ...("error" in p && { error: p.error ?? null }),
                ...("showError" in p && { showError: p.showError }), // Enable showing error on first change
            })
        );
        dispatch(
            createAction(ACTION_TYPES.USER.SET_FORM_DETAILS, {
                key,
                data: Object.assign(
                    {},
                    ...params.map(param => ({
                        [param.key]: { ...formDetails[param.key], ...param },
                    }))
                ),
            })
        );
    };

    const valid: boolean = formDetails ? isFormValid(formDetails || {}) : false;

    // const reducedFormData = Object.values(formDetails).map(v => ({
    //     [v.key]: v.value,
    // }));

    return { formDetails, setFormDetails, valid };
}

// * NOTE: Set proper rules for naming of hooks
