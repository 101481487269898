export type IModalTypes =
    | "UserDetails"
    | "GetInTouch"
    | "SocialShare"
    | "ClassicGallery"
    | "ExitIntent"
    | "EventModal"
    | "PreviewPopup"
    | "SoldOut"
    | "PaymentStatus"
    | "PaymentSelection"
    | "PaymentNotification"
    | "Share" // New share modal, will eventually replace SocialShare
    | "ContentModal"
    | "ModernTestimonial"
    | "Reviews"
    | "ExitOffer"
    | "Questions";

export const MODAL_TYPES: Record<IModalTypes, IModalTypes> = {
    UserDetails: "UserDetails",
    GetInTouch: "GetInTouch",
    SocialShare: "SocialShare",
    ClassicGallery: "ClassicGallery",
    ExitIntent: "ExitIntent",
    EventModal: "EventModal",
    PreviewPopup: "PreviewPopup",
    SoldOut: "SoldOut",
    PaymentStatus: "PaymentStatus",
    PaymentSelection: "PaymentSelection",
    PaymentNotification: "PaymentNotification",
    Reviews: "Reviews",
    Share: "Share",
    ContentModal: "ContentModal",
    ModernTestimonial: "ModernTestimonial",
    ExitOffer: "ExitOffer",
    Questions: "Questions",
};
