import React from "react";
import App, { AppContext, AppInitialProps } from "next/app";
import { Provider } from "react-redux";
import { END } from "redux-saga";
import withRedux from "next-redux-wrapper";
import { ConnectedRouter } from "connected-next-router";
import { AppWithStore, IPage } from "@Interfaces";
import { makeStore } from "@Redux";
import { validSubDomain } from "@Utils";
import RootComponent from "@Modules/common/Root";
import "@Static/css/main.scss";
import "./global-styles.scss";
import "./global-declare.d";
import "react-multi-carousel/lib/styles.css";
import { PostHog } from "repoV2/features/Common/Analytics/modules/PostHog/PostHog";
import { IAppCtxContextProviderProps } from "@Modules/common/AppCtxContext/IRootComponent";
import { fetchFeatureAccessiblility } from "repoV2/features/CreatorDetails/modules/FeatureAccessibility/utils/FeatureAccessibility.utils";
import { WHITELABEL_FEATURE_KEY } from "repoV2/features/CreatorPages/constants/CreatorPages.constants";
import { checkIsObjectEmpty } from "repoV2/utils/common/dataTypes/object";
import { CREATOR_DETAILS_ACTION_TYPES } from "repoV2/features/CreatorDetails/redux/actions";
import { logError } from "repoV2/utils/common/error/error";

class WebApp extends App<AppWithStore> {
    static async getInitialProps({
        Component,
        ctx: ctxProp,
    }: AppContext): Promise<
        AppInitialProps & { ctx: IAppCtxContextProviderProps["ctx"] }
    > {
        const _ctx = ctxProp || {};
        const ctx = _ctx as IPage.IPageContext;
        let pageProps: { [k: string]: any } = {};
        if (Component.getInitialProps) {
            const hostName = await validSubDomain(ctx);
            pageProps = await Component.getInitialProps({
                ...ctx,
                params: {
                    hostName,
                },
            } as IPage.IPageContext);

            // TODO: Read IP from here and send to geolocation API
            // const ipAddress: string | undefined =
            //     (ctx?.req as IPage.IPageContext["req"] & {
            //         ip: string | undefined;
            //     })?.ip || undefined;

            if (ctx.isServer) {
                ctx.store.dispatch(END);
                await ctx.store.sagaTask.toPromise();
            }
        }

        const ctxReq = _ctx.req || {};
        // @ts-ignore
        const ctxReqHeaders = ctxReq.headers || {};
        try {
            const whitelableAccessibility = await fetchFeatureAccessiblility({
                featureKey: WHITELABEL_FEATURE_KEY,
                hostName: pageProps?.hostName,
            });

            if (!checkIsObjectEmpty(whitelableAccessibility?.data?.data)) {
                ctx.store.dispatch({
                    type: CREATOR_DETAILS_ACTION_TYPES.SET_FEATURE_ACCESSIBILITY,
                    payload: {
                        featureKey: WHITELABEL_FEATURE_KEY,
                        accessibility:
                            whitelableAccessibility?.data?.data?.is_accessible,
                    },
                });
            }
        } catch (error) {
            logError({
                error,
                when: "setWhitelabelAccessibility",
                occuredAt: "pages/_app/index.tsx",
            });
        }

        return {
            pageProps,

            // shouldn't we spread `ctx` as it is?
            // why: ctx contains self references(circular structure), which cant be serialised
            //      using `JSON.stringify`. And `getInitialProps` uses `JSON.stringify` internally.
            // reference: https://nextjs.org/docs/messages/circular-structure
            ctx: {
                pathname: _ctx.pathname,
                asPath: _ctx.asPath,
                req: {
                    // @ts-ignore
                    query: ctxReq.query,
                    headers: { host: ctxReqHeaders.host },
                },
            },
        };
    }

    render() {
        const { Component, pageProps, store, ctx } = this.props;

        return (
            <PostHog>
                <Provider store={store}>
                    <ConnectedRouter>
                        <RootComponent ctx={ctx}>
                            <Component {...pageProps} />
                        </RootComponent>
                    </ConnectedRouter>
                </Provider>
            </PostHog>
        );
    }
}

// TODO: Type declaration
export default withRedux(makeStore as any)(WebApp);
