export const GOOGLE_TAG_MANAGER_EVENT_TYPE = {
    EVENT: "event",
};

export const GOOGLE_TAG_MANAGER_EVENTS = {
    PAGE_VIEW: "page_view",
    GENERATE_LEAD: "generate_lead",
    ADD_TO_CART: "add_to_cart",
    BEGIN_CHECKOUT: "begin_checkout",
    ADD_PAYMENT_INFO: "add_payment_info",
    PURCHASE: "purchase",
};
