import { FORM_INPUT_TYPES } from "repoV2/components/common/Form/FormInput/FormInput.constants";
import {
    GENDER_CHOICES,
    INDIAN_CITIES,
    INDIAN_STATES,
    isDateFormatValid,
    isEmpty,
    isEmptyStringPostTrimming,
    isNumeric,
    isValidURL,
} from "@Utils";
import {
    CalendarQuestion,
    FileUpload,
    MultiSelectQuestion,
    PararaphTextQuestion,
    SingleSelectQuestion,
} from "@Modals/components/UserDetails/components/CustomQuestions/components";
import React from "react";
import { ICustomQuestions } from "@Modals/components/UserDetails/components/CustomQuestions/ICustomQuestions";
import ShortTextQuestion from "@Modals/components/UserDetails/components/CustomQuestions/components/ShortTextQuestion";

export const FORM_FIELDS = {
    FULL_NAME: {
        slug: "full_name",
        label: "Name",
        inputType: FORM_INPUT_TYPES.TEXT,
    },
    EMAIL: {
        slug: "email",
        label: "Email",
        inputType: FORM_INPUT_TYPES.EMAIL,
    },
    COUNTRY_CODE: {
        slug: "country_code",
        label: "Country",
        inputType: FORM_INPUT_TYPES.SINGLE_SELECT_DROPDOWN,
    },
    PHONE: {
        slug: "phone",
        label: "Phone",
        inputType: FORM_INPUT_TYPES.TEL,
    },
    MESSAGE: {
        slug: "message",
        label: "Message",
        inputType: FORM_INPUT_TYPES.TEXTAREA,
    },
};

export const CUSTOM_QUESTION_TYPES_ID = {
    PARAGRAPH_TEXT: 1,
    SINGLE_SELECT_DROPDOWN: 2,
    MULTI_SELECT_DROPDOWN: 3,
    CALENDAR: 4,
    FILE: 5,
    /**
    Backend uses same enum for all custom questions(pre booking, post booking, express interest, recorded content exam).
    But following are not used in pre booking, post booking, express interest custom questions.
    Keeping it here so that it does not cause confusion in future.

    FIVE_STAR_RATING: 6,
    FIVE_NUMBER_RATING: 7,
    TEN_STAR_RATING: 8,    
     */
    SHORT_TEXT: 9,
    NUMBER: 10,
    URL: 11,
    GENDER: 12,
    INDIAN_STATE: 13,
    INDIAN_CITY: 14,
};

export interface IQuestionTypeDetail {
    value: number;
    label: string;
    inputType: string;
    defaultAnswerValue: any;
    validateAnswer: { (userInput: any): boolean };
    renderQuestion: {
        (commonProps: ICustomQuestions.IBaseFieldProps): JSX.Element;
    };
}

export type IQuestionTypeDetailRecords = Record<number, IQuestionTypeDetail>;

export const CUSTOM_QUESTION_TYPES: IQuestionTypeDetailRecords = {
    [CUSTOM_QUESTION_TYPES_ID.PARAGRAPH_TEXT]: {
        value: 1,
        label: "Paragraph Text",
        inputType: FORM_INPUT_TYPES.TEXTAREA,
        defaultAnswerValue: "",
        validateAnswer: (userInput: string) => {
            return isEmptyStringPostTrimming(userInput);
        },
        renderQuestion: commonProps => (
            <PararaphTextQuestion {...commonProps} />
        ),
    },
    [CUSTOM_QUESTION_TYPES_ID.SINGLE_SELECT_DROPDOWN]: {
        value: 2,
        label: "Select One Option",
        inputType: FORM_INPUT_TYPES.SINGLE_SELECT_DROPDOWN,
        defaultAnswerValue: "",
        validateAnswer: (userInput: string) => {
            return isEmptyStringPostTrimming(userInput);
        },
        renderQuestion: commonProps => (
            <SingleSelectQuestion
                // The menu will open downwards if this is the first element, otherwise upwards. This is to ensure that the menu is visible to the user
                {...commonProps}
                maxMenuHeight={150}
            />
        ),
    },
    [CUSTOM_QUESTION_TYPES_ID.MULTI_SELECT_DROPDOWN]: {
        value: 3,
        label: "Select Multiple Options",
        inputType: FORM_INPUT_TYPES.MULTI_SELECT_CHECKBOXES,
        defaultAnswerValue: [],
        validateAnswer: (userInput: string[]) => {
            return (
                isEmpty(userInput) ||
                userInput.some(item => isEmptyStringPostTrimming(item))
            );
        },
        renderQuestion: commonProps => <MultiSelectQuestion {...commonProps} />,
    },
    [CUSTOM_QUESTION_TYPES_ID.CALENDAR]: {
        value: 4,
        label: "Calendar",
        inputType: FORM_INPUT_TYPES.DATE,
        defaultAnswerValue: "",
        validateAnswer: (userInput: string) => {
            return !isDateFormatValid(userInput);
        },
        renderQuestion: commonProps => <CalendarQuestion {...commonProps} />,
    },
    [CUSTOM_QUESTION_TYPES_ID.FILE]: {
        value: 5,
        label: "File",
        inputType: FORM_INPUT_TYPES.FILE,
        defaultAnswerValue: "",
        validateAnswer: (userInput: string) => {
            return isEmpty(userInput);
        },
        renderQuestion: commonProps => <FileUpload {...commonProps} />,
    },
    [CUSTOM_QUESTION_TYPES_ID.SHORT_TEXT]: {
        value: 9,
        label: "Short Text Question",
        inputType: FORM_INPUT_TYPES.TEXT,
        defaultAnswerValue: "",
        validateAnswer: (userInput: string) => {
            return isEmptyStringPostTrimming(userInput);
        },
        renderQuestion: commonProps => <ShortTextQuestion {...commonProps} />,
    },
    [CUSTOM_QUESTION_TYPES_ID.NUMBER]: {
        value: 10,
        label: "Number",
        inputType: FORM_INPUT_TYPES.NUMBER,
        defaultAnswerValue: "",
        validateAnswer: (userInput: string) => {
            return (
                isEmptyStringPostTrimming(userInput) || !isNumeric(userInput)
            );
        },
        renderQuestion: commonProps => (
            <ShortTextQuestion inputType="number" {...commonProps} />
        ),
    },
    [CUSTOM_QUESTION_TYPES_ID.URL]: {
        value: 11,
        label: "URL",
        inputType: FORM_INPUT_TYPES.TEXT,
        defaultAnswerValue: "",
        validateAnswer: (userInput: string) => {
            return !isValidURL(userInput);
        },
        renderQuestion: commonProps => <ShortTextQuestion {...commonProps} />,
    },
    [CUSTOM_QUESTION_TYPES_ID.GENDER]: {
        value: 12,
        label: "Gender",
        inputType: FORM_INPUT_TYPES.SINGLE_SELECT_DROPDOWN,
        defaultAnswerValue: "",
        validateAnswer: (userInput: string) => {
            return isEmptyStringPostTrimming(userInput);
        },
        renderQuestion: commonProps => {
            const { item } = commonProps;
            item.choices = GENDER_CHOICES;
            return (
                <SingleSelectQuestion
                    // The menu will open downwards if this is the first element, otherwise upwards. This is to ensure that the menu is visible to the user
                    {...commonProps}
                    maxMenuHeight={150}
                />
            );
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.INDIAN_STATE]: {
        value: 13,
        label: "Indian State",
        inputType: FORM_INPUT_TYPES.SINGLE_SELECT_DROPDOWN,
        defaultAnswerValue: "",
        validateAnswer: (userInput: string) => {
            return isEmptyStringPostTrimming(userInput);
        },
        renderQuestion: commonProps => {
            const { item } = commonProps;
            item.choices = INDIAN_STATES;
            return (
                <SingleSelectQuestion
                    // The menu will open downwards if this is the first element, otherwise upwards. This is to ensure that the menu is visible to the user
                    {...commonProps}
                    maxMenuHeight={150}
                />
            );
        },
    },
    [CUSTOM_QUESTION_TYPES_ID.INDIAN_CITY]: {
        value: 14,
        label: "Indian City",
        inputType: FORM_INPUT_TYPES.SINGLE_SELECT_DROPDOWN,
        defaultAnswerValue: "",
        validateAnswer: (userInput: string) => {
            return isEmptyStringPostTrimming(userInput);
        },
        renderQuestion: commonProps => {
            const { item } = commonProps;
            item.choices = INDIAN_CITIES;
            return (
                <SingleSelectQuestion
                    // The menu will open downwards if this is the first element, otherwise upwards. This is to ensure that the menu is visible to the user
                    {...commonProps}
                    maxMenuHeight={150}
                />
            );
        },
    },
};
