import { isEmpty } from "./common";
import { emailRegex } from "./constants";

export const getUserDetailsFormKey = (n: number): string =>
    `userDetailsForm-${n}`;

export const isUserDetailFormKey = (key: string): boolean =>
    /^userDetailsForm-[0-9]+$/.test(key);

export const FORM_VALIDATORS = {
    // TODO: add methods
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    temp: (value: string, extraData?: { [k: string]: any }) => value,
};

export const validatorChain = (
    arr: Array<(value: string, extraData?: { [k: string]: any }) => string>,
    value: string,
    extraData?: { [k: string]: any }
) => {
    for (let index = 0; index < arr.length; index += 1) {
        const error = arr[index](value, extraData);
        if (error) return error;
    }
    return "";
};

export const validateEmail = (email: string) => {
    if (isEmpty(email)) {
        return "Required";
    }
    if (!emailRegex.test(email)) {
        return "Please enter a valid email!";
    }
    return "";
};
