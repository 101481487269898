import { IHost } from "@Interfaces";
import { chainShortCircuits, TEMPLATE_KEYS } from "@Utils";
import { ITemplateKey } from "@Utils/constants";
import { capitaliseWords } from "repoV2/utils/common/dataTypes/string";

export const getGetInTouchCtaText = (hostData?: IHost.IHostData) =>
    hostData?.interest_cta_text || "Get In Touch";

export const getHideGetInTouch = (hostData?: IHost.IHostData) =>
    hostData?.hide_get_in_touch_section;

export const getListingSectionTitle = ({
    templateName,
    customHeader,
    wordUsedForCourse,
}: {
    templateName: ITemplateKey;
    customHeader: string | undefined;
    wordUsedForCourse: string;
}) =>
    chainShortCircuits<string>(
        [
            [customHeader, customHeader],
            [
                [
                    TEMPLATE_KEYS.EDUVERSE,
                    TEMPLATE_KEYS.UNFOLD,
                    TEMPLATE_KEYS.GROWTH,
                    TEMPLATE_KEYS.YOGA,
                    TEMPLATE_KEYS.LINKTEMP,
                    TEMPLATE_KEYS.UNLOCK,
                ].includes(templateName),
                `Pick a ${capitaliseWords(wordUsedForCourse)} to get started`,
            ],
        ],
        "Browse All Offerings"
    );
