type IDataObject = { [key: string]: any };

/**
 * @description
 * This function returns the value at path `key` in `dataObj` passed
 * @param arg0 `{ dataObj, key, defaultValue }`
 * @param_summary
 * 1. dataObj: data object to access
 * 2. key: dot separated path for value access in dataObj
 * 3. defaultValue: if `key` path does not exist in the dataObj return the defaultValue
 * @example
 * dataObj => `{A:{a: "a", B: {b: "b", C: {c: "c"}}}}`
 * key => can be "A.a" or "A.B.b" or "A.B" or "A.B.C.c" etc.
 * @returns the value at path `key` in dataObj passed
 */
export function getObjectKeyData({
    dataObj,
    key,
}: {
    dataObj: IDataObject;
    key: string;
}) {
    // eg. case
    // dataObj => same as mentioned in function desc.
    // key => "A.B.b"

    // 1. separate path segments => ["A", "B", "b"]
    const segments = key.split(".");
    // 2. initialSegment => "A"
    const initialSegment = segments[0];

    // 3. if "A" key did not existed return undefined
    if (!(initialSegment in dataObj)) return undefined;

    // 4. segmentData => {a: "a", B: {b: "b", C: {c: "c"}}}
    let segmentData = dataObj[initialSegment];

    // 5. will loop in segments => ["B", "b"]
    for (let i = 1; i < segments.length; i += 1) {
        // 6. nextSegment => "B"
        const nextSegment = segments[i];
        // 7.
        // if "B" present in segmentData at 3. process next segment
        if (nextSegment in segmentData) {
            // 8. nextSegmentData => {b: "b", C: {c: "c"}
            const nextSegmentData = segmentData[nextSegment];
            // 9. replace segmentData with nextSegmentData and repeat for next item i.e "b"
            segmentData = nextSegmentData;
        }
        // else return undefined
        else {
            return undefined;
        }
    }
    // 10. loop terminates at key 'b' with segmentData = "b"
    return segmentData;
}

/**
 * @description
 * This function updates with or adds the `payload` at path `key` in `dataObj` passed
 * even updates the
 * @param arg0 `{ dataObj, key, payload }`
 * @param_summary
 * 1. dataObj: data object to update
 * 2. key: dot separated path for value access in dataObj
 * 3. payload: data to be added or updated with
 * @example
 * dataObj => `{A:{a: "a", B: {b: "b", C: {c: "c"}}}}`
 * key => can be "A.a" or "A.B.b" or "A.B" or "A.B.C.c" etc.
 * payload => can be string, number, object like "a", {a: "a"}, 1, {b: 2} etc.
 */
export function setObjectKeyData({
    dataObj,
    key,
    payload,
}: {
    dataObj: IDataObject;
    key: string;
    payload: IDataObject;
}) {
    // eg. case
    // dataObj => same as mentioned in function desc.
    // key => "A.B.b"
    // key2 => "A.Z.b" Z not there in dataObj (Z non-leaf node)
    // payload => "test"

    // making a deep copy so that data changes are reflected in redux store / state for deeply nested keys
    const finalDataObj = JSON.parse(JSON.stringify(dataObj));

    // 1. separate path segments => ["A", "B", "b"] or ["A", "Z", "b"] for key2
    const segments = key.split(".");
    // 2. current = {A:{a: "a", B: {b: "b", C: {c: "c"}}}}
    let current = finalDataObj;

    // 3. loops => ["A", "B"] or ["A", "Z"] for key2
    for (let i = 0; i < segments.length - 1; i += 1) {
        // 4. segments[i] => "A"
        // 5. checks if "A" if not present in current at 2.

        // For key2
        // on "Z" value for key2 since its not present below condn will be true

        if (!(segments[i] in current)) {
            // for key 2
            // current["Z"] = {}
            // here current = dataObj["A"];
            // which means dataObj will be {A:{a: "a", B: {b: "b", C: {c: "c"}},Z: {} }} after assignment
            current[segments[i]] = {};
        }
        // 7. replace current with new current data

        // for key2
        // here current = dataObj["A"]["Z"] after assignment
        current = current[segments[i]];
    }

    // 8. loop terminates at key "B" (or "Z" for key2)

    // current["b"] = "test", i.e, directly updates dataObj' reference at path "A.B.b"
    // even if current["b"] did not exist previously

    // for key2, when loop terminates
    // current["b"] = payload data provided
    // here current = dataObj["A"]["Z"] before assignment
    // so, data["A"]["Z"]["b"] = payload = "test"
    current[segments[segments.length - 1]] = payload;

    return finalDataObj;
}

/**
 * @description
 * This function appends the `payload` to the object at path `key` in `dataObj` passed.
 * If the key does not exist, it creates the necessary structure.
 * If the key exists and is an object, it merges the payload with the existing object.
 * Otherwise, it replaces the value at the key with the payload.
 * @param arg0 `{ dataObj, key, payload }`
 * @param_summary
 * 1. dataObj: data object to update
 * 2. key: dot separated path for value access in dataObj
 * 3. payload: data to be appended or merged with
 * @example
 * dataObj => `{A:{a: "a", B: {b: "b", C: {c: "c"}}}}`
 * key => can be "A.a" or "A.B.b" or "A.B" or "A.B.C.c" etc.
 * payload => can be string, number, object like "a", {a: "a"}, 1, {b: 2} etc.
 */
export function updateObjectKeyData({
    dataObj,
    key,
    payload,
}: {
    dataObj: IDataObject;
    key: string;
    payload: IDataObject;
}) {
    // Create a deep copy to prevent mutations to the original dataObj
    const finalDataObj = JSON.parse(JSON.stringify(dataObj));

    // Split the key into segments representing the path in the object
    const segments = key.split(".");
    let current = finalDataObj;

    // Traverse the object to the last key segment
    for (let i = 0; i < segments.length - 1; i += 1) {
        if (!(segments[i] in current)) {
            current[segments[i]] = {};
        }
        current = current[segments[i]];
    }

    const lastSegment = segments[segments.length - 1];
    if (!(lastSegment in current)) {
        current[lastSegment] = payload; // If last key doesn't exist, set it to payload
    } else if (
        typeof current[lastSegment] === "object" &&
        current[lastSegment] !== null &&
        !Array.isArray(current[lastSegment])
    ) {
        // If last key exists and is an object, merge with payload
        current[lastSegment] = {
            ...current[lastSegment],
            ...payload,
        };
    } else {
        // If last key is not an object, or is null, or is an array, replace it with payload
        current[lastSegment] = payload;
    }

    // Return the updated object with the appended or merged data
    return finalDataObj;
}

/**
 * @description
 * This function removes the value at the path `key` from the `dataObj` passed.
 * It handles dot-separated paths to access nested objects.
 * If a segment of the path is not an object or does not exist, the deletion is not performed.
 * @param arg0 `{ dataObj, key }`
 * @param_summary
 * 1. dataObj: The data object from which a key will be deleted
 * 2. key: Dot-separated path for value access in dataObj
 * @example
 * dataObj => `{A:{B: {b: "b", C: {c: "c"}}, D: {d: "d"}}}`
 * key => "A.B.C.c" will delete the "c" key from the nested object within "A.B.C"
 * key => "A.D" will delete the "D" key from the object nested within "A"
 */
export function removeObjectKeyData({
    dataObj,
    key,
}: {
    dataObj: IDataObject;
    key: string;
}) {
    // Create a deep copy to prevent mutations to the original dataObj
    const finalDataObj = JSON.parse(JSON.stringify(dataObj));

    // Split the key into segments representing the path in the object
    const segments = key.split(".");
    let current = finalDataObj;

    // Traverse the object to the second to last key segment
    for (let i = 0; i < segments.length - 1; i += 1) {
        // Check if the current segment is an object and exists, if not, the path is invalid
        if (
            typeof current[segments[i]] !== "object" ||
            current[segments[i]] === null
        ) {
            // If the path is invalid, return the original dataObj unchanged
            return dataObj;
        }
        // Move to the next nested object within the current path
        current = current[segments[i]];
    }

    // Delete the last segment key; this is the target key to be removed
    const lastSegment = segments[segments.length - 1];
    delete current[lastSegment];

    // Return the updated object with the specified key deleted
    return finalDataObj;
}
