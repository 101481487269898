import React from "react";

export const EmojiRating5Svg = (props: any = {}): JSX.Element => {
    const { fill } = props;
    return (
        <svg
            {...props}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 16C4.00119 12.2823 5.38295 8.69746 7.87732 5.94072C10.3717 3.18398 13.8009 1.45177 17.5 1.08C17.0017 1.02789 16.501 1.00119 16 1C12.0218 1 8.20644 2.58035 5.3934 5.3934C2.58035 8.20644 1 12.0218 1 16C1 19.9782 2.58035 23.7936 5.3934 26.6066C8.20644 29.4196 12.0218 31 16 31C16.501 30.9988 17.0017 30.9721 17.5 30.92C13.8009 30.5482 10.3717 28.816 7.87732 26.0593C5.38295 23.3025 4.00119 19.7177 4 16Z"
                fill="black"
                fillOpacity="0.1"
            />
            <path
                d="M16 32C12.8355 32 9.74207 31.0616 7.11088 29.3035C4.4797 27.5454 2.42894 25.0466 1.21793 22.1229C0.0069325 19.1993 -0.309921 15.9823 0.307443 12.8786C0.924806 9.77487 2.44866 6.92394 4.6863 4.6863C6.92394 2.44866 9.77487 0.924806 12.8786 0.307443C15.9823 -0.309921 19.1993 0.0069325 22.1229 1.21793C25.0466 2.42894 27.5454 4.4797 29.3035 7.11088C31.0616 9.74207 32 12.8355 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32ZM16 2.00001C13.2311 2.00001 10.5243 2.82109 8.22202 4.35943C5.91974 5.89777 4.12532 8.08427 3.06569 10.6424C2.00607 13.2006 1.72882 16.0155 2.26901 18.7313C2.80921 21.447 4.14258 23.9416 6.10051 25.8995C8.05845 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C30 12.287 28.525 8.72602 25.8995 6.10051C23.274 3.475 19.713 2.00001 16 2.00001Z"
                fill={fill || "#B3B3B3"}
            />
            <path
                d="M8.00004 13.9999C7.84316 14.0018 7.68836 13.964 7.55004 13.8899C7.31451 13.7701 7.13602 13.5618 7.05357 13.3107C6.97112 13.0596 6.99142 12.7861 7.11003 12.5499C7.39907 11.9785 7.82927 11.4903 8.35986 11.1317C8.89044 10.7731 9.50383 10.556 10.1419 10.5009C10.7799 10.4458 11.4214 10.5546 12.0056 10.8169C12.5898 11.0793 13.0973 11.4865 13.48 11.9999L13.8 12.4199C13.8788 12.525 13.9362 12.6446 13.9687 12.7718C14.0013 12.899 14.0086 13.0314 13.99 13.1614C13.9714 13.2914 13.9274 13.4164 13.8605 13.5294C13.7936 13.6424 13.7051 13.7412 13.6 13.8199C13.495 13.8987 13.3754 13.9561 13.2482 13.9887C13.121 14.0213 12.9886 14.0285 12.8586 14.0099C12.7286 13.9913 12.6035 13.9473 12.4905 13.8804C12.3775 13.8135 12.2788 13.725 12.2 13.6199L11.88 13.1999C11.7027 12.9558 11.4643 12.7626 11.1888 12.6398C10.9132 12.5169 10.6101 12.4687 10.31 12.4999C10.0065 12.5277 9.71556 12.6346 9.46635 12.8101C9.21714 12.9856 9.0184 13.2235 8.89004 13.4999C8.80027 13.6555 8.67025 13.7841 8.51364 13.8721C8.35703 13.9601 8.17962 14.0042 8.00004 13.9999Z"
                fill={fill || "#B3B3B3"}
            />
            <path
                d="M24 14C23.8153 13.9995 23.6344 13.9478 23.4773 13.8507C23.3202 13.7536 23.1931 13.6149 23.11 13.45C22.9816 13.1736 22.7829 12.9357 22.5337 12.7602C22.2845 12.5847 21.9935 12.4777 21.69 12.45C21.3899 12.4187 21.0869 12.467 20.8113 12.5898C20.5357 12.7127 20.2973 12.9059 20.12 13.15L19.8 13.57C19.6409 13.7822 19.404 13.9225 19.1414 13.96C18.8789 13.9975 18.6122 13.9292 18.4 13.77C18.1878 13.6109 18.0476 13.374 18.0101 13.1114C17.9725 12.8489 18.0409 12.5822 18.2 12.37L18.52 11.95C18.9043 11.4374 19.4131 11.0314 19.9983 10.7706C20.5836 10.5098 21.2257 10.4029 21.8638 10.46C22.502 10.5171 23.1149 10.7363 23.6445 11.0969C24.1742 11.4574 24.6029 11.9473 24.89 12.52C25.0086 12.7562 25.0289 13.0297 24.9465 13.2808C24.864 13.5319 24.6855 13.7401 24.45 13.86C24.3145 13.9446 24.1595 13.9928 24 14Z"
                fill={fill || "#B3B3B3"}
            />
            <path
                d="M16 23.94C14.8011 23.9495 13.622 23.6336 12.5883 23.0261C11.5547 22.4186 10.7051 21.5421 10.13 20.49C9.99742 20.2593 9.9619 19.9853 10.0313 19.7284C10.1007 19.4715 10.2693 19.2526 10.5 19.12C10.7308 18.9874 11.0047 18.9519 11.2617 19.0213C11.5186 19.0907 11.7374 19.2593 11.87 19.49C12.2782 20.2278 12.8766 20.8427 13.6029 21.2709C14.3292 21.6991 15.1569 21.925 16 21.925C16.8432 21.925 17.6709 21.6991 18.3972 21.2709C19.1235 20.8427 19.7218 20.2278 20.13 19.49C20.1957 19.3758 20.2832 19.2756 20.3876 19.1951C20.492 19.1147 20.6112 19.0556 20.7384 19.0213C20.8656 18.9869 20.9984 18.978 21.129 18.9949C21.2597 19.0118 21.3858 19.0544 21.5 19.12C21.6143 19.1857 21.7145 19.2732 21.7949 19.3776C21.8753 19.482 21.9344 19.6012 21.9688 19.7284C22.0031 19.8556 22.0121 19.9883 21.9951 20.119C21.9782 20.2497 21.9357 20.3758 21.87 20.49C21.295 21.5421 20.4454 22.4186 19.4117 23.0261C18.3781 23.6336 17.199 23.9495 16 23.94Z"
                fill={fill || "#B3B3B3"}
            />
        </svg>
    );
};
