import {
    COUNTRIES_DATA_LIST,
    ICountryData,
} from "repoV2/utils/common/location/countriesData";

/**
 * @deprecated refer `repoV2/utils/common/location/countriesData.ts`
 */
export interface ICountryCodeDropdownOption extends ICountryData {
    value: string;
    label: string;
}

/**
 * @deprecated refer `repoV2/utils/common/location/countriesData.ts`
 */
export const COUNTRY_CODE_DROPDOWN_OPTIONS: Array<ICountryCodeDropdownOption> =
    COUNTRIES_DATA_LIST.map(country => ({
        ...country,
        value: country.dial_code,
        label: `${country.dial_code} ${country.name}`,
    }));

/**
 * @deprecated refer `repoV2/utils/common/location/countriesData.ts`
 */
export const getCountryCodeDropdownOptionFromKey = (
    countryCode: string = "IN",
    key: keyof typeof COUNTRY_CODE_DROPDOWN_OPTIONS[0] = "code"
): ICountryCodeDropdownOption => {
    const filterResult = COUNTRY_CODE_DROPDOWN_OPTIONS.find(
        c => c[key] === countryCode
    );
    if (filterResult) return filterResult;
    // @ts-ignore
    return COUNTRY_CODE_DROPDOWN_OPTIONS.find(c => c[key] === "IN");
};
