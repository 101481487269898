import { IHost } from "@Interfaces";
import { isBrowser, isEmpty, ROUTES, SECTION_IDS } from "@Utils";
import { getHideGetInTouch } from "@Utils/pageProps/hostPage/common";
import { getExternalUrl } from "@Utils/urls";
import { NextRouter } from "next/router";
import React from "react";
import { getQueryParam } from "repoV2/utils/urls&routing/urlParams";
import { URL_SEARCH_PARAMS } from "repoV2/features/Common/modules/URLsNRouting/URLsNRouting.constants";
import { HostPageButtonContent } from "../components/HostPageButtonContent";
import { INavbar } from "../INavbar";
import { getCategorisationProps } from "./getCategorisationProps";

export const NAVBAR_ITEM_KEYS: Record<
    INavbar.INavbarItemKeys,
    INavbar.INavbarItemKeys
> = {
    home: "home",
    about: "about",
    team: "team",
    testimonials: "testimonials",
    gallery: "gallery",
    blog: "blog",
    myClasses: "myClasses",
    myMeetings: "myMeetings",
    purchaseHistory: "purchaseHistory",
    logout: "logout",
    login: "login",
    getInTouch: "getInTouch",
};

export const getNavbarItems = ({
    template,
    preview,
    host,
    hostData,
    router,
    logoutUser,
    currentPathIsHostPage,
    setIsMobHamMenuOpen,
    isOfferingsDropdownOpen,
    setIsOfferingsDropdownOpen,
    handlePreviewPopup,
    loggedInState,
    onGetInTouchClick,
}: {
    template?: string;
    preview?: boolean;
    host: IHost.IStore;
    hostData?: IHost.IHostData;
    router: NextRouter;
    logoutUser: () => void;
    currentPathIsHostPage: () => boolean;
    setIsMobHamMenuOpen: (value: React.SetStateAction<boolean>) => void;
    isOfferingsDropdownOpen: boolean;
    setIsOfferingsDropdownOpen: (value: React.SetStateAction<boolean>) => void;
    handlePreviewPopup?: () => void;
    loggedInState: INavbar.ILoggedInState;
    onGetInTouchClick?: () => void;
}): INavbar.IInternalProps["navbarItems"] => {
    // TODO: @aakarsh try implementing "next/link" here
    const goToRoute: INavbar.IGoToRoute = (e, route, section): void => {
        e.preventDefault();
        setIsMobHamMenuOpen(false);
        setIsOfferingsDropdownOpen(false);

        if (!isBrowser()) return;

        // if clicked link path is not same as current path
        if (router.pathname !== `/${route.filePath}`) {
            if (preview) {
                // Keeping this preview handling condition here ensures that
                // navigation within the host page is not blocked in the preview mode
                handlePreviewPopup?.();
                return;
            }
            window.location.href = `${route.urlPath}${
                section ? `#${section}` : ""
            }`;
        } else if (section) {
            window.location.hash = section;
        } else {
            window.scrollTo(0, 0);
            window.location.hash = "";
        }
    };

    const handleLogoutClick = () => {
        logoutUser();
        if (!currentPathIsHostPage() && isBrowser()) {
            window.location.href = ROUTES.LOGIN.urlPath;
        }
    };

    const onExternalLinkClick = (e: React.MouseEvent) => {
        setIsMobHamMenuOpen(false);
        setIsOfferingsDropdownOpen(false);
        if (preview) {
            e.preventDefault();
            handlePreviewPopup?.();
        }
    };

    const {
        showEventListingsTitle,
        eventListingsTitle,
        showEventListingsCategoriesDropdown,
        eventListingsCategories,
    } = getCategorisationProps({
        template,
        host,
        hostData,
        goToRoute,
    });

    return {
        home: {
            content: (
                <HostPageButtonContent
                    preview={preview}
                    title={hostData?.title}
                    logoImg={hostData?.social_info?.logo_image}
                    logoText={hostData?.social_info?.logo_text}
                />
            ),
            props: {
                onClick: e => goToRoute(e, ROUTES.HOST, SECTION_IDS.about),
                href: `${ROUTES.HOST.urlPath}#${SECTION_IDS.about}`,
            },
        },
        about: {
            content: hostData?.about_title || "About",
            props: {
                onClick: e => goToRoute(e, ROUTES.HOST, SECTION_IDS.about),
                href: `${ROUTES.HOST.urlPath}#${SECTION_IDS.about}`,
            },
        },
        eventListings: {
            showItem: showEventListingsTitle,
            content: eventListingsTitle,
            props: {
                onClick: e => {
                    if (showEventListingsCategoriesDropdown) {
                        setIsOfferingsDropdownOpen(!isOfferingsDropdownOpen);
                        return;
                    }

                    goToRoute(e, ROUTES.HOST, SECTION_IDS.eventSections);
                },
            },
            showChildren: showEventListingsCategoriesDropdown,
            children: eventListingsCategories,
        },
        team: {
            showItem: preview || !isEmpty(host?.team?.data),
            content: hostData?.custom_team_navbar_title || "Team",
            props: {
                onClick: e => goToRoute(e, ROUTES.HOST, SECTION_IDS.team),
                href: `${ROUTES.HOST.urlPath}#${SECTION_IDS.team}`,
            },
        },
        testimonials: {
            showItem: preview || !isEmpty(hostData?.testimonials?.data),

            content: hostData?.testimonials?.title || "Testimonials",
            props: {
                onClick: e => goToRoute(e, ROUTES.TESTIMONIALS),
                href: `${ROUTES.TESTIMONIALS.urlPath}`,
            },
        },
        gallery: {
            showItem: preview || !isEmpty(hostData?.gallery?.data),
            content: hostData?.gallery?.title || "Gallery",
            props: {
                onClick: e => goToRoute(e, ROUTES.GALLERY),
                href: `${ROUTES.GALLERY.urlPath}`,
            },
        },
        blog: {
            showItem: !isEmpty(host?.blog?.data),
            content: "Blog",
            props: {
                onClick: e => goToRoute(e, ROUTES.BLOG),
                href: ROUTES.BLOG.urlPath,
            },
        },
        externalLinks: (hostData?.dropdown_choices || []).map(item => {
            return {
                content: item.title,
                props: {
                    href: getExternalUrl(item.href),
                    onClick: onExternalLinkClick,
                    target: "_blank",
                    rel: "noreferrer",
                },
            };
        }),
        myClasses: {
            showItem: loggedInState === "loggedIn",
            content:
                hostData?.my_classes_page_title || ROUTES.MY_CLASSES.pageTitle,
            props: {
                onClick: e => goToRoute(e, ROUTES.MY_CLASSES),
                href: ROUTES.MY_CLASSES.urlPath,
            },
        },
        myMeetings: {
            showItem:
                loggedInState === "loggedIn" && hostData?.is_zoom_isv_enabled,
            content: ROUTES.MY_MEETINGS.pageTitle,
            props: {
                onClick: e => goToRoute(e, ROUTES.MY_MEETINGS),
                href: ROUTES.MY_MEETINGS.urlPath,
            },
        },
        purchaseHistory: {
            showItem: loggedInState === "loggedIn",
            content: "Purchase History",
            props: {
                onClick: e => goToRoute(e, ROUTES.PURCHASE_HISTORY),
                href: ROUTES.PURCHASE_HISTORY.urlPath,
            },
        },
        logout: {
            showItem: loggedInState === "loggedIn",
            content: "Logout",
            props: {
                onClick: handleLogoutClick,
            },
        },
        login: {
            showItem:
                loggedInState === "loggedOut" &&
                !getQueryParam(URL_SEARCH_PARAMS.HIDE_LOGIN_CTA), // hide login cta  in case of creator app registration flow
            content: "Login",
            props: {
                onClick: e => goToRoute(e, ROUTES.LOGIN),
            },
        },
        getInTouch: {
            showItem:
                ["loggedOut", "hidden"].includes(loggedInState) &&
                !getHideGetInTouch(hostData) &&
                !getQueryParam(URL_SEARCH_PARAMS.HIDE_CONTACT_CTA), // hide getInTouch cta  in case of creator app registration flow
            content: hostData?.interest_cta_text || "Get In Touch",
            props: {
                onClick: () => onGetInTouchClick?.(),
            },
        },
    };
};
