/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import { Button, LoadingIndicator } from "@Modules/common";
import { BUTTON_DESIGNS } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { LOADING_INDICATOR_DESIGNS } from "repoV2/components/common/LoadingIndicators/LoadingIndicator.data";
import { Popup, usePopup } from "..";
import { INotificationMessagePopup } from "./INotificationMessagePopup";
import styles from "./NotificationMessagePopup.module.scss";

export const NotificationMessagePopup = ({
    closePopup,
    data,
}: INotificationMessagePopup.IProps): JSX.Element => {
    const [canRender, setCanRender] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setCanRender(true);
        }, 400);
    }, []);

    const { closePopupWithAnimation, closing, animationDuration } = usePopup({
        closePopup,
    });

    return (
        <Popup
            closePopup={closePopupWithAnimation}
            containerClass={styles.container}
            closing={closing}
            animationDuration={animationDuration}
        >
            <>
                <div className={styles.header}>
                    <div className={styles.boldText}>
                        {canRender
                            ? data?.email_subject || "Notification"
                            : "Loading..."}
                    </div>
                    <Button
                        className={classNames(
                            styles.button,
                            styles.closeButton
                        )}
                        design={BUTTON_DESIGNS.PLAIN_TEXT}
                        content={<>&#10006;</>}
                        onClick={closePopupWithAnimation}
                    />
                </div>
                {canRender && data?.email_body ? (
                    <div
                        className={styles.body}
                        dangerouslySetInnerHTML={{ __html: data?.email_body }}
                    />
                ) : null}
                {!canRender ? (
                    <div
                        className={classNames(
                            styles.body,
                            styles.centerAlignedContainer
                        )}
                    >
                        <LoadingIndicator
                            applyTheme
                            design={LOADING_INDICATOR_DESIGNS.rotating_arcs}
                        />
                    </div>
                ) : null}
            </>
        </Popup>
    );
};
