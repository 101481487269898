import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import styles from "./LoadingIndicator.module.scss";
import { ILoadingIndicatorProps } from "./LoadingIndeCators";
import {
    LOADING_INDICATOR_DESIGNS,
    LOADING_INDICATOR_PROGRESS_TYPES,
} from "./LoadingIndicator.data";

/**
 * TODO @mayank: separate these 2 loaders
 */
export const LoadingIndicator = ({
    progress: progressProp,
    progressType,
    design,
    className: customClass,
    style,
}: ILoadingIndicatorProps) => {
    const progress = Math.max(0, Math.min(progressProp || 0, 100));

    switch (design) {
        case LOADING_INDICATOR_DESIGNS.progress_bar: {
            const combinedStyle = {
                ...style,
                ...(progressType === LOADING_INDICATOR_PROGRESS_TYPES.controlled
                    ? { width: `${progress}%` }
                    : {}),
            };

            return (
                <div
                    className={classNames(
                        styles.rectangleContainerWithCurvedProgressBar,
                        styles.container,
                        customClass
                    )}
                >
                    <div
                        className={classNames(
                            styles.rectangleContainerWithCurvedProgressBar,
                            styles.loader,
                            progressType ===
                                LOADING_INDICATOR_PROGRESS_TYPES.controlled
                                ? styles.controlled
                                : styles.auto,
                            progress === 100 && styles.loadingComplete
                        )}
                        style={combinedStyle}
                    />
                </div>
            );
        }
        default:
        case LOADING_INDICATOR_DESIGNS.rotating_arcs: {
            return (
                <div
                    className={classNames(styles.spinner, customClass)}
                    style={style}
                />
            );
        }
    }
};

LoadingIndicator.defaultProps = {
    design: undefined,
    progressType: undefined,
    className: "",
    progress: 0,
};
