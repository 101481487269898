import { TRANSACTION_STATUSES } from "repoV2/constants/payment";
import { IHost } from "@Interfaces/redux/store/IHost";
import enGB from "date-fns/locale/en-GB";
import { parseDateTime } from "@Utils/parseDateTime";
import {
    THANKYOU_PAGE_TYPES,
    THANKYOU_PAGE_TYPES_VALUE,
} from "../constants/thankyou.contants";

export const getThankyouPageTitle = (transactionStatus: number) => {
    switch (transactionStatus) {
        case TRANSACTION_STATUSES.FAILURE:
            return "Payment Failure";
        case TRANSACTION_STATUSES.PROCESSING:
            return "Payment Processing";
        case TRANSACTION_STATUSES.SUCCESS:
            return "Thank you";
        default:
            return "Thank you";
    }
};

export function getFaviconUrl({
    profileData,
}: {
    profileData?: IHost.IHostData;
}) {
    return profileData?.social_info?.favicon || profileData?.profile_pic || "";
}

export function getThankyouPageTypeBasedOnValue(value: number) {
    switch (value) {
        case THANKYOU_PAGE_TYPES_VALUE[THANKYOU_PAGE_TYPES.PURCHASE]:
            return THANKYOU_PAGE_TYPES.PURCHASE;
        case THANKYOU_PAGE_TYPES_VALUE[THANKYOU_PAGE_TYPES.OPTIN]:
            return THANKYOU_PAGE_TYPES.OPTIN;
        default:
            return null;
    }
}

export const getDateStringWithTimeZone: (dateUTCString: string) => string =
    date =>
        parseDateTime(date, {
            formatString: "dd MMMM yyyy hh:mm a zzz",
            formatOptions: {
                locale: enGB,
            },
        });
