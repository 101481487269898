export const ExamResponseCommentVisibility = {
    HIDDEN: 1,
    ATTEMPTED: 2,
    ALL: 3,
};

export const CourseCommentType = {
    TEXT: 1,
    IMAGE: 2,
    HTML: 3,
};

export const RecordedCommentSource = {
    USER_INPUT: 1,
    EXAM_SUBMISSION: 2,
    FORM_SUBMISSION: 3,
};

export const TABS = {
    DISCUSSION: 1,
    UPDATE_FROM_PEERS: 2,
};

export const TAB_OPTIONS = [
    {
        label: "Discussion",
        value: TABS.DISCUSSION,
    },
    {
        label: "Updates from peers",
        value: TABS.UPDATE_FROM_PEERS,
    },
];

export const UPDATE_FROM_PEERS_SOURCE = `${RecordedCommentSource.EXAM_SUBMISSION},${RecordedCommentSource.FORM_SUBMISSION}`;
