import intervalToDuration from "date-fns/intervalToDuration";
import { TIME_UNIT_KEYS } from "../Time.constants";

export function isValidDateObj(d: Date) {
    return d instanceof Date && !Number.isNaN(d);
}

export const getCurrentYear = () => new Date().getFullYear();

/**
 * convert value returned from `useCountdownTimer` hook into units to show
 */
export const getCountdownTimerUnitsToShow = ({
    timer,
    noOfUnitsToShow: _noOfUnitsToShow,
    getAllAfter1stShowable,
}: {
    timer: number;
    noOfUnitsToShow?: number;
    getAllAfter1stShowable?: boolean;
}) => {
    const {
        years = 0,
        months = 0,
        days = 0,
        hours = 0,
        minutes = 0,
        seconds = 0,
    } = intervalToDuration({ start: 0, end: timer });
    const allUnitsInOrder = [
        { unit: TIME_UNIT_KEYS.YEARS, value: years },
        { unit: TIME_UNIT_KEYS.MONTHS, value: months },
        { unit: TIME_UNIT_KEYS.DAYS, value: days },
        { unit: TIME_UNIT_KEYS.HOURS, value: hours },
        { unit: TIME_UNIT_KEYS.MINUTES, value: minutes },
        { unit: TIME_UNIT_KEYS.SECONDS, value: seconds },
    ];

    // largest unit that is non zero (i.e, can be shown to user in the timer)
    const firstShowableIndex = allUnitsInOrder.findIndex(i => i.value > 0);

    const maxNoOfUnitsToShow = allUnitsInOrder.length;

    if (getAllAfter1stShowable) {
        return allUnitsInOrder.slice(firstShowableIndex);
    }

    const noOfUnitsToShow = _noOfUnitsToShow ?? maxNoOfUnitsToShow;
    // if: we have sufficient units to show
    // then: pick the set of units where, `firstShowableIndex` unit is the largest
    if (firstShowableIndex + noOfUnitsToShow <= allUnitsInOrder.length) {
        return allUnitsInOrder.slice(
            firstShowableIndex,
            firstShowableIndex + noOfUnitsToShow
        );
    } else {
        // pick smallest set of noOfUnitsToShow units
        return allUnitsInOrder.slice(-noOfUnitsToShow);
    }
};
