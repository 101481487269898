import { CREATOR_DETAILS_ACTION_TYPES } from "./actions";
import {
    ICreatorDetailsAction,
    ICreatorDetailsInitialState,
} from "./redux.interface";

export const creator_details_initial_state: ICreatorDetailsInitialState = {
    featureAccessibilty: {},
};

export default function creatorDetailsReducer(
    state = creator_details_initial_state,
    { type, payload }: ICreatorDetailsAction
) {
    switch (type) {
        case CREATOR_DETAILS_ACTION_TYPES.SET_FEATURE_ACCESSIBILITY: {
            if (payload.featureKey) {
                return {
                    ...state,
                    featureAccessibilty: {
                        ...state.featureAccessibilty,
                        [payload.featureKey]: payload.accessibility,
                    },
                };
            }
            return state;
        }
        default:
            return state;
    }
}
