import { LOCAL_STORAGE_KEYS } from "repoV2/constants/common/storage/localStorage";
import { getIsKnownTimezone } from "repoV2/features/Common/modules/Timezone/Timezone.utils";
import { getLocalStorageItem } from "../repoV2/utils/common/storage/getterAndSetters";
import {
    DEFAULT_INTERNATIONAL_TIMEZONE,
    DEFAULT_TIMEZONE,
} from "../repoV2/features/Common/modules/Timezone/Timezone.constants";
import { isBrowser } from "../repoV2/utils/common/render/window";

export type ISystemLocalePayload = {
    timezone: string;
    countryCode: string;
    countryName: string;
};

/**
 * If the timezone passed to it is not in the list of known timezones, it looks into the cache to find for an override value and return that. If that is not present, it returns Europe/London as a default.
 * @param timezoneParam timezone string for the value to check
 */
const overrideWithCachedTzIfUnknown = (timezoneParam: string) => {
    if (getIsKnownTimezone(timezoneParam)) return timezoneParam;
    const cachedLocaleData: ISystemLocalePayload = getLocalStorageItem(
        LOCAL_STORAGE_KEYS.SYSTEM_LOCALE
    );
    if (cachedLocaleData?.timezone?.length > 0) {
        return cachedLocaleData.timezone;
    }
    return DEFAULT_INTERNATIONAL_TIMEZONE;
};

/**
 * @deprecated
 * use `getUserTimezone` or `getSystemTimezone` from `repoV2/features/Common/modules/Timezone/Timezone.utils.ts`
 *
 * Provides the system timezone using the window.Intl API.
 * Prefer using getTimezone instead as that syncs the timezone with localstorage as well.
 * Do not use on server as `window` does not exist.
 * @param overrideWithCacheIfInvalid whether the function should attempt to validate the browser provided timezone via the `overrideWithCachedTzIfUnknown` function
 * */
export const getBrowserTimezone = (
    overrideWithCacheIfInvalid: boolean | undefined = true
): string => {
    const resolveDefaultTimezone = (timezone: string): string =>
        // This is a bug in chromium that returns Calcutta instead of Kolkata
        // This function will just convert it to Asia/Kolkata if that is the case
        // Add any further normalisation of timezone parsing here
        timezone === "Asia/Calcutta" ? DEFAULT_TIMEZONE : timezone;

    const overrideWrapper = (v: string): string =>
        resolveDefaultTimezone(
            overrideWithCacheIfInvalid ? overrideWithCachedTzIfUnknown(v) : v
        );

    if (!isBrowser()) return overrideWrapper(DEFAULT_TIMEZONE);

    try {
        return overrideWrapper(
            new window.Intl.DateTimeFormat().resolvedOptions().timeZone
        );
    } catch (error) {
        return overrideWrapper(DEFAULT_TIMEZONE);
    }
};
