import { LOCAL_STORAGE_KEYS } from "repoV2/constants/common/storage/localStorage";
import { isBrowser } from "../repoV2/utils/common/render/window";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "../repoV2/utils/common/storage/getterAndSetters";
import { DEFAULT_TIMEZONE } from "../repoV2/features/Common/modules/Timezone/Timezone.constants";
import { getBrowserTimezone } from "./getBrowserTimezone";

/**
 * @deprecated
 * use `getUserTimezone` or `getSystemTimezone` from `repoV2/features/Common/modules/Timezone/Timezone.utils.ts`
 */
export const getTimezone = (): { timezone: string; isDefault: boolean } => {
    const getReturnPayload = (tz: string) => ({
        timezone: tz,
        isDefault: tz === DEFAULT_TIMEZONE,
    });

    if (!isBrowser()) {
        return getReturnPayload(DEFAULT_TIMEZONE);
    }

    const cachedTimezone = getLocalStorageItem(LOCAL_STORAGE_KEYS.TIMEZONE);
    // TODO: Format validation
    if (cachedTimezone) {
        return getReturnPayload(cachedTimezone);
    }

    const timezone: string = getBrowserTimezone();

    setLocalStorageItem(LOCAL_STORAGE_KEYS.TIMEZONE, timezone);

    return getReturnPayload(timezone);
};
