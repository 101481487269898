export const SESSION_STORAGE_KEYS = {
    REFERER: "referer",
    USER_IP: "user_ip_details",
    PAYMENT_STATUS_GATEWAY_EXISTS: "payment_status_gateway_exists",
    PAYMENT_PAGE_REFERRER: "payment_page_referrer", // Once user initiates the payment, this
    // key is used to store the payment page url
    END_USER_SESSION_ID: "host_session_id",

    // RECORDED CONTENT
    DOES_IDM_EXIST: "DOES_IDM_EXIST",
    HAS_CHECKED_IDM: "HAS_CHECKED_IDM",
    LAST_SUBMITTED_EXAM_SCORING_TYPE: "LAST_SUBMITTED_EXAM_SCORING_TYPE",

    // Thank you
    EXPRESS_INTEREST_FORM_DATA: "express_interest_form_data", // Incase of soldout, on submitting
    // the express interest form user is redirected to Thankyou optin page. We need to show express
    // interest for values there, this key stores that data in sessionStorage

    // ANALYTICS
    // Being used in the common analytics script (used in sales page builder pages).
    // TODO: mention the interface / data present in "META_PIXEL_UUID_DATA" key
    META_PIXEL_UUID_DATA: "META_PIXEL_UUID_DATA",

    // reload count of a page to avoid multiple freq hits to the same page
    // structure: { [url: string] : number }
    RELOAD_COUNT: "RELOAD_COUNT",
};

export const LOCAL_STORAGE_KEYS = {
    AFFILIATE_UUID: "affiliate_uuid", // affiliate_uuid to be passed in initate api
    TIMEZONE: "timezone",
    USER_IP_DETAILS_FOR_ANALYTICS_INFO: "user_ip_details",
    SYSTEM_LOCALE: "system_locale",

    // This key will store the list of uuid's of the customers who
    // have purchased any listing, Currently stored fromlisting page, post purchase
    USER_NAME_LIST: "username_list",

    // auth and logged in user data
    ACCESS_TOKEN: "accessToken",
    REFRESH_TOKEN: "refreshToken",
    IS_CREATOR: "is_creator",
    // Details of the logged in user.
    // Being used in the common analytics script (used in sales page builder pages).
    // TODO: mention the interface / data present in "logged_in_user_details" key
    LOGGED_IN_USER_DETAILS: "logged_in_user_details",

    // branded community
    IS_COMMUNITY_GUIDED_FLOW_DONE: "is_community_guided_flow_done",
    IS_COMMUNITY_ONBOARDING_DONE: "is_community_onboarding_done",
    IS_COMMUNITY_ONBOARDING_DEMO_DONE: "is_community_onboarding_demo_done",
    COMMUNITY_CURRENT_ONBOARDING_STEP: "community_current_onboarding_step",
};
