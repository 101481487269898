export const META_PIXEL_EVENTS = {
    PAGE_VIEW: "PageView",
    CONTACT: "Contact",
    LEAD: "Lead",
    ADD_TO_CART: "AddToCart",
    INITIATE_CHECKOUT: "InitiateCheckout",
    ADD_PAYMENT_INFO: "AddPaymentInfo",
    PURCHASE: "Purchase",
};

export const META_ANALYTICS_COOKIE_KEYS = {
    fbc: "_fbc",
    fbp: "_fbp",
};

export const META_ANALYTICS_QUERY_PARAMS = {
    fbclid: "fbclid",
};

export const META_ANALYTICS_REGEX = {
    REMOVE_PLUS_AND_SPACES: /[+\s]/g,
};
