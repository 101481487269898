import { EXLY_BACKEND_API_HOST } from "repoV2/constants/apis";
import { FETCH_FEATURE_ACCESSIBILITY_API } from "repoV2/features/CreatorDetails/modules/FeatureAccessibility/constants/api";
import { callApi } from "repoV2/utils/common/api/callApi";

export const fetchFeatureAccessiblility = ({
    featureKey,
    hostName,
}: {
    featureKey: string;
    hostName: string;
}) => {
    return callApi({
        url: `${EXLY_BACKEND_API_HOST}/${FETCH_FEATURE_ACCESSIBILITY_API}`,
        extraOptions: { appendExlyBackendHeaders: true },
        params: {
            sub_domain: hostName,
            feature_key: featureKey,
        },
    });
};
