import React, { useEffect, useRef, useState } from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { MODAL_TYPES, useModal, usePrevious } from "@Utils";
import { IUtils } from "@Interfaces";
import { useGetOverrideUDMODB } from "@Modules/eventPage/utils/overrideUDMODB";
import {
    UserDetails,
    GetInTouch,
    SoldOut,
    ClassicGallery,
    EventModalWrapper,
    SocialShare,
    ExitIntentForm,
    PreviewPopup,
    Reviews,
    Share,
    ModernTestimonial,
    PaymentStatus,
    PaymentNotification,
    PaymentSelection,
    ExitOffer,
} from "../components";
import styles from "./modal.module.scss";
import customStyles from "./customclasses.module.scss";

const MODAL_ENUM: Record<string, (p: any) => JSX.Element> = {
    [MODAL_TYPES.UserDetails]: (props: any) => <UserDetails {...props} />,
    [MODAL_TYPES.GetInTouch]: (props: any) => <GetInTouch {...props} />,
    [MODAL_TYPES.SocialShare]: (props: any) => <SocialShare {...props} />,
    [MODAL_TYPES.ClassicGallery]: (props: any) => <ClassicGallery {...props} />,
    [MODAL_TYPES.ExitIntent]: (props: any) => <ExitIntentForm {...props} />,
    [MODAL_TYPES.ExitOffer]: (props: any) => <ExitOffer {...props} />,
    [MODAL_TYPES.EventModal]: (props: any) => <EventModalWrapper {...props} />,
    [MODAL_TYPES.SoldOut]: (props: any) => <SoldOut {...props} />,
    [MODAL_TYPES.PreviewPopup]: (props: any) => <PreviewPopup {...props} />,
    [MODAL_TYPES.PaymentStatus]: (props: any) => <PaymentStatus {...props} />,
    [MODAL_TYPES.Reviews]: (props: any) => <Reviews {...props} />,
    [MODAL_TYPES.Share]: (props: any) => <Share {...props} />,
    [MODAL_TYPES.ModernTestimonial]: (props: any) => (
        <ModernTestimonial {...props} />
    ),
    [MODAL_TYPES.PaymentSelection]: (props: any) => (
        <PaymentSelection {...props} />
    ),
    [MODAL_TYPES.PaymentNotification]: (props: any) => (
        <PaymentNotification {...props} />
    ),
};

const Modal = (): JSX.Element => {
    // Importing the data binding and redux action methods from the modal hook
    const {
        modalInfo: hookModalInfo,
        setModalInfo,
        closeModal: handleCloseModal,
    } = useModal();

    // This `isModalOpenState` will turn false some time after `hookModalInfo.isModalOpen`,
    // just to animate the closing of the modal
    // eslint-disable-next-line prefer-const
    let [isModalOpenState, setModalOpenState] = useState<boolean>(false);

    // hookModalInfo is the true source of the modal state
    let { isModalOpen } = hookModalInfo;

    // `useGetOverrideUDMODB` related code starts
    /**
    see definition of `useGetOverrideUDMODB` to understand better
    */
    const overrideUDMODB = useGetOverrideUDMODB();
    if (overrideUDMODB) {
        isModalOpen = true;
        isModalOpenState = true;
    }
    // `useGetOverrideUDMODB` related code ends

    // If performance suffers, just set `isClosing` explicitly to false and see if it helps. Probably won't.
    const isClosing: boolean = isModalOpenState && !isModalOpen;

    // This is just the previous cached state that will be temporarily used while animating the modal closing.
    // I still question myself for over-engineering this modal closing animation just because I wanted to have it a closing animation.
    const cachedModalInfo: IUtils.IModal = usePrevious(
        hookModalInfo,
        hookModalInfo.isModalOpen
    );

    // TODO: Add provision for animations when modal type switching happens
    const finalModalInfo = isClosing ? cachedModalInfo : hookModalInfo;
    const { isCloseable }: IUtils.IModal = finalModalInfo;
    let { modalProps, modalType }: IUtils.IModal = finalModalInfo;

    const { disableClose = false } = modalProps;
    // `useGetOverrideUDMODB` related code starts
    if (overrideUDMODB) {
        modalType = MODAL_TYPES.UserDetails;
        modalProps = {
            ...modalProps,
            isBooking: true,
        };
    }
    // `useGetOverrideUDMODB` related code ends

    // Will assign the variables being read appropriately.

    const modalRef = useRef<HTMLDivElement>(null);

    // TODO: Handle back nav
    const handleClickOutside = (e: any) => {
        if (modalRef.current && !modalRef?.current?.contains(e.target)) {
            closeModal();
        }
    };

    const closeModal = () => {
        if (!disableClose) handleCloseModal();
    };

    useEffect(() => {
        const handleEscapePress = (e: KeyboardEvent) => {
            // TODO: Don't close if stripe is open
            if (e.key === "Escape") {
                closeModal();
            }
        };
        document.addEventListener("keydown", handleEscapePress);
        return () => {
            document.removeEventListener("keydown", handleEscapePress);
        };
    }, [
        hookModalInfo?.overrides?.handleClose,
        hookModalInfo?.isCloseable,
        disableClose,
    ]);

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0];
        let timer: any;
        if (body) {
            body.classList[isModalOpenState ? "add" : "remove"](
                "noscroll-modal"
            );
        }
        if (!isModalOpen) {
            if (isModalOpenState) {
                if (timer) clearInterval(timer);
                timer = setTimeout(() => {
                    setModalOpenState(false);
                }, 300);
            }
        } else if (!isModalOpenState) setModalOpenState(true);

        return () => {
            body.classList.remove("noscroll-modal");
            if (timer) clearInterval(timer);
        };
    }, [isModalOpen, isModalOpenState]);

    return (
        <div
            className={classNames(
                styles.root,
                isModalOpenState && styles.open,
                customStyles?.[`${modalType}-root`]
            )}
            onClick={handleClickOutside}
        >
            {isModalOpenState ? (
                <>
                    <div
                        className={classNames(
                            styles.backdrop,
                            customStyles?.[`${modalType}-backdrop`],
                            isClosing && styles.isClosing
                        )}
                        onClick={() => closeModal()}
                    />
                    <div
                        className={classNames(
                            overrideUDMODB && styles.disableAnimations,
                            styles.modalRoot,
                            customStyles?.[`${modalType}-modalRoot`],
                            isClosing && styles.isClosing
                        )}
                    >
                        <div
                            ref={modalRef}
                            className={classNames(
                                styles.modalContainer,
                                customStyles?.[`${modalType}-modalContainer`]
                            )}
                        >
                            {isModalOpenState &&
                                modalType &&
                                Object.keys(MODAL_TYPES).includes(modalType) &&
                                MODAL_ENUM?.[modalType]({
                                    modalInfo: {
                                        isModalOpen,
                                        // modalProps,
                                        modalType,
                                        isCloseable,
                                    },
                                    setModalInfo,
                                    closeModal,

                                    ...modalProps,
                                })}
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default Modal;
