import { APP_ROUTE_KEYS, NEXT_ROUTE_PATHNAMES } from "../urls&routing/routing";

export const LOG_IN_REQUIRED_ROUTE_PATHNAMES = [
    NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.MY_CLASSES],
    NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.PURCHASE_HISTORY],
    NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.SUBSCRIPTION_HISTORY],
    NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.MY_MEETINGS],
    NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.LEADERBOARD],
    NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.BRANDED_COMMUNITY],
];
