import {
    CURRENCY_STRINGS,
    CURRENCY_SYMBOLS,
    GATEWAYS_ENUM,
} from "repoV2/constants/payment";
import { GST_STATUS as _GST_STATUS } from "repoV2/features/Listing/Listing.constants";

export const getCurrencyString = (currencySymbol: string): string => {
    const filterEntries = Object.entries(CURRENCY_SYMBOLS).filter(
        ([, sym]) => sym === currencySymbol
    );
    return filterEntries.length > 0
        ? filterEntries[0][0]
        : CURRENCY_STRINGS.INR;
};

export const DEFAULT_PAYMENT_GATEWAY = GATEWAYS_ENUM.PAYPAL;

/**
 * @deprecated Please use the constant defined at repoV2/features/Listing/Listing.constants.ts
 */
export const GST_STATUS = _GST_STATUS;

export const PAYMENT_GATEWAYS = {
    RAZORPAY: "https://checkout.razorpay.com/v1/checkout.js",
    // STRIPE: "https://js.stripe.com/v3/",
};

// Convert the transaction status code from stripe to razorpay
export const STRIPE_STATUS = {
    succeeded: 2,
    processing: 7,
    payment_failed: 8,
};

export const AUTO_DEBIT_PAYMENT_OPTION = ["Card"];
