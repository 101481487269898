import { isEmpty } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import React, { FC, useEffect } from "react";
import Select, { GroupBase, OptionProps } from "react-select";
import { useReactHookFormController } from "repoV2/components/common/Form/utils";
import styles from "./dropdownSelector.module.scss";
import { IDropdownSelector } from "./IDropdownSelector";

/**
 * - if `name` prop is passed, when ever `onChange` is triggered, it will pass `actionMeta` along with it.\
 *      actionMeta = { name }\
 *      This is useful when you are using forms and you want to identify what field is value being assigned to
 * - extraProps can have following keys
 *      - value?: IDropdownSelector.Option | null;
 *          - null to reset the selected value
 *      - onFocus?: () => void;
 *      - onBlur?: () => void;
 *          - Don't set showError in onBlur especially if it updates redux or states.
 *              It causes race condition b/w `onChange` and `onBlur`. Rather set
 *              `showError` in `onChange` itself
 *      - isDisabled?: boolean;
 *      - isOptionDisabled?: ((option: IDropdownSelector.Option, options?: OptionsType<IDropdownSelector.Option>) => boolean)
 *      - menuIsOpen?: boolean;
 *          - true to keep the menu open for testing
 *          - in production to get the same effect, do either of the following:
 *              - Inspect Element > Elements > Event Listeners > focusout > remove
 *              - Inspect Element > Elements > Event Listeners > blur > remove\
 *                  reference - https://stackoverflow.com/questions/49299042/react-select-how-to-keep-dropdown-open-when-styling-in-inspector
 *      - isSearchable?: boolean
 *          - false to not allow the user to filter dropdown options by typing
 *      - menuPlacement?: "top" | "bottom" | "auto"
 * - `style` does not work on `react-select`, rather use `styles`. You can global search in this repo or google.
 */

export const DropdownSelector: FC<IDropdownSelector.IProps> = ({
    id,
    name,
    error,
    design,
    className: customClass,
    options,
    autoSelect,
    onChange: onChangeProp,
    editingDisabled,
    isOptionDisabled: isOptionDisabledProp,
    withController,
    ...extraProps
}) => {
    const { controllerOnChange, restControllerProps = {} } =
        useReactHookFormController({
            name: name || "",
            control: extraProps?.control,
        });

    const isOptionDisabled: IDropdownSelector.IProps["isOptionDisabled"] = (
        value,
        optionsParam
    ) => {
        if (isOptionDisabledProp) {
            return isOptionDisabledProp(value, optionsParam);
        }
        return false;
    };

    const onChange: IDropdownSelector.IProps["onChange"] = (
        value,
        actionMeta
    ) => {
        if (onChangeProp) {
            onChangeProp(value, actionMeta);
        }
    };

    useEffect(() => {
        if (
            !isEmpty(options) &&
            !editingDisabled &&
            (autoSelect === "first" ||
                // FIOOO = first if only one option
                (autoSelect === "FIOOO" && options.length === 1)) &&
            onChange &&
            // stringified is used to do deep comparison
            JSON.stringify(options[0]) !== JSON.stringify(extraProps.value) &&
            !isOptionDisabled(options[0])
        ) {
            onChange(options[0], { name });
        }

        /*
        Sometimes, options are loaded after the component is mounted,
        so we want to set the value after the options are selected.
        Stringified is used to do deep comparison
        */
    }, [JSON.stringify(options)]);

    let finalClassNames = classNames(
        editingDisabled && styles.editingDisabled,
        error && styles.inputError,
        customClass
    );

    switch (design) {
        case "custom":
            break;
        case "rounded corners":
        default:
            finalClassNames = classNames(
                styles.roundedCornersDropdownSelector,
                finalClassNames
            );
            break;
    }

    // TODO: Need to change CustomOptions so that it should be passed from calling environment
    // TBD: End of POP v2
    /**
     * CustomOptions Component to display custom labels in a select component.
     * This component renders a label and an optional "Recommended" tag based on the data provided.
     *
     * @param {Object} innerProps - The inner props passed down from the select component.
     * @param {string} label - The label to be displayed.
     * @param {Object} data - Additional data object containing properties for customization.
     * @returns {JSX.Element} - A custom option component with the label and optional "Recommended" tag.
     */
    const CustomOptions: React.ComponentType<
        OptionProps<
            IDropdownSelector.Option,
            false,
            GroupBase<IDropdownSelector.Option>
        >
    > = ({ innerProps, label, data }) => {
        const isRecommended = data?.is_recommended || false;

        const RenderLabel = () => {
            return <div className={styles.customLabel}>{label}</div>;
        };

        const RenderRecommendedLabel = () => {
            if (isRecommended) {
                return (
                    <div className={styles.labelContainer}>
                        <RenderLabel />
                        <span className={styles.recommendedLabelField}>
                            Recommended
                        </span>
                    </div>
                );
            }

            return <RenderLabel />;
        };

        return (
            <div {...innerProps}>
                <RenderRecommendedLabel />
            </div>
        );
    };

    const customComponents = {
        Option: CustomOptions,
    };

    return (
        <Select
            id={id}
            instanceId={id}
            options={options}
            components={customComponents}
            className={finalClassNames}
            menuPlacement="auto"
            isMulti={false}
            isDisabled={editingDisabled}
            isOptionDisabled={isOptionDisabled}
            {...restControllerProps}
            name={name}
            onChange={(value, action) => {
                onChange(value, action);
                controllerOnChange?.(value);
            }}
            {...extraProps}
        />
    );
};
