import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import styles from "./CarouselCustomDot.module.scss";
import { ICarouselCustomDotProps } from "./CarouselCustomDot.interfaces";

export const CarouselCustomDot = ({
    design,
    active,
    onClick,
    className,
    ...restProps
}: ICarouselCustomDotProps) => {
    const baseClasses = classNames(
        styles.base,
        className,
        active && styles.active,
        onClick && styles.button
    );

    let finalClasses = "";
    switch (design) {
        case "dot":
            finalClasses = classNames(baseClasses, styles.dot);
            break;
        case "custom":
            break;
        case "horizontal bar":
        case "square":
            finalClasses = classNames(
                baseClasses,
                styles.basegrey,
                active && styles.activered,
                styles.dot,
                styles.dotsquare
            );
            break;
        default:
            finalClasses = classNames(baseClasses, styles.horizontalBar);
            break;
    }

    return <div className={finalClasses} onClick={onClick} {...restProps} />;
};

CarouselCustomDot.defaultProps = {
    design: "horizontal bar",
    applyTheme: false,
    active: false,
    onClick: undefined,
    className: "",
};
