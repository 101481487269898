// TODO: Implement routing from here instead hard-coded strings

import {
    APP_ROUTE_KEYS,
    NEXT_ROUTE_PATHNAMES,
    ROUTE_BASE_PATHNAMES,
} from "repoV2/constants/urls&routing/routing";

export const ROUTE_KEYS = {
    HOST: "HOST",
    EVENT: "EVENT",
    UNIVERSAL_PAY: "UNIVERSAL_PAY",
    CONTENT: "CONTENT",
    LOGIN: "LOGIN",
    VERIFYOTP: "VERIFYOTP",
    SUCCESS: "SUCCESS",
    COMMON_TERMS: "COMMON_TERMS",
    TERMS_CONDITIONS: "TERMS_CONDITIONS",
    PRIVACY_POLICY: "PRIVACY_POLICY",
    NOT_FOUND: "NOT_FOUND",
    ERROR: "ERROR",
    TEAM_MEMBER: "TEAM_MEMBER",
    BRANDED_COMMUNITY: "BRANDED_COMMUNITY",
    THANKYOU_OPTIN: "THANKYOU_OPTIN",
    THANKYOU_PURCHASE: "THANKYOU_PURCHASE",
    SALE: "SALE",
};

/**
 * @deprecated use constants in repoV2/constants/urls&routing/routing.ts and utils in repoV2/utils/urls&routing/routing.ts
 * TODO: add following keys for each route and change implementations everywhere
 * ```
 * BLOG_POST: {
 *     key: "BLOG_POST",
 *     urlRoute: "/blog_post/:blogPostUuid", // react-router URL with params
 *     urlPath: "/blog_post", // URL Path
 *     filePath: "blogPost", // Path of the file
 *     route: "/blog_post " // next router's internal route name
 *     TODO: getUrlRoute needs implementation
 *     getUrlRoute: ({ ...params }) => `${outputPath}` // Function that takes params and generates a url string
 * },
 * ```
 */

export const ROUTES = {
    HOST: {
        key: "HOST",
        urlRoute: "/",
        urlPath: "/",
        filePath: "host",
        route: "/host",
    },
    EVENT: {
        key: "EVENT",
        urlRoute: "/:eventId",
        route: "/event",
    },
    THANKYOU_OPTIN: {
        key: ROUTE_KEYS.THANKYOU_OPTIN,
        urlRoute: "/:eventId/optin/thankyou",
        route: "/thankyouOptin",
    },
    THANKYOU_PURCHASE: {
        key: ROUTE_KEYS.THANKYOU_PURCHASE,
        urlRoute: "/:eventId/purchase/thankyou",
        route: "/thankyouPurchase",
    },
    SALE: {
        key: ROUTE_KEYS.SALE,
        urlRoute: "/offer/:slug",
        route: "/sale",
    },
    FEEDBACK: {
        key: "FEEDBACK",
        urlRoute: "/feedback/:eventId",
        route: "/feedback",
    },
    UNIVERSAL_PAY: {
        key: "UNIVERSAL_PAY",
        urlRoute: "/pay",
        route: "/pay",
    },
    CONTENT: {
        key: "CONTENT",
        urlRoute: "/content",
        urlPath: "/content",
        filePath: "content",
        route: "/content",
    },
    QUESTIONNAIRE: {
        key: "QUESTIONNAIRE",
        urlRoute: "/questionnaire",
        urlPath: "/questionnaire",
        filePath: "questionnaire",
        route: "/questionnaire",
    },
    INSTALLMENTS: {
        key: "INSTALLMENTS",
        urlRoute: "/installments/:listingId",
        urlPath: "/installments",
        filePath: "installments",
        route: "/installments",
    },
    DOWNLOAD: {
        key: "DOWNLOAD",
        urlPath: "/download",
        filePath: "download",
        route: "/download",
    },
    BLOG: {
        key: "BLOG",
        urlPath: "/blog",
        filePath: "blog",
        urlRoute: "/blog",
    },
    BLOG_POST: {
        key: "BLOG_POST",
        urlRoute: "/blog_post/:blogPostUuid",
        urlPath: "/blog_post",
        filePath: "blogPost",
    },
    LOGIN: {
        key: "LOGIN",
        urlRoute: "/login",
        urlPath: "/login",
        filePath: "login",
        route: "/login",
    },
    VERIFYOTP: {
        key: "VERIFYOTP",
        urlRoute: "/verifyOtp",
        route: "/verifyOtp",
    },
    SERVICELOGIN: {
        key: "SERVICELOGIN",
        urlRoute: "/serviceLogin",
        route: "/serviceLogin",
    },
    MY_CLASSES: {
        key: "MY_CLASSES",
        urlPath: "/my_bookings",
        filePath: "myClasses",
        route: "/myClasses",
        pageTitle: "My Bookings",
    },
    MY_SCHEDULE: {
        key: "MY_SCHEDULE",
        urlRoute: "/myschedule",
        urlPath: "/myschedule",
        filePath: "mySchedule",
        route: "/mySchedule",
        pageTitle: "My Schedule",
    },
    MY_MEETINGS: {
        key: "MY_MEETINGS",
        urlPath: "/my_meetings",
        filePath: "myMeetings",
        route: "/myMeetings",
        pageTitle: "My Meetings",
    },
    PURCHASE_HISTORY: {
        key: APP_ROUTE_KEYS.PURCHASE_HISTORY,
        urlPath: ROUTE_BASE_PATHNAMES[APP_ROUTE_KEYS.PURCHASE_HISTORY],
        filePath: "purchaseHistory",
        route: NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.PURCHASE_HISTORY],
    },
    SUCCESS: {
        key: "SUCCESS",
        urlRoute: "/success",
        route: "/success",
    },
    COMMON_TERMS: {
        key: "COMMON_TERMS",
        urlRoute: "/TermsAndConditions",
        urlPath: "/TermsAndConditions",
        route: "/commonterms",
    },
    TERMS_CONDITIONS: {
        key: "TERMS_CONDITIONS",
        urlRoute: "/HostTermsAndConditions",
        route: "/termsandcondition",
    },
    PRIVACY_POLICY: {
        key: "PRIVACY_POLICY",
        urlRoute: "/PrivacyPolicy",
        route: "/privacypolicy",
    },
    NOT_FOUND: {
        key: "NOT_FOUND",
        urlRoute: "/404",
        route: "/404",
    },
    ERROR: {
        key: "ERROR",
        urlRoute: "/500",
        route: "/500",
    },
    CART: {
        key: "CART",
        urlRoute: "/cart",
        urlPath: "/cart",
        filePath: "cart",
        route: "/cart",
    },
    TEAM_MEMBER: {
        key: "TEAM_MEMBER",
        urlRoute: "/teamMember/:teamMemberUuid",
        urlPath: "/teamMember",
        filePath: "teamMember",
    },
    TESTIMONIALS: {
        key: APP_ROUTE_KEYS.TESTIMONIALS,
        urlPath: ROUTE_BASE_PATHNAMES[APP_ROUTE_KEYS.TESTIMONIALS],
        filePath: "testimonial",
        urlRoute: NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.TESTIMONIALS],
    },
    GALLERY: {
        key: APP_ROUTE_KEYS.GALLERY,
        urlPath: ROUTE_BASE_PATHNAMES[APP_ROUTE_KEYS.GALLERY],
        filePath: "gallery",
        urlRoute: NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.GALLERY],
    },
};

// keeping everything at one place became confusing
//  breaking into separate constants

/**
 * - this can be used to construct different routes of this app
 * - the routes can be dynamic so we need a function
 */
export const APP_ROUTE_GENERATORS = {
    TELEGRAM_OAUTH: () => "/telegram_oauth",
};

export const MODAL_EXCLUDE = [
    ROUTES.COMMON_TERMS.route,
    ROUTES.PRIVACY_POLICY.route,
    ROUTES.TERMS_CONDITIONS.route,
];

/**
 * A constant for `window.location.hash` values used in the HostPage
 *
 * TODO: add other constants here as well and use this variable globally
 */
export const SECTION_IDS = {
    about: "about",
    eventSections: "browseSection",
    eventSection: (index: number) => `eventSection-${index}`,
    testimonials: "testimonials",
    gallery: "gallery",
    team: "team",
    blog: "blog",
};

export const LOAD_PAYMENT = [
    ROUTES.HOST.route,
    ROUTES.EVENT.route,
    ROUTES.CART.route,
    ROUTES.UNIVERSAL_PAY.route,
    ROUTES.INSTALLMENTS.route,
    ROUTES.CONTENT.route,
    ROUTE_BASE_PATHNAMES[APP_ROUTE_KEYS.OFFERINGS],
];
