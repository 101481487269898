import { IStore } from "@Interfaces";

export const SELECTORS = {
    // Host
    host: (state: IStore.IState) => state.host,
    hostName: (state: IStore.IState) => state.host.hostName,
    country: (state: IStore.IState) => state.host.country,
    hostData: (state: IStore.IState) => state.host?.dataHost?.profile_data,
    plansData: (state: IStore.IState) => state.host.plans,
    recordedContent: (state: IStore.IState) => state.host.content,
    recordedContentComments: (state: IStore.IState) =>
        state.host.content.extraDetails.comments,
    watermark: (state: IStore.IState) => state.host.watermark,

    // Event
    event: (state: IStore.IState) => state.event,
    selectedEvent: (state: IStore.IState) => state.event.selectedEvent,
    discountInfo: (state: IStore.IState) => state.event.discount,
    paymentInfo: (state: IStore.IState) => state.event.paymentInfo,
    eventList: (state: IStore.IState) => state.event.list,
    miscellaneousData: (state: IStore.IState) => state.event.miscellaneousData,
    upsellListingCollection: (state: IStore.IState) =>
        state.event.upsellListings,

    // User
    formDetailsCollection: (state: IStore.IState) => state.user.formDetails,
    forms: (state: IStore.IState) => state.user.forms, // for formsV2
    user: (state: IStore.IState) => state.user,
    username: (state: IStore.IState) => state.user.username,
    usernameList: (state: IStore.IState) => state.user.usernameList,
    orders: (state: IStore.IState) => state.user.orders,
    leadAnswers: (state: IStore.IState) => state.user.answers,
    leadAnswerThumbnails: (state: IStore.IState) => state.user.thumbnails,

    // Utils
    modalInfo: (state: IStore.IState) => state.utils.modal,
    fetchStatus: (state: IStore.IState) => state.utils.fetchStatus,
    canRender: (state: IStore.IState) => state.utils.canRender,
    isProcessing: (state: IStore.IState) => state.utils.isProcessing,
    data: (state: IStore.IState) => state.utils.data,
    communityAppData: (state: IStore.IState) => state.utils.communityAppData,

    // Props
    upsellProps: (state: IStore.IState) =>
        state.componentProps.event.upsellProps,
    paymentSummaryProps: (state: IStore.IState) =>
        state.componentProps.event.paymentSummaryProps,
};
