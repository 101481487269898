import { useDispatch } from "react-redux";
import { ACTION_TYPES, createAction } from "@Redux";
import {
    API_ACTION_TYPES,
    getUserAccessToken,
    getUserRefreshToken,
    STORAGE_KEYS,
    logoutUser,
} from "@Utils";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "repoV2/utils/common/storage/getterAndSetters";
import { useState } from "react";

export const useUserLogin = () => {
    const dispatch = useDispatch();

    const areLoginTokensPresent = () => {
        return !!(getUserAccessToken() && getUserRefreshToken());
    };

    const [isLoggedIn, setIsLoggedIn] = useState(areLoginTokensPresent());

    const logoutUserAndUpdateState = () => {
        logoutUser();
        setIsLoggedIn(false);
    };

    const updateUserAccessToken = (
        hostName: string,
        successCallback?: (param?: any) => any,
        errorCallback?: (param?: any) => any
    ) => {
        dispatch(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.FETCH_TOKEN,
                payload: {
                    token_type: 3,
                    grant_type: "refresh_token",
                    client_id: getLocalStorageItem(STORAGE_KEYS.CUSTOMER_UUID),
                    refresh_token: getUserRefreshToken(),
                    sub_domain: hostName,
                },
                successCallback: payload => {
                    const {
                        access_token: fetchedAccessToken,
                        refresh_token: refreshToken,
                    }: {
                        access_token: string;
                        refresh_token: string;
                    } = payload?.response?.data?.tokens;

                    setLocalStorageItem(
                        STORAGE_KEYS.ACCESS_TOKEN,
                        fetchedAccessToken
                    );
                    setLocalStorageItem(
                        STORAGE_KEYS.REFRESH_TOKEN,
                        refreshToken
                    );
                    if (successCallback) {
                        successCallback();
                    }
                },
                errorCallback: () => {
                    if (errorCallback) errorCallback();
                    logoutUserAndUpdateState();
                },
            })
        );
    };

    return {
        updateUserAccessToken,
        logoutUser: logoutUserAndUpdateState,
        isLoggedIn,
    };
};
