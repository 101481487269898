import { IEvent } from "@Interfaces";
import { ITemplate } from "@Templates/ITemplate";
import {
    ACTION_TYPES,
    API_ACTION_TYPES,
    createAction,
    FetchTypes,
    getEventListingWithKeyword,
    isEventFetched,
    SELECTORS,
} from "@Utils";
import { isEmpty } from "@Utils/common";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetchStatus } from ".";

export module IUseEventFetched {
    // Single prop is just the eventID. Other params fall to default
    // Extended props are provided
    export interface ExtendedProps {
        eventId: IEvent.IEventData["uuid"];
        setSelected?: boolean;
        attemptFetch?: boolean;
        callbackFn?: () => void;
        forceUsePropsEventId?: boolean;
    }

    export interface IReturn {
        isFetched: boolean;
        fetchStatus: FetchTypes;
        eventData: IEvent.IEventData | null;
    }
}

// Returns a boolean about whether the event has been fetched or not
export const useEventFetched = (
    props: IUseEventFetched.ExtendedProps
): IUseEventFetched.IReturn => {
    const {
        eventId,
        setSelected = false, // Whether to set this event to selected?
        attemptFetch = false, // Whether a fetch should be attempted if the event is not being loaded?
        callbackFn = undefined,
        forceUsePropsEventId = false,
    } = props;

    const dispatch = useDispatch();

    const eventDataList: IEvent.IStore["list"] = useSelector(
        SELECTORS.eventList
    );
    const selectedEvent: IEvent.ISelectedEvent = useSelector(
        SELECTORS.selectedEvent
    );
    const hostName = useSelector(SELECTORS.hostName);
    let selectedEventId;
    // Getting event Id from redux state because of slug support
    // When slug is passed as EventId in the url, handling that case here
    selectedEventId = useSelector(SELECTORS.selectedEvent);

    if (forceUsePropsEventId) {
        selectedEventId = eventId;
    }

    const isFetched: boolean = eventId
        ? isEventFetched(selectedEventId, eventDataList)
        : true;

    const fetchStatus = useFetchStatus(API_ACTION_TYPES.FETCH_EVENT);

    const setSelectedEvent = (eventIdProp: string): void => {
        if (eventIdProp !== selectedEvent) {
            dispatch(
                createAction(ACTION_TYPES.EVENT.SET_SELECTED_EVENT, {
                    selectedEvent: eventIdProp,
                })
            );
            callbackFn?.();
        }
    };

    useEffect(() => {
        if (eventId && attemptFetch && !isFetched) {
            dispatch(
                createAction(ACTION_TYPES.UTILS.API_CALL, {
                    apiActionType: API_ACTION_TYPES.FETCH_EVENT,
                    urlArgs: {
                        eventId,
                        hostName,
                    },
                    successCallback: () => {
                        if (setSelected) {
                            setSelectedEvent(eventId);
                        }
                    },
                })
            );
        } else if (
            setSelected &&
            isFetched &&
            eventId &&
            eventId !== selectedEvent
        ) {
            setSelectedEvent(eventId);
        }
    }, [eventId]);

    return {
        isFetched,
        fetchStatus: fetchStatus!,
        eventData: selectedEventId ? eventDataList?.[selectedEventId] : null,
    };
};

export const useEventCategorisationKeywords = (
    eventList: Array<ITemplate.IEventListingData>,
    allKeywords: Array<ITemplate.IKeyword>
) => {
    const defaultKeyword = "All";
    const [filteredEventList, setFilteredEventList] = useState(eventList);
    const [selectedKeyword, setSelectedKeyword] = useState(defaultKeyword);
    const [loading, setLoading] = useState(false);
    const keywords = [
        defaultKeyword,
        ...allKeywords
            .sort((a, b) => a.sequence - b.sequence)
            .map(({ keyword }) => keyword)
            .filter(
                keyword =>
                    !isEmpty(getEventListingWithKeyword(keyword, eventList))
            ),
    ];

    useEffect(() => {
        if (!isEmpty(eventList)) setFilteredEventList(eventList);
    }, [eventList]);

    const onKeywordClick = (keyword: string) => {
        if (selectedKeyword === keyword) return;
        setLoading(true);
        setSelectedKeyword(keyword);
        if (keyword === defaultKeyword) setFilteredEventList(eventList);
        else
            setFilteredEventList(
                getEventListingWithKeyword(keyword, eventList)
            );

        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const resetSelection = () => onKeywordClick(defaultKeyword);

    return {
        filteredEventList,
        loading,
        keywords,
        onKeywordClick,
        selectedKeyword,
        resetSelection,
    };
};
