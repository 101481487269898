import React from "react";
import { ICustomCodeData } from "repoV2/interfaces/customCodes/ICustomCode";
import { getSalePageCustomCodes } from "./apis/getSalePageCustomCodes";

export const useGetSalesPageCustomCode = ({
    salePageUuid,
    subDomain,
}: {
    salePageUuid: string | undefined;
    subDomain: string | undefined;
}) => {
    const [salesPageCodes, setSalesPageCustomCodes] = React.useState<
        ICustomCodeData[]
    >([]);
    React.useEffect(() => {
        (async () => {
            const codes = await getSalePageCustomCodes({
                salePageUuid,
                subDomain,
            });
            if (codes) setSalesPageCustomCodes(codes);
        })();
    }, []);

    return {
        salesPageCodes,
    };
};
