import { useEffect, useState } from "react";

/**
 *
 * With an SSR skeleton that is rehydrated on the browser, there might be rehydration issues witht the DOM.
 * Use the return value of this hook to re-render the whole page on the browser. Use as following:
 *
 * const isMounted = useIsMounted();
 * if(!isMounted) { // Render the initial SSR skeleton that might have the "Loading..." }
 * return ( //The final rehydrated render )
 *
 * Link: https://www.joshwcomeau.com/react/the-perils-of-rehydration/
 */
export const useIsMounted = (): boolean => {
    const [isMounted, setIsMounted] = useState<boolean>(false);
    useEffect(() => {
        setIsMounted(true);
    }, []);
    return isMounted;
};
