import { IStore } from "@Interfaces";
import {
    FETCH_STATUS,
    API_ACTION_TYPES,
    INDIA,
    DEFAULT_FONT,
    DEFAULT_TEMPLATE_KEY,
    WATERMARK_TYPES,
} from "@Utils";
import { GATEWAYS_SUPPORTED } from "repoV2/constants/payment";
import { checkout_page_reducer_initial_state } from "repoV2/features/CheckoutPages/constants/CheckoutPages.constants";
import { custom_code_initial_state } from "repoV2/features/CustomCodes/utils/reducer";
import { DEFAULT_TIMEZONE } from "repoV2/features/Common/modules/Timezone/Timezone.constants";
import { form_initial_state } from "repoV2/features/Common/modules/FormData/utils/reducer";
import { IRepoV2ReduxState } from "repoV2/features/Common/modules/Reducer/Reducer.interface";
import { creator_details_initial_state } from "repoV2/features/CreatorDetails/redux/reducer";

/**
 * - `REPOV2_APP_INITIAL_STATE` Defines reducers' initial states built in repoV2
 * - existing reducers' initial states for code outside of repov2 will be there in `APP_INITIAL_STATE`
 * ### Why?
 * - To keep distinction b/w repov2 and src reducers
 * - so that migrating old code is easy
 */
const REPOV2_APP_INITIAL_STATE: IRepoV2ReduxState = {
    formData: form_initial_state,
    checkoutPages: checkout_page_reducer_initial_state,
    creatorDetails: creator_details_initial_state,
    customCodes: custom_code_initial_state,
};

/**
 * Global App level Reducers' initial states
 */
const APP_INITIAL_STATE: IStore.IState = {
    host: {
        dataHost: {},
        plans: {
            title: "",
            list: [],
            currentPlanUuid: null,
            details: {},
        },
        team: {},
        country: {
            name: INDIA,
            code: "IN",
            locale: DEFAULT_TIMEZONE,
        },
        template: DEFAULT_TEMPLATE_KEY,
        theme: {},
        font: { ...DEFAULT_FONT },
        content: {
            list: [],
            details: {},
            extraDetails: {
                comments: {},
            },
        },
        blog: {
            data: [],
            total: 0,
            total_pages: 0,
        },
        blogPost: {},
        blogPostComments: { total: 0, total_pages: 0, data: [] },
        watermark: {
            is_watermark_enabled: true,
            only_fed_watermarking_enabled: false,
            video_watermark_type: WATERMARK_TYPES.STATIC,
        },
    },
    event: {
        list: {},
        discount: {
            discountEntered: "", // The controlled value of discount entered in the UI
            appliedDiscountCodeType: "not applied",
            discountCode: null, // Signifies whether the discount has been verified against the server
            discountError: null,
            discountData: null,
        },
        loyaltyDiscounts: {
            data: [],
        },
        selectedEvent: null,
        // Refer to type declaration for brief on each property
        paymentInfo: {
            slots: [],
            quantity: 1,
            numberOfCustomers: 1,
            customerAddress: "",
            gatewaysSupported: GATEWAYS_SUPPORTED,
            isDirectBooking: false,
            customPrice: null, // By default this is null. This will be used only if it is non-negative
            answers: {}, // Answers to custom questions asked before booking
            otherListings: {},
        },
        upsellListings: {},
        miscellaneousData: {
            reviews: {
                avgRating: 0,
                data: [],
            },
            highlights: [],
            requirements: [],
        },
    },
    utils: {
        canRender: false,
        fetchStatus: Object.assign(
            {},
            ...Object.keys(API_ACTION_TYPES).map(res => ({
                [res]: FETCH_STATUS.NOT_FETCHED,
            }))
        ),
        modal: {
            isModalOpen: false,
            modalType: null,
            modalProps: {},
            isCloseable: true,
            overrides: {},
        },
        isProcessing: false, // A global util value to keep track of if something is processing
        data: {},
        communityAppData: null,
    },
    user: {
        tokens: {
            accessToken: null,
            refreshToken: null,
        },
        formDetails: {},
        forms: {},
        answers: {},
        thumbnails: {},
        email: null,
        username: null,
        usernameList: [],
        orders: {},
        bookings: {},
        purchaseHistory: {
            data: [],
            total: 0,
            total_pages: 0,
        },
        notifications: {
            data: [],
            total: 0,
            total_pages: 0,
        },
    },
    componentProps: {
        event: {
            upsellProps: {
                disablePayment: false,
                urlQueryParams: undefined,
                resetDiscount: () => undefined,
                setAppliedDiscountCodeDetails: () => undefined,
                setShowLoyaltyDiscountSection: () => undefined,
            },
            paymentSummaryProps: {
                disablePayment: false,
                isOpenedAsEventModal: false,
                isMobile: false,
                urlQueryParams: undefined,
            },
        },
    },
    ...REPOV2_APP_INITIAL_STATE,
};

const initialState: IStore.IState = APP_INITIAL_STATE;

export default initialState;
