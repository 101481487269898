import {
    DIGITS_REGEX,
    EMAIL_REGEX,
    FULL_NAME_REGEX,
} from "repoV2/utils/common/validators/regex.constants";

/**
 * @deprecated use `EMAIL_REGEX`
 */
export const emailRegex = EMAIL_REGEX;

/**
 * @deprecated use `FULL_NAME_REGEX`
 */
export const fullNameRegex = FULL_NAME_REGEX;

/**
 * @deprecated use `DIGITS_REGEX`
 */
export const digitsRegex = DIGITS_REGEX;

export const uuidRegex =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export const checkForHttp = /^(https:|http:)\S*/;

export const validUrl =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const dateDDMMMYYYRegex = /\d{2} [a-zA-z]{3} \d{4}/;
