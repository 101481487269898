import { ICreatorPages } from "repoV2/features/CreatorPages/CreatorPages.interfaces";

export declare type ITemplateKey = ICreatorPages.ITemplateKey;

export const TEMPLATE_KEYS: Record<`${Uppercase<ITemplateKey>}`, ITemplateKey> =
    {
        CLASSIC: "classic",
        ELEMENTARY: "elementary",
        MODERN: "modern",
        UNFOLD: "unfold",
        LINKTEMP: "linktemp",
        YOGA: "yoga",
        GROWTH: "growth",
        EDUVERSE: "eduverse",
        UNLOCK: "unlock",
    };

export const TEMPLATE_CONFIG = {
    [TEMPLATE_KEYS.CLASSIC]: {
        coverImage: {
            width: 2000,
            height: 500,
            aspect_ratio: 2000 / 500,
        },
        introVideo: true,
    },
    [TEMPLATE_KEYS.MODERN]: {
        coverImage: {
            width: 2160,
            height: 1170,
            aspect_ratio: 2160 / 1170,
        },
    },
    [TEMPLATE_KEYS.ELEMENTARY]: {
        coverImage: {
            width: 2160,
            height: 1170,
            aspect_ratio: 2160 / 1170,
        },
    },
    [TEMPLATE_KEYS.UNFOLD]: {
        coverImage: {
            width: 2160,
            height: 1170,
            aspect_ratio: 2160 / 1170,
        },
        introVideo: true,
    },
    [TEMPLATE_KEYS.LINKTEMP]: {
        coverImage: {
            width: 2160,
            height: 1170,
            aspect_ratio: 2160 / 1170,
        },
        introVideo: true,
    },
    [TEMPLATE_KEYS.GROWTH]: {
        coverImage: {
            width: 2160,
            height: 1170,
            aspect_ratio: 2160 / 1170,
        },
    },
    [TEMPLATE_KEYS.EDUVERSE]: {
        coverImage: {
            width: 2160,
            height: 939,
            aspect_ratio: 2160 / 939,
        },
    },
    [TEMPLATE_KEYS.YOGA]: {
        coverImage: {
            width: 2255,
            height: 1170,
            aspect_ratio: 2255 / 1170,
        },
    },
    [TEMPLATE_KEYS.UNLOCK]: {
        coverImage: {
            width: 2160,
            height: 939,
            aspect_ratio: 2255 / 1170,
        },
        mobileCoverImage: {
            width: 750,
            height: 1120,
            aspect_ratio: 750 / 1120,
        },
    },
};
