import { OptionsWithTZ, format, utcToZonedTime } from "date-fns-tz";
import { NODE_ENV_IS_NOT_PRODUCTION } from "repoV2/constants/environment";
import { getTimezone } from "./getTimezone";
import { DEFAULT_API_PAYLOAD_DATETIME_FORMAT } from "../repoV2/features/Common/modules/DateTime/modules/Time/Time.constants";

export interface IParseDateTimeOptions {
    formatString?: string;
    format?: boolean;
    log?: boolean | string;
    formatOptions?: OptionsWithTZ;
}

// > TS Type signatures
// Multiple formats, two return types
/**
 *@deprecated use from repoV2/features/Common/modules/DateTime/modules/Time/utils/Time.utils
 * @returns
 */
export function parseDateTime(dateTime?: string | number | undefined): Date;
export function parseDateTime(
    dateTime: string | number | undefined,
    options: IParseDateTimeOptions & { format: false }
): Date;
export function parseDateTime(
    dateTime: string | number | undefined,
    options: IParseDateTimeOptions & { format: true }
): string;
export function parseDateTime(
    dateTime?: string | number | undefined,
    formatString?: string
): string;
export function parseDateTime(
    dateTime?: string | number | undefined,
    options?: IParseDateTimeOptions
): string;

// > JS functional declaration
// Format string is optional. If not passed, the moment object is returned
export function parseDateTime(
    dateTime: string | number | undefined = Date.now(),
    options?: string | IParseDateTimeOptions
) {
    try {
        let formatString: string;
        if (typeof options === "string") {
            formatString = options;
        } else if (options?.formatString) {
            formatString = options?.formatString;
        } else {
            formatString = DEFAULT_API_PAYLOAD_DATETIME_FORMAT;
        }
        // Whether the return type should be a string or a Date object
        const toReturnString: boolean =
            typeof options === "string" ||
            !!(
                typeof options === "object" &&
                (options.formatString || options.format === true)
            );

        const parsed = utcToZonedTime(
            new Date(dateTime),
            getTimezone().timezone
        );

        // tags: #dev, #log
        // Passing `options.log: true` will log that specific function call.
        // Passing `options.log: <tagName:string>` will log `tagName` alo
        if (
            typeof options === "object" &&
            options.log &&
            NODE_ENV_IS_NOT_PRODUCTION
        ) {
            console.log({
                ...(typeof options.log === "boolean" && {
                    tag: options.log,
                }),
                input: dateTime,
                d: new Date(dateTime),
                parsed,
                string: format(parsed, formatString, options.formatOptions),
                options,
            });
        }

        return toReturnString
            ? format(
                  parsed,
                  formatString,
                  typeof options === "object"
                      ? options.formatOptions
                      : undefined
              )
            : parsed;
    } catch (error) {
        console.log("error", error);
        return `<ERROR> | ${error}`;
    }
}
