/* eslint-disable no-empty */
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SELECTORS } from "../constants/selectors";
import { ACTION_TYPES, createAction } from "../constants/actions";

// Hook to run a function before the page is about to unload
// https://stackoverflow.com/a/39094299
export const useUnload = (fn: Function): void => {
    const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

    useEffect(() => {
        cb.current = fn;
    }, [fn]);

    useEffect(() => {
        const onUnload = (...args: Array<any>) => cb.current?.(...args);

        window.addEventListener("beforeunload", onUnload);

        return () => window.removeEventListener("beforeunload", onUnload);
    }, []);
};

// Returns a boolean that tells if the page has loaded all the data needed to show
// * `additionalValidation` is any page-side validation condition that might be used in addition to the fetch status
export const useCanRender = (additionalValidation: boolean = true): boolean => {
    return useSelector(SELECTORS.canRender) && additionalValidation;
};

// The setter counterpart of the above hook.
export const useRenderControl = (): {
    enableRender: () => void;
    disableRender: () => void;
} => {
    const dispatch = useDispatch();
    const setter = (val: boolean) => {
        dispatch(
            createAction(ACTION_TYPES.UTILS.SET_CAN_RENDER, {
                canRender: val ?? true,
            })
        );
    };
    return {
        enableRender: () => setter(true),
        disableRender: () => setter(false),
    };
};

export const useProcessing = (
    additionalProcessing?: boolean
): {
    isProcessing: boolean;
    startProcessing: () => void;
    stopProcessing: () => void;
} => {
    const dispatch = useDispatch();
    const isProcessing: boolean = useSelector(SELECTORS.isProcessing);

    const startProcessing = (): void => {
        dispatch(createAction(ACTION_TYPES.UTILS.START_PROCESSING));
    };
    const stopProcessing = (): void => {
        dispatch(createAction(ACTION_TYPES.UTILS.STOP_PROCESSING));
    };
    return {
        isProcessing: isProcessing || (additionalProcessing ?? false),
        startProcessing,
        stopProcessing,
    };
};

/**
 * @param scrollAfterTime how long to wait(in milliseconds) before scrolling.
 * @param customSelector if a custom query selector needs to be used instead
 * of the URL hash. Value must be compatible with `document.querySelector`.
 */
export const useScrollToSectionOnMount = (
    scrollAfterTime: number = 0,
    customSelector?: string
) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            try {
                const element = document.querySelector(
                    customSelector || window.location.hash
                );
                if (element) {
                    window.scrollTo(0, 0);
                    element.scrollIntoView({ behavior: "smooth" });
                }
            } catch (e) {}
        }, scrollAfterTime);

        return () => {
            clearTimeout(timer);
        };
    }, []);
};

export const useScrollProgress = (
    /*
    A hook to detect how much(in %) a component(that is to be tracked) is away
    from its initial position and is closer to the window top.
    
    initial position of the component = distance of the component from DOM top
    
    if %age is < 0 the returned value is 0.
    */
    trackComponentId: string, // id of the component that is to be tracked
    rootComponentId?: string // id of the root component that has scroll
) => {
    const [scrollProgress, setScrollProgress] = useState(0);
    useEffect(() => {
        const scrollFunc = () => {
            try {
                const element = document.getElementById(trackComponentId);
                let distanceFromWindowTop = Math.floor(
                    element?.getBoundingClientRect()?.top || 0
                );
                distanceFromWindowTop =
                    distanceFromWindowTop < 0 ? 0 : distanceFromWindowTop;
                const distanceFromDOMTop = Math.floor(element?.offsetTop || 0);

                const percentDistance = Math.floor(
                    ((distanceFromDOMTop - distanceFromWindowTop) /
                        distanceFromDOMTop) *
                        100
                );
                setScrollProgress(percentDistance);
            } catch {}
        };

        setTimeout(() => {
            try {
                if (rootComponentId) {
                    document
                        .getElementById(rootComponentId)
                        ?.addEventListener("scroll", scrollFunc);
                } else {
                    document?.addEventListener("scroll", scrollFunc);
                }
            } catch {}
        }, 500);

        return () => {
            try {
                if (rootComponentId) {
                    document
                        .getElementById(rootComponentId)
                        ?.removeEventListener("scroll", scrollFunc);
                } else {
                    document?.removeEventListener("scroll", scrollFunc);
                }
            } catch {}
        };
    }, []);

    return { scrollProgress };
};
