import Router from "next/router";
import { getUtmParams } from "repoV2/utils/common/analytics/utmParams";
import { CALL_API_METHODS, EXLY_BACKEND_API_HOST } from "repoV2/constants/apis";
import { URL_SEARCH_PARAMS } from "repoV2/features/Common/modules/URLsNRouting/URLsNRouting.constants";
import { ANALYTICS_TRACKING_TYPE } from "repoV2/features/Common/constants/analytics";
import { callApi } from "../../api/callApi";
import { logError } from "../../error/error";
import { getAnalyticsInfo, initAndGetEndUserSessionId } from "../analyticsInfo";
import { getUserIp } from "../userIp";
import { initAndGetReferrerWithLogic } from "../referrer";

const POST_EXLY_ANALYTICS_API_URL = `${EXLY_BACKEND_API_HOST}/analytics/event/post`;

export const callPostExlyAnalytics = (payloadProps: any) => {
    const sessionID = initAndGetEndUserSessionId();

    // Analytics are to be temporarily disabled for events other than page load for now
    // Code isn't to be removed since a future migration to a different service is planned
    // that will use the same triggers
    if (payloadProps?.event_name !== "completelyLoaded") {
        return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = getUtmParams();
    const utmDetails = {
        source: utmParams.source,
        medium: utmParams.medium,
        campaign: utmParams.campaign,
        utm_affiliate: utmParams.affiliate,
    };

    /**
     * Fetching user IP details required for sending in Payload for analytics
     */
    const analyticsInfo = getAnalyticsInfo();

    const trackType = ANALYTICS_TRACKING_TYPE[Router.router?.pathname || ""];

    const sourceIP = getUserIp();
    const payload = {
        ...payloadProps,
        ...(urlParams.get("preview") && { preview: true }),
        session_id: sessionID,
        platform: "m-web",
        source_url: window.location.href,
        origin: window.location.hostname.split(".")[0],
        source_ip: sourceIP,
        analytics_info: analyticsInfo,
        metadata: {
            ...(payloadProps?.metadata && {
                ...payloadProps.metadata,
            }),
            ...(urlParams.get(URL_SEARCH_PARAMS.DYNAMIC_LINK) && {
                dynamic_link_id: urlParams.get(URL_SEARCH_PARAMS.DYNAMIC_LINK),
            }),
        },
        ...(trackType && {
            tracking_type: trackType,
        }),
        ...(window.location.search && { ...utmDetails }),
    };

    const referer = initAndGetReferrerWithLogic();
    if (referer) {
        payload.referer = referer;
        payload.referer_group = referer;
    }

    return callApi({
        url: POST_EXLY_ANALYTICS_API_URL,
        method: CALL_API_METHODS.POST,
        data: payload,
        extraOptions: {
            appendAuthHeaders: true,
            appendExlyOriginHeaders: true,
            appendTimezoneHeaders: true,
        },
    }).catch(e => {
        logError({ error: e });
    });
};
