import React from "react";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import { IHostPageButtonContent } from "./IHostPageButtonContent";

import styles from "./hostPageButtonContent.module.scss";

export const HostPageButtonContent = ({
    preview,
    title,
    logoImg,
    logoText,
}: IHostPageButtonContent.IProps): JSX.Element => (
    <>
        {(logoImg && (
            <ExlyImage
                fetchWidth={400}
                alt={title}
                src={logoImg}
                className={styles.hostLogoImage}
            />
        )) ||
            (
                logoText ||
                title ||
                (preview && "<Your page title will be displayed here>") ||
                ""
            ).slice(0, 50)}
    </>
);
