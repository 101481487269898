import {
    ADDRESS_TYPE,
    ApiCall,
    API_ACTIONS,
    API_ACTION_TYPES,
    getUserAccessToken,
} from "@Utils";
import { useEffect, useState } from "react";

export const useExlyConnectZoomAppUrl = ({
    listingUuid,
    listingAddressType,
}: {
    listingUuid?: string;
    listingAddressType?: number;
}): { showExlyConnectZoomAppUrl: boolean; exlyConnectZoomAppUrl: string } => {
    const [exlyConnectZoomAppUrl, setExlyConnectZoomAppUrl] = useState("");
    const shouldFetchExlyConnectZoomAppUrl =
        listingUuid && listingAddressType === ADDRESS_TYPE.EXLY_CONNECT;
    async function fetchExlyConnectZoomAppUrl() {
        if (!shouldFetchExlyConnectZoomAppUrl) return;
        try {
            const apiInfo =
                API_ACTIONS[
                    API_ACTION_TYPES.GET_EXLY_CONNECT_ZOOM_APP_MEETING_URL
                ];

            const apiReturned = (await ApiCall({
                url: apiInfo.url({ listingUuid }),
                method: apiInfo.method,
                headers: { "jwt-token": getUserAccessToken() },
            })) as any;

            setExlyConnectZoomAppUrl(apiReturned?.data?.data?.zoom_url || "");
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (shouldFetchExlyConnectZoomAppUrl) {
            fetchExlyConnectZoomAppUrl();
        }
    }, [listingUuid]);

    return {
        showExlyConnectZoomAppUrl: !!(
            shouldFetchExlyConnectZoomAppUrl && exlyConnectZoomAppUrl
        ),
        exlyConnectZoomAppUrl,
    };
};
