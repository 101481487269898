import { IEvent, IStore, IUtils } from "@Interfaces";
import {
    ACTION_TYPES,
    API_ACTIONS,
    API_ACTION_TYPES,
    FETCH_STATUS,
    IAction,
    QUERY_PARAMS,
    ROUTES,
    SELECTORS,
    createAction,
    getCountryDataFromTimezone,
    isBrowser,
    isCountryCodeIndia,
    isValidCountryCode,
} from "@Utils";
import { addWeeks, isBefore } from "date-fns";
import Router from "next/router";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";
import { getQueryParam } from "repoV2/utils/urls&routing/urlParams";
import {
    getAnalyticsInfo,
    initAndGetEndUserSessionId,
} from "repoV2/utils/common/analytics/analyticsInfo";
import { initAndGetReferrerWithLogic } from "repoV2/utils/common/analytics/referrer";
import { setUserIp } from "repoV2/utils/common/analytics/userIp";
import { getUtmParams } from "repoV2/utils/common/analytics/utmParams";
import { checkIsStringEmpty } from "repoV2/utils/common/dataTypes/string";
import { logError } from "repoV2/utils/common/error/error";
import {
    getIsKnownTimezone,
    setUserTimezone,
} from "repoV2/features/Common/modules/Timezone/Timezone.utils";
import { URL_SEARCH_PARAMS } from "repoV2/features/Common/modules/URLsNRouting/URLsNRouting.constants";
import { ANALYTICS_TRACKING_TYPE } from "repoV2/features/Common/constants/analytics";
import {
    getLocalStorageItem,
    removeLocalStorageItem,
    setLocalStorageItem,
} from "repoV2/utils/common/storage/getterAndSetters";
import { PAYMENT_PAGES_TYPES } from "repoV2/features/Payments/Payments.constants";
import {
    ISystemLocalePayload,
    getBrowserTimezone,
} from "@Utils/getBrowserTimezone";
import { parseDateTime } from "@Utils/parseDateTime";
import {
    COUNTRY_CODE_TO_TIMEZONE_MAP,
    DEFAULT_INTERNATIONAL_TIMEZONE,
    DEFAULT_INTERNATIONAL_TIMEZONE_OBJECT,
    DEFAULT_TIMEZONE,
} from "repoV2/features/Common/modules/Timezone/Timezone.constants";
import { URL_SEARCH_PARAMS as THANKYOU_URL_SEARCH_PARAMS } from "@Modules/thankyou/constants/thankyou.contants";
import { getCountryCodeDropdownOptionFromKey } from "utils/location";

import { LOCAL_STORAGE_KEYS } from "../../../repoV2/constants/common/storage/localStorage";
import { IFetchDataAction } from "./api";

// Interface for the case where a saga is called as a callback for an API call
interface IApiCallbackSagaArgs {
    type: IAction.IActionTypes;
    payload: IAction.IPayloadProps;
}

// Get whether international payments are enabled for this user.
// Spelled as Intl and not International to avoid long names.
// TODO: Move to selector
const getIsIntlEnabled = (selectedEvent: IEvent.ISelectedEvent) =>
    select((state: IStore.IState) =>
        selectedEvent
            ? state.event?.list?.[selectedEvent]?.is_international_enabled
            : state.host?.dataHost?.profile_data?.is_international_enabled
    );

const canRender = (val: boolean = true) =>
    put(
        createAction(ACTION_TYPES.UTILS.SET_CAN_RENDER, {
            canRender: val,
        })
    );

function* showNotif(props: any) {
    (isBrowser() ? alert : console.log)(
        props?.message ||
            props?.payload?.response?.message ||
            "An error has occurred. Please try again."
    );
    yield;
}

function* postAnalytics(args: any) {
    const { payload: payloadProps }: { payload: any } = args;
    if (isBrowser()) {
        const sessionID = initAndGetEndUserSessionId();

        // Analytics are to be temporarily disabled for events other than page load for now
        // Code isn't to be removed since a future migration to a different service is planned
        // that will use the same triggers
        if (payloadProps?.event_name !== "completelyLoaded") {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const utmParams = getUtmParams();
        const utmDetails = {
            source: utmParams.source,
            medium: utmParams.medium,
            campaign: utmParams.campaign,
            utm_affiliate: utmParams.affiliate,
        };

        /**
         * Fetching user IP details required for sending in Payload for analytics
         */
        const analyticsInfo = getAnalyticsInfo();

        const trackType =
            ANALYTICS_TRACKING_TYPE[Router.router?.pathname || ""];

        const sourceIP: string = yield select(state => state.host.user_ip);
        const payload = {
            ...payloadProps,
            ...(urlParams.get("preview") && { preview: true }),
            session_id: sessionID,
            platform: "m-web",
            source_url: window.location.href,
            origin: window.location.hostname.split(".")[0],
            source_ip: sourceIP,
            analytics_info: analyticsInfo,
            metadata: {
                ...(payloadProps?.metadata && {
                    ...payloadProps.metadata,
                }),
                ...(urlParams.get(URL_SEARCH_PARAMS.DYNAMIC_LINK) && {
                    dynamic_link_id: urlParams.get(
                        URL_SEARCH_PARAMS.DYNAMIC_LINK
                    ),
                }),
            },
            ...(trackType && {
                tracking_type: trackType,
            }),
            ...(window.location.search && { ...utmDetails }),
        };

        const referer = initAndGetReferrerWithLogic();
        if (referer) {
            payload.referer = referer;
            payload.referer_group = referer;
        }
        yield put(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.POST_ANALYTICS,
                payload,
            })
        );
    }
    yield;
}

// There are two types of data fetches being performed that are affected by international payment
const hostDataRoutes = [
    "/host",
    "/content",
    `/${ROUTES.LOGIN.filePath}`,
    `/${ROUTES.MY_CLASSES.filePath}`,
    `/${ROUTES.MY_MEETINGS.filePath}`,
    `/${ROUTES.PURCHASE_HISTORY.filePath}`,
    `/${ROUTES.CART.filePath}`,
    "/pay",
    "/",
];
const eventDataRoutes = ["/event", "/pay", "/feedback"];

export const isEvent = (): boolean => {
    if (!isBrowser()) return false;
    // * Note: Router(next/router.default) does not update to the latest value of
    // * the route. It will work fine for the initial render but not so much after that
    // * Either try to resolve that issue, or use window.location instead (window.location
    // * should work fine inside if(isBrowser()))
    return eventDataRoutes.includes(
        Router?.route || ""

        // * Leave this commented line here till the routing flow logic is stable
        // * and well-documented (in regards to connected-next-router package)
        // Router?.router?.pathname || ""
    );
};

// Will check if the route is ONLY an event route
export const shouldMinify = (): boolean => {
    return isEvent() && !hostDataRoutes.includes(Router?.route || "");
};

// The payload params are to force override
function* refetchData({
    type: sourceActionType,
    payload,
}: IApiCallbackSagaArgs & {
    payload: {
        event?: boolean;
        host?: boolean;
        countryCodeIsIndia?: boolean;
    };
}) {
    if (isBrowser()) {
        const hostName: string = yield select(SELECTORS.hostName);
        const { currentPlanUuid: planId } = yield select(SELECTORS.plansData);
        const selectedEvent: IEvent.ISelectedEvent = yield select(
            SELECTORS.selectedEvent
        );

        // Whether this is the initial refetch caused by the IP address resolving.
        // Whether or not the host / event API section calls need to be made when this function is run has two different conditions:
        // 1. When the timezone is resolved on first client render, it uses the route to determine whether each of the API calls need to be made
        // 2. When this function is called later on by changing the timezone, it is to be told explicitly if a certain API data needs to be re-fetched.

        const isInitialRun: boolean =
            sourceActionType === ACTION_TYPES.HOST.UPDATE_USER_LOCALE;

        const fetchHost: boolean = !!(isInitialRun
            ? hostDataRoutes.includes(Router?.router?.pathname || "")
            : payload?.host);

        const fetchEvent: boolean = !!(isInitialRun
            ? eventDataRoutes.includes(Router?.router?.pathname || "") ||
              selectedEvent
            : payload?.event);

        const IsIntlEnabled: boolean =
            (yield getIsIntlEnabled(selectedEvent)) ||
            getBrowserTimezone() !== DEFAULT_TIMEZONE;

        // This refetching will only check for IsIntlEnabled only if isInitialRun === false, since the DEFAULT_TIMEZONE is assumed on initial run,
        // but the further subsequent calls to this function happen on an explicit timezone change.
        if (IsIntlEnabled || !isInitialRun) {
            yield canRender(false);

            if (fetchHost) {
                yield put(
                    createAction(ACTION_TYPES.UTILS.API_CALL, {
                        apiActionType: API_ACTION_TYPES.FETCH_HOST,
                        urlArgs: { hostName, minify: shouldMinify() },
                        successAction: ACTION_TYPES.UTILS.SET_CAN_RENDER,
                        // TODO: Add error handling
                    })
                );
                // Re-fetch the host plans with updated currency
                yield put(
                    createAction(ACTION_TYPES.UTILS.API_CALL, {
                        apiActionType: API_ACTION_TYPES.FETCH_HOST_PLANS,
                        urlArgs: {
                            hostName,
                        },
                    })
                );
            }
            if (fetchEvent) {
                const isPay: boolean = window.location.pathname === "/pay";
                // Don't make the event request again if no event is pre-loaded
                if (isPay && !getQueryParam(QUERY_PARAMS.EVENT_ID)) return;

                const communityUuid = getQueryParam(
                    URL_SEARCH_PARAMS.COMMUNITY_ID
                );
                const thankyouPgId = getQueryParam(
                    THANKYOU_URL_SEARCH_PARAMS.THANKYOU_PAGE_ID
                );

                const dynamicLinkId = getQueryParam(QUERY_PARAMS.DYNAMIC_LINK);

                yield put(
                    createAction(ACTION_TYPES.UTILS.API_CALL, {
                        apiActionType: API_ACTION_TYPES.FETCH_EVENT,
                        urlArgs: {
                            eventId:
                                // @dev: This is done if event id is not available in query params
                                // we will use the selected event id
                                // This will happen in plans if url slug is changed
                                // (from: "origin/:eventId?plan_id={planId}", to: "origin/plan/:planId")
                                // both Direct Page (SSR) + Popup Modal (CSR) is handled here
                                selectedEvent ||
                                getQueryParam(QUERY_PARAMS.EVENT_ID),
                            dynamicLink: dynamicLinkId,
                            ...(!isPay &&
                                dynamicLinkId && {
                                    paymentPageType: PAYMENT_PAGES_TYPES.CUSTOM,
                                }),
                            hostName,
                            ...(communityUuid !== undefined && {
                                community: communityUuid,
                            }),
                            ...(thankyouPgId && {
                                thankyouPgId,
                            }),
                        },
                        successAction: ACTION_TYPES.UTILS.SET_CAN_RENDER,
                        errorAction: [
                            // TODO: Will showing this alert be good?
                            ACTION_TYPES.UTILS.LOG_ERROR,
                            // For universal payment, an invalid event pre-fetch ID can be ignored
                            ...(isPay
                                ? []
                                : [
                                      ACTION_TYPES.UTILS.SHOW_ALERT,
                                      ACTION_TYPES.UTILS.REDIRECT_AWAY,
                                  ]),
                        ],
                    })
                );

                if (planId && !checkIsStringEmpty(planId)) {
                    yield put(
                        createAction(ACTION_TYPES.UTILS.API_CALL, {
                            apiActionType: API_ACTION_TYPES.FETCH_PLAN_DETAILS,
                            urlArgs: {
                                planId,
                                subDomain: hostName,
                            },
                        })
                    );
                }
            }
            // If neither fetch host or event are to be called, set canRender to true
            if (!(fetchHost || fetchEvent)) {
                yield canRender(true);
            }
        } else {
            yield canRender(true);
        }
    }
}

const parseIpPayload = (payload: string = ""): { [key: string]: string } => {
    const keyValues = payload.split("\n");
    let parsedPayload: { [k: string]: string } = {};
    keyValues.forEach((keyValue: string) => {
        const keyValuePair: Array<string> = keyValue.split("=");
        if (keyValuePair.length === 2) {
            parsedPayload = {
                ...parsedPayload,
                [keyValuePair[0]]: keyValuePair[1],
            };
        }
    });
    return parsedPayload;
};

// * NOTE: countryCodeIsIndia is no longer being used, instead, the timezone is
// * being used for the purpose of setting the currency to either USD or INR.
function* validateIPResponse({ payload }: IApiCallbackSagaArgs) {
    const { response, metadata } = payload;
    const parsedPayload: { [key: string]: string } = parseIpPayload(response);
    const countryCode: string = parsedPayload?.loc || "";
    if (isValidCountryCode(countryCode)) {
        yield put(
            createAction(ACTION_TYPES.HOST.UPDATE_USER_IP, {
                countryCodeIsIndia: isCountryCodeIndia(countryCode),
                countryCode,
                ip: parsedPayload?.ip,
                metadata,
            })
        );
    } else {
        yield put(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.FETCH_USER_IP_FALLBACK,
                metadata,
            })
        );
    }
}

function* validateIPFallbackResponse({ payload }: IApiCallbackSagaArgs) {
    const { response, metadata } = payload;
    const countryCode: string = response?.countryCode || "";
    if (isValidCountryCode(countryCode)) {
        yield put(
            createAction(ACTION_TYPES.HOST.UPDATE_USER_IP, {
                countryCodeIsIndia: isCountryCodeIndia(countryCode),
                countryCode,
                ip: response?.query,
                metadata,
            })
        );
    } else {
        yield put(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.FETCH_USER_IP_2ND_FALLBACK,
                metadata,
            })
        );
    }
}

function* validateIP2ndFallbackResponse({ payload }: IApiCallbackSagaArgs) {
    const { response, metadata } = payload;
    const countryCode: string = response?.country_code || "";
    if (isValidCountryCode(countryCode)) {
        yield put(
            createAction(ACTION_TYPES.HOST.UPDATE_USER_IP, {
                countryCodeIsIndia: isCountryCodeIndia(countryCode),
                countryCode,
                ip: response?.IPv4,
                metadata,
            })
        );
    } else {
        // Call the update action with default data
        yield put(
            createAction(ACTION_TYPES.HOST.UPDATE_USER_IP, {
                countryCodeIsIndia: false,
                countryCode: DEFAULT_INTERNATIONAL_TIMEZONE_OBJECT.country_code,
                ip: null,
                metadata,
            })
        );
    }
}

export function* onInit() {
    try {
        initAndGetEndUserSessionId();

        const selectedEvent: IEvent.ISelectedEvent = yield select(
            SELECTORS.selectedEvent
        );

        const IsIntlEnabled: boolean = yield getIsIntlEnabled(selectedEvent);

        // 1. Try to get the cached locale data
        const cachedSystemLocaleData: ISystemLocalePayload & {
            created_at?: number;
        } = getLocalStorageItem(LOCAL_STORAGE_KEYS.SYSTEM_LOCALE);

        const browserTimezone: string = getBrowserTimezone(false);

        // If the data is more than a week old, discard it
        const isCachedLocaleDataExpired = cachedSystemLocaleData?.created_at
            ? isBefore(
                  addWeeks(cachedSystemLocaleData.created_at, 1),
                  parseDateTime()
              )
            : true;

        if (isCachedLocaleDataExpired) {
            removeLocalStorageItem(LOCAL_STORAGE_KEYS.SYSTEM_LOCALE);
        }

        // Since cachedSystemLocaleData will be used below for dispatching actions, it is best to delete the created_at key from it here
        if (cachedSystemLocaleData?.created_at) {
            delete cachedSystemLocaleData.created_at;
        }

        // Note: getIsKnownTimezone can be a computationally heavy call since it needs to scan an entire list, so calls to it must be kept to a minimum
        const useCachedTimezone: boolean = !!(
            cachedSystemLocaleData?.timezone &&
            !isCachedLocaleDataExpired &&
            getIsKnownTimezone(cachedSystemLocaleData?.timezone)
        );
        const useBrowserTimezone: boolean = getIsKnownTimezone(browserTimezone);

        let systemLocalPayload: ISystemLocalePayload = cachedSystemLocaleData;

        // 2. If cached data is not to be used but browser data is to be used
        if (!useCachedTimezone && useBrowserTimezone) {
            const { countryCode, countryName } =
                getCountryDataFromTimezone(browserTimezone);

            systemLocalPayload = {
                timezone: browserTimezone,
                countryCode,
                countryName,
            };
        }

        // If either 1. or 2. are applicable, update the timezone in the store and make an API call to fetch the IP address without any further timezone related callbacks
        if (useCachedTimezone || useBrowserTimezone) {
            // Some API data is re-fetched once the user locale is resolved via the UPDATE_USER_LOCALE
            yield put(
                createAction(
                    ACTION_TYPES.HOST.UPDATE_USER_LOCALE,
                    systemLocalPayload
                )
            );

            yield put(
                createAction(ACTION_TYPES.UTILS.API_CALL, {
                    apiActionType: API_ACTION_TYPES.FETCH_USER_IP,
                    metadata: {
                        getTzFromCountryCode: false,
                    },
                })
            );
        } else {
            // 3. Try to determine the locale information from the country code from the IP checking request
            yield put(
                createAction(ACTION_TYPES.UTILS.API_CALL, {
                    apiActionType: API_ACTION_TYPES.FETCH_USER_IP,
                    metadata: {
                        // Try to get the timezone from the country code in this case
                        getTzFromCountryCode: true,
                    },
                })
            );
        }

        // If international payment is enabled, wait for 3 seconds
        // for data to load before setting canRender = true anyways
        if (IsIntlEnabled) {
            yield delay(3000);
        }
        const errorStatus: string = yield select(
            (state: IStore.IState) =>
                state.utils.fetchStatus[
                    isEvent()
                        ? API_ACTION_TYPES.FETCH_EVENT
                        : API_ACTION_TYPES.FETCH_HOST
                ]
        );
        // ! TODO: Add in a condition to wait for any pending requests to fulfil, in case an error has to be shown
        if (errorStatus !== FETCH_STATUS.FETCH_ERROR) {
            // If international payment is not enabled, render the page immediately
            yield canRender(true);
        }
    } catch (e) {
        console.log("Error initializing", e);
    }
    yield;
}

// if `payload?.metadata?.getTzFromCountryCode` is true, try to determine the approximate timezone from the country code and update that in the store and cache
function* onUserIpResolution({
    payload,
}: IApiCallbackSagaArgs & { payload: { countryCode?: string; ip?: string } }) {
    const { countryCode, ip } = payload;

    setUserIp(ip);
    if (payload?.metadata?.getTzFromCountryCode) {
        let resolvedTz;
        if (countryCode && countryCode in COUNTRY_CODE_TO_TIMEZONE_MAP) {
            resolvedTz = (
                COUNTRY_CODE_TO_TIMEZONE_MAP as Record<string, string>
            )[countryCode];
        } else {
            resolvedTz = DEFAULT_INTERNATIONAL_TIMEZONE;
        }
        const localeData = {
            timezone: resolvedTz,
            countryCode,
            countryName: getCountryCodeDropdownOptionFromKey(countryCode).name,
            created_at: parseDateTime(Date.now(), { format: true }),
        };

        setLocalStorageItem(LOCAL_STORAGE_KEYS.SYSTEM_LOCALE, localeData);
        // In case a cached system timezone value is being saved for future reference since the one
        // provided by JS isn't valid or known, the user timezone is to be updated to the same as well
        setUserTimezone({ selectedUserTimezone: localeData.timezone });

        // Resolve the user's locale, and infer the country name and code and name from it.
        yield put(
            createAction(ACTION_TYPES.HOST.UPDATE_USER_LOCALE, localeData)
        );
    }
}

function* logErrorSaga(args: any) {
    const { payload }: { payload: IFetchDataAction.IReturnPayload } = args;

    const fetchStatus: IUtils.IStore["fetchStatus"] = yield select(
        SELECTORS.fetchStatus
    );

    const apiActionType = payload?.apiCallArgs?.apiActionType;

    const apiShouldLogError =
        API_ACTIONS?.[apiActionType as keyof typeof API_ACTIONS]
            ?.shouldLogError;

    const dontLog =
        apiShouldLogError && apiShouldLogError?.(payload)?.log === false;

    if (!dontLog) {
        logError({
            error: "api failed call failed in saga",
            extraErrorData: {
                "API Name": apiActionType,
                "Client Date-time": Date().toString(),
                "API payload": payload || {},
                "API Fetch Status": fetchStatus[apiActionType],
            },
        });
    }
    yield;
}

export function* redirectAway(payload: any) {
    const url: string =
        payload?.payload?.response?.data?.redirect_url ||
        (process.env.NEXT_PUBLIC_PROJECT_DOMAIN &&
            `//${process.env.NEXT_PUBLIC_PROJECT_DOMAIN}`) ||
        "https://exlyapp.com";

    if (isBrowser()) {
        window.location.href = url;
    }
    // TODO: Add method for server redirect
    yield;
}

export default function* utilsSaga() {
    yield takeEvery(ACTION_TYPES.UTILS.SHOW_ALERT, showNotif);
    yield takeEvery(ACTION_TYPES.UTILS.POST_ANALYTICS, postAnalytics);
    yield takeEvery(ACTION_TYPES.UTILS.VALIDATE_IP, validateIPResponse);
    yield takeEvery(
        ACTION_TYPES.UTILS.VALIDATE_IP_FALLBACK,
        validateIPFallbackResponse
    );
    yield takeEvery(
        ACTION_TYPES.UTILS.VALIDATE_IP_2ND_FALLBACK,
        validateIP2ndFallbackResponse
    );
    yield takeEvery(ACTION_TYPES.HOST.UPDATE_USER_IP, onUserIpResolution);
    yield takeEvery(ACTION_TYPES.HOST.UPDATE_USER_LOCALE, refetchData); // Re-fetch on updating the locale information
    yield takeEvery(ACTION_TYPES.HOST.REFETCH_DATA, refetchData); // Re-fetch when manually asked to do so
    yield takeEvery(ACTION_TYPES.UTILS.LOG_ERROR, logErrorSaga);
    yield takeEvery(ACTION_TYPES.UTILS.REDIRECT_AWAY, redirectAway);

    yield call(onInit);
}
