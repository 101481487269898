export const TIMEZONE_LOCAL_STORAGE_KEYS = {
    // used to fetch api details such as listing pricing which does not depend on user timezone selection
    SYSTEM_TIMEZONE: "system_timezone",

    // used to fetch api details such as sessions, time related data used for changing
    // the render/design which depends on user timezone selection
    USER_TIMEZONE: "user_timezone",
};

export const DEFAULT_TIMEZONE = "Asia/Kolkata";
export const DEFAULT_TIMEZONE_OBJECT = {
    country_code: "IN",
    offset: "+0530",
    name: DEFAULT_TIMEZONE,
};

export const DEFAULT_INTERNATIONAL_TIMEZONE = "Europe/London";
export const DEFAULT_INTERNATIONAL_TIMEZONE_OBJECT = {
    country_code: "GB",
    offset: "+0000",
    name: DEFAULT_INTERNATIONAL_TIMEZONE,
};

export const TIMEZONE_DATA_LIST = [
    { country_code: "CI", offset: "+0000", name: "Africa/Abidjan" },
    { country_code: "GH", offset: "+0000", name: "Africa/Accra" },
    { country_code: "ET", offset: "+0300", name: "Africa/Addis_Ababa" },
    { country_code: "DZ", offset: "+0100", name: "Africa/Algiers" },
    { country_code: "ER", offset: "+0300", name: "Africa/Asmara" },
    { country_code: "ML", offset: "+0000", name: "Africa/Bamako" },
    { country_code: "CF", offset: "+0100", name: "Africa/Bangui" },
    { country_code: "GM", offset: "+0000", name: "Africa/Banjul" },
    { country_code: "GW", offset: "+0000", name: "Africa/Bissau" },
    { country_code: "MW", offset: "+0200", name: "Africa/Blantyre" },
    { country_code: "CG", offset: "+0100", name: "Africa/Brazzaville" },
    { country_code: "BI", offset: "+0200", name: "Africa/Bujumbura" },
    { country_code: "EG", offset: "+0200", name: "Africa/Cairo" },
    { country_code: "MA", offset: "+0100", name: "Africa/Casablanca" },
    { country_code: "ES", offset: "+0100", name: "Africa/Ceuta" },
    { country_code: "GN", offset: "+0000", name: "Africa/Conakry" },
    { country_code: "SN", offset: "+0000", name: "Africa/Dakar" },
    { country_code: "TZ", offset: "+0300", name: "Africa/Dar_es_Salaam" },
    { country_code: "DJ", offset: "+0300", name: "Africa/Djibouti" },
    { country_code: "CM", offset: "+0100", name: "Africa/Douala" },
    { country_code: "EH", offset: "+0100", name: "Africa/El_Aaiun" },
    { country_code: "SL", offset: "+0000", name: "Africa/Freetown" },
    { country_code: "BW", offset: "+0200", name: "Africa/Gaborone" },
    { country_code: "ZW", offset: "+0200", name: "Africa/Harare" },
    { country_code: "ZA", offset: "+0200", name: "Africa/Johannesburg" },
    { country_code: "SS", offset: "+0200", name: "Africa/Juba" },
    { country_code: "UG", offset: "+0300", name: "Africa/Kampala" },
    { country_code: "SD", offset: "+0200", name: "Africa/Khartoum" },
    { country_code: "RW", offset: "+0200", name: "Africa/Kigali" },
    { country_code: "CD", offset: "+0100", name: "Africa/Kinshasa" },
    { country_code: "NG", offset: "+0100", name: "Africa/Lagos" },
    { country_code: "GA", offset: "+0100", name: "Africa/Libreville" },
    { country_code: "TG", offset: "+0000", name: "Africa/Lome" },
    { country_code: "AO", offset: "+0100", name: "Africa/Luanda" },
    { country_code: "CD", offset: "+0200", name: "Africa/Lubumbashi" },
    { country_code: "ZM", offset: "+0200", name: "Africa/Lusaka" },
    { country_code: "GQ", offset: "+0100", name: "Africa/Malabo" },
    { country_code: "MZ", offset: "+0200", name: "Africa/Maputo" },
    { country_code: "LS", offset: "+0200", name: "Africa/Maseru" },
    { country_code: "SZ", offset: "+0200", name: "Africa/Mbabane" },
    { country_code: "SO", offset: "+0300", name: "Africa/Mogadishu" },
    { country_code: "LR", offset: "+0000", name: "Africa/Monrovia" },
    { country_code: "KE", offset: "+0300", name: "Africa/Nairobi" },
    { country_code: "TD", offset: "+0100", name: "Africa/Ndjamena" },
    { country_code: "NE", offset: "+0100", name: "Africa/Niamey" },
    { country_code: "MR", offset: "+0000", name: "Africa/Nouakchott" },
    { country_code: "BF", offset: "+0000", name: "Africa/Ouagadougou" },
    { country_code: "BJ", offset: "+0100", name: "Africa/Porto-Novo" },
    { country_code: "ST", offset: "+0000", name: "Africa/Sao_Tome" },
    { country_code: "LY", offset: "+0200", name: "Africa/Tripoli" },
    { country_code: "TN", offset: "+0100", name: "Africa/Tunis" },
    { country_code: "NA", offset: "+0200", name: "Africa/Windhoek" },
    { country_code: "US", offset: "-1000", name: "America/Adak" },
    { country_code: "US", offset: "-0900", name: "America/Anchorage" },
    { country_code: "AI", offset: "-0400", name: "America/Anguilla" },
    { country_code: "AG", offset: "-0400", name: "America/Antigua" },
    { country_code: "BR", offset: "-0300", name: "America/Araguaina" },
    {
        country_code: "AR",
        offset: "-0300",
        name: "America/Argentina/Buenos_Aires",
    },
    {
        country_code: "AR",
        offset: "-0300",
        name: "America/Argentina/Catamarca",
    },
    { country_code: "AR", offset: "-0300", name: "America/Argentina/Cordoba" },
    { country_code: "AR", offset: "-0300", name: "America/Argentina/Jujuy" },
    { country_code: "AR", offset: "-0300", name: "America/Argentina/La_Rioja" },
    { country_code: "AR", offset: "-0300", name: "America/Argentina/Mendoza" },
    {
        country_code: "AR",
        offset: "-0300",
        name: "America/Argentina/Rio_Gallegos",
    },
    { country_code: "AR", offset: "-0300", name: "America/Argentina/Salta" },
    { country_code: "AR", offset: "-0300", name: "America/Argentina/San_Juan" },
    { country_code: "AR", offset: "-0300", name: "America/Argentina/San_Luis" },
    { country_code: "AR", offset: "-0300", name: "America/Argentina/Tucuman" },
    { country_code: "AR", offset: "-0300", name: "America/Argentina/Ushuaia" },
    { country_code: "AW", offset: "-0400", name: "America/Aruba" },
    { country_code: "PY", offset: "-0300", name: "America/Asuncion" },
    { country_code: "CA", offset: "-0500", name: "America/Atikokan" },
    { country_code: "BR", offset: "-0300", name: "America/Bahia" },
    { country_code: "MX", offset: "-0600", name: "America/Bahia_Banderas" },
    { country_code: "BB", offset: "-0400", name: "America/Barbados" },
    { country_code: "BR", offset: "-0300", name: "America/Belem" },
    { country_code: "BZ", offset: "-0600", name: "America/Belize" },
    { country_code: "CA", offset: "-0400", name: "America/Blanc-Sablon" },
    { country_code: "BR", offset: "-0400", name: "America/Boa_Vista" },
    { country_code: "CO", offset: "-0500", name: "America/Bogota" },
    { country_code: "US", offset: "-0700", name: "America/Boise" },
    { country_code: "CA", offset: "-0700", name: "America/Cambridge_Bay" },
    { country_code: "BR", offset: "-0400", name: "America/Campo_Grande" },
    { country_code: "MX", offset: "-0500", name: "America/Cancun" },
    { country_code: "VE", offset: "-0400", name: "America/Caracas" },
    { country_code: "GF", offset: "-0300", name: "America/Cayenne" },
    { country_code: "KY", offset: "-0500", name: "America/Cayman" },
    { country_code: "US", offset: "-0600", name: "America/Chicago" },
    { country_code: "MX", offset: "-0700", name: "America/Chihuahua" },
    { country_code: "CR", offset: "-0600", name: "America/Costa_Rica" },
    { country_code: "CA", offset: "-0700", name: "America/Creston" },
    { country_code: "BR", offset: "-0400", name: "America/Cuiaba" },
    { country_code: "CW", offset: "-0400", name: "America/Curacao" },
    { country_code: "GL", offset: "+0000", name: "America/Danmarkshavn" },
    { country_code: "CA", offset: "-0700", name: "America/Dawson" },
    { country_code: "CA", offset: "-0700", name: "America/Dawson_Creek" },
    { country_code: "US", offset: "-0700", name: "America/Denver" },
    { country_code: "US", offset: "-0500", name: "America/Detroit" },
    { country_code: "DM", offset: "-0400", name: "America/Dominica" },
    { country_code: "CA", offset: "-0700", name: "America/Edmonton" },
    { country_code: "BR", offset: "-0500", name: "America/Eirunepe" },
    { country_code: "SV", offset: "-0600", name: "America/El_Salvador" },
    { country_code: "CA", offset: "-0700", name: "America/Fort_Nelson" },
    { country_code: "BR", offset: "-0300", name: "America/Fortaleza" },
    { country_code: "CA", offset: "-0400", name: "America/Glace_Bay" },
    { country_code: "CA", offset: "-0400", name: "America/Goose_Bay" },
    { country_code: "TC", offset: "-0500", name: "America/Grand_Turk" },
    { country_code: "GD", offset: "-0400", name: "America/Grenada" },
    { country_code: "GP", offset: "-0400", name: "America/Guadeloupe" },
    { country_code: "GT", offset: "-0600", name: "America/Guatemala" },
    { country_code: "EC", offset: "-0500", name: "America/Guayaquil" },
    { country_code: "GY", offset: "-0400", name: "America/Guyana" },
    { country_code: "CA", offset: "-0400", name: "America/Halifax" },
    { country_code: "CU", offset: "-0500", name: "America/Havana" },
    { country_code: "MX", offset: "-0700", name: "America/Hermosillo" },
    {
        country_code: "US",
        offset: "-0500",
        name: "America/Indiana/Indianapolis",
    },
    { country_code: "US", offset: "-0600", name: "America/Indiana/Knox" },
    { country_code: "US", offset: "-0500", name: "America/Indiana/Marengo" },
    { country_code: "US", offset: "-0500", name: "America/Indiana/Petersburg" },
    { country_code: "US", offset: "-0600", name: "America/Indiana/Tell_City" },
    { country_code: "US", offset: "-0500", name: "America/Indiana/Vevay" },
    { country_code: "US", offset: "-0500", name: "America/Indiana/Vincennes" },
    { country_code: "US", offset: "-0500", name: "America/Indiana/Winamac" },
    { country_code: "CA", offset: "-0700", name: "America/Inuvik" },
    { country_code: "CA", offset: "-0500", name: "America/Iqaluit" },
    { country_code: "JM", offset: "-0500", name: "America/Jamaica" },
    { country_code: "US", offset: "-0900", name: "America/Juneau" },
    {
        country_code: "US",
        offset: "-0500",
        name: "America/Kentucky/Louisville",
    },
    {
        country_code: "US",
        offset: "-0500",
        name: "America/Kentucky/Monticello",
    },
    { country_code: "BQ", offset: "-0400", name: "America/Kralendijk" },
    { country_code: "BO", offset: "-0400", name: "America/La_Paz" },
    { country_code: "PE", offset: "-0500", name: "America/Lima" },
    { country_code: "US", offset: "-0800", name: "America/Los_Angeles" },
    { country_code: "SX", offset: "-0400", name: "America/Lower_Princes" },
    { country_code: "BR", offset: "-0300", name: "America/Maceio" },
    { country_code: "NI", offset: "-0600", name: "America/Managua" },
    { country_code: "BR", offset: "-0400", name: "America/Manaus" },
    { country_code: "MF", offset: "-0400", name: "America/Marigot" },
    { country_code: "MQ", offset: "-0400", name: "America/Martinique" },
    { country_code: "MX", offset: "-0600", name: "America/Matamoros" },
    { country_code: "MX", offset: "-0700", name: "America/Mazatlan" },
    { country_code: "US", offset: "-0600", name: "America/Menominee" },
    { country_code: "MX", offset: "-0600", name: "America/Merida" },
    { country_code: "US", offset: "-0900", name: "America/Metlakatla" },
    { country_code: "MX", offset: "-0600", name: "America/Mexico_City" },
    { country_code: "PM", offset: "-0300", name: "America/Miquelon" },
    { country_code: "CA", offset: "-0400", name: "America/Moncton" },
    { country_code: "MX", offset: "-0600", name: "America/Monterrey" },
    { country_code: "UY", offset: "-0300", name: "America/Montevideo" },
    { country_code: "MS", offset: "-0400", name: "America/Montserrat" },
    { country_code: "BS", offset: "-0500", name: "America/Nassau" },
    { country_code: "US", offset: "-0500", name: "America/New_York" },
    { country_code: "CA", offset: "-0500", name: "America/Nipigon" },
    { country_code: "US", offset: "-0900", name: "America/Nome" },
    { country_code: "BR", offset: "-0200", name: "America/Noronha" },
    {
        country_code: "US",
        offset: "-0600",
        name: "America/North_Dakota/Beulah",
    },
    {
        country_code: "US",
        offset: "-0600",
        name: "America/North_Dakota/Center",
    },
    {
        country_code: "US",
        offset: "-0600",
        name: "America/North_Dakota/New_Salem",
    },
    { country_code: "GL", offset: "-0300", name: "America/Nuuk" },
    { country_code: "MX", offset: "-0700", name: "America/Ojinaga" },
    { country_code: "PA", offset: "-0500", name: "America/Panama" },
    { country_code: "CA", offset: "-0500", name: "America/Pangnirtung" },
    { country_code: "SR", offset: "-0300", name: "America/Paramaribo" },
    { country_code: "US", offset: "-0700", name: "America/Phoenix" },
    { country_code: "HT", offset: "-0500", name: "America/Port-au-Prince" },
    { country_code: "TT", offset: "-0400", name: "America/Port_of_Spain" },
    { country_code: "BR", offset: "-0400", name: "America/Porto_Velho" },
    { country_code: "PR", offset: "-0400", name: "America/Puerto_Rico" },
    { country_code: "CL", offset: "-0300", name: "America/Punta_Arenas" },
    { country_code: "CA", offset: "-0600", name: "America/Rainy_River" },
    { country_code: "CA", offset: "-0600", name: "America/Rankin_Inlet" },
    { country_code: "BR", offset: "-0300", name: "America/Recife" },
    { country_code: "CA", offset: "-0600", name: "America/Regina" },
    { country_code: "CA", offset: "-0600", name: "America/Resolute" },
    { country_code: "BR", offset: "-0500", name: "America/Rio_Branco" },
    { country_code: "BR", offset: "-0300", name: "America/Santarem" },
    { country_code: "CL", offset: "-0300", name: "America/Santiago" },
    { country_code: "DO", offset: "-0400", name: "America/Santo_Domingo" },
    { country_code: "BR", offset: "-0300", name: "America/Sao_Paulo" },
    { country_code: "GL", offset: "-0100", name: "America/Scoresbysund" },
    { country_code: "US", offset: "-0900", name: "America/Sitka" },
    { country_code: "BL", offset: "-0400", name: "America/St_Barthelemy" },
    { country_code: "CA", offset: "-0330", name: "America/St_Johns" },
    { country_code: "KN", offset: "-0400", name: "America/St_Kitts" },
    { country_code: "LC", offset: "-0400", name: "America/St_Lucia" },
    { country_code: "VI", offset: "-0400", name: "America/St_Thomas" },
    { country_code: "VC", offset: "-0400", name: "America/St_Vincent" },
    { country_code: "CA", offset: "-0600", name: "America/Swift_Current" },
    { country_code: "HN", offset: "-0600", name: "America/Tegucigalpa" },
    { country_code: "GL", offset: "-0400", name: "America/Thule" },
    { country_code: "CA", offset: "-0500", name: "America/Thunder_Bay" },
    { country_code: "MX", offset: "-0800", name: "America/Tijuana" },
    { country_code: "CA", offset: "-0500", name: "America/Toronto" },
    { country_code: "VG", offset: "-0400", name: "America/Tortola" },
    { country_code: "CA", offset: "-0800", name: "America/Vancouver" },
    { country_code: "CA", offset: "-0700", name: "America/Whitehorse" },
    { country_code: "CA", offset: "-0600", name: "America/Winnipeg" },
    { country_code: "US", offset: "-0900", name: "America/Yakutat" },
    { country_code: "CA", offset: "-0700", name: "America/Yellowknife" },
    { country_code: "AQ", offset: "+1100", name: "Antarctica/Casey" },
    { country_code: "AQ", offset: "+0700", name: "Antarctica/Davis" },
    { country_code: "AQ", offset: "+1000", name: "Antarctica/DumontDUrville" },
    { country_code: "AU", offset: "+1100", name: "Antarctica/Macquarie" },
    { country_code: "AQ", offset: "+0500", name: "Antarctica/Mawson" },
    { country_code: "AQ", offset: "+1300", name: "Antarctica/McMurdo" },
    { country_code: "AQ", offset: "-0300", name: "Antarctica/Palmer" },
    { country_code: "AQ", offset: "-0300", name: "Antarctica/Rothera" },
    { country_code: "AQ", offset: "+0300", name: "Antarctica/Syowa" },
    { country_code: "AQ", offset: "+0000", name: "Antarctica/Troll" },
    { country_code: "AQ", offset: "+0600", name: "Antarctica/Vostok" },
    { country_code: "SJ", offset: "+0100", name: "Arctic/Longyearbyen" },
    { country_code: "YE", offset: "+0300", name: "Asia/Aden" },
    { country_code: "KZ", offset: "+0600", name: "Asia/Almaty" },
    { country_code: "JO", offset: "+0200", name: "Asia/Amman" },
    { country_code: "RU", offset: "+1200", name: "Asia/Anadyr" },
    { country_code: "KZ", offset: "+0500", name: "Asia/Aqtau" },
    { country_code: "KZ", offset: "+0500", name: "Asia/Aqtobe" },
    { country_code: "TM", offset: "+0500", name: "Asia/Ashgabat" },
    { country_code: "KZ", offset: "+0500", name: "Asia/Atyrau" },
    { country_code: "IQ", offset: "+0300", name: "Asia/Baghdad" },
    { country_code: "BH", offset: "+0300", name: "Asia/Bahrain" },
    { country_code: "AZ", offset: "+0400", name: "Asia/Baku" },
    { country_code: "TH", offset: "+0700", name: "Asia/Bangkok" },
    { country_code: "RU", offset: "+0700", name: "Asia/Barnaul" },
    { country_code: "LB", offset: "+0200", name: "Asia/Beirut" },
    { country_code: "KG", offset: "+0600", name: "Asia/Bishkek" },
    { country_code: "BN", offset: "+0800", name: "Asia/Brunei" },
    { country_code: "RU", offset: "+0900", name: "Asia/Chita" },
    { country_code: "MN", offset: "+0800", name: "Asia/Choibalsan" },
    { country_code: "LK", offset: "+0530", name: "Asia/Colombo" },
    { country_code: "SY", offset: "+0200", name: "Asia/Damascus" },
    { country_code: "BD", offset: "+0600", name: "Asia/Dhaka" },
    { country_code: "TL", offset: "+0900", name: "Asia/Dili" },
    { country_code: "AE", offset: "+0400", name: "Asia/Dubai" },
    { country_code: "TJ", offset: "+0500", name: "Asia/Dushanbe" },
    { country_code: "CY", offset: "+0200", name: "Asia/Famagusta" },
    { country_code: "PS", offset: "+0200", name: "Asia/Gaza" },
    { country_code: "PS", offset: "+0200", name: "Asia/Hebron" },
    { country_code: "VN", offset: "+0700", name: "Asia/Ho_Chi_Minh" },
    { country_code: "HK", offset: "+0800", name: "Asia/Hong_Kong" },
    { country_code: "MN", offset: "+0700", name: "Asia/Hovd" },
    { country_code: "RU", offset: "+0800", name: "Asia/Irkutsk" },
    { country_code: "ID", offset: "+0700", name: "Asia/Jakarta" },
    { country_code: "ID", offset: "+0900", name: "Asia/Jayapura" },
    { country_code: "IL", offset: "+0200", name: "Asia/Jerusalem" },
    { country_code: "AF", offset: "+0430", name: "Asia/Kabul" },
    { country_code: "RU", offset: "+1200", name: "Asia/Kamchatka" },
    { country_code: "PK", offset: "+0500", name: "Asia/Karachi" },
    { country_code: "NP", offset: "+0545", name: "Asia/Kathmandu" },
    { country_code: "RU", offset: "+0900", name: "Asia/Khandyga" },
    { country_code: "IN", offset: "+0530", name: "Asia/Kolkata" },
    { country_code: "IN", offset: "+0530", name: "Asia/Calcutta" },
    { country_code: "RU", offset: "+0700", name: "Asia/Krasnoyarsk" },
    { country_code: "MY", offset: "+0800", name: "Asia/Kuala_Lumpur" },
    { country_code: "MY", offset: "+0800", name: "Asia/Kuching" },
    { country_code: "KW", offset: "+0300", name: "Asia/Kuwait" },
    { country_code: "MO", offset: "+0800", name: "Asia/Macau" },
    { country_code: "RU", offset: "+1100", name: "Asia/Magadan" },
    { country_code: "ID", offset: "+0800", name: "Asia/Makassar" },
    { country_code: "PH", offset: "+0800", name: "Asia/Manila" },
    { country_code: "OM", offset: "+0400", name: "Asia/Muscat" },
    { country_code: "CY", offset: "+0200", name: "Asia/Nicosia" },
    { country_code: "RU", offset: "+0700", name: "Asia/Novokuznetsk" },
    { country_code: "RU", offset: "+0700", name: "Asia/Novosibirsk" },
    { country_code: "RU", offset: "+0600", name: "Asia/Omsk" },
    { country_code: "KZ", offset: "+0500", name: "Asia/Oral" },
    { country_code: "KH", offset: "+0700", name: "Asia/Phnom_Penh" },
    { country_code: "ID", offset: "+0700", name: "Asia/Pontianak" },
    { country_code: "KP", offset: "+0900", name: "Asia/Pyongyang" },
    { country_code: "QA", offset: "+0300", name: "Asia/Qatar" },
    { country_code: "KZ", offset: "+0600", name: "Asia/Qostanay" },
    { country_code: "KZ", offset: "+0500", name: "Asia/Qyzylorda" },
    { country_code: "SA", offset: "+0300", name: "Asia/Riyadh" },
    { country_code: "RU", offset: "+1100", name: "Asia/Sakhalin" },
    { country_code: "UZ", offset: "+0500", name: "Asia/Samarkand" },
    { country_code: "KR", offset: "+0900", name: "Asia/Seoul" },
    { country_code: "CN", offset: "+0800", name: "Asia/Shanghai" },
    { country_code: "SG", offset: "+0800", name: "Asia/Singapore" },
    { country_code: "RU", offset: "+1100", name: "Asia/Srednekolymsk" },
    { country_code: "TW", offset: "+0800", name: "Asia/Taipei" },
    { country_code: "UZ", offset: "+0500", name: "Asia/Tashkent" },
    { country_code: "GE", offset: "+0400", name: "Asia/Tbilisi" },
    { country_code: "IR", offset: "+0330", name: "Asia/Tehran" },
    { country_code: "BT", offset: "+0600", name: "Asia/Thimphu" },
    { country_code: "JP", offset: "+0900", name: "Asia/Tokyo" },
    { country_code: "RU", offset: "+0700", name: "Asia/Tomsk" },
    { country_code: "MN", offset: "+0800", name: "Asia/Ulaanbaatar" },
    { country_code: "CN", offset: "+0600", name: "Asia/Urumqi" },
    { country_code: "RU", offset: "+1000", name: "Asia/Ust-Nera" },
    { country_code: "LA", offset: "+0700", name: "Asia/Vientiane" },
    { country_code: "RU", offset: "+1000", name: "Asia/Vladivostok" },
    { country_code: "RU", offset: "+0900", name: "Asia/Yakutsk" },
    { country_code: "MM", offset: "+0630", name: "Asia/Yangon" },
    { country_code: "RU", offset: "+0500", name: "Asia/Yekaterinburg" },
    { country_code: "AM", offset: "+0400", name: "Asia/Yerevan" },
    { country_code: "PT", offset: "-0100", name: "Atlantic/Azores" },
    { country_code: "BM", offset: "-0400", name: "Atlantic/Bermuda" },
    { country_code: "ES", offset: "+0000", name: "Atlantic/Canary" },
    { country_code: "CV", offset: "-0100", name: "Atlantic/Cape_Verde" },
    { country_code: "FO", offset: "+0000", name: "Atlantic/Faroe" },
    { country_code: "PT", offset: "+0000", name: "Atlantic/Madeira" },
    { country_code: "IS", offset: "+0000", name: "Atlantic/Reykjavik" },
    { country_code: "GS", offset: "-0200", name: "Atlantic/South_Georgia" },
    { country_code: "SH", offset: "+0000", name: "Atlantic/St_Helena" },
    { country_code: "FK", offset: "-0300", name: "Atlantic/Stanley" },
    { country_code: "AU", offset: "+1030", name: "Australia/Adelaide" },
    { country_code: "AU", offset: "+1000", name: "Australia/Brisbane" },
    { country_code: "AU", offset: "+1030", name: "Australia/Broken_Hill" },
    { country_code: "AU", offset: "+0930", name: "Australia/Darwin" },
    { country_code: "AU", offset: "+0845", name: "Australia/Eucla" },
    { country_code: "AU", offset: "+1100", name: "Australia/Hobart" },
    { country_code: "AU", offset: "+1000", name: "Australia/Lindeman" },
    { country_code: "AU", offset: "+1100", name: "Australia/Lord_Howe" },
    { country_code: "AU", offset: "+1100", name: "Australia/Melbourne" },
    { country_code: "AU", offset: "+0800", name: "Australia/Perth" },
    { country_code: "AU", offset: "+1100", name: "Australia/Sydney" },
    { country_code: "NL", offset: "+0100", name: "Europe/Amsterdam" },
    { country_code: "AD", offset: "+0100", name: "Europe/Andorra" },
    { country_code: "RU", offset: "+0400", name: "Europe/Astrakhan" },
    { country_code: "GR", offset: "+0200", name: "Europe/Athens" },
    { country_code: "RS", offset: "+0100", name: "Europe/Belgrade" },
    { country_code: "DE", offset: "+0100", name: "Europe/Berlin" },
    { country_code: "SK", offset: "+0100", name: "Europe/Bratislava" },
    { country_code: "BE", offset: "+0100", name: "Europe/Brussels" },
    { country_code: "RO", offset: "+0200", name: "Europe/Bucharest" },
    { country_code: "HU", offset: "+0100", name: "Europe/Budapest" },
    { country_code: "DE", offset: "+0100", name: "Europe/Busingen" },
    { country_code: "MD", offset: "+0200", name: "Europe/Chisinau" },
    { country_code: "DK", offset: "+0100", name: "Europe/Copenhagen" },
    { country_code: "IE", offset: "+0000", name: "Europe/Dublin" },
    { country_code: "GI", offset: "+0100", name: "Europe/Gibraltar" },
    { country_code: "GG", offset: "+0000", name: "Europe/Guernsey" },
    { country_code: "FI", offset: "+0200", name: "Europe/Helsinki" },
    { country_code: "IM", offset: "+0000", name: "Europe/Isle_of_Man" },
    { country_code: "TR", offset: "+0300", name: "Europe/Istanbul" },
    { country_code: "JE", offset: "+0000", name: "Europe/Jersey" },
    { country_code: "RU", offset: "+0200", name: "Europe/Kaliningrad" },
    { country_code: "UA", offset: "+0200", name: "Europe/Kiev" },
    { country_code: "RU", offset: "+0300", name: "Europe/Kirov" },
    { country_code: "PT", offset: "+0000", name: "Europe/Lisbon" },
    { country_code: "SI", offset: "+0100", name: "Europe/Ljubljana" },
    { country_code: "GB", offset: "+0000", name: "Europe/London" },
    { country_code: "LU", offset: "+0100", name: "Europe/Luxembourg" },
    { country_code: "ES", offset: "+0100", name: "Europe/Madrid" },
    { country_code: "MT", offset: "+0100", name: "Europe/Malta" },
    { country_code: "AX", offset: "+0200", name: "Europe/Mariehamn" },
    { country_code: "BY", offset: "+0300", name: "Europe/Minsk" },
    { country_code: "MC", offset: "+0100", name: "Europe/Monaco" },
    { country_code: "RU", offset: "+0300", name: "Europe/Moscow" },
    { country_code: "NO", offset: "+0100", name: "Europe/Oslo" },
    { country_code: "FR", offset: "+0100", name: "Europe/Paris" },
    { country_code: "ME", offset: "+0100", name: "Europe/Podgorica" },
    { country_code: "CZ", offset: "+0100", name: "Europe/Prague" },
    { country_code: "LV", offset: "+0200", name: "Europe/Riga" },
    { country_code: "IT", offset: "+0100", name: "Europe/Rome" },
    { country_code: "RU", offset: "+0400", name: "Europe/Samara" },
    { country_code: "SM", offset: "+0100", name: "Europe/San_Marino" },
    { country_code: "BA", offset: "+0100", name: "Europe/Sarajevo" },
    { country_code: "RU", offset: "+0400", name: "Europe/Saratov" },
    { country_code: "UA", offset: "+0300", name: "Europe/Simferopol" },
    { country_code: "MK", offset: "+0100", name: "Europe/Skopje" },
    { country_code: "BG", offset: "+0200", name: "Europe/Sofia" },
    { country_code: "SE", offset: "+0100", name: "Europe/Stockholm" },
    { country_code: "EE", offset: "+0200", name: "Europe/Tallinn" },
    { country_code: "AL", offset: "+0100", name: "Europe/Tirane" },
    { country_code: "RU", offset: "+0400", name: "Europe/Ulyanovsk" },
    { country_code: "UA", offset: "+0200", name: "Europe/Uzhgorod" },
    { country_code: "LI", offset: "+0100", name: "Europe/Vaduz" },
    { country_code: "VA", offset: "+0100", name: "Europe/Vatican" },
    { country_code: "AT", offset: "+0100", name: "Europe/Vienna" },
    { country_code: "LT", offset: "+0200", name: "Europe/Vilnius" },
    { country_code: "RU", offset: "+0300", name: "Europe/Volgograd" },
    { country_code: "PL", offset: "+0100", name: "Europe/Warsaw" },
    { country_code: "HR", offset: "+0100", name: "Europe/Zagreb" },
    { country_code: "UA", offset: "+0200", name: "Europe/Zaporozhye" },
    { country_code: "CH", offset: "+0100", name: "Europe/Zurich" },
    { country_code: "MG", offset: "+0300", name: "Indian/Antananarivo" },
    { country_code: "IO", offset: "+0600", name: "Indian/Chagos" },
    { country_code: "CX", offset: "+0700", name: "Indian/Christmas" },
    { country_code: "CC", offset: "+0630", name: "Indian/Cocos" },
    { country_code: "KM", offset: "+0300", name: "Indian/Comoro" },
    { country_code: "TF", offset: "+0500", name: "Indian/Kerguelen" },
    { country_code: "SC", offset: "+0400", name: "Indian/Mahe" },
    { country_code: "MV", offset: "+0500", name: "Indian/Maldives" },
    { country_code: "MU", offset: "+0400", name: "Indian/Mauritius" },
    { country_code: "YT", offset: "+0300", name: "Indian/Mayotte" },
    { country_code: "RE", offset: "+0400", name: "Indian/Reunion" },
    { country_code: "WS", offset: "+1300", name: "Pacific/Apia" },
    { country_code: "NZ", offset: "+1300", name: "Pacific/Auckland" },
    { country_code: "PG", offset: "+1100", name: "Pacific/Bougainville" },
    { country_code: "NZ", offset: "+1345", name: "Pacific/Chatham" },
    { country_code: "FM", offset: "+1000", name: "Pacific/Chuuk" },
    { country_code: "CL", offset: "-0500", name: "Pacific/Easter" },
    { country_code: "VU", offset: "+1100", name: "Pacific/Efate" },
    { country_code: "TK", offset: "+1300", name: "Pacific/Fakaofo" },
    { country_code: "FJ", offset: "+1200", name: "Pacific/Fiji" },
    { country_code: "TV", offset: "+1200", name: "Pacific/Funafuti" },
    { country_code: "EC", offset: "-0600", name: "Pacific/Galapagos" },
    { country_code: "PF", offset: "-0900", name: "Pacific/Gambier" },
    { country_code: "SB", offset: "+1100", name: "Pacific/Guadalcanal" },
    { country_code: "GU", offset: "+1000", name: "Pacific/Guam" },
    { country_code: "US", offset: "-1000", name: "Pacific/Honolulu" },
    { country_code: "KI", offset: "+1400", name: "Pacific/Kiritimati" },
    { country_code: "FM", offset: "+1100", name: "Pacific/Kosrae" },
    { country_code: "MH", offset: "+1200", name: "Pacific/Kwajalein" },
    { country_code: "MH", offset: "+1200", name: "Pacific/Majuro" },
    { country_code: "PF", offset: "-0930", name: "Pacific/Marquesas" },
    { country_code: "UM", offset: "-1100", name: "Pacific/Midway" },
    { country_code: "NR", offset: "+1200", name: "Pacific/Nauru" },
    { country_code: "NU", offset: "-1100", name: "Pacific/Niue" },
    { country_code: "NF", offset: "+1200", name: "Pacific/Norfolk" },
    { country_code: "NC", offset: "+1100", name: "Pacific/Noumea" },
    { country_code: "AS", offset: "-1100", name: "Pacific/Pago_Pago" },
    { country_code: "PW", offset: "+0900", name: "Pacific/Palau" },
    { country_code: "PN", offset: "-0800", name: "Pacific/Pitcairn" },
    { country_code: "FM", offset: "+1100", name: "Pacific/Pohnpei" },
    { country_code: "PG", offset: "+1000", name: "Pacific/Port_Moresby" },
    { country_code: "CK", offset: "-1000", name: "Pacific/Rarotonga" },
    { country_code: "MP", offset: "+1000", name: "Pacific/Saipan" },
    { country_code: "PF", offset: "-1000", name: "Pacific/Tahiti" },
    { country_code: "KI", offset: "+1200", name: "Pacific/Tarawa" },
    { country_code: "TO", offset: "+1300", name: "Pacific/Tongatapu" },
    { country_code: "UM", offset: "+1200", name: "Pacific/Wake" },
    { country_code: "WF", offset: "+1200", name: "Pacific/Wallis" },
];

export const COUNTRY_CODE_TO_TIMEZONE_MAP = {
    CI: "Africa/Abidjan",
    GH: "Africa/Accra",
    ET: "Africa/Addis_Ababa",
    DZ: "Africa/Algiers",
    ER: "Africa/Asmara",
    ML: "Africa/Bamako",
    CF: "Africa/Bangui",
    GM: "Africa/Banjul",
    GW: "Africa/Bissau",
    MW: "Africa/Blantyre",
    CG: "Africa/Brazzaville",
    BI: "Africa/Bujumbura",
    EG: "Africa/Cairo",
    MA: "Africa/Casablanca",
    ES: "Africa/Ceuta",
    GN: "Africa/Conakry",
    SN: "Africa/Dakar",
    TZ: "Africa/Dar_es_Salaam",
    DJ: "Africa/Djibouti",
    CM: "Africa/Douala",
    EH: "Africa/El_Aaiun",
    SL: "Africa/Freetown",
    BW: "Africa/Gaborone",
    ZW: "Africa/Harare",
    ZA: "Africa/Johannesburg",
    SS: "Africa/Juba",
    UG: "Africa/Kampala",
    SD: "Africa/Khartoum",
    RW: "Africa/Kigali",
    CD: "Africa/Kinshasa",
    NG: "Africa/Lagos",
    GA: "Africa/Libreville",
    TG: "Africa/Lome",
    AO: "Africa/Luanda",
    ZM: "Africa/Lusaka",
    GQ: "Africa/Malabo",
    MZ: "Africa/Maputo",
    LS: "Africa/Maseru",
    SZ: "Africa/Mbabane",
    SO: "Africa/Mogadishu",
    LR: "Africa/Monrovia",
    KE: "Africa/Nairobi",
    TD: "Africa/Ndjamena",
    NE: "Africa/Niamey",
    MR: "Africa/Nouakchott",
    BF: "Africa/Ouagadougou",
    BJ: "Africa/Porto-Novo",
    ST: "Africa/Sao_Tome",
    LY: "Africa/Tripoli",
    TN: "Africa/Tunis",
    NA: "Africa/Windhoek",
    US: "America/Adak",
    AI: "America/Anguilla",
    AG: "America/Antigua",
    BR: "America/Araguaina",
    AR: "America/Argentina/Buenos_Aires",
    AW: "America/Aruba",
    PY: "America/Asuncion",
    CA: "America/Atikokan",
    MX: "America/Bahia_Banderas",
    BB: "America/Barbados",
    BZ: "America/Belize",
    CO: "America/Bogota",
    VE: "America/Caracas",
    GF: "America/Cayenne",
    KY: "America/Cayman",
    CR: "America/Costa_Rica",
    CW: "America/Curacao",
    GL: "America/Danmarkshavn",
    DM: "America/Dominica",
    SV: "America/El_Salvador",
    TC: "America/Grand_Turk",
    GD: "America/Grenada",
    GP: "America/Guadeloupe",
    GT: "America/Guatemala",
    EC: "America/Guayaquil",
    GY: "America/Guyana",
    CU: "America/Havana",
    JM: "America/Jamaica",
    BQ: "America/Kralendijk",
    BO: "America/La_Paz",
    PE: "America/Lima",
    SX: "America/Lower_Princes",
    NI: "America/Managua",
    MF: "America/Marigot",
    MQ: "America/Martinique",
    PM: "America/Miquelon",
    UY: "America/Montevideo",
    MS: "America/Montserrat",
    BS: "America/Nassau",
    PA: "America/Panama",
    SR: "America/Paramaribo",
    HT: "America/Port-au-Prince",
    TT: "America/Port_of_Spain",
    PR: "America/Puerto_Rico",
    CL: "America/Punta_Arenas",
    DO: "America/Santo_Domingo",
    BL: "America/St_Barthelemy",
    KN: "America/St_Kitts",
    LC: "America/St_Lucia",
    VI: "America/St_Thomas",
    VC: "America/St_Vincent",
    HN: "America/Tegucigalpa",
    VG: "America/Tortola",
    AQ: "Antarctica/Casey",
    AU: "Antarctica/Macquarie",
    SJ: "Arctic/Longyearbyen",
    YE: "Asia/Aden",
    KZ: "Asia/Almaty",
    JO: "Asia/Amman",
    RU: "Asia/Anadyr",
    TM: "Asia/Ashgabat",
    IQ: "Asia/Baghdad",
    BH: "Asia/Bahrain",
    AZ: "Asia/Baku",
    TH: "Asia/Bangkok",
    LB: "Asia/Beirut",
    KG: "Asia/Bishkek",
    BN: "Asia/Brunei",
    MN: "Asia/Choibalsan",
    LK: "Asia/Colombo",
    SY: "Asia/Damascus",
    BD: "Asia/Dhaka",
    TL: "Asia/Dili",
    AE: "Asia/Dubai",
    TJ: "Asia/Dushanbe",
    CY: "Asia/Famagusta",
    PS: "Asia/Gaza",
    VN: "Asia/Ho_Chi_Minh",
    HK: "Asia/Hong_Kong",
    ID: "Asia/Jakarta",
    IL: "Asia/Jerusalem",
    AF: "Asia/Kabul",
    PK: "Asia/Karachi",
    NP: "Asia/Kathmandu",
    IN: "Asia/Kolkata",
    MY: "Asia/Kuala_Lumpur",
    KW: "Asia/Kuwait",
    MO: "Asia/Macau",
    PH: "Asia/Manila",
    OM: "Asia/Muscat",
    KH: "Asia/Phnom_Penh",
    KP: "Asia/Pyongyang",
    QA: "Asia/Qatar",
    SA: "Asia/Riyadh",
    UZ: "Asia/Samarkand",
    KR: "Asia/Seoul",
    CN: "Asia/Shanghai",
    SG: "Asia/Singapore",
    TW: "Asia/Taipei",
    GE: "Asia/Tbilisi",
    IR: "Asia/Tehran",
    BT: "Asia/Thimphu",
    JP: "Asia/Tokyo",
    LA: "Asia/Vientiane",
    MM: "Asia/Yangon",
    AM: "Asia/Yerevan",
    PT: "Atlantic/Azores",
    BM: "Atlantic/Bermuda",
    CV: "Atlantic/Cape_Verde",
    FO: "Atlantic/Faroe",
    IS: "Atlantic/Reykjavik",
    GS: "Atlantic/South_Georgia",
    SH: "Atlantic/St_Helena",
    FK: "Atlantic/Stanley",
    NL: "Europe/Amsterdam",
    AD: "Europe/Andorra",
    GR: "Europe/Athens",
    RS: "Europe/Belgrade",
    DE: "Europe/Berlin",
    SK: "Europe/Bratislava",
    BE: "Europe/Brussels",
    RO: "Europe/Bucharest",
    HU: "Europe/Budapest",
    MD: "Europe/Chisinau",
    DK: "Europe/Copenhagen",
    IE: "Europe/Dublin",
    GI: "Europe/Gibraltar",
    GG: "Europe/Guernsey",
    FI: "Europe/Helsinki",
    IM: "Europe/Isle_of_Man",
    TR: "Europe/Istanbul",
    JE: "Europe/Jersey",
    UA: "Europe/Kiev",
    SI: "Europe/Ljubljana",
    GB: "Europe/London",
    LU: "Europe/Luxembourg",
    MT: "Europe/Malta",
    AX: "Europe/Mariehamn",
    BY: "Europe/Minsk",
    MC: "Europe/Monaco",
    NO: "Europe/Oslo",
    FR: "Europe/Paris",
    ME: "Europe/Podgorica",
    CZ: "Europe/Prague",
    LV: "Europe/Riga",
    IT: "Europe/Rome",
    SM: "Europe/San_Marino",
    BA: "Europe/Sarajevo",
    MK: "Europe/Skopje",
    BG: "Europe/Sofia",
    SE: "Europe/Stockholm",
    EE: "Europe/Tallinn",
    AL: "Europe/Tirane",
    LI: "Europe/Vaduz",
    VA: "Europe/Vatican",
    AT: "Europe/Vienna",
    LT: "Europe/Vilnius",
    PL: "Europe/Warsaw",
    HR: "Europe/Zagreb",
    CH: "Europe/Zurich",
    MG: "Indian/Antananarivo",
    IO: "Indian/Chagos",
    CX: "Indian/Christmas",
    CC: "Indian/Cocos",
    KM: "Indian/Comoro",
    TF: "Indian/Kerguelen",
    SC: "Indian/Mahe",
    MV: "Indian/Maldives",
    MU: "Indian/Mauritius",
    YT: "Indian/Mayotte",
    RE: "Indian/Reunion",
    WS: "Pacific/Apia",
    NZ: "Pacific/Auckland",
    PG: "Pacific/Bougainville",
    FM: "Pacific/Chuuk",
    VU: "Pacific/Efate",
    TK: "Pacific/Fakaofo",
    FJ: "Pacific/Fiji",
    TV: "Pacific/Funafuti",
    PF: "Pacific/Gambier",
    SB: "Pacific/Guadalcanal",
    GU: "Pacific/Guam",
    KI: "Pacific/Kanton",
    MH: "Pacific/Kwajalein",
    UM: "Pacific/Midway",
    NR: "Pacific/Nauru",
    NU: "Pacific/Niue",
    NF: "Pacific/Norfolk",
    NC: "Pacific/Noumea",
    AS: "Pacific/Pago_Pago",
    PW: "Pacific/Palau",
    PN: "Pacific/Pitcairn",
    CK: "Pacific/Rarotonga",
    MP: "Pacific/Saipan",
    TO: "Pacific/Tongatapu",
    WF: "Pacific/Wallis",
    UK: "Europe/London",
};
