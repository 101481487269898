import React from "react";

export const ImagePreviewIconSvg = (props: any): JSX.Element => {
    return (
        <svg
            width="63"
            height="63"
            viewBox="0 0 63 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle
                cx="31.1629"
                cy="31.1629"
                r="31.1629"
                fill="#000000"
                fillOpacity="0.6"
            />
            <path d="M43 28.5V20.5H34" stroke="white" strokeWidth="3" />
            <path d="M29.5 20H21.5V29" stroke="white" strokeWidth="3" />
            <path d="M34.5 42.5H42.5V33.5" stroke="white" strokeWidth="3" />
            <path d="M21 35V43H30" stroke="white" strokeWidth="3" />
        </svg>
    );
};
