import { BsThreeDots } from "@react-icons/all-files/bs/BsThreeDots";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { SiTiktok } from "@react-icons/all-files/si/SiTiktok";
import { IoMailSharp } from "@react-icons/all-files/io5/IoMailSharp";
import { MdTextsms } from "@react-icons/all-files/md/MdTextsms";
import { RiWhatsappFill } from "@react-icons/all-files/ri/RiWhatsappFill";
import { RiSpotifyLine } from "@react-icons/all-files/ri/RiSpotifyLine";
import { RiInstagramLine } from "@react-icons/all-files/ri/RiInstagramLine";
import { RiYoutubeLine } from "@react-icons/all-files/ri/RiYoutubeLine";
import { RiFacebookBoxLine } from "@react-icons/all-files/ri/RiFacebookBoxLine";
import { RiTwitterLine } from "@react-icons/all-files/ri/RiTwitterLine";
import { RiLinkedinBoxLine } from "@react-icons/all-files/ri/RiLinkedinBoxLine";
import { RiGlobalLine } from "@react-icons/all-files/ri/RiGlobalLine";

export const SHARE_PLATFORM_KEYS = {
    WHATSAPP: "WHATSAPP",
    TWITTER: "TWITTER",
    FACEBOOK: "FACEBOOK",
    EMAIL: "EMAIL",
    SMS: "SMS",
    MOBILE_SHARE: "MOBILE_SHARE",
};
export const SHARE_PLATFORMS = {
    WHATSAPP: {
        id: SHARE_PLATFORM_KEYS.WHATSAPP,
        title: "Whatsapp",
        onlyMobile: false,
        icon: "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo-whatsapp-128.png",
        component: RiWhatsappFill,
        color: "#25D366",
    },
    TWITTER: {
        id: SHARE_PLATFORM_KEYS.TWITTER,
        title: "Twitter",
        onlyMobile: false,
        icon: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-512.png",
        component: FaTwitter,
        color: "#1DA1F2",
    },
    FACEBOOK: {
        id: SHARE_PLATFORM_KEYS.FACEBOOK,
        title: "Facebook",
        onlyMobile: false,
        icon: "https://cdn1.iconfinder.com/data/icons/social-media-2285/512/Colored_Facebook3_svg-512.png",
        component: FaFacebook,
        color: "#4267B2",
    },
    EMAIL: {
        id: SHARE_PLATFORM_KEYS.EMAIL,
        title: "E-mail",
        onlyMobile: false,
        icon: "https://cdn0.iconfinder.com/data/icons/google-material-design-3-0/48/ic_email_48px-256.png",
        component: IoMailSharp,
        color: "#DB4437",
    },
    SMS: {
        id: SHARE_PLATFORM_KEYS.SMS,
        title: "Text",
        onlyMobile: false,
        // Some OS's like KDE Linux or Mac support integration with the phone's SMS app.
        icon: "https://cdn2.iconfinder.com/data/icons/flat-ui-4/100/Chat-256.png",
        component: MdTextsms,
        color: "grey",
    },
    MOBILE_SHARE: {
        id: SHARE_PLATFORM_KEYS.MOBILE_SHARE,
        title: "More",
        onlyMobile: true,
        icon: "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-more-256.png",
        component: BsThreeDots,
        color: "grey",
    },
};
// used in about section of home page
export const ABOUT_SECTION_SOCIAL_ICONS = {
    instagram: RiInstagramLine,
    youtube: RiYoutubeLine,
    spotify: RiSpotifyLine,
    facebook: RiFacebookBoxLine,
    facebook_name: RiFacebookBoxLine,
    twitter: RiTwitterLine,
    linkedin: RiLinkedinBoxLine,
    website_url: RiGlobalLine,
    tiktok: SiTiktok,
};
