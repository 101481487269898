import { LOCAL_STORAGE_KEYS } from "../../constants/common/storage/localStorage";
import { getLocalStorageItem } from "../common/storage/getterAndSetters";

export const getUserAccessToken = (): string | null => {
    return getLocalStorageItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
};

export const getUserRefreshToken = (): string | null => {
    return getLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
};

export const areLoginTokensPresent = () => {
    return !!(getUserAccessToken() && getUserRefreshToken());
};
