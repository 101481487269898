import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import styles from "./TextInput.module.scss";

/**
 * - extraProps can have following keys
 *      - value?: string;
 *      - onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
 *      - name?: string;
 *      - maxLength?: number;
 *      - onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
 *      - onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
 *      - placeholder?: string;
 *      - type?: "email" | "text" | "number" | "tel";
 */

export const TextInput = React.forwardRef<
    HTMLInputElement,
    {
        design?: "rounded_corners";
        className?: string;
        applyTheme?: boolean;
        success?: boolean;
        error?: boolean;
        editingDisabled?: boolean;
        [k: string]: any; // for extraProps
    }
>(
    (
        {
            design = "rounded_corners",
            className,
            applyTheme = false,
            success,
            error,
            editingDisabled,
            ...extraProps
        },
        ref
    ) => {
        switch (design) {
            case "rounded_corners":
            default:
                return (
                    <input
                        ref={ref}
                        className={classNames(
                            styles.roundedCornersTextInput,
                            success && styles.inputSuccess,
                            error && styles.inputError,
                            editingDisabled && styles.editingDisabled,
                            applyTheme && styles.applyTheme,
                            className
                        )}
                        disabled={editingDisabled}
                        {...extraProps}
                    />
                );
        }
    }
);
