// if: lines of code > 300
// then: breakdown this file and move to repoV2/features/FormData/constants folder

export const ACTION_TYPES = {
    SET_FORM_DATA: "@FORM_DATA/SET",
    RESET_FORM_DATA: "@FORM_DATA/RESET",
    REMOVE_FORM_DATA: "@FORM_DATA/REMOVE",
};

export const FORM_KEYS = {
    user_details: "userDetails",

    international_details: "internationalDetails", // it is a subkey of user_details
    gst_details: "gstDetails", // it is a subkey of user_details
    booking_custom_question_answers: "bookingCustomQuestionAnswers", // it is a subkey of user_details

    listing_details: "listingDetails",
    session_details: "sessionDetails", // it is a subkey of listing_details
    applied_coupon_code_details: "appliedCouponCodeDetails", // it is a subkey of listing_details

    timezone_details: "timezoneDetails",

    express_interest: "expressInterest",

    delivery_details: "deliveryDetails",

    // Todo: Rename this to something like userDetailsMetaData
    multiple_users_helpers: "multipleUsersHelpers", // it consists of some states required as
    // helper to multiple user bookings flow

    payment_details: "paymentDetails", // payment details constists of payment method and payment calculation type
    payment_method: "paymentMethod", // it is the payment method, used for international customers
    payment_calculation_type: "paymentCalculationType", // payment type: either in installment or one time
    loyalty_coupon_details: "loyaltyCouponDetails",
} as const;

export const multiple_users_helpers_keys = {
    all_user_ids: "allUserIds",
    pre_edit_user_details: "preEditUserDetails",
    is_user_details_form_open: "isUserDetailsFormOpen",
};
