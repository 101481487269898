import React, { useState } from "react";
import { FiThumbsUp } from "@react-icons/all-files/fi/FiThumbsUp";
import { FaThumbsUp } from "@react-icons/all-files/fa/FaThumbsUp";
import { useDispatch } from "react-redux";
import {
    ACTION_TYPES,
    API_ACTION_TYPES,
    createAction,
    getUserAccessToken,
} from "@Utils";
import { ICommentReaction } from "./ICommentReaction";
import styles from "../CommentsSection/commentSection.module.scss";

const CommentReaction = ({
    comment_uid,
    likesCount,
    is_commentLiked,
}: ICommentReaction.IProps) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        likes: likesCount,
        isLiked: is_commentLiked,
    });

    const handleReaction = () => {
        dispatch(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.HANDLE_COMMENT_REACTION,
                payload: {
                    comment_uid,
                },
                headers: {
                    "jwt-token": getUserAccessToken(),
                },
                successCallback: () => {
                    setState(prevState => ({
                        likes: prevState.isLiked
                            ? prevState.likes - 1
                            : prevState.likes + 1,
                        isLiked: !prevState.isLiked,
                    }));
                },
            })
        );
    };

    return (
        <>
            {!state?.isLiked ? (
                <span>
                    <FiThumbsUp
                        className={styles.likeIcon}
                        onClick={() => handleReaction()}
                    />
                    {state?.likes}
                </span>
            ) : (
                <span>
                    <FaThumbsUp
                        className={styles.likeIcon}
                        onClick={() => handleReaction()}
                    />
                    {state?.likes}
                </span>
            )}
        </>
    );
};

export default CommentReaction;
