import { IHost } from "@Interfaces";

export const getCommenterName = (
    d: IHost.IBlogPostComment | IHost.IContentComment | IHost.ICommentReply
) => d.commenter_name || d.commenter || "";

export const colors = [
    "#FF8080",
    "#CC80FF",
    "#808EFF",
    "#949398",
    "#496076",
    "#5b84b0",
    "#80BDFF",
    "#67C1BF",
    "#69CE7D",
    "#97C90D",
    "#F0A53D",
];
