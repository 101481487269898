export const REACTION_TYPES = {
    NONE: 0,
    LIKE: 1,
};

export const NOTIFICATION_TYPES = {
    MESSAGE: 1,
    REMINDER: 2,
    POST_ADDED: 3,
    BONUS_OFFERING_ADDED: 4,
};
