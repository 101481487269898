import {
    BONUS_OFFERINGS_ACTION_TYPES,
    bonus_offerings_reducer_initial_state,
} from "../../BrandedCommunity.constants";
import { IBonusOfferingAction } from "../../BrandedCommunity.interface";

export default function bonusOfferingsReducer(
    state = bonus_offerings_reducer_initial_state,
    { type, payload }: IBonusOfferingAction
) {
    switch (type) {
        case BONUS_OFFERINGS_ACTION_TYPES.SET_MY_PURCHASES_DATA: {
            return {
                ...state,
                myPurchasesData: {
                    ...state.myPurchasesData,
                    ...payload,
                },
            };
        }

        default:
            return state;
    }
}
