import React from "react";

export const DotMatrixIconSvg = (props: any): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="90"
            height="76"
            viewBox="0 0 90 76"
            {...props}
        >
            <g
                id="Group_10163"
                data-name="Group 10163"
                transform="translate(-342 -725)"
            >
                <g
                    id="Group_10149"
                    data-name="Group 10149"
                    transform="translate(-399 -1110)"
                >
                    <circle
                        id="Ellipse_33"
                        data-name="Ellipse 33"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(741 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_34"
                        data-name="Ellipse 34"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(755 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_35"
                        data-name="Ellipse 35"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(769 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_36"
                        data-name="Ellipse 36"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(783 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_37"
                        data-name="Ellipse 37"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(797 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_38"
                        data-name="Ellipse 38"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(811 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_39"
                        data-name="Ellipse 39"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(825 1835)"
                        fill="#000000"
                    />
                </g>
                <g
                    id="Group_10149-2"
                    data-name="Group 10149"
                    transform="translate(-399 -1096)"
                >
                    <circle
                        id="Ellipse_33-2"
                        data-name="Ellipse 33"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(741 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_34-2"
                        data-name="Ellipse 34"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(755 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_35-2"
                        data-name="Ellipse 35"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(769 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_36-2"
                        data-name="Ellipse 36"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(783 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_37-2"
                        data-name="Ellipse 37"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(797 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_38-2"
                        data-name="Ellipse 38"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(811 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_39-2"
                        data-name="Ellipse 39"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(825 1835)"
                        fill="#000000"
                    />
                </g>
                <g
                    id="Group_10149-3"
                    data-name="Group 10149"
                    transform="translate(-399 -1082)"
                >
                    <circle
                        id="Ellipse_33-3"
                        data-name="Ellipse 33"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(741 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_34-3"
                        data-name="Ellipse 34"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(755 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_35-3"
                        data-name="Ellipse 35"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(769 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_36-3"
                        data-name="Ellipse 36"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(783 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_37-3"
                        data-name="Ellipse 37"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(797 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_38-3"
                        data-name="Ellipse 38"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(811 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_39-3"
                        data-name="Ellipse 39"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(825 1835)"
                        fill="#000000"
                    />
                </g>
                <g
                    id="Group_10149-4"
                    data-name="Group 10149"
                    transform="translate(-399 -1068)"
                >
                    <circle
                        id="Ellipse_33-4"
                        data-name="Ellipse 33"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(741 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_34-4"
                        data-name="Ellipse 34"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(755 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_35-4"
                        data-name="Ellipse 35"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(769 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_36-4"
                        data-name="Ellipse 36"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(783 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_37-4"
                        data-name="Ellipse 37"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(797 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_38-4"
                        data-name="Ellipse 38"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(811 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_39-4"
                        data-name="Ellipse 39"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(825 1835)"
                        fill="#000000"
                    />
                </g>
                <g
                    id="Group_10149-5"
                    data-name="Group 10149"
                    transform="translate(-399 -1054)"
                >
                    <circle
                        id="Ellipse_33-5"
                        data-name="Ellipse 33"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(741 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_34-5"
                        data-name="Ellipse 34"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(755 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_35-5"
                        data-name="Ellipse 35"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(769 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_36-5"
                        data-name="Ellipse 36"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(783 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_37-5"
                        data-name="Ellipse 37"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(797 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_38-5"
                        data-name="Ellipse 38"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(811 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_39-5"
                        data-name="Ellipse 39"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(825 1835)"
                        fill="#000000"
                    />
                </g>
                <g
                    id="Group_10149-6"
                    data-name="Group 10149"
                    transform="translate(-399 -1040)"
                >
                    <circle
                        id="Ellipse_33-6"
                        data-name="Ellipse 33"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(741 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_34-6"
                        data-name="Ellipse 34"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(755 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_35-6"
                        data-name="Ellipse 35"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(769 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_36-6"
                        data-name="Ellipse 36"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(783 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_37-6"
                        data-name="Ellipse 37"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(797 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_38-6"
                        data-name="Ellipse 38"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(811 1835)"
                        fill="#000000"
                    />
                    <circle
                        id="Ellipse_39-6"
                        data-name="Ellipse 39"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(825 1835)"
                        fill="#000000"
                    />
                </g>
            </g>
        </svg>
    );
};
