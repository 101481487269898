/**
We wanted to improve page speed when `isDirectBooking === true` in event page.

## how this is implemented:
### when: 
- we are on event page\
AND
- `init_booking === true`

Note: `isDirectBooking` is internally dependent upon `init_booking` url query param
### we do the following:
- SSR the UserDetails modal by overriding the default behaviour
- and then remove the override once we evaluate that Modal was not to be show.
## side effects of this and why are we okay with them:
### side effect 1
the user will be shown the modal for a flash and then disappear, if we remove
the overridde after evaluation.
#### why is it okay for us
We narrow down this side effect to a small number of cases.

How: 
- we only generate the `init_booking` url from admin panel\
AND
- that too for live listings
AND
- `[ListingTypes.NO_SCHEDULE.value, ListingTypes.WORKSHOP.value,
    ListingTypes.RECORDED_CONTENT.value, ListingTypes.WHATSAPP.value,
    ListingTypes.BRANDED.value]`
*/

import { useAppCtxContext } from "@Modules/common/AppCtxContext";
import { useData } from "@Utils/hooks/data";
import {
    APP_ROUTE_KEYS,
    NEXT_ROUTE_PATHNAMES,
} from "repoV2/constants/urls&routing/routing";
import { URL_SEARCH_PARAMS } from "repoV2/constants/urls&routing/urlParams";

/**
Constant to access getters and setter for the reducer value to detect
whether we should remove the overrides or not.

Read the complete file to understand the functionality.
*/
const REMOVE_OVERRIDES_FROM_UDMODB =
    "remove_overrides_from_user_details_modal_on_direct_booking";

/**
Use function returned from this hook to indicate that we need to remove the overrides and hide the modal.

Read the complete file where this hook is defined to understand the functionality.
*/
export const useSetRemoveOverridesFromUDMODB = () => {
    const { setData: setRemoveOverridesFromUDMODB } = useData(
        REMOVE_OVERRIDES_FROM_UDMODB,
        false
    );
    const removeOverridesFromUDMODB = () => {
        setRemoveOverridesFromUDMODB(true);
    };

    return removeOverridesFromUDMODB;
};

/**
Use data returned from this hook to detect that we need to overrides the default
functionality to show modal.

@returns shouldOverrideUDMODB - to represent if modal should be shown on SSR or not (represents the initial value of the override)
@returns overrideUDMODB - to represent latest value of override

Read the complete file where this hook is defined to understand the functionality.
*/
export const useGetOverrideUDMODB = () => {
    const { data: removeOverridesFromUDMODB } = useData(
        REMOVE_OVERRIDES_FROM_UDMODB
    );
    const ctx = useAppCtxContext();
    const ctxReq = ctx?.req;

    const shouldOverrideUDMODB =
        // route is of event page
        ctx?.pathname === NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.EVENT] &&
        //
        // AND we have `init_booking=true` in url query params
        (ctxReq?.query?.[URL_SEARCH_PARAMS.INIT_BOOKING] || "") === "true";

    // override only if shouldOverride is true
    // AND we have not recieved the remove override signal (from reducer)
    const overrideUDMODB = shouldOverrideUDMODB && !removeOverridesFromUDMODB;
    return overrideUDMODB;
};
