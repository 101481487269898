// Constants for the form data

import { IUser } from "@Interfaces";
import { isEmpty } from "@Utils/common";
import { isUserDetailFormKey } from "@Utils/form";
import { statesAndUtsOfIndia } from "@Utils/constants/statesOfIndia";
import { EMAIL_REGEX } from "repoV2/utils/common/validators/regex.constants";
import { getEmailHasValidTopLevelDomain } from "repoV2/utils/common/validators/validators";
import { USER_DETAILS_FORM_FIELDS } from "repoV2/constants/user&auth&login/userDetails";
import { checkIsValidIndianPincode } from "repoV2/features/Common/modules/Location/utils/pincode";
import { IUserDetails } from "./IUserDetails";

/**
 * @deprecated use `USER_DETAILS_FORM_FIELDS` in repoV2/constants/listing.ts
 */
export const FORM_FIELDS = {
    name: USER_DETAILS_FORM_FIELDS.full_name,
    email: USER_DETAILS_FORM_FIELDS.email,
    phone: USER_DETAILS_FORM_FIELDS.phone,
    country: USER_DETAILS_FORM_FIELDS.country, // Name. Eg: India
    countryCode: USER_DETAILS_FORM_FIELDS.country_code, // Code. Eg: +91
};

export const CREDS_ALREADY_EXISTS_CHOICES = {
    PROCEED_WITH_ALREADY_EXISTING_DETAILS: 1,
    PROCEED_TO_UPDATE_ACCOUNT_DETAILS: 2,
};

/*
TODO: Generic Form Component 
1. add country, country_code in FORM_ENUM and update the forms to use the generic implementation
2. hide specific field by passing the hiddenField: Array<string> props
   eg: country in user details form
3. error: boolean, showError: boolean, errorMessage: string
*/
export const EXTRA_FIELD = {
    // customer_address is used for merchandise's address collection, distinct from stripe_address
    address: "customer_address",
    message: "message",
    answers: "answers",
    gst_info_gst_name: "gst_info_gst_name",
    gst_info_gst_number: "gst_info_gst_number",
    billing_state: "billing_state",
    gst_info_address: "gst_info_address",
    gst_info_pincode: "gst_info_pincode",

    // All of the four below-mentioned fields are for stripe's address collection
    // stripe_address is kept separate from customer_address for now, to avoid coupling
    stripe_address: "stripe_address",
    city: "city",
    state: "state",
    postal_code: "postal_code",

    payment_method: "payment_method", // Custom payment method override
};

const INPUT_FORMATTING_KEYS = {
    CAPITALISE: "capitalise",
};

const INPUT_FORMATTING = {
    [INPUT_FORMATTING_KEYS.CAPITALISE]: {
        key: INPUT_FORMATTING_KEYS.CAPITALISE,
        format: (input: string = "") => input.toUpperCase(),
    },
};

export const formatFormFieldInput = (key: string, input: string = "") => {
    let formattedInput = input;
    (FORM_ENUM?.[key]?.formatInput || []).forEach(formatFunc => {
        formattedInput =
            INPUT_FORMATTING[
                formatFunc as keyof typeof INPUT_FORMATTING
            ]?.format(formattedInput) ?? formattedInput;
    });
    return formattedInput;
};

const getIsPhoneMandatoryFromExtraData = (
    extraData: {
        isInternationalCreator?: boolean;
        isTelegram?: boolean;
    } = {}
) => !(extraData.isInternationalCreator && !extraData.isTelegram);

export const FORM_ENUM: IUserDetails.IFormEnum = {
    [FORM_FIELDS.name]: {
        id: FORM_FIELDS.name,
        label: "Name",
        inputType: "text",
        validate: (data: string = "") =>
            data.trim().length > 0 ? null : "Please enter a name",
        analyticsEvent: "typeName",
        htmlId: "userInfo-name",
        mandatory: () => true,
    },
    [FORM_FIELDS.email]: {
        id: FORM_FIELDS.email,
        inputType: "email",
        label: "Email",
        validate: (data: string = "", extraData: { [key: string]: any }) => {
            const {
                formDetails = {},
                isEmailMandatory,
                disableCheckEmailTopLevelDomain = false,
            } = extraData;

            const isPhoneMandatory =
                getIsPhoneMandatoryFromExtraData(extraData);
            const isEmailAndPhoneEmpty =
                !isEmailMandatory &&
                isEmpty(data) &&
                !isPhoneMandatory &&
                isEmpty(formDetails[FORM_FIELDS.email]);

            if (isEmailAndPhoneEmpty)
                return "Please enter email or phone number.";
            if (!isEmailMandatory && isEmpty(data)) return null;

            // If email fails regex check, the this condition is false, we show Invalid email
            // Also, if we have to check top level domains and getEmailHasValidTopLevelDomain(data)
            // is also false, then also we show Invalid email
            if (
                EMAIL_REGEX.test(data) &&
                (disableCheckEmailTopLevelDomain ||
                    getEmailHasValidTopLevelDomain(data))
            ) {
                return null;
            }
            return "Invalid email";
        },
        analyticsEvent: "typeEmail",
        htmlId: "userInfo-email",
        mandatory: ({ isEmailMandatory }: { [key: string]: any } = {}) =>
            isEmailMandatory,
    },
    [FORM_FIELDS.phone]: {
        id: FORM_FIELDS.phone,
        inputType: "tel",
        label: "Phone Number",
        validate: (
            data: string = "",
            extraData: { [key: string]: any } = {}
        ) => {
            const {
                formDetails = {},
                isEmailMandatory,
                isInternationalCreator,
            } = extraData;
            const isPhoneMandatory =
                getIsPhoneMandatoryFromExtraData(extraData);
            const isEmailAndPhoneEmpty =
                !isPhoneMandatory &&
                isEmpty(data) &&
                !isEmailMandatory &&
                isEmpty(formDetails[FORM_FIELDS.email]);

            if (isEmailAndPhoneEmpty)
                return "Please enter phone number or email.";
            if (!isPhoneMandatory && isEmpty(data)) return null;

            if (
                isInternationalCreator &&
                !data.trim().length &&
                !extraData?.isTelegram
            )
                return null;
            if (!isInternationalCreator && !data.trim().length)
                return "Please enter a phone number";
            if (!/^\d+$/.test(data)) return "Phone number can only have digits";
            if (extraData?.isInIndia) {
                if (data.length !== 10) {
                    return "Phone number can only be 10 digits long";
                }
            } else {
                if (data.length < 4) return "Phone number too short";
                if (data.length > 16) return "Phone number too long";
            }
            return null;
        },
        analyticsEvent: "typePhone",
        htmlId: "userInfo-phone",
        mandatory: getIsPhoneMandatoryFromExtraData,
    },
    [EXTRA_FIELD.address]: {
        id: EXTRA_FIELD.address,
        label: "Delivery Address",
        validate: (data: string = "") => {
            if (data.length < 1) return "Please enter an address";
            if (data.trim().length < 1) return "Please enter a valid address";
            if (data.length < 5) return "Address too short";
            return null;
        },
        inputType: "textarea",
        analyticsEvent: "typeMessage",
        params: {
            rows: 3,
            placeholder:
                "House Number,\nBuilding/Street Name,\nCity, State\nPinCode",
        },
        htmlId: "userInfo-address",
        mandatory: () => true,
    },
    [EXTRA_FIELD.message]: {
        id: EXTRA_FIELD.message,
        label: "Message",
        validate: (data: string = "") => {
            if (data.length < 1) return "Please enter a message";
            if (data.trim().length < 1) return "Please enter a valid message";
            return null;
        },
        inputType: "textarea",
        analyticsEvent: "typeAddress",
        params: {
            rows: 3,
        },
        htmlId: "userInfo-message",
        mandatory: () => true,
    },
    [EXTRA_FIELD.gst_info_gst_number]: {
        id: EXTRA_FIELD.gst_info_gst_number,
        label: "GST Number",
        inputType: "text",
        validate: (
            data: string = "",
            extraData: { [key: string]: any } = {}
        ) => {
            if (extraData?.isGstChecked && data.trim().length < 1)
                return "Please enter a valid GST Number";
            return null;
        },
        formatInput: [INPUT_FORMATTING_KEYS.CAPITALISE],
        analyticsEvent: "typeGstInfoGstNumber",
        htmlId: "userInfo-gstInfoGstNumber",
        mandatory: () => true,
    },
    [EXTRA_FIELD.gst_info_gst_name]: {
        id: EXTRA_FIELD.gst_info_gst_name,
        label: "Registered Company Name",
        inputType: "text",
        validate: (
            data: string = "",
            extraData: { [key: string]: any } = {}
        ) => {
            if (extraData?.isGstChecked && data.trim().length < 1)
                return "Please enter Registered Company Name";
            return null;
        },
        analyticsEvent: "typeGstInfoName",
        htmlId: "userInfo-gstInfoName",
        mandatory: () => true,
    },
    [EXTRA_FIELD.billing_state]: {
        id: EXTRA_FIELD.billing_state,
        label: "State",
        inputType: "select",
        validate: (
            data: string = "",
            extraData: { [key: string]: any } = {}
        ) => {
            if (!extraData?.billingStateNonMandatory && data.trim().length < 1)
                return "Please select a state";
            return null;
        },
        analyticsEvent: "typeBillingState",
        htmlId: "userInfo-billingState",
        data: statesAndUtsOfIndia.map(state => ({
            label: state.name,
            value: state.name,
        })),

        mandatory: (extraData: { [key: string]: any } = {}) =>
            // Mandatory if the following is true:
            //  hostData.billing_state_non_mandatory` is false (which it always is false for merchandise)
            !extraData?.billingStateNonMandatory,
    },
    [EXTRA_FIELD.gst_info_address]: {
        id: EXTRA_FIELD.gst_info_address,
        label: "Company Address",
        inputType: "text",
        validate: (
            data: string = "",
            extraData: { [key: string]: any } = {}
        ) => {
            if (extraData?.isGstChecked && data.trim().length < 1)
                return "Please enter Registered Company Address";
            return null;
        },
        analyticsEvent: "typeGstInfoAddress",
        htmlId: "userInfo-gstInfoAddress",
        mandatory: () => true,
    },
    [EXTRA_FIELD.gst_info_pincode]: {
        id: EXTRA_FIELD.gst_info_pincode,
        label: "Company Pincode",
        inputType: "text",
        validate: (
            data: string = "",
            extraData: { [key: string]: any } = {}
        ) => {
            if (extraData?.isGstChecked && data.trim().length < 1)
                return "Please enter Registered Company Pincode";
            return null;
        },
        analyticsEvent: "typeGstInfoCompanyPincode",
        htmlId: "userInfo-gstInfoCompanyPincode",
        mandatory: () => true,
    },
    // For international creators, an option to pay via card(via Stripe) v/s Paypal(via Razorpay) is given
    [EXTRA_FIELD.payment_method]: {
        id: EXTRA_FIELD.payment_method,
        label: "Select Payment Method",
        inputType: "select",
        validate: () => null,
        analyticsEvent: "typePaymentMethod",
        htmlId: "userInfo-paymentMethod",
        data: ["Card", "Paypal"].map(v => ({
            label: v,
            value: v,
        })),
        initialValue: "Card",
        mandatory: () => false,
        // now stripe will be selected for all non INR payments
    },
    [EXTRA_FIELD.stripe_address]: {
        id: EXTRA_FIELD.stripe_address,
        label: "Street Address",
        inputType: "textarea",
        validate: (data: string = "") =>
            data.trim().length > 0 ? null : "Please enter street address",
        analyticsEvent: "typeAddress",
        htmlId: "userInfo-stripeAddress",
        mandatory: () => true,
    },
    [EXTRA_FIELD.city]: {
        id: EXTRA_FIELD.city,
        label: "Town/City",
        inputType: "text",
        validate: (data: string = "") =>
            data.trim().length > 0 ? null : "Please enter city",
        analyticsEvent: "typeCity",
        htmlId: "userInfo-city",
        mandatory: () => true,
    },
    [EXTRA_FIELD.state]: {
        id: EXTRA_FIELD.state,
        label: "State",
        inputType: "text",
        validate: (data: string = "") =>
            data.trim().length > 0 ? null : "Please enter state",
        analyticsEvent: "typeState",
        htmlId: "userInfo-state",
        mandatory: () => true,
    },
    [EXTRA_FIELD.postal_code]: {
        id: EXTRA_FIELD.postal_code,
        label: "Postal Code",
        inputType: "text",
        validate: (data: string = "") =>
            data.trim().length > 0 ? null : "Please enter postal code",
        analyticsEvent: "typePostalCode",
        htmlId: "userInfo-postalCode",
        mandatory: () => true,
    },
};

// Extra validation, primary to check for duplicate phone number/email for multiple ticketing
// This will contain data specific validation, which is why it needs the following two props
export const EXTRA_VALIDATION = (
    formDetailsCollection: IUser.IStore["formDetails"],
    formKey: string
) => ({
    /*
    .filter(([k]) => isUserDetailFormKey(k))
    reson for using this condition:
    formDetails reducer can have multiple forms stored
    we need to check only those which are UserDetailsForm
    */
    [FORM_FIELDS.email]: (data: string): string | null =>
        Object.entries(formDetailsCollection)
            .filter(([k]) => isUserDetailFormKey(k))
            .some(
                ([k, v]) =>
                    k < formKey && v?.[FORM_FIELDS.email]?.value === data
            )
            ? "Email has already been entered"
            : null,

    [FORM_FIELDS.phone]: (data: string): string | null =>
        Object.entries(formDetailsCollection)
            .filter(([k]) => isUserDetailFormKey(k))
            .some(
                ([k, v]) =>
                    k < formKey && v?.[FORM_FIELDS.phone]?.value === data
            )
            ? "Phone number has already been entered"
            : null,

    [EXTRA_FIELD.gst_info_gst_number]: (
        data: string,
        extraData: { [key: string]: any } = {}
    ): string | null =>
        extraData?.isGstChecked &&
        Object.entries(formDetailsCollection)
            .filter(([k]) => isUserDetailFormKey(k))
            .some(
                ([k, v]) =>
                    k < formKey &&
                    v?.[EXTRA_FIELD.gst_info_gst_number]?.value === data
            )
            ? "GST Number has already been entered"
            : null,

    [EXTRA_FIELD.gst_info_gst_name]: (
        data: string,
        extraData: { [key: string]: any } = {}
    ): string | null =>
        extraData?.isGstChecked &&
        Object.entries(formDetailsCollection)
            .filter(([k]) => isUserDetailFormKey(k))
            .some(
                ([k, v]) =>
                    k < formKey &&
                    v?.[EXTRA_FIELD.gst_info_gst_name]?.value === data
            )
            ? "Registered Company Name has already been entered"
            : null,
    [EXTRA_FIELD.gst_info_address]: (
        data: string,
        extraData: { [key: string]: any } = {}
    ): string | null =>
        extraData?.isGstChecked &&
        Object.entries(formDetailsCollection)
            .filter(([k]) => isUserDetailFormKey(k))
            .some(
                ([k, v]) =>
                    k < formKey &&
                    v?.[EXTRA_FIELD.gst_info_address]?.value === data
            )
            ? "Registered Company Address has already been entered"
            : null,
    [EXTRA_FIELD.gst_info_pincode]: (
        data: string,
        extraData: { [key: string]: any } = {}
    ): string | null =>
        extraData?.isGstChecked && !checkIsValidIndianPincode(data)
            ? "Please enter valid Pincode"
            : null,
});

export const ALLOWED_FORM_FIELDS_FOR_DIRECT_BOOKING_LIST = new Set([
    FORM_FIELDS.name,
    FORM_FIELDS.email,
    FORM_FIELDS.phone,
    FORM_FIELDS.countryCode,
    FORM_FIELDS.country,
]);
