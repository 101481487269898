import React, { useEffect } from "react";
import { useRouter } from "next/router";
import firebase from "firebase/app";
import "firebase/messaging";
import { CustomCode } from "@Modules/customCode/CustomCode";
import Modal from "@Modals/root";
import {
    MODAL_EXCLUDE,
    useFetchStatus,
    API_ACTION_TYPES,
    firebaseCloudMessaging,
    ApiCall,
    useUserLogin,
    useData,
    getUserAccessToken,
} from "@Utils";
import { isBrowser } from "repoV2/utils/common/render/window";
import { useIsMounted } from "repoV2/utils/common/render/mount";
import { CUSTOM_CODES_POSITION } from "repoV2/constants/customCodes/customCodes.constants";
import { useStoreUserIPDetailsForAnalytics } from "repoV2/utils/common/analytics/userIp";
import {
    NEXT_ROUTE_PATHNAMES,
    REPO_V2_APP_ROUTE_KEYS,
} from "repoV2/constants/urls&routing/routing";
import { CALL_API_METHODS } from "repoV2/constants/apis";
import ToastPopup from "repoV2/components/common/Toast";
import ToastProvider from "repoV2/components/common/Toast/ToastProvider";
import { LOG_IN_REQUIRED_ROUTE_PATHNAMES } from "repoV2/constants/user&auth&login/login&logout";
import { useDisableInspect } from "repoV2/features/Common/utils/useDisableInspect";
import { useAffiliateMarketing } from "repoV2/features/AffiliateMarketing/utils/useAffiliateMarketing";
import { LazyLoadedMSClarity } from "repoV2/features/Common/Analytics/modules/MSClarity/modules/LazyLoadedMSClarity";
import { ContactWidget } from "..";
import { IRootComponent } from "./IRootComponent";
import PaymentGateways from "../PaymentGateways";
import { AppCtxContextProvider } from "../AppCtxContext";

/** Created this root component here so that all the middleware can be added here instead of cramming it all in _app/index.tsx
 *  TODO:
 *  - Move the canRender and isMounted logic here instead of individual pages
 *  - Common host name resolution/handling here instead of all individual pages
 */

const initiateFCM = async (successCallback: () => void) => {
    if (isBrowser()) {
        try {
            const fcmToken = await firebaseCloudMessaging.init();
            if (fcmToken) {
                const jwtToken = getUserAccessToken();
                if (jwtToken) {
                    ApiCall({
                        url: "users/customer/tokens/update",
                        method: CALL_API_METHODS.POST,
                        payload: { fcm_token: fcmToken },
                        headers: {
                            "jwt-token": jwtToken,
                        },
                    });
                }
                const messaging = firebase.messaging();
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                messaging.onMessage(message => {
                    successCallback();
                });
            }
        } catch (error) {
            console.log("initiateFCM error: ", error);
        }
    }
};

const RootComponent = ({ children, ctx }: IRootComponent.IProps) => {
    const router = useRouter();
    const isMounted = useIsMounted();
    const { addAffiliateDetails } = useAffiliateMarketing();

    const { pathname = "", route = "" } = router || {};
    const { isLoggedIn } = useUserLogin();

    const hostFetchStatus = useFetchStatus(API_ACTION_TYPES.FETCH_HOST);

    const isHostDataFetched: boolean = hostFetchStatus === "FETCHED";

    const renderContactWidget: boolean = isHostDataFetched;

    const renderModal: boolean = !MODAL_EXCLUDE.includes(route);

    const { setData: setNewFirebaseNotificationReceived } = useData(
        "newFirebaseNotificationReceived"
    );

    // Calling this custom hook to store user ip details once he lands on the page
    useStoreUserIPDetailsForAnalytics();

    useEffect(() => {
        // Firebase
        if (
            isLoggedIn &&
            // add routes here that require end user to be logged in
            LOG_IN_REQUIRED_ROUTE_PATHNAMES.includes(pathname)
        ) {
            initiateFCM(() => {
                setNewFirebaseNotificationReceived(true);
            });
        }
    }, []);

    useEffect(() => {
        // store affiliate uuid and hit view analytics api whenever a new customer opens hostsite's any page
        addAffiliateDetails();
    }, []);

    useDisableInspect();

    return (
        <AppCtxContextProvider ctx={ctx}>
            <ToastProvider>
                <CustomCode position={CUSTOM_CODES_POSITION.BODY_START} />
                {children}

                {/* to exclude old code from the paths that are defined in repoV2 */}
                {!Object.values(REPO_V2_APP_ROUTE_KEYS).some(
                    key => NEXT_ROUTE_PATHNAMES[key] === pathname
                ) && (
                    <>
                        {/* @dev this section is rendered on mount to avoid the double re-rendering of the eventpage */}
                        {isMounted && renderContactWidget ? (
                            <ContactWidget />
                        ) : null}

                        {/* 
                        Modal can be SSR'd
                        why:
                            - only UserDetails Modal is SSR'd
                            - and it is not dependent upon screen size media query hook
                            - so it renders fine even on SSR
                         */}
                        {renderModal ? <Modal /> : null}

                        {/* @dev this section is rendered on mount to avoid the double re-rendering of the eventpage */}
                        {isMounted && <PaymentGateways />}
                    </>
                )}
                {isMounted && <ToastPopup />}
                <CustomCode position={CUSTOM_CODES_POSITION.BODY_END} />
            </ToastProvider>
            <LazyLoadedMSClarity />
        </AppCtxContextProvider>
    );
};

export default RootComponent;
