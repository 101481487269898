import { IHost } from "@Interfaces";

export const planListingFormat = (
    planData: IHost.IPlan,
    listingTypeTitle: string | null = null
): IHost.IPlanListingData => {
    const returnPayload = {
        uuid: planData.parent_listing_uuid,
        title: planData.title,
        cover_image: planData.cover_image,
        plan_uuid: planData.uuid,
        currency: planData?.currency,
        short_description: planData.short_description,
        updated_price: Number(planData.updated_price),
        type: planData.plan_type,
        keywords: planData.tag,
        listingTypeTitle,
        isPlan: true,
        url_slug: planData?.url_slug,
    };

    return returnPayload;
};

/**  The listing and plan arrays can be of any interface. This 
 function just arranges them as per the `prependPlans` parameter */
export const handlePrependPlans = (
    listings: Array<any>,
    plans: Array<any>,
    prependPlans?: boolean
) => [
    ...(prependPlans ? plans : []),
    ...listings,
    ...(prependPlans ? [] : plans),
];
