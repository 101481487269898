import dynamic from "next/dynamic";

export const NavbarElementary = dynamic(() => import("./NavbarElementary"));
export const NavbarClassic = dynamic(() => import("./NavbarClassic"));
export const NavbarUnfold = dynamic(() => import("./NavbarUnfold"));
export const NavbarLinktemp = dynamic(() => import("./NavbarLinktemp"));
export const NavbarYoga = dynamic(() => import("./NavbarYoga"));
export const NavbarEduverse = dynamic(() => import("./NavbarEduverse"));
export const NavbarGrowth = dynamic(() => import("./NavbarGrowth"));
export const NavbarUnlock = dynamic(() => import("./NavbarUnlock"));
