import { logoutUser as _logoutUser } from "repoV2/features/Common/modules/Auth&Login&User/Auth&Login&User.utils";
import { AUTH_STATUS_CODES } from "../../constants/user&auth&login/authCodes";

/**
 * @deprecated - use the one in repoV2/features/Common/modules/Auth&Login&User/Auth&Login&User.utils.ts
 */
export const logoutUser = _logoutUser;

/**
 * Function to check if we should refetch the auth/access token using the already existing refresh token
 * and dont need to redirect user to login page.
 */
export const shouldRelogin = (status: number) =>
    [
        AUTH_STATUS_CODES.ACCESS_TOKEN_MISSING,
        AUTH_STATUS_CODES.INVALID_JWT_TOKEN,
        AUTH_STATUS_CODES.ACCESS_TOKEN_EXPIRED,
    ].includes(status);

/**
 * Function to check if we should take the user to login page.
 * When refresh token expires OR the user is unknown, we should refetch the latest/correct refresh token.
 */
export const shouldLogout = (status: number) =>
    [
        AUTH_STATUS_CODES.UNKNOWN_USER,
        AUTH_STATUS_CODES.REFRESH_TOKEN_EXPIRED,
    ].includes(status);

/**
 * Function to check if abort/cancel signal (Abort Controller) should be passed to api calls.
 * In case response status is any of AUTH_STATUS_CODES.ACCESS_TOKEN_MISSING,
 * AUTH_STATUS_CODES.INVALID_JWT_TOKEN, AUTH_STATUS_CODES.ACCESS_TOKEN_EXPIRED,
 * AUTH_STATUS_CODES.UNKNOWN_USER, AUTH_STATUS_CODES.REFRESH_TOKEN_EXPIRED,
 * we need to fetch latest tokens and call the apis behind authentication again.
 */
export const shouldAbortAuthenticatedApiCalls = (status: number) =>
    [
        AUTH_STATUS_CODES.ACCESS_TOKEN_MISSING,
        AUTH_STATUS_CODES.INVALID_JWT_TOKEN,
        AUTH_STATUS_CODES.ACCESS_TOKEN_EXPIRED,
        AUTH_STATUS_CODES.UNKNOWN_USER,
        AUTH_STATUS_CODES.REFRESH_TOKEN_EXPIRED,
    ].includes(status);
