import { IHost } from "@Interfaces";

export const processCustomQuestions = (
    questions: Array<IHost.IQuestion>,
    answers: { [uuid: string]: string | string[] },
    thumbnails: { [uuid: string]: string } = {}
) => {
    return questions.map(ques => {
        const quesId = ques.uuid;
        const answer = answers[quesId];
        return {
            ques: quesId,
            ans: Array.isArray(answer)
                ? answer?.map(val => val.trim())
                : answer?.trim(),
            thumbnail: thumbnails?.[quesId],
        };
    });
};
