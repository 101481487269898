// This file is to store the links to media and other static assets that need to be used
import { DEFAULT_LISTING_COVER_IMG_SRC } from "repoV2/utils/common/assets/assets";
import { QuoteBeginIconSvg } from "./QuoteBeginIconSvg";
import { DotMatrixIconSvg } from "./DotMatrixIconSvg";
import { CloseIconSvg } from "./CloseIconSvg";
import { ImagePreviewIconSvg } from "./ImagePreviewIconSvg";
import { EmojiRating1Svg } from "./EmojiRating1Svg";
import { EmojiRating2Svg } from "./EmojiRating2Svg";
import { EmojiRating3Svg } from "./EmojiRating3Svg";
import { EmojiRating4Svg } from "./EmojiRating4Svg";
import { EmojiRating5Svg } from "./EmojiRating5Svg";
import { CurvedArrowSvg } from "./CurvedArrowSvg";

export { DiscountPercentSvg } from "./DiscountPercentSvg";
export {
    QuoteBeginIconSvg,
    DotMatrixIconSvg,
    CloseIconSvg,
    ImagePreviewIconSvg,
    CurvedArrowSvg,
};
export const MEDIA = {
    DEFAULT: {
        CARD_IMAGE: DEFAULT_LISTING_COVER_IMG_SRC,
        CARD_HEART: "/static/images/heartcircle.png",
        CARD_SHARE: "/static/images/ShareIcon.png",
        VIDEO_THUMBNAIL: "/static/images/video-play-icon.png",
        EXLY_LOGO: "/static/images/exly-logo.png",
        PLACEHOLDER_IMAGE: "/static/images/default-image-placeholder.png",
        BACKGROUND: {
            LINKTEMP: "/static/images/linktempTemplate/default_bg.webp",
            YOGA: "https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/yoga_cover.webp?alt=media&token=a6e6a0f4-c4fc-4136-a7e3-54f47887a703",
            EDUVERSE: `https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/wp7911176-online-school-wallpapers.jpg?alt=media&token=b8c15d43-fb57-4aee-bd71-ca10ddd32994`,
            GROWTH: `https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/growth_cover.jpg?alt=media&token=c2ac9daa-38d1-4638-9ec8-2375d97cf881`,
            UNLOCK: {
                COVER: "https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/exly%2Fe%2Fpng%3Bbasj3slAa1q?alt=media&token=38613863-8ebe-4d05-bc5d-29f5b47ba45c",
                INTRO_VIDEO:
                    "https://images.unsplash.com/photo-1515204230490-1ad00b70ed3d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80",
            },
        },
        YOGA_FEATURES:
            "https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/yoga_features_image.png?alt=media&token=b6333552-ee59-45ab-a937-573b6be2a176",
    },
    ICONS: {
        LOCATION: "/static/images/call_platforms/location.svg",
        CALL_PLATFORMS: {
            google_duo: "/static/images/call_platforms/google_duo.svg",
            google_meet: "/static/images/call_platforms/google_meet.svg",
            ms_teams: "/static/images/call_platforms/ms_teams.svg",
            skype: "/static/images/call_platforms/skype.svg",
            webex: "/static/images/call_platforms/webex.svg",
            zoom: "/static/images/call_platforms/zoom.svg",
            phone_call: "/static/images/call_platforms/phone.svg",
            whatsapp: "/static/images/call_platforms/whatsapp.svg",
            telegram: "/static/images/call_platforms/telegram.svg",
        },
        FAVICON: "/static/images/favicon.ico",
    },
};

export type ICallPlatformIds = keyof typeof MEDIA.ICONS.CALL_PLATFORMS;

export const RATING_EMOJI_ICON = {
    1: EmojiRating1Svg,
    2: EmojiRating2Svg,
    3: EmojiRating3Svg,
    4: EmojiRating4Svg,
    5: EmojiRating5Svg,
};

export const getRatingEmojiComponent = (rating: number) =>
    RATING_EMOJI_ICON[rating as keyof typeof RATING_EMOJI_ICON] ||
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ((props?: any) => null);

export const googleIcon: string = "/static/images/google.png";
export const googlePopupBlocked: string = "/static/images/popupBlocked.png";

/**
 * Standard Assets name to be followed <ASSET_NAME>_<TYPE / VALUE_INTENT>
 */
export const WHATSAPP_REDIRECTION_IMAGE_SRC: string =
    "/static/images/paymentStatusImages/whatsapp_redirection.png";

export const COURSE_CONTENT_ICONS = {
    BOOK: "/static/images/book.svg",
    PDF: "/static/images/pdf_file.svg",
    DOWNLOAD: "/static/images/download_file.svg",
    STAR_BADGE: "/static/images/star_badge.svg",
    MEDAL: "/static/images/medal.svg",
    CERTIFICATE: "/static/images/certificate.svg",
    CERTIFICATE_ROUNDED: "/static/images/certificate_rounded.svg",
    MEDIA: {
        VIDEO: "/static/images/mediaIcons/video.svg",
        EXAM: "/static/images/mediaIcons/exam.svg",
        TEXT: "/static/images/mediaIcons/text.svg",
        LIVE: "/static/images/mediaIcons/live.svg",
        LIST: "/static/images/mediaIcons/list.svg",
        LINK: "/static/images/mediaIcons/link.svg",
        EMBED: "/static/images/mediaIcons/embed.svg",
        IMAGE: "/static/images/mediaIcons/image.svg",
        FILE: "/static/images/mediaIcons/file.svg",
    },
    LOCK_CLOCK: "/static/images/lock_clock.svg",
};

export const RECORDED_CONTENT_ICONS = {
    EXAM: "/static/images/recordedContent/exam.svg",
    FORM: "/static/images/recordedContent/form.svg",
    BOOK: "/static/images/recordedContent/book.svg",
    STAR_BADGE: "/static/images/recordedContent/star_badge.svg",
    FAILED: "/static/images/recordedContent/failed.svg",
    UPLOAD: "/static/images/recordedContent/upload.svg",
    EDIT: "/static/images/edit_note.svg",
    LOCKED_LESSON: "/static/images/recordedContent/locked_lesson.svg",
    AWARD_STAR: "/static/images/recordedContent/award_star.svg",
    STAR_BADGE_GRADIENT:
        "/static/images/recordedContent/star_badge_gradient.svg",
    PENDING: "/static/images/recordedContent/auto_mode.svg",
    SUBMITTED: "/static/images/recordedContent/submitted.svg",
};

export const REFUND_ICON_SRC: string =
    "/static/images/trackOrder/refundIcon.svg";

export const MEDIA_MENTION_PLACEHOLDER_IMAGE =
    "/static/images/media_placeholder_image.png";
