import React, { useState } from "react";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import { Button } from "@Modules/common";
import {
    BUTTON_DESIGNS,
    emailRegex,
    fullNameRegex,
    isEmpty,
    createAction,
    ACTION_TYPES,
    API_ACTION_TYPES,
    isBrowser,
    setVisitorDetails,
} from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { useDispatch } from "react-redux";
import { TextInput } from "@Modules/common/Form";
import { Popup, usePopup } from "..";
import { IBlogVisitorPopup } from "./IBlogVisitorPopup";
import styles from "./BlogVisitorPopup.module.scss";

export const BlogVisitorPopup = ({
    closePopup,
    classNames: classNamesProp,
}: IBlogVisitorPopup.IProps): JSX.Element => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e?.target?.value?.trim();
        setEmail(value);
        if (isEmpty(value)) {
            setEmailError("Required");
        } else if (!emailRegex.test(value)) {
            setEmailError("Invalid Email ID");
        } else {
            setEmailError("");
        }
    };

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e?.target?.value?.trim();
        setName(value);
        if (isEmpty(value)) {
            setNameError("Required");
        } else if (!fullNameRegex.test(value.trim())) {
            setNameError("Please enter a valid name");
        } else {
            setNameError("");
        }
    };

    const { closePopupWithAnimation, closing, animationDuration } = usePopup({
        closePopup,
    });

    const dispatch = useDispatch();

    const formHasError =
        !isEmpty(emailError) ||
        isEmpty(email) ||
        !isEmpty(nameError) ||
        isEmpty(name);

    const postVisitorDetails = (e: React.FormEvent) => {
        // following line is added because
        // not adding e.preventDefault(); causes XHR to get cancelled in iOS
        // https://stackoverflow.com/questions/7577275/jquery-ajax-requests-are-getting-cancelled-without-being-sent
        e.preventDefault();

        if (formHasError) {
            alert("Please provide valid details!");
            return;
        }
        dispatch(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.UPDATE_VISITOR_DETAILS,
                payload: { email, name: name.trim() },
                successCallback: ({ response }) => {
                    closePopupWithAnimation();
                    setVisitorDetails(response?.data || {});
                },
                errorCallback: () => {
                    alert("An error has occurred. Please try again.");
                },
            })
        );
    };

    return (
        <Popup
            closePopup={closePopupWithAnimation}
            containerClass={styles.container}
            closing={closing}
            animationDuration={animationDuration}
            classNames={classNamesProp}
        >
            <>
                <div className={classNames(styles.header, styles.popupSection)}>
                    <div className={styles.boldText}>
                        Enter your username and email id
                    </div>
                    <Button
                        className={classNames(
                            styles.closeButton,
                            styles.plainButton
                        )}
                        design={BUTTON_DESIGNS.PLAIN_TEXT}
                        content={<>&#10006;</>}
                        onClick={closePopupWithAnimation}
                    />
                </div>
                <div className={styles.popupSection}>
                    Your username is how other community members will see you.
                    This name will be used to credit you for things you share
                    {isBrowser() ? ` on ${window.location.host}` : ""}. What
                    should we call you?
                </div>
                <form onSubmit={postVisitorDetails}>
                    <div className={styles.popupSection}>
                        <div
                            className={classNames(
                                styles.inputLabel,
                                styles.boldText
                            )}
                        >
                            User name
                        </div>
                        <TextInput
                            name="name"
                            type="text"
                            className={styles.inputField}
                            onChange={onNameChange}
                            error={!isEmpty(nameError)}
                        />
                        <div className={styles.inputError}>{nameError}</div>
                        <div
                            className={classNames(
                                styles.inputLabel,
                                styles.boldText
                            )}
                        >
                            Email
                        </div>
                        <TextInput
                            name="email"
                            type="email"
                            className={styles.inputField}
                            onChange={onEmailChange}
                            error={!isEmpty(emailError)}
                        />
                        <div className={styles.inputError}>{emailError}</div>
                    </div>
                    <hr style={{ margin: 0 }} />
                    <div className={styles.popupSection}>
                        <ButtonV2
                            applyTheme
                            buttonType="submit"
                            disabled={formHasError}
                        >
                            Join
                        </ButtonV2>
                        <Button
                            design={BUTTON_DESIGNS.PLAIN_TEXT}
                            content="Cancel"
                            className={classNames(
                                styles.plainButton,
                                styles.cancelButton
                            )}
                            onClick={closePopupWithAnimation}
                        />
                    </div>
                </form>
            </>
        </Popup>
    );
};
