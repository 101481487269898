import { EXLY_BACKEND_API_HOST } from "repoV2/constants/apis";
import { getS3UploadUrl } from "../Common/modules/URLsNRouting/URLsNRouting.utils";

export const LISTING_TYPES = {
    APPOINTMENTS: 0,
    ONE_ON_ONE_CLASSES: 1,
    GROUP_CLASSES: 2,
    WORKSHOPS: 3,
    NO_SCHEDULE: 4,
    RECORDED_CONTENT: 5,
    MERCHANDISE: 6,
    ROLLING_CLASSES: 7,
    TELEGRAM: 8,
    WEBINAR: 9,
    WHATSAPP: 10,
    BRANDED_COMMUNITY: 11,
    PLANS: 9999, // Since plans are pseudo-listings and need to be treated like listings on host page
};
/**
 * differnt ways which we can give a discount\
 * PERCENTAGE - eg: 10%\
 * ABSOLUTE - eg: flat Rs 10 off
 */
export const DISCOUNT_OFF_TYPES = {
    PERCENTAGE: 1,
    ABSOLUTE: 2,
};

/**
 * Availability choice, for appointments, incase creater chose to allow slots booking in futire
 */
export const AVAILABILITY_CHOICE = {
    DEFAULT: 1,
    NOT_DECIDED: 4,
};

/**
 * GST status, used to include tax during calculation of the final price
 - `INCLUSIVE` - means the basePrice coming from backend is already
 *             including the creatorGST amount
 - `EXCLUSIVE` - means we need to add the creatorGST to the basePrice
 - `INAPPLICABLE` - means that GST is not applicable and we need to consider
 *                the creatorGST value to be 0 for calculation
 */
export const GST_STATUS = {
    INCLUSIVE: 1,
    EXCLUSIVE: 2,
    INAPPLICABLE: 3,
};

export const DEFAULT_GST_STATUS = GST_STATUS.INAPPLICABLE;

/**
For customising in what format and how much of the billing information is to be shown
- `DEFAULT_SHOW_ALL` is the default behaviour.
    Creator GST inclusive base price will be shown if Creator GST is enabled.
- `SEPARATE_CGST` is just a slight variation on `DEFAULT_SHOW_ALL` where the
    CGST will be shown separately from the base price will be shown on the
    event card on host page
- `PRETEND_CGST_INAPPLICABLE` will not show any billing details (even the
    Review Details section will not be expandable). The user will only get to
    see the full amount at the payment gateway
 */
export const BILLING_DISPLAY_FORMAT = {
    DEFAULT_SHOW_ALL: 1,
    SEPARATE_CGST: 2,
    PRETEND_CGST_INAPPLICABLE: 3,
};

export const BOOKING_PAYMENT_TYPE = {
    ONE_TIME_PAYMENT: 1,
    INITIAL_PART_PAYMENT: 2,
    PART_PAYMENT_INSTALLMENTS: 3,
};

/**
 * used in no schedule subscription
 */
export const COURSE_END_CHOICE = {
    WEEKS: 1,
    MONTHS: 2,
    DAYS: 3,
};

/**
 * default listing image used as the cover image
 */
export const default_listing_image = getS3UploadUrl(
    "webapp/payment_modes/default-listing-image.jpeg"
);

/**
 * denotes different types of offering/listing skus
 */
export const LISTING_STATUSES = {
    DRAFT: 0,
    LIVE: 1,
    SOLD_OUT: 2,
    EXPIRED: 3,
};

/**
 * For custom questions, can either be pre booking or post booking
 */
export const QUESTION_PLACEMENT = {
    BEFORE_BOOKING: 1,
    AFTER_BOOKING: 2,
};

/**
 * For upsell listings get
 */
export const UPSELL_TYPES = {
    ORDER_BUMPS: 1,
    THANK_YOU_PAGE: 2,
    UPSELL: 3,
};

/**
 * LISTING_ENUM: Used to keep a constant for different listing types. Used in post post analytics.
 */
export const LISTING_ENUM = {
    [LISTING_TYPES.APPOINTMENTS]: {
        ID: LISTING_TYPES.APPOINTMENTS, // eventData.type
        TITLE: "1 on 1 Appointments", // Title to show as the heading of a collection
        SHORT_TITLE: "Appointment", // Event name to shown in the event page
        SHOW_ADDRESS: true, // Whether this type of events needs to display address details
        SHARE_TEXT: "Join my session", // The text to show as the text in the whatsapp share link
    },
    [LISTING_TYPES.ONE_ON_ONE_CLASSES]: {
        ID: LISTING_TYPES.ONE_ON_ONE_CLASSES,
        TITLE: "1 on 1 Classes",
        SHORT_TITLE: "Class",
        SHOW_ADDRESS: true,
        SHARE_TEXT: "Join my class",
    },
    [LISTING_TYPES.GROUP_CLASSES]: {
        ID: LISTING_TYPES.GROUP_CLASSES,
        TITLE: "Group Classes",
        SHORT_TITLE: "Class",
        SHOW_ADDRESS: true,
        SHARE_TEXT: "Join my class",
    },
    [LISTING_TYPES.WORKSHOPS]: {
        ID: LISTING_TYPES.WORKSHOPS,
        TITLE: "Workshops",
        SHORT_TITLE: "Workshop",
        SHOW_ADDRESS: true,
        SHARE_TEXT: "Join my workshop",
    },
    [LISTING_TYPES.NO_SCHEDULE]: {
        ID: LISTING_TYPES.NO_SCHEDULE,
        TITLE: "Package",
        SHORT_TITLE: "Package",
        SHOW_ADDRESS: false,
        SHARE_TEXT: "Buy my package",
    },
    [LISTING_TYPES.RECORDED_CONTENT]: {
        ID: LISTING_TYPES.RECORDED_CONTENT,
        TITLE: "Recorded Content",
        SHORT_TITLE: "Content",
        SHOW_ADDRESS: false,
        SHARE_TEXT: "Buy my content",
    },
    [LISTING_TYPES.TELEGRAM]: {
        ID: LISTING_TYPES.TELEGRAM,
        TITLE: "Telegram Community",
        SHORT_TITLE: "Community",
        SHOW_ADDRESS: true,
        SHARE_TEXT: "Join my Telegram Community",
    },
    [LISTING_TYPES.MERCHANDISE]: {
        ID: LISTING_TYPES.MERCHANDISE,
        TITLE: "Products",
        SHORT_TITLE: "Product",
        SHOW_ADDRESS: false,
        SHARE_TEXT: "Buy my product",
    },
    [LISTING_TYPES.ROLLING_CLASSES]: {
        ID: LISTING_TYPES.ROLLING_CLASSES,
        TITLE: "Flexible Classes",
        SHORT_TITLE: "Class",
        SHOW_ADDRESS: true,
        SHARE_TEXT: "Join my class",
    },
    [LISTING_TYPES.BRANDED_COMMUNITY]: {
        ID: LISTING_TYPES.BRANDED_COMMUNITY,
        TITLE: "Community",
        SHORT_TITLE: "Community",
        SHOW_ADDRESS: true,
        SHARE_TEXT: "Join my community",
    },
    [LISTING_TYPES.WEBINAR]: {
        ID: LISTING_TYPES.WEBINAR,
        TITLE: "Webinars",
        SHORT_TITLE: "Webinar",
        SHOW_ADDRESS: true,
        SHARE_TEXT: "Join my Sessions",
    },
    [LISTING_TYPES.PLANS]: {
        ID: LISTING_TYPES.PLANS,
        TITLE: "Plans",
        SHORT_TITLE: "Plans",
        SHOW_ADDRESS: true,
        SHARE_TEXT: "Buy my plan",
    },
    [LISTING_TYPES.WHATSAPP]: {
        ID: LISTING_TYPES.WHATSAPP,
        TITLE: "Whatsapp",
        SHORT_TITLE: "community",
        SHOW_ADDRESS: true,
        SHARE_TEXT: "Join my community",
    },
};

export const listing_apis = {
    get_upsell_listings: `${EXLY_BACKEND_API_HOST}/host/upsell`,
    get_merchandise_variants: `${EXLY_BACKEND_API_HOST}/host/booking/variants`,
    get_shipping_details: `${EXLY_BACKEND_API_HOST}/users/shiprocket/pincode/check`,
};

// Log types for form responses, used to categorize logs created during form submission
export const FORM_LOG_TYPES = {
    GET_IN_TOUCH: 18,
    EXPRESSED_INTEREST: 19,
    EXIT_INTENT_WEBPAGE: 21,
    EXIT_INTENT_OFFERING: 42,
};
export const DEFAULT_LISTING_AVAILABILITY_CHOICE = AVAILABILITY_CHOICE.DEFAULT;
