import {
    APP_ROUTE_KEYS,
    NEXT_ROUTE_PATHNAMES,
} from "repoV2/constants/urls&routing/routing";
import { ANALYTICS_EVENTS } from "../../Analytics.constants";

export const MS_CLARITY_ROUTE_PATHNAMES = [
    NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.SALE],
    NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.CHECKOUT_LISTING],
    NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.THANKYOU_OPTIN],
    NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.THANKYOU_PURCHASE],
];

export const MS_CLARITY_KEY = process.env.NEXT_PUBLIC_MS_CLARITY_KEY;

export const MS_CLARITY_EVENT_TYPE = {
    EVENT: "event",
};

const MS_CLARITY_EVENTS = {
    CHECKOUT_PAY_CTA_CLICKED_EVENT_NAME: "checkoutPayCtaClicked",
    OFFER_REGISTER_CTA_CLICKED_EVENT_NAME: "offerCtaClicked",
};

export const MS_CLARITY_EVENTS_MAP = {
    [ANALYTICS_EVENTS.LEAD]:
        MS_CLARITY_EVENTS.CHECKOUT_PAY_CTA_CLICKED_EVENT_NAME,
    [ANALYTICS_EVENTS.ADD_PAYMENT_INFO]:
        MS_CLARITY_EVENTS.CHECKOUT_PAY_CTA_CLICKED_EVENT_NAME,
    [ANALYTICS_EVENTS.SALE_PAGE_REGISTER_CTA_CLICK]:
        MS_CLARITY_EVENTS.OFFER_REGISTER_CTA_CLICKED_EVENT_NAME,
};
