import { ALLOWED_EMAIL_TOP_LEVEL_DOMAINS } from "repoV2/utils/urls&routing/domain";
import { checkIsStringEmpty } from "../dataTypes/string";

export const getEmailHasValidTopLevelDomain = (email: string) =>
    ALLOWED_EMAIL_TOP_LEVEL_DOMAINS.find(a =>
        email.toLocaleLowerCase().endsWith(a)
    );

/**
 * @function checks if an HTML string is empty or not.
 * @param {string} htmlString - The HTML string to check.
 * @description If the HTML string contains any of empty tags(eg: <img>) that can be visible for a viewer, the function returns false.
 * If not, the function strips the HTML string of all tags and whitespace characters, then checks if the resulting string is empty.
 * If the stripped string is empty returns true.
 * @returns {boolean} - Returns true if the HTML string is considered empty, and false otherwise.
 */
export const checkIsEmptyHtmlString = (htmlString: string) => {
    if (checkIsStringEmpty(htmlString)) return true;
    const visibleEmptyHTMLTagsList = ["img"]; // array of elemets tag which wants to save
    // Check if the HTML string contains an tag
    const isContains = visibleEmptyHTMLTagsList.some(element =>
        new RegExp(`<${element}[^>]*>`, "i").test(htmlString)
    );
    // If the string contains an tag, it's not empty
    if (isContains) {
        return false;
    }
    // Remove all HTML tags, spaces, line breaks, etc.
    const strippedString = htmlString
        .replace(/<[^>]*>/g, "")
        .replace(/\s|&nbsp;/g, "");
    // Check if the resulting string is empty
    return checkIsStringEmpty(strippedString);
};
