import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { isEmpty } from "@Utils";
import { ICustomQuestions } from "../ICustomQuestions";
import styles from "../../../userDetails.module.scss";
import { CUSTOM_QUESTION_TYPES } from "../../../../../../../../repoV2/utils/getInTouchForm/getInTouchForm.constants";

const ShortTextQuestion = ({
    htmlId,
    item,
    showInputError,
    userInput,
    answers,
    setAnswers,
    inputType = "",
    editingDisabled,
}: ICustomQuestions.ITextQuestionProps) => {
    const castedUserInput = (userInput as string) || "";

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAnswers({ ...answers, [e.target.name]: e.target.value });
    };

    // https://eslint.org/docs/latest/rules/no-param-reassign
    const questionItem = item;

    if (!questionItem.validations) {
        questionItem.validations = {
            type: isEmpty(inputType) ? "text" : inputType,
        };
    } else if (!questionItem.validations.type) {
        questionItem.validations.type = isEmpty(inputType) ? "text" : inputType;
    }

    return (
        <div className={classNames(styles.fieldContainer, styles.flexCol)}>
            <input
                id={htmlId}
                className={classNames(
                    styles.formInput,
                    editingDisabled && styles.editingDisabled,
                    showInputError && isEmpty(castedUserInput)
                        ? styles.formInputError
                        : false
                )}
                name={questionItem.uuid}
                onChange={handleChange}
                value={castedUserInput}
                disabled={editingDisabled}
                {...questionItem.validations}
            />
            <div className={styles.fieldError}>
                {showInputError &&
                CUSTOM_QUESTION_TYPES[questionItem.type].validateAnswer(
                    castedUserInput
                ) ? (
                    "Please enter a valid answer"
                ) : (
                    <>&nbsp;</>
                )}
            </div>
        </div>
    );
};

export default ShortTextQuestion;
