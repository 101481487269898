import { getQueryParam } from "repoV2/utils/urls&routing/urlParams";
import { URL_SEARCH_PARAMS } from "repoV2/constants/urls&routing/urlParams";
import { checkIsStringEmpty } from "repoV2/utils/common/dataTypes/string";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "repoV2/utils/common/storage/getterAndSetters";
import { LOCAL_STORAGE_KEYS } from "repoV2/features/Common/modules/Storage/Storage.constants";
import { logError } from "repoV2/utils/common/error/error";
import { TRACKING_TYPES } from "repoV2/features/Common/Analytics/Analytics.constants";
import { callPostExlyAnalytics } from "repoV2/utils/common/analytics/exlyPostAnalytics/exlyPostAnalytics";
import { isRequestSuccessful } from "repoV2/utils/common/api/api";
import { useRouter } from "next/router";

export const useAffiliateMarketing = () => {
    const router = useRouter();

    const addAffiliateDetails = async () => {
        try {
            const offeringUuid =
                router.query?.eventId ?? router.query?.listingSlug;
            const affiliateUuid = getQueryParam(
                URL_SEARCH_PARAMS.AFFILIATE_UUID
            );

            if (checkIsStringEmpty(affiliateUuid)) return;

            // if same affiliateUuid already existing in loacl storage then not to add view count,
            // if new affiliateUuid then only add view count and store the new affiliateUuid in local storage
            const storedAffiliateUuid = getLocalStorageItem(
                LOCAL_STORAGE_KEYS.AFFILIATE_UUID
            );
            if (storedAffiliateUuid === affiliateUuid) return;

            if (affiliateUuid) {
                const response = await callPostExlyAnalytics({
                    screen: "mypageScreen",
                    funnel: "mypage",
                    flow: "mypage",
                    event_name: "completelyLoaded",
                    action: "view",
                    tracking_type: TRACKING_TYPES.AFFILAITES_TRACKING,
                    metadata: { affiliate_uuid: affiliateUuid },
                    event_meta: offeringUuid,
                });

                if (response && isRequestSuccessful(response.status)) {
                    setLocalStorageItem(
                        LOCAL_STORAGE_KEYS.AFFILIATE_UUID,
                        affiliateUuid
                    );
                }
            }
        } catch (error) {
            logError({
                error,
                when: "addAffiliateDetails",
                occuredAt:
                    "repoV2/features/AffiliateMarketing/utils/AffiliateMarketing.utils.ts",
            });
        }
    };

    return {
        addAffiliateDetails,
    };
};
