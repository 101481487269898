/* eslint-disable react/button-has-type */
import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import styles from "./Button.module.scss";
import { IButtonV2 } from "./IButtonV2";

/**
 * @deprecated - use `repoV2/components/common/Button/index.tsx`
 *  Notes
 *  - extraProps can have following keys
 *      style?: {[k: string]: string};
 */
export const ButtonV2 = ({
    children,
    disabled,
    design,
    applyTheme,
    className,
    ...extraProps
}: IButtonV2) => {
    const baseClassName = classNames(
        styles.button,
        disabled && styles.disabledButton,
        className,
        // apply theme only if custom design is not used
        design !== "custom" && applyTheme && styles.applyTheme
    );

    let designClassName = "";

    switch (design) {
        case "custom":
            break;
        case "pill":
            designClassName = styles.pillButton;
            break;
        case "plain":
            designClassName = styles.plainButton;
            break;
        case "rounded corners":
        default:
            designClassName = styles.roundedCornersButton;
            break;
    }

    return (
        <button
            disabled={disabled}
            className={classNames(baseClassName, designClassName)}
            {...extraProps}
        >
            {children}
        </button>
    );
};

ButtonV2.defaultProps = {
    disabled: false,
    design: "rounded corners",
    applyTheme: false,
};
