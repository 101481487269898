import { HTTP_STATUS_SUCCESS_CODES } from "../../../constants/apis";

/**
 * Direct process.env.NEXT_PUBLIC_API_URL is used instead of using EXLY_BACKEND_API_HOST as value of the
 * variable was coming as undefined for usage in server.ts files this ensured that api is hit to correct url.
 */
export function prependBackendAPIHost(url: string) {
    return `${process.env.NEXT_PUBLIC_API_URL}/${url}`;
}
/**
 * Checks if an HTTP request is successful based on the status code.
 *
 * @param {number} statusCode - The HTTP status code to check.
 * @returns {boolean} True if the status code indicates success, otherwise false.
 */
export function isRequestSuccessful(statusCode: number) {
    return Object.values(HTTP_STATUS_SUCCESS_CODES).includes(statusCode);
}
