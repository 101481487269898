import { useEffect } from "react";
import { NODE_ENV_IS_NOT_PRODUCTION } from "repoV2/constants/environment";

/**
 * Custom Hook to make inspect difficult for users.
 */

export const useDisableInspect = () => {
    useEffect(() => {
        const disableContextMenu = (e: MouseEvent) => {
            e.preventDefault();
        };

        const disableKeyDowns = (e: KeyboardEvent) => {
            // Disable F12
            if (e.keyCode === 123) e.preventDefault();

            // Disabling ctrl+shift+I and ctrl+shift+J for windows
            if (
                e.ctrlKey &&
                e.shiftKey &&
                (e.keyCode === 73 || e.keyCode === 74)
            )
                e.preventDefault();
            // Disabling meta+option+I and meta+option+J for mac
            if (e.metaKey && e.altKey && (e.keyCode === 73 || e.keyCode === 74))
                e.preventDefault();
        };

        if (!NODE_ENV_IS_NOT_PRODUCTION) {
            document.addEventListener("contextmenu", disableContextMenu);
            document.addEventListener("keydown", disableKeyDowns);
        }

        return () => {
            if (!NODE_ENV_IS_NOT_PRODUCTION) {
                document.removeEventListener("contextmenu", disableContextMenu);
                document.removeEventListener("keydown", disableKeyDowns);
            }
        };
    }, []);
};
