import { IHost } from "@Interfaces/redux/store/IHost";
import { IUser } from "@Interfaces/redux/store/IUser";
import {
    ISocialPlatformKey,
    SOCIAL_PLATFORMS,
} from "repoV2/utils/social/constants";
import { isBrowser as _isBrowser } from "repoV2/utils/common/render/window";
import { EVENT_TYPE } from "./constants/event";

/**
 * @deprecated use `isBrowser` in `repoV2/utils/common/render/window.ts`
 */
export const isBrowser = _isBrowser;

// TODO; Expand scope and add types
/**
 * @deprecated use checkIsStringEmpty or checkIsArrayEmpty or checkIsObjectEmpty
 * @param str
 * @description checks whether an enumerable object contains no values (no enumerable own-properties).
 * For strings and array-like objects, _.isEmpty() checks if the length property is 0
 * @return true if input is empty false otherwise
 */
export function isEmpty(str: any) {
    return (
        !str ||
        (str.constructor === String && str.length === 0) ||
        (Array.isArray(str) && str.length === 0) ||
        (str.constructor === Object && Object.keys(str).length === 0)
    );
}

/**
 * @param str
 * @return true if input `str` is empty or if it is empty after trimming(removing leading spaces)
 */
export function isEmptyStringPostTrimming(str: string) {
    return isEmpty(str) || isEmpty(str?.trim());
}

/**
 * @param str
 * @description This function takes a string as input and checks if the string is a valid URL, and false otherwise.
 * The regular expression matches the following patterns:
 *  - http or https: The protocol must be either http or https if present.
 *  - w+: The domain name can be any length and can contain any characters.
 *  - a-z0-9@:%._\\+~#?&//=: The path and query string can contain any characters except escape characters
 * @contraint Added check to limit input length to 1024 characters, regexMatch times out at 1500+
 * Google image URL length is ~800 characters so most valid URLS should come in the decided constraints
 * @return true if string is a valid URL false otherwise
 */
export function isValidURL(str: string) {
    if (isEmpty(str) || str.length > 1024) {
        return false;
    }

    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return pattern.test(str) || false;
}

export function isLastElement(arr: Array<any>, index: number) {
    return arr.length > 0 && index === arr.length - 1;
}

/**
 * @deprecated - function is quite complex
 */
// Created this function because there was a requirement to return a condition(bool or otherwise)
// BASED on the truthiness of another condition, all in an object. This was the short way of
// doing that without opening an inline function every time riddled with if else-if statements
export function chainShortCircuits<T = any>(
    chain: any[][], // TODO: Better TS declaration
    defaultValue: any = false
): T {
    // eslint-disable-next-line no-restricted-syntax
    for (const [checker, condition = true] of chain) {
        if (checker) {
            return condition as T;
        }
    }
    return defaultValue;
}

export const roundUp = (val: number, n = 2): number =>
    Math.round(val * 10 ** n) / 10 ** n; // Round up val with precision of n

// TODO: Test if this works fine everywhere it is being used before using it as the new version
// export const isEmpty = (str: any) =>
//     chainShortCircuits(
//         [
//             [str.constructor === Number, false],
//             [!str],
//             [str.constructor === String, str.length === 0],
//             [str.constructor === Array, str.length === 0],
//             [str.constructor === Object, Object.keys(str).length === 0],
//         ],
//         false
//     );

// Categorising event cards for host page
// TODO: Refactor during API v2.0 update to event collection structure
export const allEvents = (data: any) => {
    const allEventArray: any = [];
    for (let index = 0; index < data.length; index += 1) {
        const element = data[index];
        if (element[0].length !== 0) {
            allEventArray.push(element[0]);
        }
    }
    return allEventArray;
};

// Check whether form data is valid for the standard form data structure being used.
// TODO: Document the structure
export const isFormValid = (data: {
    [k: string]: IUser.IFormDataVal;
}): boolean =>
    Object.values(data)
        .filter(v => v)
        .map(v => !v?.error ?? true) // The form is valid only if the showError has been set to true AND there's no error
        .reduce((curr, accum) => curr && accum, true) ?? true;
// Checks for the existence of recorded content
export const checkRecordedContent = (
    listingData: IHost.IHostData["listings"]
): boolean =>
    (listingData || []).some(l => l.type === EVENT_TYPE.RECORDED_CONTENT);

export const getRandomKey = (
    keyLength: number = 7,
    baseLength: number = 36
): string => Math.random().toString(baseLength).substring(keyLength);

/**
 * a function to get the current number of items
 displayed in a carousel according to the device width
 * @param responsive object to be passed in multi carousel
 * @returns integer of items count
 */
export const getItemCountFromResponsive = (responsive: {
    [key: string]: { breakpoint: { max: number; min: number }; items: number };
}): number => {
    const currentDeviceWidth =
        typeof window !== "undefined" ? window.innerWidth : 4000;
    let noOfItems = 0;
    let maxNoOfItems = 0;
    const breakPoints = Object.values(responsive);
    for (let i = 0; i < breakPoints.length; i += 1) {
        if (
            breakPoints[i]?.breakpoint?.min <= currentDeviceWidth &&
            currentDeviceWidth <= breakPoints[i]?.breakpoint?.max
        ) {
            noOfItems = breakPoints[i]?.items;
            break;
        }
        maxNoOfItems =
            breakPoints[i]?.items > maxNoOfItems
                ? breakPoints[i]?.items
                : maxNoOfItems;
    }
    if (!noOfItems) {
        noOfItems = maxNoOfItems;
    }
    return noOfItems;
};

/**
 * @deprecated use trimText from `repoV2/utils/common/dataTypes/string.ts`
 */
export const trimText = (textParam: string | undefined, length: number) => {
    const text = textParam || "";
    return text.length > length ? `${text.slice(0, length)}...` : text;
};

export const VOWELS = new Set(["a", "e", "i", "o", "u"]);

/**
 * @deprecated Please use the util defined in repoV2/features/Common/utils/strings.ts
 * @returns pluralised `word` by appending an "s" when the `count > 0`
 */
export const pluralise = (word: string, count: number) => {
    if (!word) return "";

    const len = word.length;
    const hasYAtEnd = word[len - 1].toLowerCase() === "y";
    const hasVowelBeforeY = VOWELS.has(word[len - 2].toLowerCase());

    if (hasYAtEnd && !hasVowelBeforeY) {
        const splittedWord = word.slice(0, -1);
        return count > 1 ? `${splittedWord}ies` : word;
    }

    return count > 1 ? `${word}s` : word;
};

/**
 * Function to get the desired value based on a mapping against corresponding screen widths
 * @param widthLengthMapping Array<minScreenWidth, return value for widths above or equal>
 * @param customScreenWidth Optional param for a custom screen width.
 * @returns number from widthLengthMapping[1] corresponding to the current screen width.
 */
export const getMaxLengthBasedOnDeviceWidth = (
    widthLengthMapping: Array<[number, number]>,
    customScreenWidth?: number
) => {
    const deviceWidth =
        customScreenWidth || (isBrowser() ? window.innerWidth : 1024);
    const maxLength = chainShortCircuits<number>(
        widthLengthMapping.map(([IthdeviceWidth, IthMaxLength]) => [
            deviceWidth > IthdeviceWidth,
            IthMaxLength,
        ]),
        200
    );

    return maxLength;
};

export type ISizeUnit = "B" | "KB" | "MB" | "GB";

/**
 * @deprecated use `getFileSizeString` defined at repoV2/features/Common/utils/file.ts
 */
export const getFileSizeString = (
    sizeInBytes: number,
    options?: { precision?: number }
) => {
    const { precision = 2 } = options || {};
    let postfix: ISizeUnit = "B";
    let sizeVal: number = sizeInBytes;

    if (sizeInBytes > 1024) {
        sizeVal = sizeInBytes / 1024;
        postfix = "KB";
    }

    if (sizeInBytes > 1024 * 1024) {
        sizeVal = sizeInBytes / (1024 * 1024);
        postfix = "MB";
    }

    if (sizeInBytes > 1024 * 1024 * 1024) {
        sizeVal = sizeInBytes / (1024 * 1024 * 1024);
        postfix = "GB";
    }

    return {
        postfix,
        sizeVal,
        outputString: `${sizeVal.toPrecision(precision)} ${postfix}`,
    };
};

export const deleteObjectKey = (obj: Object, deleteKey: string): Object => {
    let newObj = {};
    Object.keys(obj)
        .filter(key => key !== deleteKey)
        .forEach(key => {
            newObj = { ...newObj, [key]: obj[key as keyof typeof obj] };
        });
    return newObj;
};

/**
 * @param list array of objects to be processed
 * @param valueExtractor a function that extracts a specific key from the object that needs to be summed
 * @returns sum of the extracted key rounded up to 2 decimal places
 */
export const cumulationPipe = <T = any>(
    list: Array<T>,
    valueExtractor: (p: T) => number
) => roundUp(list.map(valueExtractor).reduce((sum, curr) => sum + curr, 0));

// check whether the entered value is valid number
export const isNumeric = (value: string) => {
    return /^-?\d+$/.test(value);
};

export const getSocialData = (socialKey: string) =>
    SOCIAL_PLATFORMS[socialKey as ISocialPlatformKey];

export const getGoogleDriveFileIdFromUrl = (url: string) =>
    url.match(/[-\w]{25,}/)?.[0];

/**
 *
 * @param obj any object
 * @returns true is has even one key as undefined or null
 */
export const checkUndefinedOrNull = (obj: any) => {
    return Object.values(obj).some(val => val === undefined || val === null);
};
