// @ts-nocheck
/* tslint:disable */
import React, { FC } from "react";
import { Chip, ChipProps } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { classNames } from "repoV2/utils/common/render/classNames";
import styles from "./index.module.scss";

type IconProps = {
    icon: string | undefined;
    className: string;
};

const Icon: FC<IconProps> = ({ icon = "info", className = "" }) => {
    return (
        {
            success: (
                <CheckCircleOutlineRoundedIcon
                    viewBox="0 0 24 24"
                    className={className}
                />
            ),
            info: (
                <InfoOutlinedIcon viewBox="0 0 24 24" className={className} />
            ),
            error: (
                <WarningAmberRoundedIcon
                    viewBox="0 0 24 24"
                    className={className}
                />
            ),
            warning: (
                <WarningAmberRoundedIcon
                    viewBox="0 0 24 24"
                    className={className}
                />
            ),
        }[icon] || (
            <CheckCircleOutlineRoundedIcon
                viewBox="0 0 24 24"
                className={className}
            />
        )
    );
};

const Toast = (props: ChipProps) => {
    const { type, variant } = props;
    const iconColorStyle = styles[`icon_${type}_${variant}`];
    return (
        <Chip
            deleteIcon={
                <CloseRoundedIcon
                    viewBox="0 0 22 22"
                    className={iconColorStyle}
                />
            }
            icon={
                <div
                    className={classNames(
                        styles.iconStyle,
                        iconColorStyle,
                        styles[`icon_${type}_${variant}_container`]
                    )}
                >
                    <Icon icon={type} className={iconColorStyle} />
                </div>
            }
            classes={{
                root: [styles.default_style, styles[`${type}_${variant}`]].join(
                    " "
                ),
                label: styles.labelStyle,
            }}
            {...props}
        />
    );
};

export default Toast;
