import { CourseCommentType } from "@Modules/contentList/MainContent/components/CommentsSection/CommentsSection.data";
import {
    ACTION_TYPES,
    API_ACTION_TYPES,
    BUTTON_DESIGNS,
    COMMENT_STATUS,
    createAction,
    getUserAccessToken,
    isEmpty,
} from "@Utils";
import { getTimezone } from "@Utils/getTimezone";
import { parseDateTime } from "@Utils/parseDateTime";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LoadingIndicator } from "repoV2/components/common/LoadingIndicators";
import useToastMessage from "repoV2/components/common/Toast/useToastMessage";
import { classNames } from "repoV2/utils/common/render/classNames";
import { get32BitIntegerCode } from "repoV2/features/Common/modules/Hashing/utils/32BitIntegerCode";
import { ButtonV2 } from "../ButtonV2";
import { Button } from "../Buttons";
import CommentReaction from "../CommentReaction";
import { TextAreaInput } from "../Form";
import ReplySection from "../ReplySection";
import { ICommentsSection } from "./ICommentsSection";
import styles from "./commentSection.module.scss";
import { colors, getCommenterName } from "./data";

export function CommenterIcon({
    initial,
    // A single user may have multiple comments, and we need
    // the color of the icon to be the same, so the color
    // assigned to a user is stored in the parent component
    // for consistency. The parent passes the index of the
    // color from colors[] to be used
    colorKey,
}: {
    initial: string;
    colorKey: number;
}) {
    return (
        <div>
            <div
                className={styles.commenterAlphabetIcon}
                style={{ backgroundColor: colors[colorKey] }}
            >
                {initial}
            </div>
        </div>
    );
}

export const CommentsSection = ({
    data,
    createComment,
    preview,
    handlePreviewPopup,
    showLoadMoreFunctionality,
    isLoading,
    onLoadMoreClick,
    // TODO: make this independent of componentParent prop
    componentParent,
    enableComments,
    showTopBorder = true,
}: ICommentsSection.IProps) => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [commentInput, setCommentInput] = useState<string>("");
    const [commentColors, setCommentColors] = useState<Record<string, number>>(
        {}
    );

    const { notify } = useToastMessage();

    const onCommentInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (preview) {
            if (handlePreviewPopup) handlePreviewPopup();
            return;
        }
        setCommentInput(e.target.value);
    };

    useEffect(() => {
        // The intention here is to generate a color for a user and have it persist
        // even if `data` changes, so we append the old colors after the new
        // generated data to overwrite the new values for colors of existing comments
        // A caveat is that if two users have the same name, they will also have the same color
        setCommentColors({
            ...Object.assign(
                {},
                ...data?.map(comment => {
                    const commenterName = getCommenterName(comment);
                    if (commenterName) {
                        return {
                            /**
                             * generate a unique hash code from `commenterName` and do modulo `colors.length` to
                             * get a number in range `[0, colors.length)`
                             */
                            [commenterName]:
                                commentColors?.[commenterName] ||
                                get32BitIntegerCode(commenterName) %
                                    colors?.length,
                        };
                    }
                    return null;
                })
            ),
        });
    }, [data]);

    const handleSubmit = () => {
        setIsSubmitting(true);
        createComment({
            commentInput,
            successCallback: () => {
                setCommentInput("");
                notify("Comment added successfully.", {
                    type: "success",
                    variant: "outlined",
                });
            },
            finallyCallback: () => {
                // To be called for both success and error
                setIsSubmitting(false);
            },
        });
    };

    const fetchReplies = (type: string, commentId?: string | number) => {
        if (commentId && type === "Blog") {
            dispatch(
                createAction(ACTION_TYPES.UTILS.API_CALL, {
                    apiActionType: API_ACTION_TYPES.FETCH_COMMENT_REPLIES,
                    urlArgs: { commentId },
                    headers: {
                        "jwt-token": getUserAccessToken(),
                    },
                })
            );
        } else {
            dispatch(
                createAction(ACTION_TYPES.UTILS.API_CALL, {
                    apiActionType:
                        API_ACTION_TYPES.FETCH_CONTENT_COMMENT_REPLIES,
                    urlArgs: { commentId },
                    headers: {
                        "jwt-token": getUserAccessToken(),
                    },
                })
            );
        }
    };

    if (isLoading)
        return (
            <div className="text-center">
                <LoadingIndicator />
            </div>
        );

    return (
        <div>
            {componentParent === "Blog" || enableComments ? (
                <>
                    <div
                        className={classNames(
                            styles.commentContainer,
                            styles.sectionGap
                        )}
                    >
                        <TextAreaInput
                            placeholder="Write a comment..."
                            className={styles.commentInput}
                            value={commentInput}
                            onChange={onCommentInput}
                            editingDisabled={isSubmitting}
                        />
                    </div>
                    <div
                        className={classNames(
                            styles.commentPostButtonContainer,
                            styles.sectionGap
                        )}
                    >
                        <ButtonV2
                            applyTheme
                            disabled={
                                isEmpty(commentInput.trim()) || isSubmitting
                            }
                            onClick={handleSubmit}
                        >
                            {isSubmitting ? "Posting..." : "Post"}
                        </ButtonV2>
                    </div>
                </>
            ) : null}
            {!isEmpty(data) && showTopBorder ? <hr /> : null}
            {data?.map(comment => {
                const { type } = comment;
                const content = comment.comment || comment.content;
                const commenterName = getCommenterName(comment);
                const isHtml = type === CourseCommentType.HTML;
                return (
                    <div key={comment.uuid}>
                        {comment?.status === COMMENT_STATUS.SHOWN ? (
                            <div
                                key={get32BitIntegerCode(comment?.created_at)}
                                className={classNames(
                                    styles.commentContainer,
                                    styles.largeSectionGap
                                )}
                            >
                                <CommenterIcon
                                    initial={commenterName?.[0]}
                                    colorKey={commentColors?.[commenterName]}
                                />
                                <div className={styles.commentInfoContainer}>
                                    <div className={styles.commentTitle}>
                                        <div className={styles.boldText}>
                                            {commenterName}
                                        </div>
                                        <div className={styles.boldText}>
                                            &nbsp;&middot;&nbsp;
                                        </div>
                                        <div
                                            className={styles.timestamp}
                                            title={parseDateTime(
                                                comment?.created_at,
                                                {
                                                    formatString:
                                                        "E MMM dd, yyyy 'at' hh:mm a xxx",
                                                    log: true,
                                                    formatOptions: {
                                                        timeZone:
                                                            getTimezone()
                                                                .timezone,
                                                    },
                                                }
                                            )}
                                        >
                                            {parseDateTime(
                                                comment?.created_at,
                                                "MMM dd, yyyy 'at' hh:mm a"
                                            )}
                                            {componentParent ===
                                            "Recorded Content" ? (
                                                <CommentReaction
                                                    comment_uid={comment?.uuid}
                                                    likesCount={
                                                        comment?.likes_count
                                                    }
                                                    is_commentLiked={
                                                        comment?.is_comment_liked
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className={styles.commentContent}>
                                        {isHtml ? (
                                            <div
                                                className={styles.examResponse}
                                                // eslint-disable-next-line react/no-danger
                                                dangerouslySetInnerHTML={{
                                                    __html: content,
                                                }}
                                            />
                                        ) : (
                                            <div className={styles.textComment}>
                                                {content}
                                            </div>
                                        )}
                                        {comment?.replies_count === 0 ? null : (
                                            <div
                                                className={
                                                    styles.replyContainer
                                                }
                                            >
                                                <ReplySection
                                                    commentId={comment?.id}
                                                    commentUuid={comment?.uuid}
                                                    replyCount={
                                                        comment?.replies_count
                                                    }
                                                    fetchReplies={fetchReplies}
                                                    commentColors={
                                                        commentColors
                                                    }
                                                    commenterName={
                                                        commenterName
                                                    }
                                                    componentParent={
                                                        componentParent
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                );
            })}
            {showLoadMoreFunctionality ? (
                <div
                    className={classNames(
                        styles.loadMoreContainer,
                        styles.largeSectionGap
                    )}
                >
                    <Button
                        design={BUTTON_DESIGNS.ROUNDED_CORNERS}
                        content={isLoading ? "Loading..." : "Load more"}
                        disabled={isLoading}
                        onClick={onLoadMoreClick}
                    />
                </div>
            ) : null}
        </div>
    );
};

CommentsSection.defaultProps = {};
