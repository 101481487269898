import {
    setObjectKeyData,
    updateObjectKeyData,
} from "repoV2/features/Common/modules/FormData/utils/helpers";
import { checkout_page_reducer_initial_state } from "../../constants/CheckoutPages.constants";
import {
    CHECKOUT_PAGES_ACTION_TYPES,
    CHECKOUT_REDUCER_ACCESS_KEYS,
} from "../../constants/CheckoutPagesReducer";
import { ICheckoutPagesAction } from "../../interfaces/CheckoutPages.interface";

export default function checkoutPagesReducer(
    state = checkout_page_reducer_initial_state,
    { type, payload }: ICheckoutPagesAction
) {
    switch (type) {
        case CHECKOUT_PAGES_ACTION_TYPES.SET_PRICE_SECTION_DATA: {
            let newState = state;
            if (payload.listingUuid) {
                newState = setObjectKeyData({
                    dataObj: state,
                    key: `${CHECKOUT_REDUCER_ACCESS_KEYS.listing_details}.${payload.listingUuid}.${CHECKOUT_REDUCER_ACCESS_KEYS.price_section}`,
                    payload: {
                        data: payload?.data,
                        isFetched: payload?.isFetched,
                    },
                });
            }
            return newState;
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_SESSIONS_DATA: {
            let newState = state;
            if (payload.listingUuid)
                newState = setObjectKeyData({
                    dataObj: state,
                    key: `${CHECKOUT_REDUCER_ACCESS_KEYS.listing_details}.${payload.listingUuid}.${CHECKOUT_REDUCER_ACCESS_KEYS.session_details}`,
                    payload: {
                        data: payload?.data,
                        isFetched: payload?.isFetched,
                    },
                });
            return newState;
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_PRICE_SUMMARY_DATA: {
            return {
                ...state,
                priceSummary: {
                    ...state.priceSummary,
                    ...payload,
                },
            };
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_IS_PAYMENT_PROCESSING: {
            return {
                ...state,
                isPaymentProcessing: payload.isPaymentProcessing,
            };
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_IS_EXPRESS_INTEREST_PROCESSING: {
            return {
                ...state,
                isExpressInterestProcessing:
                    payload.isExpressInterestProcessing,
            };
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_CUSTOM_QUESTIONS_DATA: {
            let newState = state;
            if (payload.listingUuid) {
                newState = setObjectKeyData({
                    dataObj: state,
                    key: `${CHECKOUT_REDUCER_ACCESS_KEYS.listing_details}.${payload.listingUuid}.${CHECKOUT_REDUCER_ACCESS_KEYS.custom_questions}`,
                    payload: {
                        data: payload?.data,
                        isFetched: payload?.isFetched,
                    },
                });
            }
            return newState;
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_IS_CUSTOM_QUESTION_FILE_UPLOADING: {
            let newState = state;
            if (payload.listingUuid) {
                newState = updateObjectKeyData({
                    dataObj: state,
                    key: `${CHECKOUT_REDUCER_ACCESS_KEYS.listing_details}.${payload.listingUuid}.${CHECKOUT_REDUCER_ACCESS_KEYS.file_uploading_details}`,
                    payload: payload?.data,
                });
            }
            return newState;
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_PART_PAYMENTS_DATA: {
            let newState = state;
            if (payload.listingUuid) {
                newState = setObjectKeyData({
                    dataObj: state,
                    key: `${CHECKOUT_REDUCER_ACCESS_KEYS.listing_details}.${payload.listingUuid}.${CHECKOUT_REDUCER_ACCESS_KEYS.part_payment_details}`,
                    payload: {
                        data: payload?.data,
                        isFetched: payload?.isFetched,
                    },
                });
            }
            return newState;
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_ORDER_BUMPS_DATA: {
            let newState = state;
            if (payload.listingUuid) {
                newState = setObjectKeyData({
                    dataObj: state,
                    key: `${CHECKOUT_REDUCER_ACCESS_KEYS.listing_details}.${payload.listingUuid}.${CHECKOUT_REDUCER_ACCESS_KEYS.order_bumps_details}`,
                    payload: {
                        data: payload?.data,
                    },
                });
            }
            return newState;
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_COUPON_CODE_PROCESSING: {
            let newState = state;
            if (payload.listingUuid) {
                newState = setObjectKeyData({
                    dataObj: state,
                    key: `${CHECKOUT_REDUCER_ACCESS_KEYS.listing_details}.${payload.listingUuid}.${CHECKOUT_REDUCER_ACCESS_KEYS.coupon_code_processing}`,
                    payload: {
                        data: payload?.data,
                    },
                });
            }
            return newState;
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_COUPON_CODE_HELPER_DATA: {
            let newState = state;
            if (payload.listingUuid) {
                newState = setObjectKeyData({
                    dataObj: state,
                    key: `${CHECKOUT_REDUCER_ACCESS_KEYS.listing_details}.${payload.listingUuid}.${CHECKOUT_REDUCER_ACCESS_KEYS.coupon_code_helper_text}`,
                    payload: {
                        data: payload?.data,
                    },
                });
            }
            return newState;
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_MERCHANDISE_VARIANTS_DATA: {
            let newState = state;
            if (payload.listingUuid) {
                newState = setObjectKeyData({
                    dataObj: state,
                    key: `${CHECKOUT_REDUCER_ACCESS_KEYS.listing_details}.${payload.listingUuid}.${CHECKOUT_REDUCER_ACCESS_KEYS.merchandise_variants_details}`,
                    payload: {
                        data: payload?.data,
                    },
                });
            }
            return newState;
        }
        case CHECKOUT_PAGES_ACTION_TYPES.SET_MERCHANDISE_SHIPROCKET_DATA: {
            let newState = state;
            if (payload.listingUuid) {
                newState = setObjectKeyData({
                    dataObj: state,
                    key: `${CHECKOUT_REDUCER_ACCESS_KEYS.listing_details}.${payload.listingUuid}.${CHECKOUT_REDUCER_ACCESS_KEYS.merchandise_shiprocket_details}`,
                    payload: {
                        data: payload?.data,
                    },
                });
            }
            return newState;
        }
        default:
            return state;
    }
}
