/**
 * TODO: move to repoV2/constants/form/validators.ts
 */
export const FULL_NAME_REGEX = /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/;

/**
 * TODO: move to repoV2/constants/form/validators.ts
 */
export const EMAIL_REGEX = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

/**
 * TODO: move to repoV2/constants/form/validators.ts
 */
export const DIGITS_REGEX = /^\d*$/;
