import { IHost } from "@Interfaces";

import { getCssKeyName } from "./style";

export const getThemeData = ({ host }: { host: IHost.IStore }): string[][] => {
    const returnArr = [];

    const {
        theme,
        font: { title: fontTitle },
    }: IHost.IStore = host;

    // Font name
    if (fontTitle) {
        returnArr.push([getCssKeyName("primary", "font"), `"${fontTitle}"`]);
    }

    // Labels is meant for theme customisation use. No use in this project.
    const {
        // @ts-ignore
        schemas = {},
        // @ts-ignore
        colors = {},

        // * Extracting these keys out so they are not parsed
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        labels = {},
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        categories = {},
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        previewColors = {},

        ...otherProperties
    } = theme;

    // Theme data
    if (theme) {
        Object.entries(otherProperties || {}).forEach(
            ([propertyKey, propertyVal]) => {
                // If the value is a string, load it up into the DOM
                if (typeof propertyVal === "string") {
                    returnArr.push([getCssKeyName(propertyKey), propertyVal]);
                } else {
                    // Else if the value is an object, iterate through it first
                    Object.entries(propertyVal).forEach(
                        ([nestedKey, nestedVal]) => {
                            returnArr.push([
                                getCssKeyName(
                                    nestedKey,
                                    `${propertyKey}-host-theme`
                                ),
                                nestedVal,
                            ]);
                        }
                    );
                }
            }
        );

        Object.entries(colors).forEach(([colorKey, colorVal]) => {
            // @ts-ignore
            returnArr.push([getCssKeyName(colorKey, `host-theme`), colorVal]);
        });

        Object.keys(schemas).forEach(schemaKey => {
            Object.keys(schemas[schemaKey]).forEach(rgbKey => {
                // console.log({
                //     key: getCssKeyName(rgbKey, `${schemaKey}-host-theme`),
                //     value: colors[schemas[schemaKey][rgbKey]],
                // });
                returnArr.push([
                    getCssKeyName(rgbKey, `${schemaKey}-host-theme`),
                    colors?.[schemas?.[schemaKey]?.[rgbKey]],
                ]);
            });

            /** Recommended to keep the below commented console.log statement for ease
             * of testing till the feature is finalized and pushed into production */
            // if (key.includes("rgbColor"))
            // console.log(
            //     `${getCssKeyName(key)}[${readCssVar(
            //         getCssKeyName(key)
            //     )}]: %c      `,
            //     `background: rgb(${readCssVar(getCssKeyName(key))})`
            // );
        });
    }

    return returnArr;
};
