import { isBrowser } from "./common";
import { QUERY_PARAMS, ROUTES } from "./constants";

/**
 * to avoid handling of `https://` and `http://`, we simply use `//` for external urls
 */
export const getExternalUrl = (url?: string) =>
    `${
        url && ["http://", "https://"].some(prefix => url.startsWith(prefix))
            ? ""
            : `//`
    }${url}`;

/**
 * we have to use this method instead of `new URL(url)` since URL constructor fails on non proper urls.
 * And at some places we jut pass routes(see this file `utils/constants/api.ts`) to this.
 * @deprecated `use the function from repoV2/utils/urls&routing/urlParams.ts`
 */
const getUrlPathnameOriginSearchHash = (url: string) => {
    const indexOfUrlSearch = url.indexOf("?");
    const indexOfUrlSearchExists = indexOfUrlSearch >= 0;
    const indexOfUrlHash = url.indexOf("#");
    const indexOfUrlHashExists = indexOfUrlHash >= 0;

    let hash = "";
    if (indexOfUrlHashExists) {
        hash = url.slice(indexOfUrlHash);
    }
    const urlWOHash = url.replace(hash, "");

    let search = "";
    if (indexOfUrlSearchExists) {
        search = urlWOHash.slice(indexOfUrlSearch);
    }

    const originAndPathname = urlWOHash.replace(search, "");

    return { originAndPathname, search, hash };
};

/**
 * @param urlArg - the url to be processed. `window.location.href` is the default value
 * @returns `new URLSearchParams` object
 */
export const getURLSearchParamsObj = (urlArg: string = "") => {
    let url = urlArg;
    if (isBrowser() && !url) {
        url = window.location.href;
    }
    const { search } = getUrlPathnameOriginSearchHash(url);
    return new URLSearchParams(search);
};

/**
 * @deprecated `use the `appendURLSearchParams` from repoV2/utils/urls&routing/urlParams.ts`
 */

export const appendQueryParams = (url: string, params: Object) => {
    const urlObj = getUrlPathnameOriginSearchHash(url);
    const newUrlOriginAndPathname = urlObj.originAndPathname;
    let newUrlSearch = urlObj.search;
    const newUrlHash = urlObj.hash;

    const newUrlParams = new URLSearchParams(newUrlSearch);
    Object.entries(params).forEach(i => {
        if ((i[1] ?? null) !== null) newUrlParams.append(i[0], i[1]);
    });
    newUrlSearch = newUrlParams.toString();
    newUrlSearch = (newUrlSearch ? "?" : "") + newUrlSearch;
    return newUrlOriginAndPathname + newUrlSearch + newUrlHash;
};

/**
 * @deprecated `use the `deleteURLSearchParams` from repoV2/utils/urls&routing/urlParams.ts`
 */
export const deleteUrlParams = (url: string, params: Array<string>) => {
    const urlObj = getUrlPathnameOriginSearchHash(url);
    const newUrlOriginAndPathname = urlObj.originAndPathname;
    let newUrlSearch = urlObj.search;
    const newUrlHash = urlObj.hash;

    const newUrlParams = new URLSearchParams(newUrlSearch);
    params.forEach(i => newUrlParams.delete(i));
    newUrlSearch = newUrlParams.toString();
    newUrlSearch = (newUrlSearch ? "?" : "") + newUrlSearch;
    return newUrlOriginAndPathname + newUrlSearch + newUrlHash;
};

export const getExlyConnectMeetingUrl = (meetingId?: string) =>
    appendQueryParams(`${window.location.origin}${ROUTES.LOGIN.urlPath}`, {
        [QUERY_PARAMS.NEXT]: encodeURIComponent(
            `${process.env.NEXT_PUBLIC_EXLY_CON_DOMAIN}/customer/${meetingId}`
        ),
    });
