import { MODAL_TYPES } from "@Utils/constants";
import { getURLSearchParamsObj } from "@Utils/urls";
import { useEffect } from "react";
import { useModal } from "./modal";

export const useDisableContextMenu = () => {
    useEffect(() => {
        document.addEventListener("contextmenu", event =>
            event.preventDefault()
        );
    }, []);
};

/**
 * Handle click of social icons
 */
export const useSocialIconClick = () => {
    const { openModal, closeModal } = useModal();

    const onSocialIconClick = (link: string) => {
        const urlQueryParams = getURLSearchParamsObj();
        const preview = urlQueryParams.get("preview");
        if (preview) {
            openModal(MODAL_TYPES.PreviewPopup, {
                modalProps: {
                    onClickBack: closeModal,
                    onClickContinue: closeModal,
                },
            });
            return;
        }
        window.open(link, "_blank");
    };

    return { onSocialIconClick };
};

/**
 * Adds click event listener to DOM body, if the click is outside `containerRef`,
 * it triggers the `onClickOutsideCallback`.
 */
export const useOnClickOutsideContainer = (
    containerRef: React.RefObject<HTMLDivElement>,
    onClickOutsideCallback: () => void
) => {
    const onClickOutsideContainer = (e: any) => {
        if (
            containerRef?.current &&
            !containerRef?.current?.contains(e.target)
        ) {
            onClickOutsideCallback();
        }
    };
    useEffect(() => {
        document.body.addEventListener("click", onClickOutsideContainer);

        return () => {
            document.body.removeEventListener("click", onClickOutsideContainer);
        };
    }, []);
};
