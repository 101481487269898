import { IEvent, IHost } from "@Interfaces";
import { ITemplate } from "@Templates/ITemplate";

import { useSelector } from "react-redux";
import { SELECTORS } from "@Utils";
import { parseDateTime } from "@Utils/parseDateTime";
import { CURRENCY_SYMBOLS } from "repoV2/constants/payment";
import { LISTING_TYPES } from "repoV2/constants/listing";
import { isEmpty } from "./common";
import {
    EVENT_TYPE,
    EVENT_CATEGORISATION_TYPE,
    AVAILABILITY_CHOICE,
} from "./constants";
import { IPaymentInfoForSingleOffering } from "./payment";
import { handlePrependPlans } from "./plans";
import { isTimePassed } from "./time";

// Whether the event has been fetched and loaded in store.event.list
export const isEventFetched = (
    eventUUID: IEvent.ISelectedEvent,
    eventDataList: IEvent.IEventDataList
): boolean => {
    return !!(eventUUID && Object.keys(eventDataList).includes(eventUUID));
};

export const getIsSingleSlot = ({
    eventData,
}: {
    eventData: IPaymentInfoForSingleOffering.IEventDataNeeded | null;
}) => {
    /** Not decided can be done only in the case of appointments hence not adding that extra check
     * Availability choice not decided means a creator hasn't decided onto when he is free hence a customer can make a booking without booking a slot
     */
    if (
        eventData?.type === EVENT_TYPE.APPOINTMENTS &&
        eventData?.availability_choice === AVAILABILITY_CHOICE.NOT_DECIDED
    ) {
        return true;
    }

    const eventType = eventData?.type || 0;
    const isSingleSlot: boolean = [
        EVENT_TYPE.WORKSHOPS,
        EVENT_TYPE.NO_SCHEDULE,
        EVENT_TYPE.RECORDED_CONTENT,
    ].includes(eventType); // For

    return isSingleSlot;
};

/**
 * Slots availability based on spot vacancies
 */
export const getIfSlotsAvailable = ({
    eventData,
    numberOfCustomers = 1,
    merchQuantity = 1,
    slots: selectedSlots = undefined, // Send this if the availability is to be checked for a certain configuration
}: {
    eventData: IEvent.IEventData | null;
    numberOfCustomers?: number;
    merchQuantity?: number;
    slots?: IEvent.IPaymentInfo["slots"];
}): boolean => {
    if (!eventData) return false;
    // To handle the console error when the page renders on SSR
    // TODO: Handle this in a middleware during refactoring
    // if (typeof eventType !== "number") return false;

    /** Not decided can be done only in the case of appointments hence not adding that extra check
     * Availability choice not decided means a creator hasn't decided onto when he is free hence a customer can make a booking without booking a slot
     */
    if (
        eventData?.type === EVENT_TYPE.APPOINTMENTS &&
        eventData?.availability_choice === AVAILABILITY_CHOICE.NOT_DECIDED
    ) {
        return true;
    }

    switch (eventData?.type) {
        case EVENT_TYPE.APPOINTMENTS:
        case EVENT_TYPE.WORKSHOPS:
        case EVENT_TYPE.WEBINAR:
            // eslint-disable-next-line no-case-declarations
            const areSlotsPresent: boolean = !!(
                eventData?.slots &&
                Array.isArray(eventData.slots) &&
                eventData.slots.length > 0
            );

            if (!areSlotsPresent) return false;

            // Whether the number of bookings in the slot allows for the requested new bookings
            // eslint-disable-next-line no-case-declarations
            const isSlotVacancyPresent = (spotsFilled: number): boolean =>
                spotsFilled + numberOfCustomers <= eventData.max_spots;

            // Whether the slot has the end_time in the future
            // eslint-disable-next-line no-case-declarations
            const isSlotVacancyValid = (slot: IEvent.ISlot): boolean =>
                isSlotVacancyPresent(slot.spots_filled) &&
                !isTimePassed(parseDateTime(slot.end_time).valueOf());

            // * If selectedSlots = true, need to check only the slots in SelectedSlots
            // * If selectedSlots = false, need to check for all the slots available in eventData.slots
            // eslint-disable-next-line no-case-declarations
            const slotsToCheck: IEvent.ISlot[] = selectedSlots
                ? eventData.slots.filter((slot: IEvent.ISlot) =>
                      selectedSlots.includes(slot.uuid)
                  )
                : eventData.slots;

            if (selectedSlots) {
                return slotsToCheck.every(isSlotVacancyValid);
            }
            return slotsToCheck.some(isSlotVacancyValid);

        case EVENT_TYPE.ONE_ON_ONE_CLASSES:
        case EVENT_TYPE.GROUP_CLASSES:
        case EVENT_TYPE.ROLLING_CLASSES:
            return (
                !isEmpty(eventData.classes) &&
                eventData.classes
                    .filter((classItem: IEvent.IClass) =>
                        // If selectedSlots exists, pick only the slots in the selectedSlots
                        selectedSlots
                            ? selectedSlots.includes(classItem.uuid)
                            : true
                    )
                    .some(
                        (classItem: IEvent.IClass) =>
                            classItem.slots_filled + numberOfCustomers <=
                            eventData.max_spots
                    )
            );
        case EVENT_TYPE.MERCHANDISE: {
            const {
                variantsData,
                selectedVariant,
                allow_overflow: allowOfferingOverflow,
                total_inventory: offeringTotalInventory = 0,
                purchased_inventory: offeringPurchaseInventory = 0,
            } = eventData;

            if (!isEmpty(variantsData)) {
                // if variant is selected then check variant inventory
                if (selectedVariant) {
                    const {
                        total_inventory = 0,
                        purchased_inventory = 0,
                        allow_overflow: allowVariantOverflow,
                    } = selectedVariant;

                    return (
                        allowVariantOverflow ||
                        total_inventory - purchased_inventory >=
                            numberOfCustomers * merchQuantity
                    );
                }
                // else show available until user selects one
                return true;
            }

            const isQtyAvailable =
                offeringTotalInventory - offeringPurchaseInventory >=
                numberOfCustomers * merchQuantity;

            return allowOfferingOverflow || isQtyAvailable;
        }
        case EVENT_TYPE.NO_SCHEDULE:
        case EVENT_TYPE.RECORDED_CONTENT:
        default:
            return true;
    }
};

export const getEventListingsOfCategory = (
    category: string | undefined,
    categoriseType: number,
    eventListings: Array<ITemplate.IEventListingData>,
    optionalArgs:
        | undefined
        | {
              listingType?: number | undefined;
              keyword?: string;
              showByKeywordAndSkuType?: boolean;
          } = {}
    // The `listingType` param is optionally passed for categorisation of SKU_TYPE and SKU_TYPE_KEYWORDS
    // to ensure that two listing categorisations set to the same title do not clash and end
    // up with duplications of a listing association with that title in both sections
): Array<ITemplate.IEventListingData> => {
    const { listingType = -1, keyword, showByKeywordAndSkuType } = optionalArgs;
    if (showByKeywordAndSkuType && keyword) {
        return (eventListings || []).filter(eventListing => {
            // If the eventListing is a plan type, it need not fit into a skuType category, since it will be shown separately
            // Since multiple sku types can be given the same title, this check is to ensure that such a case does not cause duplicate rendering of event cards.
            // `listingType` > -1 is a basic check since -1 is the default value used wherever the listingType is not available
            const isSkuTypeMatching: boolean =
                eventListing.type === listingType &&
                (
                    eventListing?.metadata?.keywords ||
                    ("keywords" in eventListing && eventListing?.keywords) ||
                    []
                ).includes(keyword);
            return isSkuTypeMatching;
        });
    }
    if (
        [
            EVENT_CATEGORISATION_TYPE.SKU_TYPE,
            EVENT_CATEGORISATION_TYPE.SKU_TYPE_KEYWORDS,
        ].includes(categoriseType)
    ) {
        return (eventListings || []).filter(eventListing => {
            // If the category is a plan, then it needs to not fall into any other category with the same listing type as the plan's parent listing
            const categoryIsPlan: boolean = listingType === EVENT_TYPE.PLANS;
            // If the eventListing is a plan type, it need not fit into a skuType category, since it will be shown separately
            // Since multiple sku types can be given the same title, this check is to ensure that such a case does not cause duplicate rendering of event cards.
            // `listingType` > -1 is a basic check since -1 is the default value used wherever the listingType is not available
            const isSkuTypeMatching: boolean = categoryIsPlan
                ? "isPlan" in eventListing && eventListing.isPlan
                : (!("isPlan" in eventListing) || !eventListing.isPlan) &&
                  eventListing.type === listingType;
            return isSkuTypeMatching;
        });
    }
    if (
        [
            EVENT_CATEGORISATION_TYPE.KEYWORDS_SKU_TYPE,
            EVENT_CATEGORISATION_TYPE.KEYWORDS,
        ].includes(categoriseType)
    ) {
        return (eventListings || []).filter(eventListing =>
            (
                eventListing?.metadata?.keywords ||
                ("keywords" in eventListing && eventListing?.keywords) ||
                []
            ).includes(category)
        );
    }
    return [];
};

export const getEventListingWithKeyword = (
    category: string,
    listings: Array<ITemplate.IEventListingData>
): Array<ITemplate.IEventListingData> => {
    return getEventListingsOfCategory(
        category,
        EVENT_CATEGORISATION_TYPE.KEYWORDS_SKU_TYPE,
        listings
    );
};

// Returns an array of SKU titles, in the order intended by the back-end
export const getSkuTitleInOrder = (
    hostData?: IHost.IHostData
): Array<{ title: string; listingType: number }> => {
    const { sku_order } = hostData || {};
    return (sku_order || []).map(listingType => ({
        title: hostData?.sku_map?.[listingType] || `${listingType}`,
        listingType,
    }));
};

export interface IEventCategory {
    title: string;
    listingType?: number;
    priority?: number;
}

export const getAllEventCategories = (
    hostData?: IHost.IHostData,
    plansTitle?: string,
    categoriseTypeParam?: number
): Array<IEventCategory> => {
    const categoriseType =
        categoriseTypeParam ||
        hostData?.categorise_type ||
        EVENT_CATEGORISATION_TYPE.NO_CATEGORISATION;

    if (
        [
            EVENT_CATEGORISATION_TYPE.SKU_TYPE,
            EVENT_CATEGORISATION_TYPE.SKU_TYPE_KEYWORDS,
        ].includes(categoriseType)
    ) {
        return handlePrependPlans(
            getSkuTitleInOrder(hostData),
            plansTitle
                ? [{ title: plansTitle, listingType: EVENT_TYPE.PLANS }]
                : [],
            hostData?.prepend_plans
        ).map((data, priority) => ({ ...data, priority }));
    }

    if (
        [
            EVENT_CATEGORISATION_TYPE.KEYWORDS,
            EVENT_CATEGORISATION_TYPE.KEYWORDS_SKU_TYPE,
        ].includes(categoriseType)
    ) {
        return (hostData?.all_keywords || []).map(
            ({ keyword: title }, priority) => ({
                title,
                priority,
            })
        );
    }

    return [];
};

export const colorByListingType = (
    eventType: number,
    isPlan: boolean = false
) => {
    if (isPlan) return "#CA3E47";
    switch (eventType) {
        case EVENT_TYPE.APPOINTMENTS:
            return "#F25AC8";
        case EVENT_TYPE.GROUP_CLASSES:
            return "#929292";
        case EVENT_TYPE.MERCHANDISE:
            return "#00BCC8";
        case EVENT_TYPE.NO_SCHEDULE:
            return "#1FB148";
        case EVENT_TYPE.ONE_ON_ONE_CLASSES:
            return "#0079E9";
        case EVENT_TYPE.RECORDED_CONTENT:
            return "#F37807";
        case EVENT_TYPE.ROLLING_CLASSES:
            return "#493ab1";
        case EVENT_TYPE.WORKSHOPS:
            return "#F0BB00";
        default:
            return "#F0BB00";
    }
};

export interface ISlotDisplay {
    uuid: IEvent.ISlot["uuid"];
    startDate: string;
    startTime: string;
    endTime: string;
    spotsFilled: number;
}

/**
 * This function basically returns the filter condition for the
 * slots of sessions-based offerings based on
 * offering type
 * @param eventData data contains the details of Offering
 * @param slot slot data for the session-based offerings
 * @returns the filter condition for slots
 */
export const getSlotFilterCondition = (
    eventData: IEvent.IEventData,
    slot: ISlotDisplay
) => {
    let slotFilterCondition;
    switch (eventData.type) {
        case LISTING_TYPES.WEBINAR:
            slotFilterCondition = slot.spotsFilled < eventData.max_spots;
            break;
        default:
            slotFilterCondition =
                slot.spotsFilled < eventData.max_spots &&
                !isTimePassed(parseDateTime(slot.endTime).valueOf());
            break;
    }
    return slotFilterCondition;
};

export const getDisplayedSlots = (eventData: IEvent.IEventData) => {
    const slotDisplay: ISlotDisplay[] = eventData.slots
        ? eventData.slots
              .map((slot: IEvent.ISlot) => ({
                  uuid: slot.uuid,
                  startDate: parseDateTime(slot.start_time, "E, MMM dd, yyyy"),
                  startTime: parseDateTime(slot.start_time, "hh:mm a"),
                  endTime: slot.end_time,
                  spotsFilled: slot.spots_filled,
              }))
              .filter((slot: ISlotDisplay) =>
                  getSlotFilterCondition(eventData, slot)
              )
        : [];
    return slotDisplay;
};
export const getDummyEventListings =
    (): ITemplate.IEventSectionsProps["eventListings"] =>
        [1, 2, 3].map(i => ({
            key: `${i}`,
            id: i,
            title: `< Event Title ${i} >`,
            type: i,
            short_description: "< Event short description >",
            cover_image: "",
            description: "< Event description >",
            price_per_head: i,
            /**
             * TODO: delete if not used till 9th Aug 2023
            price_per_head_international: i,
            */
            min_price: i,
            min_price_usd: i,
            max_spots: i,
            priority: i,
            is_draft: false,
            is_active: true,
            is_hidden: false,
            status: i,
            start_date: i,
            total_inventory: i,
            purchased_inventory: i,
            uuid: `${i}`,
            updated_price: i,
            end_date: i,
            interval_type: i,
            currency: CURRENCY_SYMBOLS.INR,
            next_slot_time: `${i}`,
            upcoming_slot_datetime: `${i}`,
            metadata: {},
            sku_title: `${i}`,
            ctaText: "Book Now",
        }));

export const getListingIsClass = (eventType?: number): boolean =>
    [
        EVENT_TYPE.GROUP_CLASSES,
        EVENT_TYPE.ONE_ON_ONE_CLASSES,
        EVENT_TYPE.ROLLING_CLASSES,
    ].includes(eventType!);

export const listingIsSlotType = (eventTypeProp: number): boolean =>
    [
        EVENT_TYPE.APPOINTMENTS,
        EVENT_TYPE.WORKSHOPS,
        EVENT_TYPE.WEBINAR,
    ].includes(eventTypeProp);

export const listingIsMerch = (eventTypeProp: number): boolean =>
    [EVENT_TYPE.MERCHANDISE].includes(eventTypeProp);

export const listingIsAppointment = (eventTypeProp: number): boolean =>
    [EVENT_TYPE.APPOINTMENTS].includes(eventTypeProp);

// Generic Get Event Data (Events Page)
export const useEventDataOfSelectedEvent = () => {
    const event: IEvent.IStore = useSelector(SELECTORS.event);
    const { list: eventList, selectedEvent: selectedEventUuid } = event;
    const eventData = eventList[selectedEventUuid!];

    return {
        event,
        eventData,
        selectedEventUuid,
    };
};

export const getIsSelectingScheduleMandatory = (
    eventData: IEvent.IEventData
) => {
    const { type: eventType, availability_choice }: IEvent.IEventData =
        eventData;

    // if type is appointment and availability not decided (can add without schedule)
    if (
        (eventType === EVENT_TYPE.APPOINTMENTS &&
            availability_choice === AVAILABILITY_CHOICE.NOT_DECIDED) ||
        eventData?.allow_customer_scheduling_post_payment
    )
        return false;

    return [
        EVENT_TYPE.APPOINTMENTS,
        EVENT_TYPE.ONE_ON_ONE_CLASSES,
        EVENT_TYPE.GROUP_CLASSES,
        EVENT_TYPE.ROLLING_CLASSES,
        EVENT_TYPE.WEBINAR,
    ].includes(eventType);
};
