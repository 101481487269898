import { EXLY_BACKEND_API_HOST } from "repoV2/constants/apis";

export const THANKYOU_PAGE_API_URLS = {
    // sections data for ty pg
    GET_SECTION_DATA: ({
        listingUuid,
        hostName,
    }: {
        listingUuid: string;
        hostName: string;
    }) => `host/thankyou/page/details/${hostName}/${listingUuid}`,

    // payments
    ADD_CUSTOMER_ADDRESS_POST_BOOKING: `payments/exly/address/update`,
    GET_SUCCESS_PAGE_ORDER_DATA: ({
        transactionId,
    }: {
        transactionId: string;
    }) => `payments/successpage?order_uid=${transactionId}`,
    PAYMENT_REBOOK: ({ transactionId }: { transactionId: string }) =>
        `payments/order/action/rebook?order_uid=${transactionId}`,

    // HOST
    GET_HOST_DATA: ({ hostName }: { hostName: string }) =>
        `${EXLY_BACKEND_API_HOST}/host/view/${hostName}`,

    // Event Data
    GET_EVENT_DATA: ({
        hostName,
        eventId,
    }: {
        hostName: string;
        eventId: string;
    }) => `${EXLY_BACKEND_API_HOST}/host/listing/${hostName}/${eventId}`,

    // Transaction Status
    GET_TRANSACTION_STATUS: ({ transactionId }: { transactionId: string }) =>
        `${EXLY_BACKEND_API_HOST}/payments/exly/status?order_uid=${transactionId}`,
};
