import Router from "next/router";
import {
    APP_ROUTE_KEYS,
    ROUTE_BASE_PATHNAMES,
} from "repoV2/constants/urls&routing/routing";
import { IPageGetInitialPropsArgs } from "repoV2/interfaces/IPage";
import { checkIsStringEmpty } from "repoV2/utils/common/dataTypes/string";

import {
    getGoogleDriveDownloadableLink as _getGoogleDriveDownloadableLink,
    isGoogleDriveLink as _isGoogleDriveLink,
    isVimeoVideoLink as _isVimeoVideoLink,
    isLoomVideoUrl as _isLoomVideoUrl,
    getLoomVideoEmbedUrlFromShareUrl as _getLoomVideoEmbedUrlFromShareUrl,
    getAllUtmParams as _getAllUtmParams,
    // @ts-ignore
} from "@my-scoot/exly-react-component-lib/build/cjs/utils/common/url";
import type {
    getGoogleDriveDownloadableLink as getGoogleDriveDownloadableLinkType,
    isGoogleDriveLink as isGoogleDriveLinkType,
    isVimeoVideoLink as isVimeoVideoLinkType,
    isLoomVideoUrl as isLoomVideoUrlType,
    getLoomVideoEmbedUrlFromShareUrl as getLoomVideoEmbedUrlFromShareUrlType,
    getAllUtmParams as getAllUtmParamsType,
} from "@my-scoot/exly-react-component-lib/build/types/utils/common/url";
import { logError } from "repoV2/utils/common/error/error";
import { callApi } from "repoV2/utils/common/api/callApi";
import { appendURLSearchParams } from "repoV2/utils/urls&routing/urlParams";
import { isBrowser } from "repoV2/utils/common/render/window";
import {
    getSessionStorageItem,
    setSessionStorageItem,
} from "repoV2/utils/common/storage/getterAndSetters";
import {
    video_platforms_oembed_api_urls,
    vimeo_video_url_formats,
    youtube_video_url_formats,
} from "./URLsNRouting.constants";
import { SESSION_STORAGE_KEYS } from "../Storage/Storage.constants";

/**
 * This util returns the cdn url taking the file path
 * @param fileRelativePath string: file path of the file that has been uploaded on server
 * @returns public s3 url of the file
 */
export const getS3UploadUrl = (fileRelativePath: string) => {
    return `${process.env.NEXT_PUBLIC_S3_CDN_URL}/${fileRelativePath}`;
};

export const isWistiaLink = (url: string) => {
    if (checkIsStringEmpty(url)) return false;
    return url.includes("wistia");
};

export const getGoogleDriveDownloadableLink: typeof getGoogleDriveDownloadableLinkType =
    _getGoogleDriveDownloadableLink;

export const isGoogleDriveLink: typeof isGoogleDriveLinkType =
    _isGoogleDriveLink;

export const isVimeoVideoLink: typeof isVimeoVideoLinkType = _isVimeoVideoLink;

export const redirectOnFailureInGetInitialProps = (
    ctx: IPageGetInitialPropsArgs
) => {
    if (ctx?.res) {
        // server
        ctx.res.writeHead(302, {
            Location: ROUTE_BASE_PATHNAMES[APP_ROUTE_KEYS.HOME],
        });
        ctx.res.end();
    } else {
        // client
        Router.push(ROUTE_BASE_PATHNAMES[APP_ROUTE_KEYS.HOME]);
    }
};

export const checkIsYTVidUrl = (url: string) =>
    youtube_video_url_formats.some(format => url.startsWith(format));

/**
 * function to get youtube video ID from the youtube video URL
 */
export const getYTVidIdFromUrl = (url: string) => {
    try {
        if (checkIsYTVidUrl(url)) {
            const urlObj = new URL(url);
            let vidId: string | null = null;

            // fetch video id from https://www.youtube.com/watch?v=<vid_id> format
            const urlSearchParams = new URLSearchParams(urlObj.search);
            vidId = urlSearchParams.get("v");

            // Fetch video id from https://youtu.be/<vid_id> OR https://www.youtube.com/shorts/<vid_id> format.
            if (!vidId) {
                vidId = urlObj.pathname.replace("/shorts/", "");
                vidId = urlObj.pathname.replace("/", "");
            }

            if (vidId) return vidId;
        }
    } catch (e) {
        logError({
            error: e,
            occuredAt:
                "repoV2/features/Common/modules/URLsNRouting/URLsNRouting.utils.ts",
            when: "calling getYTVidIdUrl",
            extraErrorData: { url },
        });
    }
    return null;
};

/**
 * funtion to get YT video thumbnail URL from YT video URL
 * ref - https://stackoverflow.com/a/2068371
 */
export const getYTVidThumbnailUrl = (url: string) => {
    const vidId = getYTVidIdFromUrl(url);
    if (vidId) {
        return `https://img.youtube.com/vi/${vidId}/0.jpg`;
    }

    return null;
};

export const isLoomVideoUrl: typeof isLoomVideoUrlType = _isLoomVideoUrl;

export const getLoomVideoEmbedUrlFromShareUrl: typeof getLoomVideoEmbedUrlFromShareUrlType =
    _getLoomVideoEmbedUrlFromShareUrl;

export const checkIsVimeoVidUrl = (url: string) =>
    vimeo_video_url_formats.some(format => url.startsWith(format));

/**
 * function to get vimeo or wisita video Thumbnail using oembded
 * ref - https://stackoverflow.com/a/61839510 for vimeo
 * ref - https://docs.wistia.com/docs/wistia-and-oembed for wisita
 */
export const getOembedThumbnailUrl = async (url: string, type: string) => {
    const apiUrl = video_platforms_oembed_api_urls[type];
    if (!apiUrl) return null;
    const { data } = await callApi({
        url: appendURLSearchParams(apiUrl, { url }),
    });
    if (data && data.thumbnail_url) return data.thumbnail_url;
    return null;
};
export const getAllUtmParams: typeof getAllUtmParamsType = _getAllUtmParams;

/**
 * get reload count of current page from session storage
 */
export const getCurrentPageReloadCount = () => {
    if (!isBrowser()) return;
    const reloadCountMap =
        getSessionStorageItem(SESSION_STORAGE_KEYS.RELOAD_COUNT) || {};
    reloadCountMap[window.location.href] =
        reloadCountMap[window.location.href] || 0;
    return reloadCountMap[window.location.href];
};

/**
 * bump reload count of a page in session storage
 */
export const bumpCurrentPageReloadCount = () => {
    if (!isBrowser()) return;
    const reloadCountMap =
        getSessionStorageItem(SESSION_STORAGE_KEYS.RELOAD_COUNT) || {};
    reloadCountMap[window.location.href] =
        (reloadCountMap[window.location.href] || 0) + 1;
    setSessionStorageItem(SESSION_STORAGE_KEYS.RELOAD_COUNT, reloadCountMap);
};
