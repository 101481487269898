import { getAllUtmParams } from "repoV2/features/Common/modules/URLsNRouting/URLsNRouting.utils";
import { IUtmParams } from "repoV2/interfaces/IUtmParams";
import { isBrowser } from "../render/window";

export const getUtmParams = (): IUtmParams => {
    let utmDetails: IUtmParams = {
        source: null,
        medium: null,
        campaign: null,
        affiliate: null,
        term: null,
        content: null,
    };

    if (isBrowser()) {
        const utmParams = getAllUtmParams();
        utmDetails = {
            source: utmParams?.utm_source || null,
            medium: utmParams?.utm_medium || null,
            campaign: utmParams?.utm_campaign || null,
            affiliate: utmParams?.utm_affiliate || null,
            term: utmParams?.utm_term || null,
            content: utmParams?.utm_content || null,
        };
    }

    return utmDetails;
};
