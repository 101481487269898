/* eslint-disable no-case-declarations */
import {
    ACTION_TYPES,
    INDIA,
    DEFAULT_FONT,
    isEmpty,
    isBrowser,
    STORAGE_KEYS,
    UNITED_STATES,
    DEFAULT_TEMPLATE_KEY,
} from "@Utils";
import { setLocalStorageItem } from "repoV2/utils/common/storage/getterAndSetters";
import { IAction, IHost } from "@Interfaces";
import { DEFAULT_TIMEZONE } from "repoV2/features/Common/modules/Timezone/Timezone.constants";
import initialState from "../initialState";

export default function hostReducer(
    state = initialState?.host || {},
    { type, payload }: IAction<any>
): IHost.IStore {
    const data = payload?.response?.data || {};
    switch (type) {
        // TODO: Rename to UPDATE_HOST_DATA
        case ACTION_TYPES.HOST.UPDATE_DATA:
            return {
                ...state,
                dataHost: {
                    profile_data: data?.profile_data,
                },
            };

        case ACTION_TYPES.HOST.UPDATE_SITE_BUILDER_DATA: {
            const siteBuilderHostData = payload?.dataHost?.profile_data;
            const prevStateHostData = state?.dataHost?.profile_data;
            const hostData = {
                ...prevStateHostData,
                ...siteBuilderHostData,
                social_info: {
                    ...prevStateHostData?.social_info,
                    ...siteBuilderHostData?.social_info,
                },
            };
            const hostName = payload?.hostName || state?.hostName;
            const font = payload?.font || state?.font;
            const colors = payload?.theme?.colors || state?.theme?.colors;
            return {
                ...state,
                dataHost: { profile_data: hostData },
                theme: { ...state?.theme, colors },
                font,
                hostName,
            };
        }
        case ACTION_TYPES.HOST.UPDATE_PLAN_LIST:
            return {
                ...state,
                plans: {
                    ...state?.plans,
                    list: data?.plans || [],
                    title: data?.title || "Plans",
                },
            };

        case ACTION_TYPES.HOST.UPDATE_PLAN_DETAILS: {
            const { listings, ...cardData } = data || {};

            return {
                ...state,
                plans: {
                    ...state?.plans,
                    currentPlanUuid: payload?.apiCallArgs?.urlArgs?.planId,
                    details: {
                        ...state?.plans?.details,
                        [payload?.apiCallArgs?.urlArgs?.planId as string]: {
                            listings,
                            ...cardData,
                        },
                    },
                },
            };
        }

        case ACTION_TYPES.HOST.SET_CURRENT_PLAN_ID: {
            return {
                ...state,
                plans: {
                    ...state?.plans,
                    currentPlanUuid: payload?.planUuid,
                },
            };
        }

        case ACTION_TYPES.HOST.UPDATE_TEAM_DATA:
            return {
                ...state,
                team: {
                    data: data?.staff_members,
                },
            };

        case ACTION_TYPES.HOST.UPDATE_THEME_DATA:
            return {
                ...state,
                theme: {
                    ...data?.theme?.codes,
                    colors: data?.theme?.colors || data?.theme?.codes?.colors,
                },
                font: !isEmpty(data?.font) ? data?.font : DEFAULT_FONT,
                template: data?.template?.slug || DEFAULT_TEMPLATE_KEY,
            };

        case ACTION_TYPES.HOST.UPDATE_USER_LOCALE:
            const { timezone, countryCode, countryName } = payload;
            return {
                ...state,
                country: {
                    ...state.country,
                    locale: timezone || DEFAULT_TIMEZONE,
                    name:
                        countryName ||
                        (DEFAULT_TIMEZONE === timezone ? UNITED_STATES : INDIA),
                    code:
                        countryCode ||
                        (DEFAULT_TIMEZONE === timezone ? "IN" : "US"),
                },
            };

        case ACTION_TYPES.HOST.UPDATE_USER_IP:
            return {
                ...state,
                user_ip: payload?.ip || "",
            };

        case ACTION_TYPES.HOST.SET_HOST:
            return {
                ...state,
                hostName: payload?.hostName,
            };
        case ACTION_TYPES.HOST.UPDATE_CONTENT_LIST:
            return {
                ...state,
                content: {
                    ...state.content,
                    list: [...data],
                },
            };

        case ACTION_TYPES.HOST.UPDATE_CONTENT_DETAILS:
            return {
                ...state,
                content: {
                    ...state.content,
                    details: {
                        ...state.content.details,
                        [payload.apiCallArgs.urlArgs.listingId]: {
                            ...state.content.details[
                                payload.apiCallArgs.urlArgs.listingId
                            ],
                            ...data,
                        },
                    },
                },
            };

        case ACTION_TYPES.HOST.UPDATE_CONTENT_COMMENTS:
            return {
                ...state,
                content: {
                    ...state.content,
                    extraDetails: {
                        ...state.content.extraDetails,
                        comments: {
                            ...state.content.extraDetails.comments,
                            [payload.apiCallArgs.urlArgs.subCategoryUuid]: data,
                        },
                    },
                },
            };

        case ACTION_TYPES.HOST.UPDATE_CONTENT_CERTIFICATE: {
            const { listingUuid } = payload?.apiCallArgs?.urlArgs;
            const certificateData = payload?.response?.data;
            return {
                ...state,
                content: {
                    ...state.content,
                    details: {
                        ...state.content.details,
                        [listingUuid]: {
                            ...state.content.details[listingUuid],
                            certificate_details: certificateData,
                        },
                    },
                },
            };
        }

        case ACTION_TYPES.HOST.UPDATE_BLOG_POSTS:
            const fetchBlogPostsResponse = payload?.response?.data || {};

            return {
                ...state,
                blog: {
                    data:
                        payload?.apiCallArgs?.urlArgs?.page > 1
                            ? [
                                  ...state.blog.data,
                                  ...(fetchBlogPostsResponse?.results || []),
                              ]
                            : fetchBlogPostsResponse?.results || [],
                    total: fetchBlogPostsResponse?.total || 0,
                    total_pages: fetchBlogPostsResponse?.total_pages || 0,
                },
            };

        case ACTION_TYPES.HOST.UPDATE_BLOG_POST_DETAILS:
            return {
                ...state,
                blogPost: payload?.response?.data,
            };

        case ACTION_TYPES.HOST.UPDATE_BLOG_POST_COMMENTS:
            const blogPostComments = payload?.response?.data || {};
            return {
                ...state,
                blogPost: {
                    ...state.blogPost,
                },
                blogPostComments: {
                    data:
                        payload?.apiCallArgs?.urlArgs?.page > 1
                            ? [
                                  ...(state?.blogPostComments?.data || []),
                                  ...(blogPostComments?.results || []),
                              ]
                            : blogPostComments?.results || [],
                    total: blogPostComments?.total || 0,
                    total_pages: blogPostComments?.total_pages || 0,
                },
            };
        case ACTION_TYPES.HOST.UPDATE_COMMENT_REPLIES:
            const blogPostCommentReplies = payload?.response?.data || {};
            return {
                ...state,
                commentReplies: {
                    ...state.commentReplies,
                    results:
                        blogPostCommentReplies?.replies ||
                        blogPostCommentReplies?.results ||
                        [],
                    total: blogPostCommentReplies?.total,
                    total_pages: blogPostCommentReplies?.total_pages,
                },
            };

        case ACTION_TYPES.HOST.UPDATE_WATERMARK_TYPE:
            const watermark = payload?.response?.data || {};
            return { ...state, watermark };

        case ACTION_TYPES.HOST.UPDATE_BLOG_SUBSCRIPTION_EMAIL:
            const subscriptionEmail = payload?.response?.data?.email || "";
            if (isBrowser() && subscriptionEmail) {
                setLocalStorageItem(
                    STORAGE_KEYS.BLOG_SUBSCRIPTION_EMAIL,
                    subscriptionEmail
                );
            }
            return state;

        default:
            return state;
    }
}
