import { IUtils } from "@Interfaces";
import { ACTION_TYPES, createAction, SELECTORS, getRandomKey } from "@Utils";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useDataRead = <T = any>(key: string) => {
    const dataList: IUtils.IStore["data"] = useSelector(SELECTORS.data);
    const dataObj: { key: string; value: T; initiated: boolean } =
        dataList?.[key] || {};
    return dataObj;
};

// TODO: Create a type for keyProp with all the allowed discrete values for useData
// Hook that works in a similar way to useState, except that its stored in the redux store
export function useData<T = any>(
    keyProp: string,
    initialValue?: T
): { data: T; setData: (p: T) => void; deleteData: () => void; key: string } {
    const key: string = keyProp || getRandomKey(); // If no key is provided, use a random key for one-time usage
    const dispatch = useDispatch();

    const dataObj = useDataRead<T>(key);

    const setData = (value: T): void => {
        dispatch(
            createAction(ACTION_TYPES.UTILS.SET_DATA, {
                key,
                value,
            })
        );
    };

    useEffect(() => {
        // If data already exists, do not read from the initial value since that might
        // be an unintentional override in the case of a re-initialisation of the component.
        // Use the setData explicity if an override is needed
        if (initialValue !== undefined && !dataObj?.initiated) {
            setData(initialValue);
        }
    }, []);

    const deleteData = (): void => {
        dispatch(createAction(ACTION_TYPES.UTILS.DELETE_DATA, { key }));
    };

    return {
        data: dataObj?.value,
        setData,
        deleteData,
        key, // Return the key in case a random one was generated
    };
}
