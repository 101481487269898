import {
    APP_ROUTE_KEYS,
    NEXT_ROUTE_PATHNAMES,
} from "repoV2/constants/urls&routing/routing";

export const ANALYTICS_TRACKING_TYPE = {
    [NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.EVENT]]: 1,
    // Todo: Add affiliate changes here as well
    [NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.CHECKOUT_LISTING]]: 3,
    [NEXT_ROUTE_PATHNAMES[APP_ROUTE_KEYS.SALE]]: 4,
};
