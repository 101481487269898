export const TRANSACTION_STATUSES: {
    [k in "SUCCESS" | "PROCESSING" | "FAILURE"]: number;
} = {
    SUCCESS: 2,
    PROCESSING: 7,
    FAILURE: 8,
};

export const CURRENCY_STRINGS = {
    INR: "INR",
    USD: "USD",
    EUR: "EUR",
    GBP: "GBP",
    CAD: "CAD",
    AED: "AED",
    AUD: "AUD",
    IDR: "IDR",
    SAR: "SAR",
    ARS: "ARS",
    TTD: "TTD",
    NGN: "NGN",
    PKR: "PKR",
    MUR: "MUR",
    MMK: "MMK",
    EGP: "EGP",
    HUF: "HUF",
    ZAR: "ZAR",
    PHP: "PHP",
    THB: "THB",
    SGD: "SGD",
    KHR: "KHR",
    LAK: "LAK",
    BDT: "BDT",
    MYR: "MYR",
};
// TODO: Better type declaration
export const CURRENCY_SYMBOLS = {
    INR: "₹",
    USD: "$",
    EUR: "€",
    GBP: "£",
    CAD: "CA$",
    AED: "AED",
    AUD: "AU$",
    IDR: "Rp",
    SAR: "SR",
    ARS: "AR$",
    TTD: "TT$",
    NGN: "NGN",
    PKR: "PKRs",
    MUR: "MURs",
    MMK: "K",
    EGP: "EGP",
    HUF: "Ft",
    ZAR: "R",
    PHP: "PHP",
    THB: "THB",
    SGD: "S$",
    KHR: "KHR",
    LAK: "LAK",
    BDT: "Tk",
    MYR: "RM",
};

/**
 * @deprecated use the constant in repoV2/features/Payments/Payments.constants.ts
 * Enum for gateway names
 */
export const GATEWAYS_ENUM = {
    PAYPAL: "Paypal",
    RAZORPAY: "Razorpay",
    NEW_JUSPAY: "NewJuspay",
    FREE: "free",
    STRIPE: "Stripe",
    TAZAPAY: "Tazapay",
};

/**
 * @deprecated use the one in repoV2/features/Payments/Payments.constants.ts
 * Gateways supported in the standard transaction
 */
export const GATEWAYS_SUPPORTED: Array<string> = [
    GATEWAYS_ENUM.RAZORPAY,
    GATEWAYS_ENUM.TAZAPAY,
    GATEWAYS_ENUM.NEW_JUSPAY,
    GATEWAYS_ENUM.STRIPE,
];

export const PAYOUT_GATEWAY = {
    RAZORPAY: 1,
    STRIPE: 2,
    PAYTM: 3,
    CASH: 4,
    CASHFREE: 5,
    PHONEPE: 6,
    JUSPAY: 7,
    NEW_JUSPAY: 8,
    PAYPAL: 9,
    TAZAPAY: 10,
    BAJAJ_FINANCE: 11,
};
