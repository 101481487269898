import React from "react";

export const CurvedArrowSvg = (props: any): JSX.Element => {
    const { stroke } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200.623"
            height="114.809"
            viewBox="0 0 200.623 114.809"
            {...props}
        >
            <g
                id="Group_13402"
                data-name="Group 13402"
                transform="matrix(-0.891, 0.454, -0.454, -0.891, 1905.73, 2272.57)"
            >
                <g
                    id="Group_12761"
                    data-name="Group 12761"
                    transform="translate(715.359 2145.9) rotate(85)"
                >
                    <path
                        id="Path_5754"
                        data-name="Path 5754"
                        d="M0,38.032C57.08,72.379,93.83-4.231,67.179.185S91.627,71.234,174.933,27.44"
                        transform="matrix(-0.809, 0.588, -0.588, -0.809, 715.993, 141.983)"
                        fill="none"
                        stroke={stroke || "#fff"}
                        strokeLinecap="round"
                        strokeWidth="4"
                    />
                    <path
                        id="Path_5755"
                        data-name="Path 5755"
                        d="M17.855,0,12.617,15.343,0,1.909Z"
                        transform="matrix(-0.809, 0.588, -0.588, -0.809, 714.424, 109.447)"
                        fill="none"
                        stroke={stroke || "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                        fillRule="evenodd"
                    />
                </g>
                <g
                    id="Ellipse_104"
                    data-name="Ellipse 104"
                    transform="translate(513 2749)"
                    fill="none"
                    stroke={stroke || "#fff"}
                    strokeWidth="4"
                >
                    <circle cx="13.5" cy="13.5" r="11.5" fill="none" />
                </g>
            </g>
        </svg>
    );
};
