import {
    ORDER_PAYMENT_TYPE,
    PAYMENT_PLAN_TYPE,
} from "repoV2/constants/listing";

/**
 * Checks if a given payment plan type is equal to 'INSTALLMENTS'.
 *
 * @param {number} planType - The payment plan type to be checked.
 * @returns {boolean} True if the planType is 'INSTALLMENTS', otherwise false.
 */
export const isInstallmentsPlanType = (planType?: number) =>
    planType === PAYMENT_PLAN_TYPE.INSTALLMENTS;

export const isInstallmentPaymentType = (type?: number) =>
    type === ORDER_PAYMENT_TYPE.INITIAL_PART_PAYMENT;
