import { differenceInMilliseconds, isPast } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";

import { getFixedLengthNumString } from "repoV2/utils/common/dataTypes/string";
import { getTimezone } from "@Utils/getTimezone";
import { parseDateTime } from "@Utils/parseDateTime";
import { getCountryCodeDropdownOptionFromKey } from "utils/location";
import {
    DEFAULT_TIMEZONE,
    DEFAULT_TIMEZONE_OBJECT,
    TIMEZONE_DATA_LIST,
} from "../repoV2/features/Common/modules/Timezone/Timezone.constants";

export const UTC_OFFSET = 19800000; // The UTC offset of 5½ hours
// TODO: start replacing UTC_OFFSET with DYNAMIC_UTC_OFFSET
// export const DYNAMIC_UTC_OFFSET = moment().utcOffset() * 60 * 1000;
export const DYNAMIC_UTC_OFFSET = getTimezoneOffset(
    getTimezone().timezone,
    Date.now()
);

// Whether the timestamp is in the past(includes current moment)
// Timestamps are being compared in UTC since just a true/false is needed, not the date difference
export const isTimePassed = (timeStamp: number): boolean => {
    return isPast(timeStamp);
    // const targetDate = moment(timeStamp).utc();
    // const currentDate = moment().utc();

    // const diff = targetDate.valueOf() - currentDate.valueOf();

    // #tags: test
    // Use the following for debugging
    // console.log(
    //     "=====In isTimePassed=====",
    //     `targetDate(${targetDate.valueOf()}, ${targetDate.format(
    //         "ddd, DD MMM, hh:mm A Z"
    //     )}) - currentDate(${currentDate.valueOf()}, ${currentDate.format(
    //         "ddd, DD MMM, hh:mm A Z"
    //     )}) = ${diff} (${moment(diff).format("hh:mm")})`
    // );

    // return diff < 0;
};

/**
 * @deprecated use from repoV2/features/Common/modules/DateTime/modules/Time/utils/Time.utils
 */
export const timeLeft = (timeStamp: number) => {
    let timeLeftString = "";

    const currentDate = parseDateTime(Date.now());
    const targetDate = parseDateTime(timeStamp);

    // const tzDiff = adjustForLocale ? DYNAMIC_UTC_OFFSET * (60 * 1000) : 0;

    const diff = differenceInMilliseconds(targetDate, currentDate);

    // const dFormat = "dd-MM-yyyy, HH:mm:ss z";
    // console.clear();
    // console.table([
    //     ["targetDate", Date.now(), format(targetDate, dFormat)],
    //     [
    //         "currentDate",
    //         timeStamp - DYNAMIC_UTC_OFFSET,
    //         format(currentDate, dFormat),
    //     ],
    //     [
    //         "diff",
    //         timeStamp - DYNAMIC_UTC_OFFSET - Date.now(),
    //         format(diff, dFormat),
    //     ],
    // ]);

    // const currentDate = moment().tz(getTimezone().timezone);
    // const targetDate = moment(timeStamp - UTC_OFFSET).tz(
    //     getTimezone().timezone
    // );
    // const tzDiff = adjustForLocale ? targetDate.utcOffset() * (60 * 1000) : 0;

    // const diff = moment(
    //     targetDate.valueOf() - currentDate.valueOf() + tzDiff
    // ).valueOf();

    const timeLeftObj = {
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor(diff / (1000 * 60 * 60)) % 24,
        minutes: Math.floor(diff / (1000 * 60)) % 60,
        seconds: Math.floor(diff / 1000) % 60,
    };

    // Go from largest unit to smallest unit.
    // If (current unit === 0) && (all units greater than current unit === 0),
    // don't display current unit (make it = -1)
    if (timeLeftObj.days <= 0) {
        timeLeftObj.days = -1;
        if (timeLeftObj.hours <= 0) {
            timeLeftObj.hours = -1;
            if (timeLeftObj.minutes <= 0) {
                timeLeftObj.minutes = -1;
                if (timeLeftObj.seconds <= 0) {
                    timeLeftObj.seconds = -1;
                }
            }
        }
    }

    if (timeLeftObj.seconds >= 0) {
        timeLeftString =
            (timeLeftObj.days >= 0 ? `${timeLeftObj.days} DAY : ` : "") +
            (timeLeftObj.hours >= 0
                ? `${getFixedLengthNumString(timeLeftObj.hours)} HR : `
                : "") +
            (timeLeftObj.minutes >= 0
                ? `${getFixedLengthNumString(timeLeftObj.minutes)} MIN : `
                : "") +
            (timeLeftObj.seconds >= 0
                ? `${getFixedLengthNumString(timeLeftObj.seconds)} SEC`
                : "");
    }

    return { timeLeftObj, timeLeftString };
};

/**
 * @deprecated
 * use `getCountryDataBasedOnTimezone` from `repoV2/features/Common/modules/Timezone/Timezone.utils.ts`
 */
export const getCountryDataFromTimezone = (
    timezone: string
): { countryCode: string; countryName: string } => {
    const countryCode =
        TIMEZONE_DATA_LIST.find(t => t.name === timezone)?.country_code ||
        (timezone === DEFAULT_TIMEZONE
            ? DEFAULT_TIMEZONE_OBJECT.country_code
            : "US");

    const countryName = getCountryCodeDropdownOptionFromKey(countryCode).name;

    return { countryCode, countryName };
};
