import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import styles from "./keywords.module.scss";

export const Keywords = ({
    keywords,
    keywordClick,
    selectedKeyword,
    styles: stylesProp,
    template,
}: {
    keywords: Array<string>;
    keywordClick: (param: string) => void;
    selectedKeyword: string;
    styles?: {
        keywordsContainer?: string;
        keyword?: string;
        selectedKeyword?: string;
    };
    template: string;
}) => {
    if (keywords.length > 1)
        return (
            <div
                className={classNames(
                    stylesProp?.keywordsContainer,
                    styles.keywordsContainer,
                    styles?.[template]
                )}
            >
                {keywords.map(keyword => (
                    <button
                        onClick={() => keywordClick(keyword)}
                        className={classNames(
                            stylesProp?.keyword,
                            styles.keyword,
                            keyword === selectedKeyword &&
                                stylesProp?.selectedKeyword,
                            keyword === selectedKeyword &&
                                styles.selectedKeyword
                        )}
                        key={keyword}
                        type="button"
                    >
                        {keyword}
                    </button>
                ))}
            </div>
        );

    return null;
};

Keywords.defaultProps = {
    styles: undefined,
};
