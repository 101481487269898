/**
|                       | message                | status_code | http_status_code |
| --------------------- | ---------------------- | ----------- | ---------------- |
| AUTH_TOKEN_MISSING    | Auth Token Missing     | 1           | 400              |
| INVALID_AUTH_TOKEN    | Invalid Auth Token     | 2           | 400              |
| ACCESS_TOKEN_MISSING  | Access Token Missing   | 3           | 400              |
| INVALID_JWT_TOKEN     | Invalid JWT Token      | 4           | 401              |
| ACCESS_TOKEN_EXPIRED  | Access Token Expired   | 5           | 401              |
| UNKNOWN_USER          | Unknown User           | 6           | 400              |
| REFRESH_TOKEN_EXPIRED | Refresh Token Expired  | 7           | 401              |
| REFRESH_TOKEN_MISSING | Refresh Token Missing  | 8           | 401              |
| INVALID_PLATFORM      | Platform not allowed   | 9           | 401              |
| INVALID_CREDENTAILS   | Operations not allowed | 10          | 401              |
 */
export const AUTH_STATUS_CODES = {
    AUTH_TOKEN_MISSING: 1,
    INVALID_AUTH_TOKEN: 2,
    ACCESS_TOKEN_MISSING: 3,
    INVALID_JWT_TOKEN: 4,
    ACCESS_TOKEN_EXPIRED: 5,
    UNKNOWN_USER: 6,
    REFRESH_TOKEN_EXPIRED: 7,
    REFRESH_TOKEN_MISSING: 8,
    INVALID_PLATFORM: 9,
    INVALID_CREDENTAILS: 10,
};
