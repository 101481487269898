/**
 * Inspired by https://www.npmjs.com/package/classnames
 * works like the `classnames` library, congregating multiple css classes into one string
 */
export function classNames(
    ...classes: Array<string | boolean | null | undefined | number>
): string {
    return classes
        .filter(c => typeof c === "string")
        .join(" ")
        .trim();
}
