import React from "react";
import { useSelector } from "react-redux";

import { SELECTORS, getCustomStripeKey } from "@Utils";
import StripeForm from "./StripeForm";

/**
 * @deprecated Use Stripe component at repoV2/features/Payments/modules/Stripe/Stripe.tsx
 */
const Stripe = () => {
    const hostData = useSelector(SELECTORS.hostData);

    // `stripeKey` is to be null initially until it is known if it is to
    // be loaded for a domestic or an international case
    let stripeKey: string | undefined;
    let stripeConnectId: string | undefined;
    if (hostData) {
        const customStripeKey = getCustomStripeKey(hostData);

        stripeKey = `${
            customStripeKey ||
            (hostData?.is_international_creator
                ? process.env.NEXT_PUBLIC_STRIPE_INTERNATIONAL_CLIENT_KEY
                : process.env.NEXT_PUBLIC_STRIPE_DOMESTIC_CLIENT_KEY)
        }`;
        stripeConnectId = hostData?.stripe_connect_id;
    }

    if (!stripeKey) return null;

    return (
        <StripeForm stripeKey={stripeKey} stripeConnectId={stripeConnectId} />
    );
};

export default Stripe;
