import { all, put, call, takeEvery, select } from "redux-saga/effects";

import {
    ACTION_TYPES,
    createAction,
    FETCH_STATUS,
    // API_ACTION_TYPES,
    API_ACTIONS,
    ApiCall,
    IAction,
    SELECTORS,
} from "@Utils";
import { IHost } from "@Interfaces";
import { DEFAULT_TIMEZONE } from "repoV2/features/Common/modules/Timezone/Timezone.constants";
import { getBrowserTimezone } from "@Utils/getBrowserTimezone";
import { isBrowser } from "repoV2/utils/common/render/window";

// TODO: Proper interfaces
// import { IAction } from "@Interfaces";

function* updateFetchStatus(resourceType: string, fetchStatus: string) {
    // TODO: Control logging with .env param
    // console.log("updateFetchStatus", resourceType, "=>", fetchStatus);
    yield put(
        createAction(ACTION_TYPES.UTILS.UPDATE_API_CALL_STATUS, {
            [resourceType]: fetchStatus,
        })
    );
}

export namespace IFetchDataAction {
    export interface IReturnPayload {
        data?: any;
        response: {
            status: number;
            data?: any;
            message: string;
        };
        apiCallArgs: IAction.IPayloadProps;
        metadata: IPayload["metadata"];
    }
    export interface IPayload {
        apiActionType: string;
        urlArgs: { [k: string]: string };
        queryParams?: { [k: string]: string };
        payload?: { [k: string]: string } | null;
        headers?: { [k: string]: string };
        shouldRelogin?: boolean;

        successAction?: string | Array<string>;
        errorAction?: string | Array<string>;

        successCallback?: (k: IReturnPayload) => void;
        errorCallback?: (k: IReturnPayload) => void;
        finallyCallback?: (k: IReturnPayload) => void;

        metadata?: any;
    }
}

// Takes in multiple types of actionTypes and always returns an array
const standardizeActionArray = (
    successAction: string | Array<string> | undefined | null
): Array<string> => {
    if (Array.isArray(successAction)) return successAction;
    if (typeof successAction === "string") return [successAction];
    return [];
};

/*
Note:
The best way to encode the uri params is to pass them as the object in queryParams
and axios takes care of the rest.

If you manually append them in the url, you need to encode each param value like this
`https://www.website.com?param1=${encodeURIComponent(param1Value)&param2=${encodeURIComponent(param2Value)}`
*/

export function* fetchData({ payload: args }: any) {
    // TODO: Proper types for actions
    const {
        apiActionType,
        urlArgs,
        queryParams,
        payload = null,
        headers = {},

        successAction: argSuccessAction,
        errorAction: argErrorAction,
        successCallback: argSuccessCallback, // Callback to call on success
        errorCallback: argErrorCallback, // Callback to call on error
        finallyCallback, // Callback to call irrespective
        metadata,
    }: IFetchDataAction.IPayload = args;

    const {
        baseURL,
        url,
        method,
        disableAuthHeaders = false,
        disableTimezoneHeaders = false,
        shouldRelogin,

        successAction: constSuccessAction,
        successStatus = [200],
        errorAction: constErrorAction,
        successCallback: constSuccessCallback,
        errorCallback: constErrorCallback,
    } = API_ACTIONS[apiActionType];

    try {
        yield put(
            createAction(ACTION_TYPES.UTILS.UPDATE_API_CALL_STATUS, {
                [apiActionType]: FETCH_STATUS.FETCHING,
            })
        );

        const { locale }: IHost.ICountryInfo = yield select(SELECTORS.country);

        const { status, data: response } = yield call<any>(ApiCall, {
            url: url?.(urlArgs) || "",
            baseURL,
            method,
            payload,
            queryParams,
            configOptions: {
                disableAuthHeaders,
                disableTimezoneHeaders,
                shouldRelogin,
            },
            headers: {
                ...(!disableTimezoneHeaders && {
                    "user-timezone": locale,
                    "system-timezone": isBrowser()
                        ? getBrowserTimezone()
                        : DEFAULT_TIMEZONE,
                }),
                ...headers,
            },
        });

        // if (url?.(urlArgs)?.includes("/host/view/")) {
        //     console.log("In saga", {
        //         apiActionType,
        //         urlArgs,
        //         queryParams,
        //         status,
        //         response,
        //     });
        // }

        // Payload for the response callback/action. Contains the data
        // from th API response and the original action payload
        const responsePayload: IFetchDataAction.IReturnPayload = {
            response,
            apiCallArgs: args,
            metadata,
        };

        // TODO: <event>actions as an array instead of a single action
        // If the call was successful, handle the success
        if (successStatus.includes(status)) {
            const successActions = [
                ...standardizeActionArray(constSuccessAction),
                ...standardizeActionArray(argSuccessAction),
            ];
            yield updateFetchStatus(apiActionType, FETCH_STATUS.FETCHED);
            yield all(
                successActions.map((actionItem: string) =>
                    put(createAction(actionItem, responsePayload))
                )
            );
            if (argSuccessCallback) {
                yield call(argSuccessCallback, responsePayload);
            }
            if (constSuccessCallback) {
                yield call(constSuccessCallback, responsePayload);
            }
        } else {
            // If the call was successful but returned an error, handle that
            const errorActions = [
                ...standardizeActionArray(constErrorAction),
                ...standardizeActionArray(argErrorAction),
                ACTION_TYPES.UTILS.LOG_ERROR,
            ];
            yield updateFetchStatus(apiActionType, FETCH_STATUS.FETCH_ERROR);
            yield all(
                errorActions.map((actionItem: string) =>
                    put(createAction(actionItem, responsePayload))
                )
            );

            if (argErrorCallback) {
                yield call(argErrorCallback, responsePayload);
            }
            if (constErrorCallback) {
                yield call(constErrorCallback, responsePayload);
            }
        }
        // The finallyCallback will be called after the API call is over, no matter what the result.
        // Eg: To stop a spinner on either a success or fail.
        if (finallyCallback) {
            yield call(finallyCallback, responsePayload);
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        // console.log("Axios error", { error, apiActionType });
        // These errors are JS or back-end errors, not deliberate API errors
        yield put(
            createAction(
                ACTION_TYPES.UTILS.SHOW_ALERT,
                error as IAction.IPayloadProps
            )
        );
    }
}

export default function* apiSaga() {
    yield takeEvery(ACTION_TYPES.UTILS.API_CALL, fetchData);
}
