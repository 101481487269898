import { IAccount } from "./IAccount";
import { INavbar } from "../../INavbar";

export const getAccountProps = ({
    loggedInState,
    navbarItems,
}: {
    loggedInState: INavbar.ILoggedInState;
    navbarItems: INavbar.IInternalProps["navbarItems"];
}): IAccount.IBaseProps => {
    const accountItems: IAccount.IBaseProps["accountItems"] = [
        navbarItems.myClasses,
        navbarItems.myMeetings,
        navbarItems.purchaseHistory,
        navbarItems.logout,
    ];

    return { accountItems, hideComponent: loggedInState !== "loggedIn" };
};
