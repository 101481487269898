import { isValid } from "date-fns";

export const checkSpaces = (str: string) => {
    const inValid = /\s/;
    return inValid.test(str);
};

export const isCountryCodeIndia = (countryCode: string = ""): boolean =>
    typeof countryCode === "string" &&
    (countryCode.toUpperCase() === "IN" ||
        countryCode.toUpperCase() === "IND" ||
        countryCode === "356");

export const isValidCountryCode = (countryCode: string = ""): boolean =>
    typeof countryCode === "string" &&
    (/^[a-zA-Z]{2,3}$/.test(countryCode) || /^[0-9]{3}$/.test(countryCode));

/**
 *
 * @param dateString The string to test for the date format passed in the second param
 * @param dateFormatToTest The date format to test against. If left empty, the default date format used by `moment().format()` is used`
 * @returns boolean
 */
export const isDateFormatValid = (
    dateString: string
    // TODO @aakarsh: Remove this function in favor of date-fns/isValid
    // dateFormatToTest: string = "YYYY-MM-DDTHH:mm:ssZ" // The default format in moment().format() if no param is passed
): boolean => isValid(new Date(dateString));
