/* eslint-disable no-case-declarations */
import { ACTION_TYPES } from "@Utils";

import { IAction, IUser } from "@Interfaces";
import initialState from "../initialState";

export default function userReducer(
    state = initialState?.user || {},
    { type, payload }: IAction<any>
): IUser.IStore {
    switch (type) {
        case ACTION_TYPES.USER.UPDATE_AUTH_TOKEN:
            const { access_token: accessToken, refresh_token: refreshToken } =
                payload?.response?.data?.tokens;

            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    ...(accessToken && { accessToken }),
                    ...(refreshToken && { refreshToken }),
                },
            };

        case ACTION_TYPES.USER.UPDATE_EMAIL:
            return {
                ...state,
                email: payload?.apiCallArgs?.urlArgs?.email,
            };

        case ACTION_TYPES.USER.RESET_EMAIL:
            return {
                ...state,
                email: null,
            };

        case ACTION_TYPES.USER.UPDATE_USERNAME:
            return {
                ...state,
                username: payload?.response?.data?.usernames?.[0], // first user username in case of multiple users
                usernameList: payload?.response?.data?.usernames, // List of users
            };

        case ACTION_TYPES.USER.UPDATE_RENEW_USERNAME:
            return {
                ...state,
                username: payload?.response?.data?.username, // for rebooking flow, only one user is booked

                // Even though there is only one user in the rebooking flow, the state.user.usernameList is
                // used in event>sagas>initiatePayment() to map transaction answers to the users, where the
                // loop that inserts the booking answers into the initiate API payload does not have any
                // special handling for rebooking flow, so adding this usernameList here allows to keep that
                // code generic.
                usernameList: [payload?.response?.data?.username], // List of users
            };

        // TODO: delete after migration to formv2
        case ACTION_TYPES.USER.SET_FORM_DETAILS:
            // update the passed fields and keep the previous values for the rest
            if (!payload?.key)
                // TODO: Decide if throwing an error is the best way to do this
                throw new Error(
                    `Must provide a key for ${ACTION_TYPES.USER.SET_FORM_DETAILS}`
                );
            return {
                ...state,
                formDetails: {
                    ...state.formDetails,
                    [payload?.key]: {
                        ...state.formDetails[payload?.key],
                        ...payload.data,
                    },
                },
            };

        // TODO: delete after migration to formv2
        case ACTION_TYPES.USER.INITIATE_FORM_FIELDS:
            // only initiate those fields that are passed
            if (!payload?.key)
                // TODO: Decide if throwing an error is the best way to do this
                throw new Error(
                    `Must provide a key for ${ACTION_TYPES.USER.INITIATE_FORM_FIELDS}`
                );
            return {
                ...state,
                formDetails: {
                    ...state.formDetails,
                    [payload?.key]: {
                        ...payload.data,
                    },
                },
            };

        // TODO: delete after migration to formv2
        case ACTION_TYPES.USER.CLEAR_FORM_DETAILS:
            // TODO: Check if Does this cause lag?
            // TODO: Use Ramda JS?
            const newFormDetails: IUser.IStore["formDetails"] = {
                ...state.formDetails,
            };
            if (payload?.key) {
                delete newFormDetails[payload?.key];
            }
            return {
                ...state,
                formDetails: newFormDetails || {},
            };

        case ACTION_TYPES.USER.INITIATE_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [payload.key]: {
                        key: payload.key,
                        fields: payload.fields,
                        options: payload.options,
                        initiated: payload.initiated,
                    },
                },
            };
        case ACTION_TYPES.USER.RESET_FORM_DATA:
        case ACTION_TYPES.USER.SET_FORM_DATA:
        case ACTION_TYPES.USER.ADD_FORM_FIELDS:
        case ACTION_TYPES.USER.DELETE_FORM_FIELDS:
            /*
            The actions are same because the dispatch call handles the data manipulation.
            But the cases are kept different for easy tracking of redux form changes.
            */
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [payload.key]: {
                        ...state.forms[payload.key],
                        fields: payload.fields,
                    },
                },
            };
        case ACTION_TYPES.USER.DELETE_FORM:
            let newForms = {};
            Object.entries(state.forms).forEach(([formKey, formData]) => {
                if (!payload.keys.includes(formKey))
                    newForms = { ...newForms, [formKey]: formData };
            });
            return {
                ...state,
                forms: newForms,
            };

        case ACTION_TYPES.USER.UPDATE_ORDER_DETAILS: {
            const {
                order,
                telegram_data: telegramData,
            }: {
                order: IUser.IOrder;
                telegram_data: IUser.IOrderTelegramData;
            } = payload?.response?.data;
            return {
                ...state,
                orders: {
                    ...state?.orders,
                    [order?.order_id]: {
                        orderData: order,
                        telegramData,
                    },
                },
            };
        }

        case ACTION_TYPES.USER.SET_BOOKINGS_OF_SKU_TYPE:
            return {
                ...state,
                bookings: {
                    ...state?.bookings,
                    [payload.skuTitle]: {
                        data:
                            payload?.apiCallArgs?.urlArgs?.page > 1
                                ? [
                                      ...(state?.bookings?.[payload.skuTitle]
                                          ?.data || []),
                                      ...(payload?.data || []),
                                  ]
                                : payload?.data || [],
                        skuType: payload.skuType,
                        sku_title: payload.skuTitle,
                        total: payload.total,
                        total_pages: payload.total_pages,
                    },
                },
            };

        case ACTION_TYPES.USER.SET_PURCHASE_HISTORY:
            const responseData = payload?.response?.data;

            const newData =
                payload?.apiCallArgs?.urlArgs?.page > 1
                    ? [
                          ...(state?.purchaseHistory?.data || []),
                          ...(payload?.response?.data?.purchase_history || []),
                      ]
                    : responseData?.purchase_history || [];
            return {
                ...state,
                purchaseHistory: {
                    data: newData,
                    total: responseData?.total || 0,
                    total_pages: responseData?.total_pages || 0,
                },
            };

        case ACTION_TYPES.USER.UPDATE_ANSWERS:
            return {
                ...state,
                answers: {
                    ...payload?.answers,
                },
            };
        case ACTION_TYPES.USER.UPDATE_ANSWER_THUMBNAILS:
            return {
                ...state,
                thumbnails: {
                    ...payload?.thumbnails,
                },
            };
        case ACTION_TYPES.USER.UPDATE_NOTIFICATIONS:
            const notificationsData = payload?.response?.data;
            return {
                ...state,
                notifications: {
                    data:
                        notificationsData?.page > 1
                            ? [
                                  ...(state?.notifications?.data || []),
                                  ...(notificationsData?.customer_notifications ||
                                      []),
                              ]
                            : notificationsData?.customer_notifications || [],
                    total: notificationsData?.total || 0,
                    total_pages: notificationsData?.total_pages || 0,
                },
            };

        default:
            return state;
    }
}
