import { ButtonV2 } from "@Modules/common/ButtonV2";
import { IButtonV2 } from "@Modules/common/ButtonV2/IButtonV2";
import {
    ADDRESS_TYPE,
    EVENT_TYPE,
    JOIN_MEETING,
    SELECTORS,
    isBrowser,
    timeLeft,
    useTimer,
} from "@Utils";
import { getExlyConnectMeetingUrl } from "@Utils/urls";
import { MdContentCopy } from "@react-icons/all-files/md/MdContentCopy";
import React from "react";
import { useSelector } from "react-redux";
import { classNames } from "repoV2/utils/common/render/classNames";
import { parseDateTime } from "@Utils/parseDateTime";
import { Popup, usePopup } from "..";
import { IMyClassAddressPopup } from "./IMyClassAddressPopup";
import styles from "./MyClassAddressPopup.module.scss";
import { useMyClassAddressPopup } from "./data";

export const MyClassAddressPopup = ({
    data,
    isLive,
    nextSlot,
    closePopup,
}: IMyClassAddressPopup.IProps): JSX.Element => {
    const { closePopupWithAnimation, closing, animationDuration } = usePopup({
        closePopup,
    });
    const { showExlyConnectZoomAppUrl, exlyConnectZoomAppUrl } =
        useMyClassAddressPopup({ data });

    const listingAddressType = data?.listing__address_type ?? ADDRESS_TYPE.NONE;
    const physicalAddressInfo =
        listingAddressType === ADDRESS_TYPE.PERSONAL_ADDRESS &&
        data?.venue_details?.address;
    const whatsappGroupLink = data?.listing__type === EVENT_TYPE.WHATSAPP;
    const host = useSelector(SELECTORS.host);
    const hideOnlineVenueDetails =
        host?.dataHost?.profile_data?.hide_venue_details;
    let hideCopyButton = hideOnlineVenueDetails;

    let videoCallInfo =
        [
            ADDRESS_TYPE.MEET_AUTO_GENERATE,
            ADDRESS_TYPE.EXISTING_LINK,
            ADDRESS_TYPE.ZOOM_AUTO_GENERATE,
            ADDRESS_TYPE.ZOOM_WEBINAR,
        ].includes(listingAddressType) && data?.venue_details?.joining_link;
    const videoCallInfoObj = {
        ctaText: hideOnlineVenueDetails ? JOIN_MEETING : videoCallInfo || "",
        ctaDesign: hideOnlineVenueDetails
            ? "rounded corners"
            : ("plain" as IButtonV2["design"]),
        ctaClassName: hideOnlineVenueDetails
            ? styles.joinMeetButton
            : styles.meetLink,
    };
    if (listingAddressType === ADDRESS_TYPE.EXLY_CONNECT) {
        videoCallInfo = getExlyConnectMeetingUrl(
            data?.venue_details?.meeting_uuid
        );
        videoCallInfoObj.ctaText = JOIN_MEETING;
        videoCallInfoObj.ctaDesign = "rounded corners";
        videoCallInfoObj.ctaClassName = "w-100";
        hideCopyButton = true;
    }

    const phoneInfo =
        ADDRESS_TYPE.PHONE_NUMBER === listingAddressType &&
        data?.venue_details?.contact_number;

    useTimer(!!nextSlot);

    const whatsAppInfo =
        ADDRESS_TYPE.WHATSAPP === listingAddressType &&
        data?.venue_details?.whatsapp_link;

    useTimer(!!nextSlot);

    const { timeLeftString: nextSlotTimeLeftString } = timeLeft(
        parseDateTime(nextSlot?.start_datetime!).valueOf()
    );

    const primaryButton: {
        content: string;
        onClick?: () => void;
        render?: React.ReactNode;
        design: IButtonV2["design"];
        className?: string;
    } = { content: "", design: "plain" };
    primaryButton.content =
        physicalAddressInfo || videoCallInfo || phoneInfo || whatsAppInfo || "";

    switch (primaryButton.content) {
        case physicalAddressInfo:
            primaryButton.render = physicalAddressInfo;
            break;
        case videoCallInfo:
            primaryButton.content = videoCallInfoObj.ctaText;
            primaryButton.onClick = () => {
                if (isBrowser()) {
                    window.open(videoCallInfo || "", "_blank");
                }
            };
            primaryButton.design = videoCallInfoObj.ctaDesign;
            primaryButton.className = videoCallInfoObj.ctaClassName;
            break;
        case phoneInfo:
            primaryButton.onClick = () => {
                if (isBrowser()) {
                    window.open(`tel:${phoneInfo}`, "_blank");
                }
            };
            break;
        case whatsAppInfo:
            primaryButton.onClick = () => {
                if (isBrowser()) {
                    window.open(`https://wa.me/${whatsAppInfo}`, "_blank");
                }
            };
            break;
        default:
            break;
    }
    return (
        <Popup
            closePopup={closePopupWithAnimation}
            containerClass={styles.container}
            closing={closing}
            animationDuration={animationDuration}
            classNames={{
                root: styles.popupRoot,
            }}
        >
            <>
                <div className={styles.header}>
                    <div className={styles.boldText}>
                        {physicalAddressInfo ? "Address" : null}
                        {videoCallInfo && data?.video_platform?.title
                            ? `Join ${data?.video_platform?.title}`
                            : null}
                        {(phoneInfo || whatsAppInfo) &&
                        data?.video_platform?.title
                            ? `Join ${data?.video_platform?.title} Call`
                            : null}
                        &nbsp;
                        {whatsappGroupLink ? "Whatsapp Group Link" : null}
                    </div>
                    <ButtonV2
                        className={classNames(
                            styles.button,
                            styles.closeButton
                        )}
                        design="plain"
                        onClick={closePopupWithAnimation}
                    >
                        &#10006;
                    </ButtonV2>
                </div>
                <div
                    className={classNames(
                        styles.centerAlignedContainer,
                        styles.body
                    )}
                >
                    {primaryButton.content ? (
                        <div
                            className={classNames(
                                styles.addressInfosContainer,
                                "w-100"
                            )}
                        >
                            <div
                                className={classNames(
                                    styles.addressInfosContainer,
                                    "w-100"
                                )}
                            >
                                {primaryButton.render ? (
                                    <div className={styles.boldText}>
                                        {primaryButton.render}
                                    </div>
                                ) : (
                                    <ButtonV2
                                        applyTheme
                                        design={primaryButton.design}
                                        className={primaryButton.className}
                                        onClick={primaryButton.onClick}
                                    >
                                        {primaryButton.content}
                                    </ButtonV2>
                                )}
                                {!hideCopyButton ? (
                                    <ButtonV2
                                        className={classNames(
                                            "ml-3",
                                            styles.button
                                        )}
                                        design="plain"
                                        onClick={() => {
                                            navigator.clipboard
                                                .writeText(
                                                    primaryButton.content
                                                )
                                                .then(() => {
                                                    alert(
                                                        "copied successfully"
                                                    );
                                                })
                                                .catch(() => {
                                                    alert(
                                                        "failed to copy, please copy manually!"
                                                    );
                                                });
                                        }}
                                    >
                                        <MdContentCopy size={18} />
                                    </ButtonV2>
                                ) : null}
                            </div>
                            {videoCallInfo &&
                            data?.venue_details?.joining_password ? (
                                <div className={styles.addressInfoContainer}>
                                    Passcode:&nbsp;
                                    <div
                                        className={classNames(styles.boldText)}
                                    >
                                        {data?.venue_details?.joining_password}
                                    </div>
                                </div>
                            ) : null}
                            {videoCallInfo && showExlyConnectZoomAppUrl ? (
                                <div
                                    className={classNames(
                                        styles.addressInfoContainer,
                                        "d-flex flex-column align-items-center w-100"
                                    )}
                                >
                                    <hr className="w-100 m-0 mb-2" />
                                    <span>
                                        Facing issues?{" "}
                                        <a
                                            className={styles.joinViaZoomButton}
                                            href={exlyConnectZoomAppUrl}
                                        >
                                            Join via Zoom
                                        </a>
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    {!isLive && nextSlot ? (
                        <>
                            <div
                                className={classNames(
                                    styles.boldText,
                                    styles.largeText
                                )}
                            >
                                Looks like you are very early
                            </div>
                            <div
                                className={classNames(
                                    styles.orangeContainer,
                                    styles.centerAlignedContainer,
                                    styles.startsOnContainer
                                )}
                            >
                                <div className={styles.smallText}>
                                    Class starts on
                                </div>
                                <div
                                    className={classNames(
                                        styles.boldText,
                                        styles.largeText
                                    )}
                                >
                                    {parseDateTime(
                                        nextSlot?.start_datetime,
                                        "E, MMM dd, yyyy, hh:mm a"
                                    )}
                                </div>
                            </div>
                            {nextSlotTimeLeftString &&
                            data?.listing__type &&
                            [
                                EVENT_TYPE.WORKSHOPS,
                                EVENT_TYPE.APPOINTMENTS,
                                EVENT_TYPE.WEBINAR,
                            ].includes(data?.listing__type) ? (
                                <div
                                    className={classNames(
                                        styles.orangeContainer
                                    )}
                                >
                                    <div
                                        className={classNames(
                                            styles.orangeText,
                                            styles.smallText,
                                            styles.boldText,
                                            styles.timeLeft
                                        )}
                                    >
                                        {nextSlotTimeLeftString}
                                    </div>
                                </div>
                            ) : null}
                        </>
                    ) : null}
                </div>
            </>
        </Popup>
    );
};
