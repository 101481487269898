export const HTTP_STATUS_SUCCESS_CODES = {
    HTTP_STATUS_OK: 200,
    HTTP_STATUS_CREATED: 201,
    HTTP_STATUS_ACCEPTED: 202,
    HTTP_STATUS_NON_AUTHORITATIVE_INFORMATION: 203,
    HTTP_STATUS_NO_CONTENT: 204,
    HTTP_STATUS_RESET_CONTENT: 205,
    HTTP_STATUS_PARTIAL_CONTENT: 206,
    HTTP_STATUS_MULTI_STATUS: 207,
    HTTP_STATUS_ALREADY_REPORTED: 208,
    HTTP_STATUS_IM_USED: 226,
};

export const HTTP_STATUS_REDIRECT_CODES = {
    HTTP_STATUS_TEMPORARY_REDIRECT: 307,
};

export const HTTP_STATUS_CLIENT_ERROR_CODES = {
    HTTP_STATUS_BAD_REQUEST: 400,
};

export const CALL_API_METHODS = {
    GET: "get",
    POST: "post",
};

export const EXLY_BACKEND_API_HOST = `${process.env.NEXT_PUBLIC_API_URL}`;

/* common apis used accross features */
export const COMMON_API_URLS = {
    // Theme
    GET_HOST_THEME: `${EXLY_BACKEND_API_HOST}/host/theme/active`, // theme colors should be `colors || theme.color_codes`

    // bookings
    BOOKING_DETAILS: `${EXLY_BACKEND_API_HOST}/payments/exly/booking`,
};
