import { booking_initial_user_id } from "repoV2/features/CheckoutPages/constants/CheckoutPages.constants";
import {
    DEFAULT_PAYMENT_CARD_TYPE,
    DEFAULT_PAYMENT_METHOD,
} from "repoV2/features/Payments/Payments.constants";
import { IFormDataAction, IFormDataState } from "../interfaces/FormData";
import { ACTION_TYPES, FORM_KEYS } from "../FormData.constants";
import { removeObjectKeyData, setObjectKeyData } from "./helpers";

export const form_initial_state: IFormDataState = {
    [FORM_KEYS.user_details]: {},
    [FORM_KEYS.listing_details]: {},
    [FORM_KEYS.timezone_details]: {},
    [FORM_KEYS.express_interest]: {},
    [FORM_KEYS.delivery_details]: {},
    // Todo: Move this from this reducer to the checkout page reducer,
    // and rename 'preEditUserDetails' to 'initialEditUserDetails'
    [FORM_KEYS.multiple_users_helpers]: {
        // allUserIds consists of the userId for the users of which we have validated data,
        // The total customers for price calculations in checkout is dependent upon its length
        allUserIds: [
            {
                userId: booking_initial_user_id,
                userIdBase: 1,
            },
        ],
        // In multiple users usecase, If user edit the data of a user, then instead of saving
        // the modal is closed, the we need to reset the react-hook-form state and the redux state
        // for that user. This state sotres the pre edit data for that user, also the id of the
        // user to edit
        preEditUserDetails: {
            userIdToEdit: "",
            userRefsData: {},
            userReduxData: {},
        },
        // User Details Modal, open and close state
        isUserDetailsFormOpen: false,
    },
    [FORM_KEYS.payment_details]: {
        paymentMethod: DEFAULT_PAYMENT_METHOD,
        paymentCalculationType: DEFAULT_PAYMENT_CARD_TYPE,
    },
};

export default function formDataReducer(
    state = form_initial_state,
    { type, payload }: IFormDataAction
): IFormDataState {
    switch (type) {
        case ACTION_TYPES.SET_FORM_DATA: {
            const newState = setObjectKeyData({
                dataObj: state,
                key: payload.formKey,
                payload: payload.formData,
            });
            return newState;
        }
        case ACTION_TYPES.RESET_FORM_DATA: {
            const newState = setObjectKeyData({
                dataObj: state,
                key: payload.formKey,
                payload: {},
            });
            return newState;
        }
        case ACTION_TYPES.REMOVE_FORM_DATA: {
            const newState = removeObjectKeyData({
                dataObj: state,
                key: payload.formKey,
            });
            return newState;
        }
        default:
            return state;
    }
}
