import dynamic from "next/dynamic";

import { Keywords } from "./Keywords";
import { Navbar } from "./Navbar";

const HTMLHeader = dynamic(() => import("./HTMLHeader"));

/**
 * @deprecated use `repoV2/components/common/LoadingIndicators/index.tsx`
 */
const LoadingSpinner = dynamic(
    () => import("repoV2/components/common/LoadingIndicators/LoadingSpinner")
);

/**
 * @deprecated use `repoV2/components/common/LoadingIndicators/index.tsx`
 */
const Spinner = dynamic(
    () => import("repoV2/components/common/LoadingIndicators/Spinner")
);
const VideoPlayer = dynamic(
    () => import("../../../repoV2/components/common/VideoPlayer/VideoPlayer")
);
const ContactWidget = dynamic(() => import("./ContactWidget"));
const SelectTimezone = dynamic(() => import("./SelectTimezone"));

export { CarouselCustomDot } from "./CarouselCustomDot";
export { Button } from "./Buttons";
export { LoadingIndicator } from "./LoadingIndicators";
export * from "./Popups";
export { CommentsSection } from "./CommentsSection";
export { BlogPostCard } from "./BlogPostCard";
export {
    HTMLHeader,
    LoadingSpinner,
    Spinner,
    VideoPlayer,
    Keywords,
    Navbar,
    ContactWidget,
    SelectTimezone,
};
