import {
    EXLY_CREATOR_CUSTOM_DOMAIN_REGEX,
    EXLY_CREATOR_DOMAIN_REGEX,
} from "repoV2/constants/urls&routing/domain";
import { isBrowser } from "../common/render/window";
import { checkIsStringEmpty } from "../common/dataTypes/string";

/**
 * - pass `window.location.host` or
 * - it wont work on server side, if no argument is passed since there is no `window` object
 */
export function getCreatorSubdomainFromUrlHost(hostArg?: string) {
    // return, if no argument is passed since there is no `window` object on server side
    if (!isBrowser() && checkIsStringEmpty(hostArg)) {
        return undefined;
    }
    const host = hostArg || window.location.host;
    if (host && EXLY_CREATOR_DOMAIN_REGEX.test(host)) {
        return host.split(".")[0];
    }
    // Handling for custom user domains
    if (EXLY_CREATOR_CUSTOM_DOMAIN_REGEX.test(host || "")) {
        const hostArr = host?.split(".");
        if (hostArr && hostArr?.length >= 2) {
            return hostArr[hostArr[0] === "www" ? 1 : 0];
        }
    }
}

/**
 * Top-level domain - https://en.wikipedia.org/wiki/Top-level_domain
 * Have only added these domains because there were many false leads being created with incorrect email id
 */
export const ALLOWED_EMAIL_TOP_LEVEL_DOMAINS = [
    ".com",
    ".co",
    ".org",
    ".net",
    ".cc",
    ".co.in",
    ".co.th",
    ".in",
    ".gov",
    ".uk",
    ".us",
    ".edu",
    ".edu.in",
    ".si",
    ".ca",
    ".com.sg",
    ".io",
    ".com.au",
    ".de",
    ".me",
    ".jetzt",
    ".com.my",
    ".co.za",
    ".club",
];
