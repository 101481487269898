import firebase from "firebase/app";
import "firebase/messaging";
import { STORAGE_KEYS } from "./constants";
import { getLocalStorageItem, setLocalStorageItem } from "../repoV2/utils/common/storage/getterAndSetters";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

const firebaseCloudMessaging = {
  //initializing firebase app
  init: async function () {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      try {
        const messaging = firebase.messaging();

        // requesting notification permission from browser
        const status = await Notification.requestPermission();
        let fcmToken = getLocalStorageItem(STORAGE_KEYS.FCM_TOKEN);

        if (status && status === "granted") {
          if (!fcmToken) {
            fcmToken = await messaging.getToken();
            if (fcmToken) {
              setLocalStorageItem(STORAGE_KEYS.FCM_TOKEN, fcmToken);
            }
          }
        }

        return fcmToken;
      } catch (error) {
        console.log("firebaseCloudMessaging init error: ", error);
      }
    }
  },
};
export { firebaseCloudMessaging };
