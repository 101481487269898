import { URL_SEARCH_PARAMS } from "repoV2/constants/urls&routing/urlParams";
import {
    APP_ROUTE_KEYS,
    ROUTE_BASE_PATHNAMES,
} from "repoV2/constants/urls&routing/routing";
import { getAllUtmParams } from "repoV2/features/Common/modules/URLsNRouting/URLsNRouting.utils";
import { isBrowser } from "../common/render/window";
import { getQueryParam } from "../urls&routing/urlParams";
import { checkIsObjectEmpty } from "../common/dataTypes/object";

export const getReturnUrl = ({
    isDirectBooking,
    eventId = null,
    from = "", // using in checkout-page, to redirect the user back to checkout after Go Back on failure payment page
}: {
    isDirectBooking: boolean;
    customPrice?: number | null;
    eventId?: string | null;
    from?: string;
}): string | null => {
    if (!isBrowser()) return null;
    const queryParams: Partial<Record<keyof typeof URL_SEARCH_PARAMS, any>> = {
        [URL_SEARCH_PARAMS.DYNAMIC_LINK]: getQueryParam(
            URL_SEARCH_PARAMS.DYNAMIC_LINK
        ) as string,
        [URL_SEARCH_PARAMS.COMPACT]: getQueryParam(
            URL_SEARCH_PARAMS.COMPACT
        ) as string,
        [URL_SEARCH_PARAMS.RENEW_BOOKING]: getQueryParam(
            URL_SEARCH_PARAMS.RENEW_BOOKING
        ) as string,
        ...(isDirectBooking && {
            [URL_SEARCH_PARAMS.INIT_BOOKING]: getQueryParam(
                URL_SEARCH_PARAMS.INIT_BOOKING
            ) as string,
        }),
        [URL_SEARCH_PARAMS.FORCE_INTERNATIONAL]: getQueryParam(
            URL_SEARCH_PARAMS.FORCE_INTERNATIONAL
        ) as string,

        // Whether this payment is being made for a plan
        ...(getQueryParam(URL_SEARCH_PARAMS.PLAN_ID) && {
            [URL_SEARCH_PARAMS.PLAN_ID]: getQueryParam(
                URL_SEARCH_PARAMS.PLAN_ID
            ),
        }),
        [URL_SEARCH_PARAMS.COMMUNITY_ID]: getQueryParam(
            URL_SEARCH_PARAMS.COMMUNITY_ID
        ) as string,

        ...(from && {
            [URL_SEARCH_PARAMS.FROM]: encodeURIComponent(from),
        }),

        ...getAllUtmParams(),
    };

    const queryString: string = Object.entries<typeof queryParams>(queryParams)
        .filter(([, val]: any) => !checkIsObjectEmpty(val))
        .map(([key, val]: any) => `${key}=${val}`)
        .reduce((accum: string, curr: string) => `${accum}&${curr}`, "");

    const returnSubRoute = `${eventId}${
        ROUTE_BASE_PATHNAMES[APP_ROUTE_KEYS.THANKYOU_PURCHASE]
    }`;

    const returnUrl = `${window.location.protocol}//${
        window.location.host
    }/${returnSubRoute}${queryString.length > 0 ? "?" : ""}${queryString}`;
    return returnUrl;
};
