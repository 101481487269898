import { LOCAL_STORAGE_KEYS } from "repoV2/constants/common/storage/localStorage";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "../common/storage/getterAndSetters";

interface ILoggedInUserDetails {
    uuid?: string;
    fullName?: string;
    email?: string;
    phone?: string;
    country?: string;
    countryCode?: string;
}

export const getLoggedInUserDetails = (): ILoggedInUserDetails =>
    getLocalStorageItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER_DETAILS) || {};

export const setLoggedInUserDetails = (data: ILoggedInUserDetails) =>
    setLocalStorageItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER_DETAILS, data);
