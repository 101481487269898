import { IUser } from "@Interfaces";
import { FORM_FIELDS_KEYS } from "@Utils";
import React from "react";
import { DateInput, DropdownSelector, TextAreaInput, TextInput } from "..";
import { IDropdownSelector } from "../FormInputs/DropdownSelector/IDropdownSelector";

export const FormField = ({ field }: { field: IUser.IFormField }) => {
    const {
        style,
        styles,
        className,
        onChange,
        onBlur,
        placeholder,
        error,
        showError,
        editingDisabled,
    } = field;
    let value;
    switch (field.key) {
        case FORM_FIELDS_KEYS.country_code:
            value = field.options?.find(
                (option: IDropdownSelector.Option) =>
                    field.value === option.dial_code
            );
            break;
        default:
            value = field.value;
            break;
    }
    const name = field.key;

    const commonProps = {
        style,
        styles,
        className,
        placeholder,
        value,
        onChange,
        onBlur,
        name,
        error: error && showError,
        editingDisabled,
    };

    switch (field.inputType) {
        case "text":
        case "email":
        case "tel":
            return <TextInput type={field.inputType} {...commonProps} />;
        case "date":
            return <DateInput {...commonProps} />;
        case "textarea":
            return <TextAreaInput {...commonProps} />;
        case "select":
            return (
                <DropdownSelector options={field.options} {...commonProps} />
            );
        default:
            return null;
    }
};
