import dynamic from "next/dynamic";

import CalendarQuestion from "./CalendarQuestion";
import MultiSelectQuestion from "./MultiSelectQuestion";
import SingleSelectQuestion from "./SingleSelectQuestion";
import PararaphTextQuestion from "./PararaphTextQuestion";
import ShortTextQuestion from "./ShortTextQuestion";

const FileUpload = dynamic(() => import("./FileUpload"));

export {
    FileUpload,
    CalendarQuestion,
    MultiSelectQuestion,
    SingleSelectQuestion,
    PararaphTextQuestion,
    ShortTextQuestion,
};
