import { fork, takeEvery } from "redux-saga/effects";

import { QUERY_PARAMS, getQueryParam } from "@Utils";

import { NODE_ENV_IS_NOT_PRODUCTION } from "repoV2/constants/environment";
import api from "./api";
import cart from "./cart";
import event from "./event";
import utils from "./utils";

function* logSagas(data: any) {
    if (NODE_ENV_IS_NOT_PRODUCTION) {
        // Enable logging in dev mode if the appropriate query param is present
        if (getQueryParam(QUERY_PARAMS.ENABLE_LOGGING)) {
            // eslint-disable-next-line no-console
            console.log("[Redux Saga log]:", data);
        }
    }
    yield;
}

export default function getSagas() {
    return function* rootSaga() {
        yield fork(api);
        yield fork(utils);
        yield fork(event);
        yield fork(cart);
        yield takeEvery("*", logSagas);
    };
}
