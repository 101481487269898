import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import { ICarouselCustomDotProps } from "repoV2/components/common/Carousel/CarouselCustomDot/CarouselCustomDot.interfaces";
import { CarouselCustomDot as CarouselCustomDotBase } from "repoV2/components/common/Carousel/CarouselCustomDot/CarouselCustomDot";
import styles from "./CarouselCustomDot.module.scss";

/**
 * @deprecated - use `repoV2/components/common/Carousel/CarouselCustomDot/CarouselCustomDot`\
 * why? - this is deprectaed to keep this component independet of theming.\
 * let the parent component decide the the color schemes to be used.
 */
export const CarouselCustomDot = ({
    design,
    applyTheme,
    className,
    ...props
}: ICarouselCustomDotProps & { applyTheme?: boolean }) => {
    const baseClasses = classNames(
        className,
        design !== "custom" && applyTheme && styles.applyTheme
    );

    return (
        <CarouselCustomDotBase
            className={baseClasses}
            design={design}
            {...props}
        />
    );
};

CarouselCustomDot.defaultProps = {
    applyTheme: false,
};
