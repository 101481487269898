import React from "react";

export const EmojiRating2Svg = (props: any = {}): JSX.Element => {
    const { fill } = props;
    return (
        <svg
            {...props}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 16C4.00119 12.2823 5.38295 8.69746 7.87732 5.94072C10.3717 3.18398 13.8009 1.45177 17.5 1.08C17.0017 1.02789 16.501 1.00119 16 1C12.0218 1 8.20644 2.58035 5.3934 5.3934C2.58035 8.20644 1 12.0218 1 16C1 19.9782 2.58035 23.7936 5.3934 26.6066C8.20644 29.4196 12.0218 31 16 31C16.501 30.9988 17.0017 30.9721 17.5 30.92C13.8009 30.5482 10.3717 28.816 7.87732 26.0593C5.38295 23.3025 4.00119 19.7177 4 16Z"
                fill="black"
                fillOpacity="0.1"
            />
            <path
                d="M16 32C12.8355 32 9.74207 31.0616 7.11088 29.3035C4.4797 27.5454 2.42894 25.0466 1.21793 22.1229C0.0069325 19.1993 -0.309921 15.9823 0.307443 12.8786C0.924806 9.77487 2.44866 6.92394 4.6863 4.6863C6.92394 2.44866 9.77487 0.924806 12.8786 0.307443C15.9823 -0.309921 19.1993 0.0069325 22.1229 1.21793C25.0466 2.42894 27.5454 4.4797 29.3035 7.11088C31.0616 9.74207 32 12.8355 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32ZM16 2.00001C13.2311 2.00001 10.5243 2.82109 8.22202 4.35943C5.91974 5.89777 4.12532 8.08427 3.06569 10.6424C2.00607 13.2006 1.72882 16.0155 2.26901 18.7313C2.80921 21.447 4.14258 23.9416 6.10051 25.8995C8.05845 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C30 12.287 28.525 8.72602 25.8995 6.10051C23.274 3.475 19.713 2.00001 16 2.00001Z"
                fill={fill || "#B3B3B3"}
            />
            <path
                d="M16.0018 21.0001C15.2018 20.9944 14.4151 21.1856 13.7259 21.5535C13.0366 21.9214 12.4708 22.452 12.089 23.0886C12.0452 23.1575 12.0168 23.2336 12.0056 23.3125C11.9943 23.3914 12.0002 23.4715 12.0231 23.5483C12.046 23.6251 12.0854 23.697 12.139 23.76C12.1926 23.823 12.2594 23.8759 12.3356 23.9155C12.4117 23.9551 12.4958 23.9808 12.5829 23.991C12.67 24.0012 12.7585 23.9958 12.8433 23.9751C12.9281 23.9544 13.0075 23.9187 13.0771 23.8702C13.1467 23.8216 13.205 23.7611 13.2488 23.6922C13.5209 23.2469 13.9198 22.8757 14.4039 22.6172C14.888 22.3588 15.4398 22.2224 16.0018 22.2224C16.5639 22.2224 17.1156 22.3588 17.5998 22.6172C18.0839 22.8757 18.4828 23.2469 18.7548 23.6922C18.8127 23.7854 18.8969 23.863 18.9988 23.9171C19.1007 23.9712 19.2167 23.9998 19.3348 24C19.4485 23.9998 19.5605 23.9749 19.6614 23.9276C19.815 23.849 19.9279 23.7184 19.9754 23.5645C20.0229 23.4106 20.0011 23.246 19.9147 23.1067C19.5355 22.4667 18.9708 21.9324 18.2814 21.5613C17.592 21.1901 16.8039 20.9961 16.0018 21.0001Z"
                fill={fill || "#B3B3B3"}
            />
            <path
                d="M13.2162 12.865C13.1643 12.7944 13.1075 12.7276 13.0461 12.6651C12.6685 12.1845 12.1824 11.8001 11.6277 11.5435C11.073 11.2868 10.4653 11.1653 9.85457 11.1887C9.2438 11.2121 8.64726 11.3798 8.1138 11.6782C7.58034 11.9765 7.12513 12.397 6.78545 12.9051L6.66019 13.0983C6.53466 13.3169 6.49633 13.5748 6.55287 13.8205C6.60942 14.0662 6.75666 14.2814 6.96512 14.4232C7.17359 14.5649 7.4279 14.6227 7.67715 14.5849C7.92639 14.5472 8.15219 14.4167 8.30933 14.2196L8.44383 14.0226C8.66705 13.6776 8.99986 13.4179 9.38866 13.2851C9.77746 13.1523 10.1996 13.1542 10.5872 13.2905C10.5567 13.3762 10.5356 13.4649 10.5243 13.5551C10.4963 13.7491 10.5081 13.9468 10.5592 14.1361C10.6102 14.3253 10.6994 14.5021 10.8211 14.6557C10.9405 14.8145 11.0905 14.9476 11.2624 15.0471C11.4343 15.1467 11.6244 15.2106 11.8215 15.2351C12.0792 15.2671 12.3409 15.2325 12.5814 15.1347C12.8252 15.0342 13.038 14.8709 13.198 14.6613C13.358 14.4517 13.4595 14.2034 13.4922 13.9418C13.5193 13.753 13.5089 13.5607 13.4615 13.3759C13.4141 13.1912 13.3308 13.0175 13.2162 12.865Z"
                fill={fill || "#B3B3B3"}
            />
            <circle cx="11.5" cy="13.5" r="1.5" fill={fill || "#B3B3B3"} />
            <path
                d="M25.2162 12.865C25.1643 12.7944 25.1075 12.7276 25.0461 12.6651C24.6685 12.1845 24.1824 11.8001 23.6277 11.5435C23.073 11.2868 22.4653 11.1653 21.8546 11.1887C21.2438 11.2121 20.6473 11.3798 20.1138 11.6782C19.5803 11.9765 19.1251 12.397 18.7855 12.9051L18.6602 13.0983C18.5347 13.3169 18.4963 13.5748 18.5529 13.8205C18.6094 14.0662 18.7567 14.2814 18.9651 14.4232C19.1736 14.5649 19.4279 14.6227 19.6771 14.5849C19.9264 14.5472 20.1522 14.4167 20.3093 14.2196L20.4438 14.0226C20.6671 13.6776 20.9999 13.4179 21.3887 13.2851C21.7775 13.1523 22.1996 13.1542 22.5872 13.2905C22.5567 13.3762 22.5356 13.4649 22.5243 13.5551C22.4963 13.7491 22.5081 13.9468 22.5592 14.1361C22.6102 14.3253 22.6994 14.5021 22.8211 14.6557C22.9405 14.8145 23.0905 14.9476 23.2624 15.0471C23.4343 15.1467 23.6244 15.2106 23.8215 15.2351C24.0792 15.2671 24.3409 15.2325 24.5814 15.1347C24.8252 15.0342 25.038 14.8709 25.198 14.6613C25.358 14.4517 25.4595 14.2034 25.4922 13.9418C25.5193 13.753 25.5089 13.5607 25.4615 13.3759C25.4141 13.1912 25.3308 13.0175 25.2162 12.865Z"
                fill={fill || "#B3B3B3"}
            />
            <circle cx="23.5" cy="13.5" r="1.5" fill={fill || "#B3B3B3"} />
        </svg>
    );
};
