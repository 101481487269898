import React from "react";
import { IHost } from "@Interfaces";
import { ROUTES } from "@Utils";
import { NextRouter } from "next/router";
import { INavbar } from "../INavbar";
import { IAccount } from "../components/Account/IAccount";
import { getAccountProps } from "../components/Account/getAccountProps";
import { getNavbarTemplate } from "./getNavbarTemplate";
import { getNavbarItems } from "./getNavbarItems";

export const getNavbarInternalProps = ({
    host,
    hostData,
    setIsMobHamMenuOpen,
    isOfferingsDropdownOpen,
    setIsOfferingsDropdownOpen,
    router,
    preview,
    handlePreviewPopup,
    logoutUser,
    template,
    loggedInState,
    onGetInTouchClick,
}: {
    host: IHost.IStore;
    hostData?: IHost.IHostData;
    setIsMobHamMenuOpen: (value: React.SetStateAction<boolean>) => void;
    isOfferingsDropdownOpen: boolean;
    setIsOfferingsDropdownOpen: (value: React.SetStateAction<boolean>) => void;
    router: NextRouter;
    preview?: boolean;
    handlePreviewPopup?: () => void;
    logoutUser: () => void;
    template?: string;
    loggedInState: INavbar.ILoggedInState;
    onGetInTouchClick?: () => void;
}): INavbar.IInternalProps => {
    const currentPathIsHostPage = () =>
        router.pathname === `/${ROUTES.HOST.filePath}`;

    const navbarItems = getNavbarItems({
        template,
        preview,
        host,
        hostData,
        router,
        logoutUser,
        currentPathIsHostPage,
        setIsMobHamMenuOpen,
        isOfferingsDropdownOpen,
        setIsOfferingsDropdownOpen,
        handlePreviewPopup,
        loggedInState,
        onGetInTouchClick,
    });

    const NavbarTemplate: React.ComponentType<INavbar.IChildProps> =
        getNavbarTemplate({ template });

    const accountProps: IAccount.IBaseProps = getAccountProps({
        loggedInState,
        navbarItems,
    });

    return {
        navbarItems,
        currentPathIsHostPage,
        NavbarTemplate,
        accountProps,
    };
};
